import styled, { css } from 'styled-components';
import media from 'styled-media-query';

export const Container = styled.div`
  ${({ theme }) => css`
    padding: ${theme.spacings.medium};
    border-radius: 5px;
    background-color: ${theme.colors.white};
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);

    display: flex;
    flex-direction: column;
    gap: ${theme.spacings.small};
  `}
`

export const Label = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.fonts.sizes.small};
    font-family: ${theme.fonts.bold};
    color: ${theme.colors.gray.medium};

    ${media.lessThan('small')`
      font-size: ${theme.fonts.sizes.xsmall};
    `}
  `}
`