import styled from 'styled-components'

import { fonts, colors } from 'styles'

export const Container = styled.div``

export const styles = {
  control: (provided) => ({
    ...provided,
    minWidth: '250px',
    fontFamily: fonts.medium,
    border: `2px solid ${colors.gray.medium}`,
    borderRadius: '8px',
    fontSize: '12px',
  }),
  placeholder: (provided) => ({
    ...provided,
    fontSize: '12px',
  }),
  option: (provided) => ({
    ...provided,
    fontSize: '12px',
    fontFamily: fonts.medium,
  }),
}

export const Error = styled.p`
  padding: 6px 10px 0;

  font-size: 12px;
  font-family: ${({ theme }) => theme.fonts.medium};

  color: ${({ theme }) => theme.colors.danger};
`
