export const PROPOSAL_KM_AND_PERIOD_OPTIONS = [
  {
    month: 3,
    milages: [{ km: 3 }, { km: 4.5 }, { km: 6 }, { km: 7.5 }],
  },
  {
    month: 6,
    milages: [{ km: 6 }, { km: 12 }, { km: 15 }],
  },
]

export const PERIOD_OPTIONS = [
  {
    value: 3,
    label: '3 meses',
  },
  {
    value: 6,
    label: '6 meses',
  },
]
export const STATE_OPTIONS = [
  {
    value: 'sp',
    label: 'São Paulo',
  },
  {
    value: 'rj',
    label: 'Rio de Janeiro',
  },
  {
    value: 'pr',
    label: 'Paraná',
  },
  {
    value: 'mg',
    label: 'Minas Gerais',
  },
  {
    value: 'df',
    label: 'Distrito Federal',
  },
  {
    value: 'go',
    label: 'Goiás',
  },
  {
    value: 'mt',
    label: 'Mato Grosso',
  },
]

export const PROPOSAL_PLATE_OPTIONS = [
  { value: 'indifferent', label: 'Indiferente' },
  { value: '1,2', label: '1 ou 2' },
  { value: '3,4', label: '3 ou 4' },
  { value: '5,6', label: '5 ou 6' },
  { value: '7,8', label: '7 ou 8' },
  { value: '9,0', label: '9 ou 0' },
]
