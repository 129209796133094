import React, { useEffect, useRef, useState } from 'react'
import { Icon, Button, Tip } from 'components'
import { useOutsideClick, useWindowWidth } from 'hooks'

import { Controller, ErrorMessage, useFormContext } from 'react-hook-form'
import ptBR from 'date-fns/locale/pt-BR'

import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import * as theme from 'styles'
import * as S from './styles'
import { formatPtBRDate } from 'utils'

export default function DateRangePicker({
  name,
  label,
  onClick,
  initialState,
  ...props
}) {
  const { errors, getValues, setValue, control } = useFormContext()
  const [hasValue, setHasValue] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [iconName, setIconName] = useState('calendar')
  const [isClearIcon, setIsClearIcon] = useState(false)

  const width = useWindowWidth()
  const dropdownRef = useRef()

  const [dateRange, setDateRange] = useState(initialState)

  const OpenDropdown = () => {
    setIsOpen(true)
    setIconName('chevron-down')
  }
  const CloseDropdown = () => {
    setIsOpen(false)
    setIconName('calendar')
  }

  const currentValue = getValues()[name]

  const handleChange = (e) => {
    e.target.value ? setHasValue(true) : setHasValue(false)
  }

  const ShowClearIcon = () => {
    hasValue && setIsClearIcon(true)
  }

  const HideClearIcon = () => {
    hasValue && setIsClearIcon(false)
  }

  useEffect(() => {
    currentValue ? setHasValue(true) : setHasValue(false)
  }, [currentValue])

  useOutsideClick(dropdownRef, () => {
    CloseDropdown()
  })

  return (
    <S.Container hasValue={hasValue} isOpen={isOpen} ref={dropdownRef}>
      <S.Label>{label}</S.Label>
      <S.Content onMouseEnter={ShowClearIcon} onMouseLeave={HideClearIcon}>
        <Controller
          as={
            <S.Input
              {...props}
              onClick={OpenDropdown}
              autoComplete="off"
              readOnly
            />
          }
          defaultValue=""
          name={name}
          control={control}
          onChange={([e]) => handleChange(e)}
        />
        <S.IconWrapper isOpen={isOpen} isClearIcon={isClearIcon}>
          {isClearIcon ? (
            <Tip
              size="18px"
              color={theme.colors.dangerDark}
              icon="x-circle"
              place="right"
              onClick={() => {
                setDateRange({
                  startDate: new Date(),
                  endDate: new Date(),
                  key: 'selection',
                })
                HideClearIcon()
                setValue(name, '')
                CloseDropdown()
                onClick()
              }}
            >
              Limpar seleção
            </Tip>
          ) : (
            <Icon name={iconName} />
          )}
        </S.IconWrapper>
      </S.Content>
      <ErrorMessage errors={errors} name={name} as={<S.Error />} />

      <S.Dropdown>
        <S.DateRange
          onChange={(item) => {
            setDateRange(item.selection)
          }}
          color={theme.colors.primary}
          rangeColors={[theme.colors.primary]}
          moveRangeOnFirstSelection={true}
          months={width < 768 ? 1 : 2}
          ranges={[dateRange]}
          direction="horizontal"
          locale={ptBR}
          showDateDisplay={false}
        />
        <S.ButtonContainer>
          <Button
            link
            onClick={CloseDropdown}
            data-gtm-type="click"
            data-gtm-clicktype="button"
            data-gtm-name="cancelar"
          >
            Cancelar
          </Button>
          <Button
            data-gtm-type="click"
            data-gtm-clicktype="button"
            data-gtm-name="aplicar"
            variant="outline"
            onClick={() => {
              const formattedStartDate = formatPtBRDate(dateRange.startDate)
              const formattedEndDate = formatPtBRDate(dateRange.endDate)
              setValue(name, `${formattedStartDate} - ${formattedEndDate}`)
              CloseDropdown()
              onClick()
            }}
          >
            Aplicar
          </Button>
        </S.ButtonContainer>
      </S.Dropdown>
    </S.Container>
  )
}
