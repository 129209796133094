import styled, { css } from 'styled-components'
import media from 'styled-media-query'

export const Container = styled.div`
  ${({ theme }) => css`
    padding: ${theme.spacings.small};
    max-width: 1440px;
    margin-inline: auto;

    animation-name: slide;
    animation-duration: 1s;
    transform: translateY(-50%);
    animation-fill-mode: forwards;

    @keyframes slide {
      from {
        opacity: 0;
      }

      to {
        transform: translateY(0);
        opacity: 1;
      }
    }
  `}
`

export const Content = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    gap: ${theme.spacings.medium};
  `}
`

export const Form = styled.form`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: ${theme.spacings.small};

    ${media.lessThan('medium')`
      grid-template-columns: repeat(2, 1fr);
    `}

    ${media.lessThan('small')`
      grid-template-columns: repeat(1, 1fr);
    `}
  `}
`

export const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: ${({ theme }) => theme.spacings.medium};

  ${({ isUpToDate }) =>
    !isUpToDate &&
    css`
      justify-content: flex-end;
    `}

  ${media.lessThan('small')`
      flex-direction: column;
      align-items: stretch;
      gap: 
      ${({ theme }) => theme.spacings.small};
    `}
`

export const Error = styled.p`
  ${({ theme }) => css`
    padding-top: ${theme.spacings.small};

    font-size: ${theme.fonts.sizes.xxsmall};
    color: ${theme.colors.danger};
    font-family: ${theme.fonts.bold};

    ${media.lessThan('large')`
      font-size: ${theme.fonts.sizes.xxxsmall};
    `}
  `}
`

export const Loader = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: ${({ theme }) => theme.layers.modal};
  pointer-events: none;
  content: '';

  &:before {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    width: 50px;
    height: 50px;

    content: '';
    background-image: url('/images/loader.svg');
    background-size: contain;
  }
`
