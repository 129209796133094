import styled from 'styled-components'
import media from 'styled-media-query'
import { rgba, rem } from 'polished'
import styledMap from 'styled-map'

import colors from 'styles/colors'

const flagTheme = styledMap('color', {
  ...colors,
  default: colors.gray.medium,
})

export const Container = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: ${({ theme }) => theme.layers.modal};

  display: flex;
  align-items: center;
  justify-content: center;

  overflow-y: auto;
`

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  background-color: ${rgba('#000', 0.96)};
`

export const Dialog = styled.div`
  position: fixed;
  z-index: ${({ theme }) => theme.layers.modal};

  padding: 40px 20px 20px;

  text-align: center;
  border-radius: 10px;
  background-color: #fff;

  ${media.greaterThan('large')`
    width: 980px;
    padding: 90px 60px 60px;
  `}

  ${media.lessThan('large')`
    margin: 20px;
  `}
`

export const Flag = styled.div`
  position: absolute;
  top: 20px;
  left: -20px;

  padding: 8px 20px;

  color: #fff;
  border-radius: 30px;
  font-size: ${rem('12px')};
  background-color: ${flagTheme};
  font-family: ${({ theme }) => theme.fonts.bold};

  ${media.lessThan('large')`
    top: -10px;
    left: 50%;
    transform: translateX(-50%);
  `}

  ${media.lessThan('large')`
    font-size: ${rem('10px')};
  `}
`

export const Content = styled.div``

export const Actions = styled.div`
  display: flex ;
  justify-content: center;
  gap: 1rem ;
  flex-wrap: wrap; 
`
