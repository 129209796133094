import { createSlice } from '@reduxjs/toolkit'
import { USER_ROLE_BR } from 'helpers'

import * as rehydrate from 'store/helpers/rehydrate'
import { encryptTextWithKey } from 'utils'

import { handleLoginAndSetUserInfos } from './middlewares'
const dataLayer = window.dataLayer || []

const user = createSlice({
  name: 'users',

  initialState: {
    isLoading: false,
    error: '',
    showUpdateUserModal: false,
    isUpToDate: true,
    payload: {
      id: '',
      role: '',
      token: '',
      infos: {
        name: '',
        email: '',
      },
    },
  },

  reducers: {
    setLogout: (state) => {
      state.showUpdateUserModal = false
      state.isUpToDate = true
      state.payload = {
        id: '',
        role: '',
        token: '',
        infos: {
          name: '',
          email: '',
        },
      }
    },
    setShowUpdateUserModal: (state, action) => {
      state.showUpdateUserModal = action.payload
    },

    updateNameAndEmail: (state, action) => {
      state.payload.infos = {
        name: action.payload.name,
        email: action.payload.name,
      }
    },
  },

  extraReducers: {
    [handleLoginAndSetUserInfos?.rejected]: (state, action) => {
      state.isLoading = false
      state.error = action.error.message

      dataLayer.push({
        event: 'login',
        retorno: 'erro',
        descricao: 'ocorreu um erro ao realizar o lgin',
      })
    },

    [handleLoginAndSetUserInfos?.pending]: (state) => {
      state.isLoading = true
      state.error = ''
    },

    [handleLoginAndSetUserInfos?.fulfilled]: (state, action) => {
      const { token, user } = action.payload
      const {
        external_id,
        role,
        customer,
        broker,
        administrator,
        adviser,
        isUpToDate,
      } = user

      state.isLoading = false
      state.error = ''
      state.isUpToDate = isUpToDate

      dataLayer.push({
        event: 'login',
        user_id: encryptTextWithKey(customer?.cpf?.number, 'chave'),
        id_plataforma: external_id,
        susep: broker?.susep || '',
        sucursal: adviser?.branch || '',
        perfil: USER_ROLE_BR[role.type],
        retorno: 'sucesso',
        descricao: 'Login feito com sucesso',
      })

      state.payload = {
        id: external_id,
        token,
        role: role.type,
        infos: {
          name: customer?.fullName,
          email: customer?.email,
          document: customer?.cpf?.number,
        },
        broker,
        administrator,
        adviser,
      }
    },

    ...rehydrate.resetLoadingAndErrorState('users'),
  },
})

export const {
  setInfos,
  setAuthenticated,
  setLogout,
  updateNameAndEmail,
  setShowUpdateUserModal,
} = user.actions

export default user.reducer
