import api from 'services'

export async function getNewsTags() {
  try {
    const { data } = await api.get('/cr_news_tags/news_tags')

    const tagOptions = data.map((tag) => ({
      value: tag.id,
      label: tag.name,
    }))

    return tagOptions
  } catch (error) {
    throw new Error('Ocorreu um problema, tente novamente mais tarde.')
  }
}

export async function uploadDesktopImages(images) {
  try {
    const { data } = await api.post('/cr_news/news/upload_banner', images, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })

    return data
  } catch (error) {
    throw new Error(
      'Ocorreu um erro ao fazer upload da imagem, tente novamente mais tarde.'
    )
  }
}

export async function uploadMobileImages(images) {
  try {
    const { data } = await api.post(
      '/cr_news/news/upload_mobile_banner',
      images,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    )

    return data
  } catch (error) {
    throw new Error(
      'Ocorreu um erro ao fazer upload da imagem, tente novamente mais tarde.'
    )
  }
}

export async function createNews(payload) {
  try {
    const response = await api.post('/cr_news/news', payload)

    return response
  } catch (error) {
    throw new Error(
      'Ocorreu um erro ao cadastrar sua novidade, tente novamente mais tarde.'
    )
  }
}

export async function updateNews({ id, data }) {
  try {
    const response = await api.patch(`/cr_news/news/${id}`, data)

    return response
  } catch (error) {
    throw new Error(
      'Ocorreu um erro ao cadastrar sua novidades, tente novamente mais tarde.'
    )
  }
}

export async function getOneNews(id) {
  try {
    const { data } = await api.get(`/cr_news/news/${id}`)

    const formattedData = {
      ...data,
      tags: {
        value: data.tags[0].id,
        label: data.tags[0].name,
      },
    }

    return formattedData
  } catch (error) {
    throw new Error('Ocorreu um problema, tente novamente mais tarde.')
  }
}

export async function getHighlightedNews() {
  try {
    const { data } = await api.get('/cr_news/news/search', {
      params: { highlighted: true, limit: 3 },
    })

    return data
  } catch (err) {
    throw new Error('Ocorreu um problema, tente novamente mais tarde.')
  }
}

export async function getLastNews(params) {
  try {
    const { data } = await api.get('/cr_news/news/search', {
      params: params,
    })

    return data
  } catch (err) {
    throw new Error('Ocorreu um problema, tente novamente mais tarde.')
  }
}

export async function getFavoriteNews() {
  try {
    const { data } = await api.get('/cr_favorite_news/list')

    const formattedData = data?.data?.map((news) => ({
      ...news,
      id: news._id,
      createdAt: news.created_at,
    }))

    return { data: formattedData, pagination: data?.pagination }
  } catch (err) {
    throw new Error('Ocorreu um problema, tente novamente mais tarde.')
  }
}

export async function favoriteNews({ newsId }) {
  try {
    const { data } = await api.post('/cr_favorite_news/user/add', { newsId })

    return data
  } catch (err) {
    throw new Error('Ocorreu um problema, tente novamente mais tarde.')
  }
}

export async function removefavoriteNews({ newsId }) {
  try {
    const { data } = await api.delete('/cr_favorite_news', { data: { newsId } })

    return data
  } catch (err) {
    throw new Error('Ocorreu um problema, tente novamente mais tarde.')
  }
}

export async function deleteNews(newsId) {
  try {
    const response = await api.delete(`/cr_news/news/${newsId}`)

    return response
  } catch (err) {
    throw new Error('Ocorreu um problema, tente novamente mais tarde.')
  }
}
