import React, { useEffect } from 'react'

import { Dialog } from '../common'

import { Grid, Input } from 'components'

export default function Broker({ forms, broker, consultant }) {
  useEffect(() => {
    forms.setValue([
      { 'broker.susep': broker?.susep },
      { 'broker.name': broker?.name?.toUpperCase() },
      { 'broker.email': broker?.email },
      { 'broker.phone': broker?.phone },
      { 'consultant.name': consultant?.name?.toUpperCase() },
      { 'consultant.email': consultant?.email },
      { 'broker.sucursal': broker?.sucursal },
    ])
  }, [broker, consultant]) // eslint-disable-line

  return (
    <Dialog title="4. Corretor">
      <Grid
        my={1}
        gridTemplateColumns={['1fr', '1fr 1fr', '1fr 1fr 1fr']}
        gridGap="16px"
        data-testid="bloco-corretor"
      >
        <Input name="broker.susep" placeholder="SUSEP" label="SUSEP" disabled />
        <Input
          name="broker.name"
          placeholder="Nome do Corretor"
          label="Nome do Corretor"
          disabled
        />

        <Input
          name="broker.email"
          placeholder="E-mail Corretor"
          label="E-mail Corretor"
          disabled
        />
        <Input
          name="broker.phone"
          placeholder="Telefone Corretor"
          label="Telefone Corretor"
          mask="## ####-####"
          disabled
        />
        <Input
          name="consultant.name"
          placeholder="Consultor"
          label="Consultor"
          disabled
        />

        <Input
          name="consultant.email"
          placeholder="E-mail Consultor"
          label="E-mail Consultor"
          disabled
        />
        <Input
          name="broker.sucursal"
          placeholder="Sucursal"
          label="Sucursal"
          disabled
        />
      </Grid>
    </Dialog>
  )
}
