import React, { useEffect, useState } from 'react'
import { FormContext, useForm } from 'react-hook-form'
import { useHistory, useParams } from 'react-router-dom'
import { AlertModal, Button, Input } from 'components'
import { editUser, editUserEmail, getUserById } from 'services'
import { USER_ROLES } from 'helpers'
import { useNotification } from 'hooks'
import EditForm from './components/EditForm'
import { schema, emailSchema } from './validation'

import * as S from './styles.js'

export default function Edit() {
  const [error, setError] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [user, setUser] = useState({})
  const [isOpen, setIsOpen] = useState(false)
  const notification = useNotification()

  const title = {
    broker: `ALTERAR CORRETOR: ${user.susep}`,
    adviser: `ALTERAR CONSULTOR: ${user.name}`,
  }

  const handleCloseModal = () => {
    setIsOpen(false)
  }

  const handleOpenModal = () => {
    setIsOpen(true)
  }

  const form = useForm({
    validationSchema: schema,
  })

  const formEditEmail = useForm({ validationSchema: emailSchema })

  const { id } = useParams()
  const history = useHistory()

  useEffect(() => {
    fetchUser(id)
  }, [id]) // eslint-disable-line

  const handleRouteNavigation = () => {
    if (history.action === 'PUSH') {
      history.goBack()

      return
    }

    history.push('/usuarios')
  }

  const fetchUser = async (id) => {
    try {
      const data = await getUserById(id)
      setUser({ ...data })

      form.setValue([
        { name: data.name },
        { email: data.email },
        { document: data.document },
        { branches: data.branches },
        { cellphone: data.cellphone },
      ])
    } catch (error) {
      setUser((prevState) => ({ ...prevState, error: error.message }))
    }
  }

  const onSubmit = form.handleSubmit(async (values) => {
    const branches = values.branches.split(',')
    const branchesLengthValidation = branches.every(
      (ele) => +ele.length > 1 && +ele.length < 4
    )

    const commaSeparetedIntegers = /^\d+(,\s?\d+)*$/

    if (user.role === USER_ROLES.BROKER && branches.length > 1) {
      form.setError(
        'branches',
        'manual',
        'Por favor insira somente uma sucursal'
      )
      return
    }

    if (
      !branchesLengthValidation &&
      commaSeparetedIntegers.test(values.branches)
    ) {
      form.setError(
        'branches',
        'manual',
        'Por favor insira uma sucursal válida'
      )
      return
    }

    const data = {
      name: values.name,
      document: values.document,
      branches: values.branches,
      cellphone: values?.cellphone.replace(/\s/g, ''),
    }

    try {
      setIsLoading(true)

      const response = await editUser(id, data)
      if (response.status === 200) {
        history.goBack()
        notification.open({
          type: 'success',
          content: `Usuário alterado com sucesso, por favor peça para o usuário com os dados alterados relogar!`,
        })
      }
    } catch (error) {
      setError(error.message)
    } finally {
      setIsLoading(false)
    }
  })

  const onSubmitEditEmail = formEditEmail.handleSubmit(async (values) => {
    handleCloseModal()
    try {
      setIsLoading(true)

      const response = await editUserEmail(id, values)

      if (response.status === 200) {
        form.setValue([{ email: values.email }])

        notification.open({
          type: 'success',
          content: `E-mail alterado com sucesso, por favor peça para o usuário com os dados alterados relogar!`,
        })
      }
    } catch (error) {
      setError(error.message)
    } finally {
      setIsLoading(false)
    }
  })

  const EditEmailModal = () => {
    return (
      <AlertModal
        isVisible={isOpen}
        flag={{ text: 'Alteração de e-mail' }}
        actions={{
          primary: {
            onClick: onSubmitEditEmail,
            label: 'Confirmar',
          },
          secondary: {
            onClick: handleCloseModal,
            label: 'Cancelar',
          },
        }}
      >
        <S.ModalBody>
          <S.LabelModal>
            Informe o novo e-mail desejado para este usuário:
          </S.LabelModal>
          <FormContext {...formEditEmail}>
            <S.Form>
              <Input
                name="email"
                label="E-mail"
                placeholder="Digite seu novo e-mail"
              />
            </S.Form>
          </FormContext>
        </S.ModalBody>
      </AlertModal>
    )
  }

  return (
    <S.Wrapper>
      <S.Container>
        <S.Content>
          <S.Box>
            <S.Label>{title[user.role]}</S.Label>

            <FormContext {...form}>
              <EditForm
                user={user}
                handleOpenModal={handleOpenModal}
                error={error}
              />
            </FormContext>

            <S.Actions>
              <Button color="gray" onClick={handleRouteNavigation}>
                Voltar
              </Button>

              <Button
                onClick={onSubmit}
                isLoading={isLoading}
                color="success"
                disabled={!!error || !!user.error}
              >
                Salvar alterações
              </Button>
            </S.Actions>

            {user.error && <S.Error>{user.error}</S.Error>}
            {error && <S.Error>{error}</S.Error>}
          </S.Box>
        </S.Content>
      </S.Container>
      {isOpen && EditEmailModal()}
    </S.Wrapper>
  )
}

Edit.properties = {
  metatags: {
    title: 'Editar usuário',
  },
}
