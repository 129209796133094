import React from 'react'
import { Status } from 'components'

import * as S from './styles'

function DrawerCustomers({ status }) {
  return (
    <S.ContentDrawer>
      <div>
        <S.Image src={status?.img} />
      </div>

      <div>
        <Status backgroundColor={status?.bgColor} textColor={status?.textColor}>
          {status?.label}
        </Status>

        <S.Description
          dangerouslySetInnerHTML={{
            __html: status?.description,
          }}
        />
      </div>
    </S.ContentDrawer>
  )
}

export default DrawerCustomers
