import React from 'react'
import { Allow, Icon } from 'components'
import { Tag } from '../components'
import { useDeleteNews, useGetOneNews } from 'services/hooks/News'
import { useNotification } from 'hooks'
import { queryClient } from 'services/queryClient'
import { useHistory, useParams } from 'react-router-dom'
import { formatPublishDate } from 'utils'
import { USER_ROLES } from 'helpers'

import * as S from './styles'

function View() {
  const { id } = useParams()
  const history = useHistory()
  const notification = useNotification()

  const { data, isLoading, isFetching } = useGetOneNews(id)

  const publishDate = formatPublishDate(data?.createdAt)

  const { mutate, isLoading: isLoadingDeleteNews } = useDeleteNews({
    onSuccess: () => {
      notification.open({
        type: 'success',
        content: `Novidade excluída com sucesso!`,
      })

      queryClient.invalidateQueries('last_news')
      history.push('/novidades')
    },
    onError: () => {
      notification.open({
        type: 'danger',
        content: `Ocorreu um erro ao excluir sua novidade, por favor tente novamente.`,
      })
    },
  })

  const handleDeleteNews = (newsId) => {
    if (!isLoadingDeleteNews) {
      mutate(newsId)
    }
  }

  return (
    <>
      <S.Divider />
      <S.Container>
        {isLoading || isFetching ? (
          <S.Loader />
        ) : (
          <S.Content>
            <S.Header>
              <S.BackButton type="button" onClick={() => history.goBack()}>
                <Icon name="chevron-left" />
                Voltar
              </S.BackButton>
              <Allow roles={[USER_ROLES.ADMINISTRATOR]}>
                <S.NewsActions>
                  <S.EditButton to={`/novidades/editar/${data?.id}`}>
                    <img src="/images/icons/edit.svg" alt="Editar novidade" />
                    Editar publicação
                  </S.EditButton>
                  <S.DeleteButton
                    onClick={() => handleDeleteNews(data?.id)}
                    isLoading={isLoadingDeleteNews}
                  >
                    <Icon
                      name={isLoadingDeleteNews ? 'loader' : 'trash2'}
                      size={24}
                    />
                    Excluir publicação
                  </S.DeleteButton>
                </S.NewsActions>
              </Allow>
            </S.Header>
            <S.News>
              <S.NewsHeader>
                <S.PublishDate>{publishDate}</S.PublishDate>

                <Tag>{data?.tags.label}</Tag>
              </S.NewsHeader>

              <S.NewsBody>
                <S.NewsContent>
                  <S.Title>{data?.title}</S.Title>
                  {!!data?.subTitle && (
                    <S.Subtitle>{data?.subTitle}</S.Subtitle>
                  )}

                  <S.Description
                    dangerouslySetInnerHTML={{
                      __html: data?.content,
                    }}
                  />
                </S.NewsContent>
              </S.NewsBody>
            </S.News>
          </S.Content>
        )}
      </S.Container>
    </>
  )
}

export default View

View.properties = {
  metatags: {
    title: 'Novidades',
  },
}
