import styled, { css } from 'styled-components'
import { rem } from 'polished'

export const Container = styled.div`
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  background: #fff;
  border-radius: 5px;
  margin-top: 1rem;
`
export const Title = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: ${rem('30px')};

  span {
    color: ${({ theme }) => theme.colors.gray.jet};
    font-family: ${({ theme }) => theme.fonts.bold};
    font-size: 16px;
    line-height: 20px;
  }
`

export const Arrow = styled.div`
  border: solid ${({ theme }) => theme.colors.gray.jet};
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 5px;

  transform: rotate(45deg);
  transition: transform 1s ease;

  ${({ isOpen }) =>
    isOpen &&
    css`
      transform: rotate(225deg);
    `}
`

export const Content = styled.div`
  overflow: hidden;
  max-height: 0;
  opacity: 0;
  transition: max-height 0.7s ease, opacity 0.5s ease 0.5s;

  ${({ isOpen }) =>
    isOpen &&
    css`
      max-height: 100vh;
      opacity: 1;
    `}
`

export const Wrap = styled.div`
  padding: ${rem('30px')};
  padding-top: 0;
`
