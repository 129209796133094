import React from 'react'
import { FormContext, useForm } from 'react-hook-form'
import { AlertModal } from 'components'

import * as S from './styles'

export default function StatusModal({
  isVisible,
  onSubmit,
  onClose,
  flag,
  title,
  validationSchema,
  children,
}) {
  const form = useForm({ validationSchema })

  const handleSubmit = form.handleSubmit((values) => {
    onSubmit(values)
  })

  return (
    <>
      <AlertModal
        isVisible={isVisible}
        flag={{ text: flag }}
        actions={{
          primary: {
            onClick: handleSubmit,
            label: 'Confirmar',
          },
          secondary: {
            onClick: onClose,
            label: 'Cancelar',
          },
        }}
      >
        <S.ModalText>{title}</S.ModalText>
        <FormContext {...form}>
          <S.Form>{children}</S.Form>
        </FormContext>
      </AlertModal>
    </>
  )
}
