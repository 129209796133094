import React from 'react'

import { Container } from './styles'
import { Loading } from 'components'

export default function Button({
  link,
  block,
  color,
  size,
  isLoading,
  children,
  disabled,
  gtmName,
  testid,
  variant,
  ...props
}) {
  return (
    <Container
      {...props}
      data-testid={testid}
      link={link}
      color={color}
      variant={variant}
      size={size}
      block={block}
      disabled={disabled}
      data-gtm-type="click"
      data-gtm-clicktype="button"
      data-gtm-name={gtmName || children.toString().toLowerCase()}
      isLoading={isLoading}
    >
      {!isLoading ? <>{children}</> : <Loading size={'8px'} />}
    </Container>
  )
}
