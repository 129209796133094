import React from 'react'

import { Flex } from 'components'
import { Dialog, Label, Content } from './styles'

export default function DialogContent({ title, children }) {
  return (
    <Dialog>
      <Flex justifyContent="space-between" alignItems="center">
        <Label>{title}</Label>
      </Flex>

      <Content>{children}</Content>
    </Dialog>
  )
}
