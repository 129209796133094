import { useSelector, useDispatch } from 'react-redux'

import {
  handleLoginAndSetUserInfos,
  handleLogoutToCurrentUser,
} from 'store/reducers/users/middlewares'

export function useAuthentication() {
  const dispatch = useDispatch()

  const users = useSelector((state) => state.users)

  return {
    isAuthenticated: !!users.payload.token,
    isLoading: users.isLoading,
    error: users.error,
    login: (payload) => dispatch(handleLoginAndSetUserInfos(payload)),
    logout: () => dispatch(handleLogoutToCurrentUser()),
  }
}

export function useCurrentUser() {
  const users = useSelector((state) => state.users)

  return users.payload
}
