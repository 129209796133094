import React, { useState } from 'react'
import { Editor as EditorWysiwyg } from 'react-draft-wysiwyg'
import {
  EditorState,
  convertToRaw,
  ContentState,
  convertFromHTML,
} from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import {
  Controller,
  ErrorMessage as Error,
  useFormContext,
} from 'react-hook-form'
import { ErrorMessage } from 'components'

import * as S from './styles'

function Editor({ name, initialValue = '' }) {
  const { errors, control } = useFormContext()

  const [editorState, setEditorState] = useState(() =>
    EditorState.createWithContent(
      ContentState.createFromBlockArray(convertFromHTML(initialValue))
    )
  )

  const handleEditorStateChange = (values) => {
    setEditorState(values)
  }

  return (
    <S.Container>
      <Controller
        control={control}
        name={name}
        defaultValue={initialValue}
        as={
          <EditorWysiwyg
            name={name}
            editorState={editorState}
            toolbarClassName="toolbarClassName"
            wrapperClassName="wrapperClassName"
            editorClassName="editorClassName"
            onEditorStateChange={handleEditorStateChange}
            placeholder="Escreva aqui"
            localization={{
              locale: 'pt',
            }}
            stripPastedStyles={true}
            toolbar={{
              options: [
                'inline',
                'blockType',
                'list',
                'textAlign',
                'link',
                'emoji',
                'remove',
                'history',
                'image'
              ],
            }}
          />
        }
        onChange={() => {
          return draftToHtml(convertToRaw(editorState.getCurrentContent()))
        }}
      />

      <Error errors={errors} name={name} as={<ErrorMessage />} />
    </S.Container>
  )
}

export default Editor
