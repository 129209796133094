import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useFormContext, ErrorMessage, Controller } from 'react-hook-form'
import 'cleave.js/dist/addons/cleave-phone.br'

import './types'

import { Clipboard, Tip } from 'components'
import { Container, Field, Label, Value, Error } from './styles'

/**
 * @param {InputProps} props
 */
export default function Input({
  label,
  name,
  placeholder,
  mask,
  numericOnly,
  creditCard,
  phone,
  date,
  prefix,
  shortDate,
  rules,
  invertColor,
  toUpperCase,
  onBlur,
  isLoading,
  onChange,
  maskedValue,
  numeral,
  copyToClipBorad,
  gtmName,
  tip,
  rightIcon,
  ...props
}) {
  const { unregister, errors, getValues, control } = useFormContext()
  const [focus, setFocus] = useState(false)

  const currentValue = getValues()[name]

  useEffect(() => {
    currentValue || prefix ? setFocus(true) : setFocus(false)
  }, [currentValue, prefix])

  const blocks = mask ? mask.match(/#+/g).map((each) => each.length) : [99999]
  const delimiters = mask ? mask.match(/[^#]/g) : creditCard ? [' '] : ['/']
  const datePattern = shortDate ? ['m', 'y'] : ['d', 'm', 'Y']

  const handleBlur = (e) => {
    onBlur && onBlur(e)
    !e.target.value && setFocus(false)
    return maskedValue ? e.target.value : e.target.rawValue
  }

  const handleChange = (e) => {
    e.target.value ? setFocus(true) : setFocus(false)

    if (typeof onChange === 'function') return onChange(e)

    return toUpperCase
      ? e.target.rawValue.toUpperCase()
      : maskedValue
      ? e.target.value
      : e.target.rawValue
  }

  useEffect(() => {
    return () => unregister(name)
  }, [unregister, name])

  const options = {
    blocks,
    delimiters,
    numericOnly,
    creditCard,
    phone,
    date,
    prefix,
    datePattern,
    phoneRegionCode: 'BR',
    numeral,
  }

  return (
    <Container>
      <Field
        focused={focus}
        invertColor={invertColor}
        readOnly={props.readOnly}
        disabled={props.disabled}
        isLoading={isLoading}
        rightIcon={rightIcon}
      >
        <Label>{label}</Label>

        <Controller
          as={
            <Value
              {...props}
              options={options}
              onFocus={() => setFocus(true)}
              autoComplete="off"
              placeholder={placeholder}
              onBlur={(e) => handleBlur(e)}
              data-gtm-name={gtmName || label.toLowerCase()}
              data-gtm-form="input"
            />
          }
          rules={rules}
          onBlur={([e]) => handleBlur(e)}
          control={control}
          name={name}
          onChange={([e]) => handleChange(e)}
        />
        {copyToClipBorad && <Clipboard name={name} inputValue={currentValue} />}
        {tip && (
          <Tip size={tip.size} name={tip.name} icon={tip.icon}>
            {tip.children()}
          </Tip>
        )}
        {!!rightIcon && rightIcon}
      </Field>

      <ErrorMessage
        errors={errors}
        name={name}
        as={<Error invertColor={invertColor} />}
      />
    </Container>
  )
}

Input.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  mask: PropTypes.string,
  numericOnly: PropTypes.bool,
  creditCard: PropTypes.bool,
  phone: PropTypes.bool,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  prefix: PropTypes.string,
  shortDate: PropTypes.bool,
  rules: PropTypes.object,
  invertColor: PropTypes.bool,
  toUpperCase: PropTypes.bool,
  onBlur: PropTypes.func,
  isLoading: PropTypes.bool,
}
