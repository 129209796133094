import api from 'services'
import { AppError } from 'utils'
import { setTelephoneMask } from 'utils'

export async function fetchBrokerBySusep(susep) {
  try {
    const response = await api.get(`/integracao/brokers/${susep}`)

    const { branch } = response.data

    return {
      branch,
    }
  } catch (error) {
    const { response } = error
    if (response.data.code === 'ERRIBK002') return response

    if (response.status === 400)
      throw Error('Não existe nenhum corretor associado a esta SUSEP.')

    throw Error(
      'Houve uma instabilidade no sistema. Tente novamente mais tarde.'
    )
  }
}

export async function createBroker(payload) {
  const { name, email, document, susep, branch, password, cellphone } =
    payload.broker

  const PAYLOAD_PARSE = {
    isUpToDate: true,
    fullName: name,
    email,
    cpf: {
      number: document,
    },
    broker: {
      susep,
      branch,
    },
    cellphone,
    susep,
    branch,
    password,
  }

  try {
    const response = await api.post('/integracao/brokers', PAYLOAD_PARSE)

    return response.data
  } catch (error) {
    if (
      error?.response?.data?.message[0]?.messages[0]?.message ===
      'Email already taken.'
    ) {
      throw new AppError(
        'Este e-mail já foi cadastrado no sistema.',
        error.response.status
      )
    }

    throw new AppError(
      'Houve uma instabilidade no sistema. Tente novamente mais tarde.',
      error.response.status
    )
  }
}

export async function createLandingPageBroker(data) {
  try {
    const response = await api.post(
      `/cf_landing_page/user_contact_info`,
      data,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    )

    return response.data
  } catch (error) {
    throw new AppError(
      'Houve uma instabilidade no sistema, tente novamente em alguns minutos',
      error.response.status
    )
  }
}

export async function getBrokerContactDetails(susep) {
  try {
    const { data } = await api.get(
      `/cf_landing_page/user_contact_info?susep=${susep}`
    )

    data.whatsapp_number = setTelephoneMask(data.whatsapp_number)
    data.phone = setTelephoneMask(data?.phone)

    return data
  } catch (error) {
    throw Error(
      'Houve uma instabilidade no sistema. Tente novamente mais tarde ou entre em contato com a Porto Seguro.'
    )
  }
}

export async function fetchAllBrokers() {
  try {
    const response = await api.get(`/integracao/brokers`, {
      params: {
        _limit: 0,
      },
    })

    return response.data
  } catch (error) {
    throw Error(
      'Houve uma instabilidade no sistema. Tente novamente mais tarde ou entre em contato com a Porto Seguro.'
    )
  }
}

export async function updateBroker(id, data) {
  try {
    const response = await api.put(`/integracao/brokers/${id}`, data)

    return response.data
  } catch (error) {
    console.error(error)
    throw error
  }
}
