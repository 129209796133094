import styled, { css } from 'styled-components'
import { Link } from 'react-router-dom'
import media from 'styled-media-query'
import * as LoadingStyles from 'components/Loading/styles'

export const Container = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Divider = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: 8px;
    background: ${theme.colors.primary};
  `}
`

export const Content = styled.div`
  ${({ theme }) => css`
    max-width: 1440px;
    width: 100%;
    padding: ${theme.spacings.large};

    ${media.lessThan('medium')`
      padding: ${theme.spacings.small};
    `}
  `}
`

export const Header = styled.header`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;

    ${media.lessThan('small')`
      flex-direction: column;
      gap: ${theme.spacings.small};
    `}
    padding-bottom: ${theme.spacings.xsmall};

    border-bottom: ${theme.border.thin} solid ${theme.colors.newGray[20]};
  `}
`

export const BackButton = styled.button`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    gap: ${theme.spacings.xxsmall};
    margin-right: auto;

    font-size: ${theme.fonts.sizes.xsmall};
    font-weight: 600;
    color: ${theme.colors.newGray[80]};

    border: 0;
    background: transparent;
    cursor: pointer;

    > svg {
      color: ${theme.colors.newGray[80]};
      width: 24px;
      height: 24px;
    }
  `}
`

export const EditButton = styled(Link)`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    gap: ${theme.spacings.xxsmall};

    font-size: ${theme.fonts.sizes.xsmall};
    font-weight: 600;
    color: ${theme.colors.newGray[80]};

    border: 0;
    background: transparent;
    cursor: pointer;
  `}
`

export const DeleteButton = styled.button`
  ${({ theme, isLoading }) => css`
    display: flex;
    align-items: center;
    gap: ${theme.spacings.xxsmall};

    font-size: ${theme.fonts.sizes.xsmall};
    font-weight: 600;
    color: ${theme.colors.newGray[80]};

    border: 0;
    background: transparent;
    cursor: pointer;

    @keyframes spinner {
      from {
        transform: rotate(0);
      }

      to {
        transform: rotate(360deg);
      }
    }

    ${isLoading &&
    css`
      cursor: not-allowed;
      > svg {
        animation: spinner 1s infinite linear;
      }
    `}

    > svg {
      color: ${theme.colors.newGray[80]};
    }
  `}
`

export const NewsActions = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    gap: ${theme.spacings.small};
  `}
`

export const News = styled.div`
  ${({ theme }) => css``}
`

export const NewsHeader = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin-top: ${theme.spacings.small};

    > div {
      display: flex;
      align-items: center;
      gap: ${theme.spacings.small};
    }
  `}
`

export const PublishDate = styled.span`
  ${({ theme }) => css`
    font-size: ${theme.fonts.sizes.small};
    font-weight: 600;
    color: ${theme.colors.newGray[80]};
    line-height: 1.5;
  `}
`

export const NewsBody = styled.div`
  ${({ theme }) => css`
    margin-top: ${theme.spacings.small};
  `}
`

export const NewsContent = styled.div``

export const Title = styled.h1`
  ${({ theme }) => css`
    color: ${theme.colors.primary};
    font-size: ${theme.fonts.sizes.huge};
    font-weight: 700;
    margin: 0;
  `}
`

export const Subtitle = styled.h6`
  ${({ theme }) => css`
    font-size: ${theme.fonts.sizes.large};
    color: ${theme.colors.black};
    line-height: 1.5;
    font-weight: 700;
    margin: ${theme.spacings.small} 0;
  `}
`

export const Description = styled.div`
  ${({ theme }) => css`
    word-break: break-word;

    font-family: ${theme.fonts.book};

    > p {
      font-family: inherit;
      color: ${theme.colors.newGray[80]};
      font-size: ${theme.fonts.sizes.medium};
      font-weight: 400;
      text-align: left;

      margin-bottom: ${theme.spacings.xsmall};
    }

    span {
      font-family: inherit;
      color: ${theme.colors.newGray[80]};
      font-size: ${theme.fonts.sizes.medium};
      font-weight: 400;
    }

    strong {
      font-size: ${theme.fonts.sizes.medium};
      font-weight: 700;
      color: ${theme.colors.primary};
    }

    a {
      font-family: inherit;
      font-size: ${theme.fonts.sizes.medium};
      font-weight: 400;
      color: ${theme.colors.primary};
      text-decoration: underline;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-family: inherit;
      color: ${theme.colors.primary};
      margin: ${theme.spacings.small} 0;
    }

    ul,
    ol {
      font-family: inherit;
      padding: ${theme.spacings.xxsmall} ${theme.spacings.small}
        ${theme.spacings.xsmall};
      color: ${theme.colors.newGray[80]};
      font-size: ${theme.fonts.sizes.medium};
      font-weight: 400;
    }
  `}
`

export const RelatedNews = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    gap: ${theme.spacings.xxsmall};
  `}
`

export const RelatedNewsTitle = styled.div`
  ${({ theme, hasRelatedNews }) => css`
    display: flex;
    align-items: center;
    gap: ${theme.spacings.xsmall};

    ${!hasRelatedNews &&
    css`
      display: none;
    `}

    > span {
      font-size: ${theme.fonts.sizes.small};
      font-weight: 700;
      color: ${theme.colors.newGray[60]};
      margin: ${theme.spacings.xsmall} 0 ${theme.spacings.small};
    }

    ${LoadingStyles.Loading} {
      width: auto;

      > div {
        background: ${theme.colors.newGray[60]};
      }
    }
  `}
`

export const Loader = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: ${({ theme }) => theme.layers.modal};

  &:before {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    width: 50px;
    height: 50px;

    content: '';
    background-image: url('/images/loader.svg');
    background-size: contain;
  }
`
