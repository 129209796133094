import { useCurrentUser } from 'hooks'
import React from 'react'
import * as S from './styles'

export default function Home() {
  const {
    infos: { name },
  } = useCurrentUser()

  return (
    <S.Main>
      <S.Container>
        <S.Content>
          <S.Title> Olá, {name}</S.Title>
          <S.HighlightTitle>Seja bem-vindo(a)!</S.HighlightTitle>
          <S.Subtitle>
            Este é o painel do corretor, acesse as funcionalidades através do
            menu.
          </S.Subtitle>
        </S.Content>
        <S.Image src="/images/home/home_banner.svg" />
      </S.Container>
    </S.Main>
  )
}

Home.properties = {
  metatags: {
    title: 'Página inicial',
  },
}
