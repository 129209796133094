import React from 'react'
import { ErrorMessage as Error, useFormContext } from 'react-hook-form'
import { ErrorMessage } from 'components'

import * as S from './styles'

function Input({ name, ...props }) {
  const { errors, register } = useFormContext()

  return (
    <S.Container>
      <S.Input ref={register} name={name} {...props} />
      <Error errors={errors} name={name} as={<ErrorMessage />} />
    </S.Container>
  )
}

export default Input
