import styled from 'styled-components'
import media from 'styled-media-query'
import { rgba, rem } from 'polished'

export const Container = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: ${({ theme }) => theme.layers.modal};

  overflow-y: auto;

  display: flex;
  align-items: flex-start;
  justify-content: center;
`

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  background-color: ${rgba('#000', 0.8)};
`

export const Dialog = styled.div`
  position: relative;
  z-index: ${({ theme }) => theme.layers.modal};

  margin: 30px 0;

  border-radius: 10px;
  background-color: #fff;

  ${media.greaterThan('large')`
    width: 980px;
  `}

  ${media.lessThan('large')`
    width: 100%;

    margin: 20px;
  `}
`

export const Head = styled.div`
  padding: 30px;

  ${media.lessThan('large')`
    padding: 20px;
  `}
`

export const Title = styled.h2`
  font-family: ${({ theme }) => theme.fonts.bold};
  color: ${({ theme }) => theme.colors.gray.dark};

  ${media.lessThan('large')`
    font-size: ${rem('15px')};
  `}
`

export const Body = styled.div`
  padding: 0 30px 30px;

  ${media.lessThan('large')`
    padding: 0 20px 20px;
  `}
`

export const Footer = styled.div`
  padding: 0 30px 30px;

  text-align: right;

  ${media.lessThan('large')`
    padding: 0 20px 20px;
  `}
`
