import styled, { css } from 'styled-components'
import { layout, space, typography } from 'styled-system'
import { darken, lighten } from 'polished'
import media from 'styled-media-query'
import styledMap from 'styled-map'
import * as LoadingStyles from 'components/Loading/styles'
import colors from 'styles/colors'

const buttonTheme = styledMap('color', {
  ...colors,
  gray: colors.gray.medium,
  default: colors.primary,
})

const buttonVariants = {
  outline: (theme, isLoading) => css`
    background-color: ${theme.colors.white};
    border: ${theme.border.hairline} solid ${theme.colors.newGray[60]};
    color: ${theme.colors.newGray[60]};
    transition: background 0.3s ease-in-out;

    &:disabled {
      background-color: ${theme.colors.white};
      border: 1px solid ${theme.colors.newGray[10]};
      color: ${theme.colors.newGray[50]};

      cursor: not-allowed;

      &:hover {
        background-color: ${theme.colors.white};
      }
    }
    &:hover {
      background-color: ${theme.colors.newGray[10]};
    }

    ${isLoading &&
    css`
      height: 100%;
      ${LoadingStyles.Loading} {
        > div {
          background: ${theme.colors.newGray[60]};
        }
      }
    `}
  `,
}

export const Container = styled.button`
  ${({ theme, variant, rightIcon, leftIcon, isLoading }) => css`
    all: unset;

    padding: ${theme.spacings.xsmall} ${theme.spacings.xxlarge};

    ${layout}
    ${space}
    ${typography}
    color: ${theme.colors.white};
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;

    font-weight: 700;
    font-size: ${theme.fonts.sizes.xsmall};
    line-height: 1.5;

    box-sizing: border-box;
    border-radius: ${theme.borderRadius.pill};
    white-space: nowrap;

    background-color: ${buttonTheme};
    transition: background-color color 0.3s ease-in-out;

    &:disabled {
      background-color: ${(props) => lighten('0.2', buttonTheme(props))};
      cursor: not-allowed;

      &:hover {
        background-color: ${(props) => lighten('0.2', buttonTheme(props))};
      }
    }

    &:hover {
      background-color: ${(props) => darken('.06', buttonTheme(props))};
    }

    ${({ block }) =>
      block &&
      css`
        width: 100%;
        display: block;

        text-align: center;
      `}

    ${({ size }) =>
      size?.small &&
      css`
        padding: ${theme.spacings.xxsmall} ${theme.spacings.medium};
        font-size: ${theme.fonts.sizes.xxsmall};

        ${media.lessThan('large')`
          padding: ${theme.spacings.xxsmall} ${theme.spacings.small};
          font-size: ${theme.fonts.sizes.xxxsmall};
        `}
      `}

  ${({ link }) =>
      link &&
      css`
        padding: ${theme.spacings.xxsmall};

        background-color: transparent;
        color: ${theme.colors.newGray[60]};

        &:hover {
          text-decoration: underline;
          background-color: transparent;
        }
      `}

      ${media.lessThan('large')`
        padding: ${theme.spacings.xsmall} ${theme.spacings.xsmall};
        font-weight: 600;
      `}

      ${media.lessThan('medium')`
        height:40px;
        padding: ${theme.spacings.xxsmall} ${theme.spacings.xsmall};
        font-weight: 600;
      `}

      ${isLoading &&
    css`
      height: 100%;
      background-color: ${theme.colors.primary};
      border: ${theme.border.thin} solid ${theme.colors.white};
      cursor: not-allowed;
      ${LoadingStyles.Loading} {
        > div {
          background: ${theme.colors.white};
        }
      }
    `}

    ${!!variant && buttonVariants[variant](theme, isLoading)}
  `}
`
