import styled, { css } from 'styled-components'
import media from 'styled-media-query'
import { darken, rem, rgba } from 'polished'
import { Button } from 'components'
import { colors } from 'styles'

export const Container = styled.div`
  padding: 40px 50px;

  ${media.lessThan('small')`
  padding: 40px 1rem;
`}
`

export const Dialog = styled.div`
  margin-bottom: 50px;

  padding: 30px;

  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  background: #fff;
  border-radius: 5px;
`

export const Label = styled.p`
  color: ${({ theme }) => theme.colors.gray.medium};
  font-family: ${({ theme }) => theme.fonts.bold};
  font-size: 14px;

  ${media.lessThan('small')`
    justify-self: center;
  `}
`

export const Message = styled.p`
  margin-top: 40px;

  color: ${({ theme }) => theme.colors.gray.medium};
  font-family: ${({ theme }) => theme.fonts.bold};
  font-size: 12px;
  text-align: center;
`

export const Box = styled.div`
  display: flex;
`

export const Name = styled.p`
  padding-right: 5px;

  color: ${({ theme }) => theme.colors.gray.medium};
  font-family: ${({ theme }) => theme.fonts.medium};
  font-size: ${rem('12px')};
`

export const Value = styled.p`
  font-family: ${({ theme }) => theme.fonts.medium};
  color: ${({ theme }) => theme.colors.info};
  font-size: ${rem('12px')};
`

export const Choice = styled.div`
  height: 60px;
  padding-left: 20px;

  display: flex;
  align-items: center;

  background: #333;
  color: #fff;
  font-family: ${({ theme }) => theme.fonts.medium};
  border-radius: 5px;
`

export const Success = styled(Choice)`
  background: ${({ theme }) => theme.colors.gray.light};

  ${({ active }) =>
    active &&
    css`
      background: ${({ theme }) => theme.colors.success};
    `};
`

export const Failure = styled(Choice)`
  background: ${({ theme }) => theme.colors.gray.light};

  ${({ active }) =>
    active &&
    css`
      background: ${({ theme }) => theme.colors.danger};
    `};
`

export const Loader = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: ${({ theme }) => theme.layers.modal};

  content: '';
  background-color: ${rgba('#000', 0.8)};

  &:before {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    width: 50px;
    height: 50px;

    content: '';
    background-image: url('/images/loader.svg');
    background-size: contain;
  }
`

export const Alert = {
  Text: styled.p`
    margin-bottom: 40px;

    font-size: 20px;
    font-family: ${({ theme }) => theme.fonts.bold};
  `,
}

const handleButtonColor = (color) => {
  const button = {
    primary: colors.primary,
    success: colors.green,
    danger: colors.danger,
  }
  return button[color]
}

export const CustomButton = styled(Button)`
  background: ${({ color }) => handleButtonColor(color)};


  &:disabled {
    background: ${({ color }) => handleButtonColor(color)};
    cursor: not-allowed;

    &:hover {
      background: ${({ color }) => darken('.06', handleButtonColor(color))};
    }
  }
  ${media.lessThan('large')`
    white-space: normal;
  `}
`

export const Error = styled.p`
  ${({ theme }) => css`
    padding: ${theme.spacings.small} 0;

    font-size: ${theme.fonts.sizes.medium};
    font-family: ${theme.fonts.medium};

    color: ${theme.colors.danger};
  `}
`

export const Form = styled.form`
  margin: 1rem 0;
`

export const ModalText = styled.p`
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.gray.davys};

  ${media.lessThan('small')`
    font-size: ${rem('14px')};
  `}

  margin-bottom: 1rem;
`

export const Text = styled.p`
  ${({theme}) => css`
    color: ${theme.colors.white};
    font-size: ${theme.fonts.sizes.xxsmall};
  `}
`