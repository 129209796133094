import styled, { css } from 'styled-components'
import media from 'styled-media-query'
import { componentLoader } from 'styles'
import { rem } from 'polished'

export const Container = styled.div`
  position: relative;
  z-index: 2;

  height: 44px;
  min-width: 260px;
  padding: 0 20px;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 90px;
  cursor: pointer;

  background-color: ${({ theme }) => theme.colors.primary};

  ${media.lessThan('large')`
    min-width: 160px;
  `}

  ${({ isLoading }) =>
    isLoading &&
    css`
      ${componentLoader({
        visible: true,
        primary: true,
        centered: true,
      })}
    `}
`

export const Input = styled.input`
  position: absolute;
  z-index: -1;

  width: 0.1px;
  height: 0.1px;

  opacity: 0;
  overflow: hidden;
  cursor: pointer;
`

export const Label = styled.label`
  font-family: ${({ theme }) => theme.fonts.bold};

  position: relative;
  z-index: 1;

  cursor: pointer;

  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  color: #fff;
`

export const Error = styled.small`
  position: absolute;
  bottom: ${rem('-20px')};

  left: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: ${({ theme }) => theme.fonts.bold};
  font-size: 12px;
  color: ${({ theme }) => theme.colors.danger};
`
