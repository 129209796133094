import React, { useEffect } from 'react'

import { Dialog } from '../common'

import { Grid, Input } from 'components'
import { handleDateIsValid } from 'utils'

export default function PersonalData({ client, forms }) {
  useEffect(() => {
    forms.setValue([
      { 'client.document': client?.document },
      { 'client.name': client?.name?.toUpperCase() },
      { 'client.email': client?.email },
      { 'client.zipcode': client?.zip_code },
      { 'client.address': client?.address },
      { 'client.house_number': client?.number },
      { 'client.complement': client?.complement },
      { 'client.district': client?.district },
      { 'client.city': client?.city },
      { 'client.state': client?.state },
      { 'client.mother_name': client?.mother_name?.toUpperCase() },
      { 'client.dispatching_agency': client?.dispatching_agency },
      { 'client.identification': client?.identification },
      { 'client.birth_date': handleDateIsValid(client?.birth_date) },
      { 'client.cellphone': client?.cellphone },
      { 'client.driver_license': client?.driver_license },
      {
        'client.first_date_driver_license': handleDateIsValid(
          client?.first_date_driver_license
        ),
      },
      {
        'client.issue_date_driver_lincense': handleDateIsValid(
          client?.issue_date_driver_lincense
        ),
      },
      {
        'client.validity_driver_license': handleDateIsValid(
          client?.validity_driver_license
        ),
      },
      { 'client.occupation': client?.occupation },
      { 'client.marital_status': client?.marital_status },
    ])
  }, [client]) // eslint-disable-line

  return (
    <Dialog title="2. Dados pessoais">
      <Grid
        gridTemplateColumns={['1fr', '1fr 1fr', '1fr 1fr 1fr']}
        gridGap="16px"
        data-testid="bloco-dados"
      >
        <Input
          name="client.document"
          placeholder="CPF"
          label="CPF"
          mask="###.###.###-##"
          copyToClipBorad
          disabled
        />
        <Input
          name="client.name"
          placeholder="Nome completo"
          label="Nome completo"
          disabled
        />
        <Input
          name="client.email"
          placeholder="E-mail"
          label="E-mail"
          disabled
        />

        <Input
          name="client.zipcode"
          placeholder="CEP"
          label="CEP"
          copyToClipBorad
          mask="#####-###"
          disabled
        />
        <Input
          name="client.address"
          placeholder="Endereço"
          label="Endereço"
          disabled
        />

        <Grid
          gridTemplateColumns={['100%', '100px 1fr ', '100px 1fr ']}
          gridGap="12px"
        >
          <Input
            name="client.house_number"
            placeholder="Nº"
            label="Nº"
            disabled
          />
          <Input
            name="client.complement"
            placeholder="Complemento"
            label="Complemento"
            disabled
          />
        </Grid>

        <Input
          name="client.district"
          placeholder="Bairro"
          label="Bairro"
          disabled
        />

        <Grid
          gridGap="12px"
          gridTemplateColumns={['100%', '1fr 110px', '1fr 110px']}
        >
          <Input
            name="client.city"
            placeholder="Cidade"
            label="Cidade"
            disabled
          />
          <Input name="client.state" placeholder="UF" label="UF" disabled />
        </Grid>

        <Input
          name="client.mother_name"
          placeholder="Filiação"
          label="Filiação"
          disabled
        />
      </Grid>

      <Grid
        my={3}
        gridTemplateColumns={['1fr', '1fr 1fr', '1fr 1fr 1fr']}
        gridGap="16px"
      >
        <Grid
          gridTemplateColumns={['100%', 'repeat(2, 1fr)', 'repeat(2, 1fr)']}
          gridGap="12px"
        >
          <Input
            name="client.dispatching_agency"
            placeholder="Órgão emissor"
            label="Órgão emissor"
            disabled
          />
          <Input
            name="client.identification"
            placeholder="RG"
            label="RG"
            disabled
          />
        </Grid>

        <Input
          name="client.birth_date"
          placeholder="Data de nascimento"
          label="Data de nascimento"
          mask="##/##/####"
          disabled
        />
        <Input
          name="client.cellphone"
          placeholder="Celular"
          label="Celular"
          disabled
        />

        <Input
          name="client.driver_license"
          placeholder="Número da CNH"
          label="Número da CNH"
          disabled
        />
        <Input
          name="client.first_date_driver_license"
          placeholder="Data da 1° CNH"
          label="Data da 1° CNH"
          mask="##/##/####"
          disabled
        />
        <Input
          name="client.issue_date_driver_lincense"
          placeholder="Data de emissão CNH"
          label="Data de emissão CNH"
          mask="##/##/####"
          disabled
        />
        <Input
          name="client.validity_driver_license"
          placeholder="Data de validade CNH"
          label="Data de validade CNH"
          mask="##/##/####"
          disabled
        />
        <Input
          name="client.occupation"
          placeholder="Profissão"
          label="Profissão"
          disabled
        />
        <Input
          name="client.marital_status"
          placeholder="Estado civil"
          label="Estado civil"
          disabled
        />
      </Grid>
    </Dialog>
  )
}
