import styled, { css } from 'styled-components'
import * as TipStyles from 'components/Tip/styles'

const containerModifiers = {
  primary: (theme) => css`
    background: ${theme.colors.primary};
    color: ${theme.colors.white};
  `,
  secondary: (theme) => css`
    background: ${theme.colors.white};
    color: ${theme.colors.primary};
  `,

  small: () => css`
    width: 24px;
    height: 24px;
  `,

  medium: () => css`
    width: 32px;
    height: 32px;
  `,

  large: () => css`
    width: 40px;
    height: 40px;
  `,
}

export const Container = styled.button`
  ${({ theme, color, size }) => css`
    width: 32px;
    height: 32px;

    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    cursor: pointer;

    background: ${theme.colors.primary};
    color: ${theme.colors.white};
    border-radius: ${theme.borderRadius.pill};

    :not(:first-of-type) {
      margin-left: ${theme.spacings.xxsmall};
    }

    &:disabled {
      cursor: not-allowed;
      background: ${theme.colors.newGray[20]};
      color: ${theme.colors.newGray[50]};

      ${TipStyles.Mark} {
        cursor: not-allowed;
      }
    }

    ${!!color && containerModifiers[color](theme)};
    ${!!size && containerModifiers[size](theme)};
  `}
`
