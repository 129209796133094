import api from 'services'

export async function getPositions() {
  try {
    const response = await api.get('/integracao/system-roles/positionsBroker')

    return response.data
  } catch (e) {
    throw Error(
      'Houve uma instabilidade no sistema. Tente novamente mais tarde ou entre em contato com a Porto Seguro.'
    )
  }
}

export async function updateBrokerPositions(data) {
  try {
    const response = await api.put(
      '/integracao/system-roles/positionsBroker/nextQueue',
      data
    )

    return response.data
  } catch (e) {
    throw Error(
      'Houve uma instabilidade no sistema. Tente novamente mais tarde ou entre em contato com a Porto Seguro.'
    )
  }
}

export async function addBrokerToQueue(susep) {
  try {
    const response = await api.put(
      '/integracao/system-roles/positionsBroker/add',
      {
        susep,
      }
    )

    return response.data
  } catch (e) {
    throw Error(
      'Houve uma instabilidade no sistema. Tente novamente mais tarde ou entre em contato com a Porto Seguro.'
    )
  }
}

export async function removeBrokerFromQueue(susep) {
  try {
    const response = await api.delete(
      `/integracao/system-roles/positionsBroker/remove/${susep}`
    )

    return response.status
  } catch (e) {
    throw Error(
      'Houve uma instabilidade no sistema. Tente novamente mais tarde ou entre em contato com a Porto Seguro.'
    )
  }
}
