import React from 'react'
import PropTypes from 'prop-types'
import { useFormContext, ErrorMessage } from 'react-hook-form'

import './types'

import { Icon, Tip } from 'components'
import * as S from './styles'

/**
 * @param {SelectProps} props
 */
export default function Select({
  options,
  color,
  isLoading,
  name,
  placeholder,
  disabled,
  removeDefaultError,
  tip,
  gtmName,
  ...props
}) {
  const { register, errors = {} } = useFormContext() || {}

  return (
    <S.Container color={color} disabled={disabled}>
      <S.Options
        {...props}
        name={name}
        ref={register}
        disabled={disabled || isLoading}
        data-gtm-name={gtmName || placeholder.toLowerCase()}
        data-gtm-form="select"
      >
        <S.Option hidden value="">
          {placeholder ? placeholder : 'Selecione'}
        </S.Option>

        {options.map(({ value, label, disabled }, index) => (
          <S.Option disabled={disabled || false} value={value} key={index}>
            {label}
          </S.Option>
        ))}
      </S.Options>

      {isLoading ? <S.Loader /> : <Icon name="chevron-down" />}

      {!removeDefaultError && (
        <ErrorMessage errors={errors} name={name} as={<S.Error />} />
      )}
      {tip && (
        <Tip size={tip.size} name={tip.name} icon={tip.icon} place="bottom">
          {tip.children()}
        </Tip>
      )}
    </S.Container>
  )
}

Select.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
  color: PropTypes.string,
  name: PropTypes.string,
  error: PropTypes.object,
}
