import styled from 'styled-components'
import media from 'styled-media-query'
import { rem } from 'polished'
import styledMap from 'styled-map'
import { motion } from 'framer-motion'

import colors from 'styles/colors'

export const Container = styled(motion.div)`
  position: fixed;
  top: 5px;
  left: 5px;
  z-index: ${({ theme }) => theme.layers.notification};

  padding: 10px 20px;

  border-radius: 10px;
  background-color: ${styledMap('type', {
    ...colors,
  })};

  ${media.greaterThan('large')`
    top: 25px;
    left: 50%;
    transform: translateX(-50%) !important;

    max-width: 400px;
    padding: 14px 30px;
  `}

  ${media.lessThan('large')`
    width: calc(100% - 10px);
  `}
`

export const Content = styled.div`
  color: #fff;
  font-size: ${rem('13px')};
  font-family: ${({ theme }) => theme.fonts.bold};

  ${media.lessThan('large')`
    font-size: ${rem('11px')};
  `}
`
