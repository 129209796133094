import api from 'services'

export async function fetchBranchBySusep(susep) {
  try {
    const { data } = await api.get(`/cr_susep/susep/${susep}/branch`)

    return data
  } catch (error) {
    throw Error(
      'Houve uma instabilidade no sistema. Tente novamente mais tarde.'
    )
  }
}
