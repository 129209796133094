import React from 'react'
import { AnimatePresence } from 'framer-motion'

import { useNotification } from 'hooks'

import { Container, Content } from './styles'

export default function Notification() {
  const { type, content, visible } = useNotification()

  return (
    <AnimatePresence>
      {visible && (
        <Container
          type={type}
          transition={{ ease: [0.77, 0, 0.175, 1] }}
          initial={{ opacity: 0, y: -30 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -30 }}
        >
          <Content>{content}</Content>
        </Container>
      )}
    </AnimatePresence>
  )
}
