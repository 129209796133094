import styled from 'styled-components'
import media from 'styled-media-query'

import { Content } from 'components/Modals/Simple/styles'

export const Container = styled.div`
  padding: 24px;

  ${media.lessThan('small')`
        padding: 24px 16px;
    `}

  ${Content} {

    max-height: none;
    top: 50%;
    transform: translate(50%, -50%);

    max-width: 780px;
    width: 90%;

    ${media.lessThan('medium')`
    h2 {
      font-size: 1rem;
    }
    p{
      font-size: 0.875rem;
    }
  `}
  }
`
