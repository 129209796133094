import React from 'react'
// eslint-disable-next-line
import { registerLocale, ReactDatePickerProps } from 'react-datepicker'
import { Controller, ErrorMessage, useFormContext } from 'react-hook-form'

import 'react-datepicker/dist/react-datepicker.css'
import ptBR from 'date-fns/locale/pt-BR'

import { useWindowWidth } from 'hooks'

import { Container, Date, Error, Label } from './styles'

registerLocale('pt-BR', ptBR)

/**
 * @param {ReactDatePickerProps} props
 */
export default function Datepicker({ name, clearable = true, ...props }) {
  const width = useWindowWidth()
  const { errors } = useFormContext()

  return (
    <Container>
      <Label selected={props.selected}>{props.placeholderText}</Label>
      <Controller
        name={name}
        as={
          <Date
            {...props}
            locale="pt-BR"
            dateFormat="dd/MM/yyyy"
            todayButton="Hoje"
            isClearable={clearable}
            withPortal={width < 450}
            autoComplete="off"
            selected={props.selected}
          />
        }
      />
      <ErrorMessage errors={errors} name={name} as={<Error />} />
    </Container>
  )
}
