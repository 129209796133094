import React from 'react'
import { TableSimple as Table, Loading } from 'components'
import * as S from './styles'

function PriceTable({ data, packages, isLoading, error }) {
  if (error) {
    return <S.ErrorMessage data-testid="error_message">{error}</S.ErrorMessage>
  }

  return (
    <>
      <S.TableContainer data-testid="price_tabel">
        {isLoading ? (
          <S.WrappLoading>
            <Loading size={'8px'} color={'#000'} />
          </S.WrappLoading>
        ) : (
          <Table
            data={data}
            columns={[
              {
                header: 'Marca',
                acessor: 'brand',
              },
              {
                header: 'Modelo',
                acessor: 'model',
              },
              {
                header: 'Categoria',
                acessor: 'category',
              },
              {
                header: 'Disponível',
                acessor: 'disponibility',
              },
              ...packages,
              {
                header: 'Co-participação',
                acessor: 'franchise',
              },
            ]}
          />
        )}
      </S.TableContainer>
    </>
  )
}

export default PriceTable
