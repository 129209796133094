import api from 'services'

export async function getProducts(params = {}) {
  const { states, brands, category, models, pagination = {}, visible } = params
  const { showing = null, current = 1 } = pagination

  try {
    const response = await api.get('/integracao/products', {
      params: {
        visible: visible || null,
        'disponibility_zones.id': states || null,
        'vehicle.category.id': category || null,
        'vehicle.model.brand.id': brands || null,
        'vehicle.model.id': models || null,
        _limit: showing,
        _start: showing && showing * (current - 1),
      },
    })

    const docs = response.headers['x-doc-count']

    return {
      pagination: {
        items: docs,
        total: showing && docs / showing,
      },
      data: response.data.map((product) => {
        const { id, vehicle, plans, disponibilityZones, packages } = product

        const { category, cover } = vehicle
        const { frontCover, backCover } = cover

        return {
          id,
          code: {
            version: vehicle.code,
            model: vehicle.model.code,
            brand: vehicle.model.brand.code,
          },
          title: vehicle?.title || '',
          category: category?.title || '',
          cover: [frontCover.url, backCover.url],
          available: {
            states: disponibilityZones.map(({ id, title, initials }) => ({
              id,
              title,
              initials,
            })),
            plans: plans.map((plan) => ({ title: plan.title, id: plan.id })),
            packages: packages.map((pkg) => {
              const { cost, disponibilities, mileage, months, plans, id } = pkg
              return {
                id,
                months,
                mileage,
                price: cost.rawValue,
                priceFormatted: cost.formattedValue,
                plans: plans.map((plan) => ({
                  title: plan.title,
                  id: plan.id,
                })),
                states: disponibilities.map((state) => state.title),
              }
            }),
          },
        }
      }),
    }
  } catch (error) {
    console.error(error)
    throw error
  }
}

export async function getProductDetail(params) {
  const { state = 'SP', version, model, brand } = params

  try {
    const response = await api.get('/integracao/stock', {
      params: {
        brandCode: brand || null,
        modelCode: model || null,
        versionCode: version || null,
        uf: state?.toUpperCase() || null,
      },
    })

    return response.data.psstockFixedAsset.map((item) => {
      const {
        trademarkCode,
        trademarkDescription,
        modelCode,
        modelDescription,
        versionCode,
        versionDescription,
        colorCode,
        colorDescription,
        currentBalance,
        balancesbyaddress = [],
        purchaseorder = [],
      } = item

      return {
        brand: {
          code: trademarkCode,
          title: trademarkDescription,
        },
        model: {
          code: modelCode,
          title: modelDescription,
        },
        version: {
          code: versionCode,
          title: versionDescription,
        },
        color: {
          code: colorCode,
          title: colorDescription,
        },
        available: {
          total: currentBalance,
          balance: {
            quantity: balancesbyaddress?.filter(
              (item) => item.address === '31003'
            ).length,
            items: balancesbyaddress?.map((item) => {
              const { vehiclePlate, chassisNumber } = item

              return {
                plate: vehiclePlate,
                chassis: chassisNumber,
              }
            }),
          },
          purchase: {
            quantity: balancesbyaddress?.filter(
              (item) => item.address === '31002'
            ).length,
            items: purchaseorder?.map((item) => {
              const { deliveryDate, orderQuantity } = item

              return {
                date: deliveryDate,
                quantity: orderQuantity,
              }
            }),
          },
        },
      }
    })
  } catch (error) {
    console.error(error)
    throw Error(
      'Houve uma instabilidade no sistema. Tente novamente mais tarde ou entre em contato com a Porto Seguro.'
    )
  }
}

export async function getDisponibilityZones() {
  try {
    const response = await api.get('/integracao/disponibility-zones')

    return response.data
  } catch (error) {
    console.error(error)
    throw error
  }
}

export async function getBrands(params = {}) {
  const { states } = params

  try {
    const response = await api.get('/integracao/vehicle-brands', {
      params: {
        disponibility_zones_in: states || null,
      },
    })

    return response.data.map((brand) => {
      const { id, code, title } = brand

      return {
        id,
        code,
        title,
      }
    })
  } catch (error) {
    console.error(error)
    throw error
  }
}

export async function getModels(params = {}) {
  const { states, brands } = params

  try {
    const response = await api.get('/integracao/vehicle-models', {
      params: {
        disponibility_zones_in: states || null,
        'brand.id': brands || null,
      },
    })

    return response.data.map((model) => {
      const { id, code, title } = model

      return {
        id,
        code,
        title,
      }
    })
  } catch (error) {
    console.error(error)
    throw error
  }
}

