import React from 'react'
import { Flex, Icon } from 'components'
import { Card, Text, Wrapper } from './styles'
import { REANALYSIS_STATUS } from 'helpers'

export default function MessageCard({ type, message }) {
  return REANALYSIS_STATUS[type] ? (
    <Card bgColor={REANALYSIS_STATUS[type]?.bgColor}>
      <Wrapper>
        <Flex alignItems="center" justifyContent="center">
          <Icon
            size="16px"
            name={REANALYSIS_STATUS[type]?.icon}
            color={REANALYSIS_STATUS[type]?.color}
          />
        </Flex>
        <Text color={REANALYSIS_STATUS[type]?.color}>
          {message ? message : REANALYSIS_STATUS[type]?.message}
        </Text>
      </Wrapper>
    </Card>
  ) : (
    <></>
  )
}
