import { rem, rgba } from 'polished'
import styled, { css } from 'styled-components'
import media from 'styled-media-query'
import * as Tip from '../../components/Tip/styles'

export const Container = styled.div`
  padding: 40px 50px;

  ${media.lessThan('small')`
    padding: 40px 15px;
  `}

  ${Tip.Mark} {
    padding: 0;
  }

  #distribution {
    max-width: 300px;
  }
`

export const Filter = styled.div`
  margin: 24px 0;

  display: flex;
  justify-content: space-between;
  align-items: center;

  ${media.lessThan('medium')`
    flex-direction: column;
    gap: 24px;
  `}
`

export const Dialog = styled.div`
  ${({ theme }) => css`
    margin-bottom: 50px;

    padding: ${theme.spacings.medium};
    border-radius: ${theme.borderRadius.large};

    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
    background: #fff;

    ${media.lessThan('medium')`
      padding: ${theme.spacings.small};
    `}
  `}
`

export const Label = styled.p`
  ${({ theme }) => css`
    margin-right: ${rem('18px')};

    color: ${theme.colors.black};
    font-family: ${theme.fonts.bold};
    font-size: ${theme.fonts.sizes.xsmall};
  `}
`

export const Header = styled.div`
  height: 60px;
  padding: 0 20px;

  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const Title = styled.p`
  margin-bottom: 20px;

  color: ${({ theme }) => theme.colors.gray.medium};
  font-family: ${({ theme }) => theme.fonts.bold};
  font-size: 14px;
  text-align: center;

  ${media.lessThan('small')`
    font-size: 10px;
  `}
`

export const Table = styled.div`
  width: 100%;

  display: grid;
  grid-row-gap: 20px;
`

export const Column = styled.div`
  display: flex;
  justify-content: center;

  font-family: ${({ theme }) => theme.fonts.book};
`

export const Item = styled.div`
  height: 60px;
  padding: 0 20px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  box-shadow: 0px 0px 20px ${rgba('#000', 0.1)};
  border-radius: 10px;
  background: #fff;
  cursor: grab;
  text-align: center;

  ${({ dragged }) =>
    dragged &&
    css`
      cursor: grabbing;
    `};
`

export const Frame = styled.button`
  width: 30px;
  height: 30px;
  margin: auto;

  display: flex;
  justify-content: center;
  align-items: center;

  background: ${({ theme }) => theme.colors.danger};
  border-radius: 10px;
  cursor: pointer;
  border: none;
  outline: none;

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
    `}
`

export const Input = styled.input`
  width: 30px;
`

export const Loader = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: ${({ theme }) => theme.layers.modal};

  content: '';
  background-color: ${rgba('#000', 0.8)};

  &:before {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    width: 50px;
    height: 50px;

    content: '';
    background-image: url('/images/loader.svg');
    background-size: contain;
  }
`
