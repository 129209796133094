// eslint-disable-next-line
import { RouteProps } from 'react-router-dom'

import { USER_ROLES } from 'helpers'

import * as Pages from 'pages'

/**
 * @typedef {Object} RoutePropsExtended
 * @property {RouteProps} [layout]
 * @property {RouteProps} [public]
 * @property {RouteProps} [roles]
 * @typedef {RouteProps & RoutePropsExtended} Route
 */

/**
 * @type {Array<Route>}
 */
const routes = [
  {
    path: '/',
    component: Pages.Home,
  },
  // {
  //   path: '/pedidos/:id',
  //   component: Pages.OrdersEdit,
  // },
  {
    path: '/clientes',
    component: Pages.CustomerList,
  },
  {
    path: '/clientes/:id',
    component: Pages.CustomerView,
  },
  {
    path: '/corretor',
    component: Pages.Brokers,
    roles: [USER_ROLES.BROKER],
  },
  {
    path: '/estoque',
    component: Pages.StockList,
  },
  {
    path: '/precos',
    component: Pages.Prices,
  },
  {
    path: '/usuarios',
    component: Pages.UsersList,
    roles: [USER_ROLES.ADMINISTRATOR],
  },
  {
    path: '/usuarios/novo',
    component: Pages.UsersRegister,
    roles: [USER_ROLES.ADMINISTRATOR],
  },
  {
    path: '/usuarios/:id',
    component: Pages.UsersEdit,
    roles: [USER_ROLES.ADMINISTRATOR],
  },
  {
    path: '/cupons',
    component: Pages.CouponsList,
    roles: [USER_ROLES.ADMINISTRATOR],
  },
  {
    path: '/cupons/:id',
    component: Pages.CouponsEdit,
    roles: [USER_ROLES.ADMINISTRATOR],
  },
  {
    path: '/cupons/novo',
    component: Pages.CouponsEdit,
    roles: [USER_ROLES.ADMINISTRATOR],
  },
  {
    path: '/distribuicao',
    component: Pages.Distribution,
    roles: [USER_ROLES.ADMINISTRATOR],
  },
  {
    path: '/renovacao',
    component: Pages.RenovationList,
  },
  {
    path: '/renovacao/:id',
    component: Pages.RenovationView,
  },
  {
    path: '/alterar-status/:id',
    component: Pages.RenovationEdit,
    roles: [USER_ROLES.ADMINISTRATOR],
  },
  {
    public: true,
    path: '/login',
    component: Pages.Login,
  },
  {
    public: true,
    path: '/esqueci-senha',
    component: Pages.UsersForgotPassword,
  },
  {
    public: true,
    path: '/maintenance',
    component: Pages.Maintenance,
  },

  {
    public: true,
    path: '/cadastro',
    component: Pages.UsersSignUp,
  },
  {
    path: '/indicadores',
    component: Pages.Indicators,
  },
  {
    path: '/novidades/:id',
    component: Pages.ViewNews,
  },
  {
    path: '/novidades',
    component: Pages.ListNews,
  },
  {
    path: '/editar-perfil',
    component: Pages.Profile,
  },
  {
    path: '/cadastro-novidades',
    component: Pages.CreateNews,
    roles: [USER_ROLES.ADMINISTRATOR],
  },
  {
    path: '/novidades/editar/:id',
    component: Pages.EditNews,
    roles: [USER_ROLES.ADMINISTRATOR],
  },
  { path: '*', component: Pages.Page404 },
]

export default routes
