import React, { useEffect, useRef } from 'react'

import { useOffcanvas } from 'hooks'
import { Button, Allow } from 'components'

import { Container, Box, Head, Body, Nav, Item, Overlay } from './styles'

export default function Offcanvas({ items }) {
  const ref = useRef()
  const { visible, toggle } = useOffcanvas()
  const dataLayer = window.dataLayer || []

  useEffect(() => {
    if (visible) {
      document.body.style.overflow = 'hidden'
    }
    return () => (document.body.style.overflow = 'unset')
  }, [visible])

  const closeOffcanvas = () => {
    toggle()

    dataLayer.push({
      event: 'modal',
      action: 'closed',
      name: 'menu-lateral',
    })
  }

  return (
    visible && (
      <Container>
        <Overlay onClick={closeOffcanvas} />
        <Box ref={ref}>
          <Head>
            <Button
              data-testid="button-close-menu"
              data-gtm-type="click"
              data-gtm-clicktype="button"
              data-gtm-name="fechar"
              size="small"
              color="secondary"
              onClick={closeOffcanvas}
            >
              Fechar
            </Button>
          </Head>

          <Body>
            <Nav data-testid={`menu-item`}>
              {items.map((item, index) => (
                <Allow key={index} roles={item.roles}>
                  <Item
                    exact
                    data-testid={item.testid}
                    data-gtm-type="click"
                    data-gtm-clicktype="button"
                    data-gtm-name={item.label.toLowerCase()}
                    data-gtm-subname="modal-menu"
                    to={item.path}
                    onClick={toggle}
                    activeClassName="active-link"
                  >
                    {item.label}
                  </Item>
                </Allow>
              ))}
            </Nav>
          </Body>
        </Box>
      </Container>
    )
  )
}
