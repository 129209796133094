import styled, { css } from 'styled-components'

export const Container = styled.div`
  ${({ theme }) => css`
    .customStyles {
      border-radius: 10px;
      font-size: ${theme.fonts.sizes.xxsmall};
      font-weight: 400;
    }
  `}
`

export const Mark = styled.div`
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
  transition: 0.15s;
  border-radius: 3px;
`
