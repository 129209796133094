import { createAsyncThunk } from '@reduxjs/toolkit'
import { setLogin } from 'services'
import { setLogout } from './index'

export const handleLoginAndSetUserInfos = createAsyncThunk(
  'users/handleLoginAndSetUserInfos',
  async (payload) => {
    const { employee = {}, broker = {} } = payload

    const PAYLOADS = {
      employee: {
        email: employee.email,
        password: employee.password,
        type: 'employee',
      },
      broker: {
        susep: broker.susep,
        password: broker.password,
        type: 'broker',
      },
    }

    const response = await setLogin({
      payload: PAYLOADS[payload.type],
    })

    return response
  }
)

export const handleLogoutToCurrentUser = () => (dispatch) => {
  dispatch(setLogout())
  localStorage.removeItem('token')
  localStorage.removeItem('refresh_token')
}
