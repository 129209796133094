export const convertToUsdValue = (value) => {
  if (typeof value === 'number') {
    return value
  }
  const field = value?.replace(/([^0-9,])+/g, '')
  const decimal = field?.replace(',', '.')

  return parseFloat(decimal)
}

export const handleBrlValue = (value) => {
  const convertedValue = convertToUsdValue(value)

  if (!isNaN(convertedValue)) {
    const brl = convertedValue?.toLocaleString('pt-br', {
      style: 'currency',
      currency: 'BRL',
    })

    return brl
  }
}
