import React, { useEffect, useState } from 'react'
import { X } from 'react-feather'
import { Loading } from 'components'

import { getEndOfPlate } from 'services'

import { SimpleModal as Modal } from 'components'

import { END_OF_PLATE } from 'helpers'
import * as S from './styles'
import { useLocation } from 'react-router-dom'

export default function Table({ data, vehicleId }) {
  const [isOpen, setIsOpen] = useState(false)
  const [selectedColor, setSelectedColor] = useState({})
  const [endOfPlateData, setEndOfPlateData] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState('')

  const location = useLocation()
  const stockParams = new URLSearchParams(location.search)
  const state = stockParams.get('state')
  const disabledModalEndOfPlate = state === 'all'

  const isEmptyObject = !!Object.keys(selectedColor).length
  const dataLayer = window.dataLayer || []

  useEffect(() => {
    if (isEmptyObject) {
      fetchEndOfPlate()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedColor])

  const fetchEndOfPlate = async () => {
    setIsLoading(true)
    setError('')
    setEndOfPlateData([])
    try {
      const id = vehicleId

      const params = {
        colorCode: selectedColor?.code,
        state: selectedColor?.zone,
      }

      const data = await getEndOfPlate(id, params)

      setEndOfPlateData(data)
    } catch (error) {
      setError(error.message)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden'
    }
    return () => (document.body.style.overflow = 'unset')
  }, [isOpen])

  const handleSelectColor = (row) => {
    !disabledModalEndOfPlate && setSelectedColor(row)
  }

  const handleOpenModal = (title) => {
    !disabledModalEndOfPlate && setIsOpen(true)
    dataLayer.push({
      event: 'modal',
      action: 'open',
      name: title,
    })
  }

  const handleCloseModal = (title) => {
    setIsOpen(false)
    dataLayer.push({
      event: 'modal',
      action: 'closed',
      name: title,
    })
  }

  const rederTableHead = () => {
    return (
      <tr>
        <td></td>
        {data?.delivery?.map((el, index) => (
          <th key={index}>
            <S.Color color={el.hex}>
              <S.Tip>
                <p>{el.name}</p>
              </S.Tip>
              <span />
            </S.Color>
          </th>
        ))}
      </tr>
    )
  }
  const rederTableFast = () => {
    return (
      <tr>
        <S.TH>
          Rápida
          <S.Tip>
            <p>Até 10 dias úteis</p>
          </S.Tip>
        </S.TH>
        {data?.delivery?.map((row, index) => (
          <S.TD
            data-gtm-click="click"
            data-gtm-clicktype="icone"
            data-gtm-name={row.name}
            key={index}
            onClick={() => {
              handleOpenModal(data.model)
              handleSelectColor(row)
            }}
          >
            {row.type.fast}
            {!disabledModalEndOfPlate && (
              <S.Tip>
                <p>Ver final de placas</p>
              </S.Tip>
            )}
          </S.TD>
        ))}
      </tr>
    )
  }

  const rederTableRegular = () => {
    return (
      <tr>
        <S.TH>
          Normal
          <S.Tip>
            <p>Até 30 dias úteis</p>
          </S.Tip>
        </S.TH>
        {data?.delivery?.map((row, index) => (
          <S.TD key={index}>{row.type.regular}</S.TD>
        ))}
      </tr>
    )
  }

  const modalEndOfPlate = () => {
    return (
      <Modal open={isOpen}>
        <S.ModalContainer>
          <S.ModalHeader>
            <S.ModalContent>
              <S.ModalTitle>{data.model}</S.ModalTitle>
              <S.ModalSubtitle>{data.category}</S.ModalSubtitle>
              <S.Tag>Entrega Rápida</S.Tag>
            </S.ModalContent>

            <S.VehicleColor>
              <S.Color color={selectedColor.hex}>
                <span />
              </S.Color>
              <span>{selectedColor.name}</span>
            </S.VehicleColor>

            <S.CloseButton
              data-gtm-click="click"
              data-gtm-clicktype="button"
              data-gtm-name="fechar"
              onClick={() => {
                handleCloseModal(data.model)
              }}
            >
              <X size="20px" />
            </S.CloseButton>
          </S.ModalHeader>
          <S.Line />
          <S.ModalBody>
            <h4>Finais de placa disponíveis</h4>
            {isLoading && <Loading size={'8px'} color={'#0027F4'} />}
            {error && <S.Error>{error}</S.Error>}
            <S.EndOfPlate>
              {endOfPlateData
                ?.filter((plate) =>
                  Object.keys(END_OF_PLATE).includes(plate.type)
                )
                ?.map((plate) => {
                  return (
                    <S.PlateContainer key={plate.type}>
                      <p>{END_OF_PLATE[plate.type]}</p>
                      <div>
                        <img src="/images/icons/car.svg" alt="Carro" />
                        <span>{plate.value} un.</span>
                      </div>
                    </S.PlateContainer>
                  )
                })}
            </S.EndOfPlate>
          </S.ModalBody>
        </S.ModalContainer>
      </Modal>
    )
  }

  return (
    <>
      <S.Container>
        <thead>{rederTableHead()}</thead>
        <tbody>
          {rederTableFast()}
          {rederTableRegular()}
        </tbody>
      </S.Container>
      {isOpen && modalEndOfPlate()}
    </>
  )
}
