import React from 'react'
import { Controller, ErrorMessage, useFormContext } from 'react-hook-form'

import * as S from './styles'

const Input = ({ name, label, onChange, uppercase = false, ...props }) => {
  const { errors, control } = useFormContext()

  return (
    <S.Wrapper>
      {!!label && <S.Label htmlFor={name}>{label}</S.Label>}
      <S.InputContainer>
        <Controller
          control={control}
          name={name}
          onChange={([event]) => {
            event.target.value = uppercase
              ? event.target.value.toUpperCase()
              : event.target.value

            return onChange({
              name: event.target.name,
              value: event.target.value,
            })
          }}
          as={
            <S.Input
              id={name}
              data-gtm-name={label.toLowerCase()}
              data-gtm-form="input"
              {...props}
            />
          }
        />

        <ErrorMessage errors={errors} name={name} as={<S.Error />} />
      </S.InputContainer>
    </S.Wrapper>
  )
}

export default Input
