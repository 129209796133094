import {
  Button,
  Flex,
  Icon,
  Input,
  Loading,
  Pagination,
  Table,
  Tip,
} from 'components'
import { USER_ROLES, USER_ROLE_BR } from 'helpers'
import { useNotification } from 'hooks'
import React, { useCallback, useEffect, useState } from 'react'
import { FormContext, useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { getAllUsers, resetPassowrd } from 'services'
import * as S from './styles'
import validationSchema from './validation'
import qs from 'qs'

import { formatCPF } from 'utils'

export default function List() {
  const query = qs.parse(window.location.search, {
    ignoreQueryPrefix: true,
  })

  const history = useHistory()
  const notification = useNotification()
  const form = useForm({ validationSchema })

  const [users, setUsers] = useState([])

  const [loading, setLoading] = useState(false)
  const [resetPasswordLoadin, setResetPasswordLoading] = useState(false)
  const [hasError, setHasError] = useState('')

  const [params, setParams] = useState({
    email: query?.email || null,
    susep: query?.susep || null,
  })

  const [updateFilter, setUpdateFilter] = useState(true)
  const [pagination, setPagination] = useState({
    limit: 25,
    page: 1,
    end: false,
  })

  const sendChangePassword = useCallback(async ({ role, ...rest }) => {
    setResetPasswordLoading(true)
    try {
      const params = {
        type: role === 'broker' ? role : 'employee',
        ...(role === 'broker' ? { susep: rest.susep } : { email: rest.email }),
      }
      const { email } = await resetPassowrd(params)

      notification.open({
        type: 'success',
        content: `Nós enviamos ao e-mail ${email} um link para alterar a senha.`,
      })
    } catch (error) {
      notification.open({
        type: 'danger',
        content: error.message,
      })
    } finally {
      setResetPasswordLoading(false)
    }
  }, []) //eslint-disable-line

  const clearFilters = () => {
    setUsers([])
    setParams({})

    form.setValue('email', '')
    form.setValue('susep', '')
    form.clearError('email')
    history.push('usuarios')
    setUpdateFilter(true)
  }

  const onSubmit = form.handleSubmit((values) => {
    setUsers([])

    const params = {
      email: values?.email || null,
      susep: values?.susep || null,
    }

    const queryString = qs.stringify({ ...params }, { skipNulls: true })

    history.push(`/usuarios?${queryString}`)

    setParams((prev) => ({ ...prev, ...params }))
    setPagination((state) => ({ ...state, page: 1 }))
    setUpdateFilter(true)
  })

  const fetchUsersInfo = useCallback(async () => {
    setLoading(true)
    try {
      const { limit, page } = pagination

      const response = await getAllUsers({
        page,
        limit,
        ...params,
      })

      const data = response?.data.map((value) => {
        const { email, name, document, role, susep, id } = value

        return {
          email: email || '-',
          name: name || '-',
          document: formatCPF(document) || '-',
          role: USER_ROLE_BR[role],
          susep: susep || '-',
          actions: () => (
            <Flex>
              <S.Action
                onClick={() => sendChangePassword({ role, email, susep })}
                size="small"
              >
                <Tip size="15px" name="Recuperação de senha" icon="key">
                  Recuperação de senha
                </Tip>
              </S.Action>
              {[USER_ROLES.BROKER, USER_ROLES.ADVISER].includes(role) && (
                <S.Action
                  size="small"
                  onClick={() => {
                    history.push(`/usuarios/${id}`)
                  }}
                >
                  <Tip size="15px" name="Editar usuário" icon="edit">
                    Editar usuário
                  </Tip>
                </S.Action>
              )}
            </Flex>
          ),
        }
      })
      setUsers(data)

      const end =
        response?.pagination.current_page === response?.pagination.total_pages

      setPagination((state) => ({ ...state, end }))
    } catch (error) {
      setHasError(error.message)
    } finally {
      setUpdateFilter(false)
      setLoading(false)
    }
  }, [pagination, params, sendChangePassword, history])

  const handlePagination = (type) => {
    window.scrollTo(0, 0)
    setUsers([])
    setPagination((state) => ({
      ...state,
      page: {
        first: 1,
        prev: state.page - 1,
        next: state.page + 1,
      }[type],
    }))

    setUpdateFilter(true)
  }

  useEffect(() => {
    const query = qs.parse(window.location.search, {
      ignoreQueryPrefix: true,
    })

    if (query?.email) {
      form.setValue('email', query.email)
    }

    if (query?.susep) {
      form.setValue('susep', query.susep)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (updateFilter) {
      fetchUsersInfo()
    }
  }, [fetchUsersInfo, updateFilter])

  return (
    <S.Container>
      <FormContext {...form}>
        <S.Filters>
          <Input name="email" label="E-mail" placeholder="E-mail" />
          <Input name="susep" label="SUSEP" placeholder="SUSEP" toUpperCase />

          <S.SearchButton
            disabled={!(form.watch('email') || form.watch('susep'))}
            onClick={onSubmit}
          >
            <Flex justifyContent="center" alignItems="center">
              <Icon name="search" size={21} color="white" />
            </Flex>
          </S.SearchButton>

          <Button onClick={() => history.push('/usuarios/novo')}>
            Cadastrar novo usuário
          </Button>
          {(form.watch('email') || form.watch('susep')) && (
            <Button type="button" link onClick={clearFilters}>
              Limpar filtros
            </Button>
          )}
        </S.Filters>
      </FormContext>

      <S.Content>
        {!hasError ? (
          <>
            <S.TableContainer>
              <Table
                data={users}
                isLoading={loading}
                columns={[
                  {
                    header: 'Nome',
                    acessor: 'name',
                  },
                  {
                    header: 'E-mail',
                    acessor: 'email',
                  },
                  {
                    header: 'CPF',
                    acessor: 'document',
                  },
                  {
                    header: 'Função',
                    acessor: 'role',
                  },
                  {
                    header: 'SUSEP',
                    acessor: 'susep',
                  },
                  {
                    header: '',
                    acessor: 'actions',
                  },
                ]}
              />
            </S.TableContainer>
            {!!users.length && !loading && (
              <Pagination
                handlePagination={handlePagination}
                pagination={pagination}
              />
            )}
          </>
        ) : (
          <S.Error>{hasError}</S.Error>
        )}
      </S.Content>

      <S.Loader showLoading={resetPasswordLoadin}>
        <S.Overlay showLoading={resetPasswordLoadin} />
        <S.WrapLoading showLoading={resetPasswordLoadin}>
          <Loading size={'10px'} color={'#FFF'} />
        </S.WrapLoading>
      </S.Loader>
    </S.Container>
  )
}

List.properties = {
  metatags: {
    title: 'Usuários',
  },
}
