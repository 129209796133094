import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { Allow, Button, Grid, Searchable } from 'components'
import { USER_ROLES } from 'helpers'
import { getBrokers, updateOrder } from 'services'
import { useCurrentUser } from 'hooks'

import { Dialog, Label } from '../../styles'

export default function Brokers() {
  const user = useCurrentUser()
  const params = useParams()

  const [brokers, setBrokers] = useState({
    loading: false,
    selected: '',
    button: {
      text: 'Enviar',
      color: '',
    },
    data: [],
  })

  const fetchBrokers = async () => {
    setBrokers((state) => ({ ...state, loading: true }))

    try {
      const response = await getBrokers()

      const data = response
        .filter((user) => user.susep)
        .map((user) => ({
          value: user.id,
          label: `${user.name} | ${user.susep}`,
        }))

      setBrokers((state) => ({ ...state, data }))
    } catch (error) {
      setBrokers((state) => ({
        ...state,
        button: { text: 'Erro na busca de corretores', color: 'danger' },
      }))
    } finally {
      setBrokers((state) => ({ ...state, loading: false }))
    }
  }

  const setSelectedBroker = async (event, { action }) => {
    if (action === 'select-option') {
      setBrokers((state) => ({ ...state, selected: event.value }))
    }
  }

  const changeBroker = async () => {
    setBrokers((state) => ({ ...state, loading: true }))
    try {
      await updateOrder(params.id, { representative: brokers.selected })
      setBrokers((state) => ({
        ...state,
        button: { text: 'Enviado!', color: 'success' },
        selected: '',
      }))
    } catch (error) {
      setBrokers((state) => ({
        ...state,
        button: { text: 'Erro ao atualizar!', color: 'danger' },
      }))
    } finally {
      setBrokers((state) => ({
        ...state,
        loading: false,
      }))
    }
  }

  useEffect(() => {
    user.role === USER_ROLES.ADMINISTRATOR && fetchBrokers()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Allow roles={[USER_ROLES.ADMINISTRATOR]}>
      <Dialog>
        <Label>Mudar corretor</Label>
        <Grid
          my={4}
          gridGap="16px"
          justifyContent="space-between"
          gridTemplateColumns={['200px', '400px 200px']}
        >
          <Searchable
            options={brokers.data}
            placeholder="Procurar SUSEP"
            isClearable
            onChange={setSelectedBroker}
            isLoading={brokers.loading}
          />
          <Button
            onClick={changeBroker}
            isLoading={brokers.loading}
            disabled={!brokers.selected}
            color={brokers.button.color}
          >
            {brokers.button.text}
          </Button>
        </Grid>
      </Dialog>
    </Allow>
  )
}
