import styled, { css } from 'styled-components'
import media from 'styled-media-query'
import { rem } from 'polished'

export const Container = styled.table`
  width: 100%;
  font-size: 14px;
  line-height: 18px;
  text-align: left;
  border-collapse: collapse;
  table-layout: auto;
  position: relative;

  ${media.lessThan('small')`
    font-size: 12px;
  `}
`
export const THead = styled.thead``
export const TBody = styled.tbody`
  overflow: auto;
`

export const Tip = styled.div`
  background-color: ${({ theme }) => theme.colors.gray.medium};
  min-width: ${rem('160px')};
  position: absolute;
  text-align: center;
  top: ${rem('-40px')};
  left: 50%;
  transform: translateX(-50%);
  border-radius: 8px;
  padding: 8px 5px;

  visibility: hidden;
  opacity: 0;
  z-index: 100;

  transition: opacity 0.5s ease-in-out;

  &::after {
    content: '';
    display: block;
    position: absolute;
    background-color: ${({ theme }) => theme.colors.gray.medium};

    width: 12px;
    height: 12px;

    left: 50%;
    transform: translateX(-50%) rotate(45deg);
  }

  p {
    color: #fbfbfb;
    font-size: 11px;
    font-weight: 400;
    font-family: ${({ theme }) => theme.fonts.book};
  }
`

export const TH = styled.th`
  display: table-cell;
  vertical-align: bottom;

  padding: ${rem('12px')};
  height: 100px;

  span {
    position: relative;
    &:hover {
      ${({ showToolTip }) =>
        showToolTip &&
        css`
          ${Tip} {
            visibility: visible;
            opacity: 1;
          }
        `}
    }
  }
`
export const TD = styled.td`
  padding: ${rem('12px')};
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  white-space: nowrap;
  transition: background 0.5s ease;
  text-transform: uppercase;
  font-size: ${rem('12px')};

  ${media.greaterThan('huge')`
    font-size: ${rem('14px')};
  `}
`

export const TR = styled.tr`
  ${({ cursor }) => css`
    cursor: ${cursor};

    &:hover ${TD} {
      background: ${({ theme }) => theme.colors.gray.light};
    }
  `}
`
