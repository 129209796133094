import { Grid } from 'components'
import { MARITAL_STATUSES } from 'helpers'
import React, { useEffect, useState } from 'react'

import { Box, Dialog, Label, Message, Name, Value } from '../../styles'

export default function Drivers({ order }) {
  const [drivers, setDrivers] = useState([])

  useEffect(() => {
    const { additionalDrivers } = order

    const addDrivers = additionalDrivers?.map((driver) => {
      const {
        id,
        fullName,
        email,
        cnh,
        fatherName,
        motherName,
        birthdate,
        rg,
        cpf,
        marital,
      } = driver
      const address = `${driver?.defaultAddress?.street}, ${driver?.defaultAddress?.number}, ${driver?.defaultAddress?.city} - ${driver?.defaultAddress?.state}`
      return {
        id,
        fullName,
        email,
        address,
        cnh,
        fatherName,
        motherName,
        birthdate,
        rg,
        cpf,
        marital,
      }
    })

    setDrivers(addDrivers)
  }, [order])

  return (
    <Dialog>
      <Label>Condutores adicionais</Label>
      {drivers?.length > 0 ? (
        drivers.map((driver) => (
          <Grid
            my={4}
            gridTemplateColumns={['1fr', '1fr 1fr', '1fr 1fr 1fr']}
            gridGap="30px"
            key={driver.id}
          >
            <Box>
              <Name>Nome: </Name>
              <Value>{driver.fullName}</Value>
            </Box>
            <Box>
              <Name>E-mail: </Name>
              <Value>{driver.email}</Value>
            </Box>
            <Box>
              <Name>Endereço: </Name>
              <Value>{driver.address}</Value>
            </Box>
            <Box>
              <Name>Estado Civil: </Name>
              <Value>{MARITAL_STATUSES[driver.marital]}</Value>
            </Box>
            <Box>
              <Name>CPF: </Name>
              <Value>{driver.cpf?.number}</Value>
            </Box>
            <Box>
              <Name>RG: </Name>
              <Value>{driver.rg?.number}</Value>
            </Box>
            <Box>
              <Name>Data de nascimento: </Name>
              <Value>{driver.birthdate}</Value>
            </Box>
            <Box>
              <Name>Nome do pai: </Name>
              <Value>{driver.fatherName}</Value>
            </Box>
            <Box>
              <Name>Nome da mãe: </Name>
              <Value>{driver.motherName}</Value>
            </Box>
            <Box>
              <Name>Data de emissão da CNH: </Name>
              <Value>{driver.cnh.emission}</Value>
            </Box>
            <Box>
              <Name>Data da validade da CNH: </Name>
              <Value>{driver.cnh.expiration}</Value>
            </Box>
            <Box>
              <Name>Numero da CNH: </Name>
              <Value>{driver.cnh.number}</Value>
            </Box>
            <Box>
              <Name>Data de emissão da 1° CNH: </Name>
              <Value>{driver.cnh.firstCNH}</Value>
            </Box>
          </Grid>
        ))
      ) : (
        <Message>Não há condutores adicionais</Message>
      )}
    </Dialog>
  )
}
