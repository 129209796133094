import styled from 'styled-components'
import media from 'styled-media-query'
import { rgba } from 'polished'

export const Container = styled.div`
  padding: 40px 50px;

  ${media.lessThan('small')`
    padding: 40px 1rem;
  `}
`

export const Loader = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: ${({ theme }) => theme.layers.modal};

  content: '';
  background-color: ${rgba('#000', 0.8)};

  &:before {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    width: 50px;
    height: 50px;

    content: '';
    background-image: url('/images/loader.svg');
    background-size: contain;
  }
`

export const ErrorMessage = styled.div`
  span {
    font-size: 18px;
    font-family: ${({ theme }) => theme.fonts.medium};

    color: ${({ theme }) => theme.colors.danger};
  }
`
