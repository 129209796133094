import styled, { css } from 'styled-components';
import media from 'styled-media-query';
import { grid, layout, space } from 'styled-system';
import * as TipStyles from 'components/Tip/styles'


export const Container = styled.div`
  ${({ theme }) => css ``}
`;

export const Fields = styled.div`
  ${grid}
  ${layout}
  ${space}

  display: grid;

  ${({ hidden }) =>
    hidden &&
    css`
      display: none;
    `}
`

export const InputWrapper = styled.div`
  position: relative;
`

export const InputWithTooltip = styled.div`
  position: relative;

  ${TipStyles.Mark} {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    right: -32px;

    ${media.lessThan('medium')`
      right: -24px;
    `}
  }
`

export const Action = styled.div`
  width: 38px;
  height: 38px;

  ${media.lessThan('small')`
    width: 32px;
    height: 32px;
  `}
  position: absolute;
  right: 16px;
  top: 50%;

  transform: translate(0, -50%);

  display: flex;
  justify-content: center;
  align-items: center;

  background: ${({ theme }) => theme.colors.primary};
  color: #fff;
  border-radius: 15px;

  :not(:first-of-type) {
    margin-left: 10px;
  }
`

