import styled, { css } from 'styled-components'

export const Container = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    gap: ${theme.spacings.xsmall};
    margin-top: ${theme.spacings.small};
  `}
`

export const PaginationButton = styled.button`
  ${({ theme, disabled }) => css`
    border: 0;
    cursor: pointer;
    background: ${theme.colors.white};
    width: 30px;
    height: 30px;
    border-radius: ${theme.borderRadius.pill};
    display: flex;
    align-items: center;
    justify-content: center;

    > svg {
      color: ${theme.colors.primary};
    }

    ${disabled &&
    css`
      cursor: not-allowed;
      > svg {
        color: ${theme.colors.newGray[20]};
      }
    `}
  `}
`

export const PaginationItem = styled.span`
  ${({ theme, currentPage }) => css`
    cursor: pointer;
    font-weight: 400;
    font-size: ${theme.fonts.sizes.small};
    color: ${theme.colors.newGray[60]};

    ${currentPage &&
    css`
      font-weight: 600;
      color: ${theme.colors.primary};
    `}

    &:hover {
      font-weight: 600;
      color: ${theme.colors.primary};
    }
  `}
`
