import api from 'services'

export async function uploadCSV(file) {
  try {
    const response = await api.post('/cr_renovation/upload_csv', file, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })

    return response.data
  } catch (error) {
    console.error(error)
    throw Error(
      'Houve uma instabilidade no sistema. Tente novamente mais tarde ou entre em contato com a Porto Seguro.'
    )
  }
}
