import React, { useState } from 'react'

import {
  Container,
  Wapper,
  THead,
  TBody,
  TD,
  TH,
  TR,
  Span,
  Tip,
} from './styles'

export default function Table({ data, columns, showTable }) {
  const [clipboard, setClipboard] = useState(false)

  const handleCopyTopClipboard = ({ number, hover }) => {
    if (!hover) {
      return
    }
    setClipboard(!clipboard)
    navigator.clipboard.writeText(number)

    setTimeout(() => {
      setClipboard(false)
    }, 2500)
  }

  const rederTableHead = () => {
    return (
      <TR>
        {columns.map((el, index) => (
          <TH key={index}>{el.header}</TH>
        ))}
      </TR>
    )
  }

  const renderTableCell = ({ row, column }) => {
    if (typeof row[column.acessor] === 'object') {
      return (
        <Wapper>
          <Span color={row?.situation}>{row[column.acessor].situation}</Span>
          <Span>{row[column.acessor].number}</Span>

          <Tip isCopied={clipboard}>
            <span>{!clipboard ? 'Clique para copiar' : 'Copiado!'}</span>
          </Tip>
        </Wapper>
      )
    }

    return <span>{row[column.acessor]}</span>
  }

  const renderTableRow = (row) => {
    return columns?.map((column, index) => (
      <TD
        key={index}
        hasHover={column.hasHover}
        hasMarker={column.hasMarker && row.type === 'Renov. mesmo carro'}
        onClick={() =>
          handleCopyTopClipboard({
            number: row[column.acessor].number,
            hover: column.hasHover,
          })
        }
      >
        {renderTableCell({ row, column })}
      </TD>
    ))
  }

  const rederTableBody = () => {
    return data?.map((row, index) => <TR key={index}>{renderTableRow(row)}</TR>)
  }

  return (
    showTable && (
      <Container>
        <THead>{rederTableHead()}</THead>
        <TBody>{rederTableBody()}</TBody>
      </Container>
    )
  )
}
