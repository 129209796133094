import React, { useEffect, useState } from 'react'
import { FormContext, useForm } from 'react-hook-form'
import qs from 'qs'
import moment from 'moment'
import { useHistory } from 'react-router-dom'
import {
  capitalize,
  handleStatusPcoColor,
  removeSpecialCharacters,
  formatCPF,
} from 'utils'

import {
  Button,
  File,
  Flex,
  Grid,
  Table,
  Input,
  Multiselect,
  Tip,
  Datepicker,
  Allow,
  Pagination,
  Status,
} from 'components'

import { Accordion as AccordionRenovation } from './components/Accordion'

import {
  Container,
  Label,
  Filters,
  Overflow,
  Action,
  FilterButton,
  FiltersContainer,
} from './styles'

import { format as dateFormat } from 'date-fns'
import { getAllClients, uploadCSV } from 'services'
import { STATUS_PCO_OPTIONS, STATUS_PCO, USER_ROLES } from 'helpers'
import { setCpfMask } from 'utils'

export default function List() {
  const forms = useForm()
  const history = useHistory()
  const dataLayer = window.dataLayer || []

  const [statuses, setStatuses] = useState({
    loading: false,
    data: [],
    filtered: false,
  })

  const [filesStatus, setFileStatus] = useState({
    error: '',
    loading: false,
  })
  const [pagination, setPagination] = useState({
    limit: 50,
    page: 1,
    end: false,
  })
  const [params, setParams] = useState({})

  const [pcoStatus, setPcoStatus] = useState([])
  const [updateFilter, setUpdateFilter] = useState(false)

  const [nameOrDocLength, setNameOrDocLength] = useState(150)

  const onFilterSubmit = forms.handleSubmit((values) => {
    if (!values?.nameOrDocument) {
      handleParams({ ...values })
      return
    }

    const formattedCPF = removeSpecialCharacters(values?.nameOrDocument)

    const isNumber = new RegExp('^[0-9]+$')

    if (isNumber.test(formattedCPF)) {
      return formattedCPF.length === 11
        ? handleParams({ ...values, document: formattedCPF })
        : forms.setError('nameOrDocument', 'notMatch', 'Verifique o CPF')
    }

    if (!isNumber.test(formattedCPF)) {
      handleParams({ ...values, name: values.nameOrDocument.toUpperCase() })
      return
    }
  })

  const handleParams = (filters) => {
    let start = ''
    let end = ''

    if (filters?.start) {
      start = dateFormat(filters?.start, 'yyyy-MM-dd')
    }

    if (filters?.end) {
      end = dateFormat(filters?.end, 'yyyy-MM-dd')
    }

    const params = {
      document: filters?.document || null,
      name: filters?.name || null,
      startFinalDateContractPeriod: start || null,
      finalDateContractPeriod: end || null,
    }

    const queryString = qs.stringify(
      { ...params, status: pcoStatus?.status?.map((el) => el.value) },
      { skipNulls: true }
    )

    history.push(`/renovacao?${queryString}`)

    setPagination((state) => ({ ...state, page: 1 }))
    setParams((prev) => ({ ...prev, ...params }))
    setStatuses((prev) => ({ ...prev, filtered: true }))
    setUpdateFilter(true)
  }

  const handleStatusPcoSelect = (values) => {
    if (!values) {
      setPcoStatus(null)
      return
    }

    let status = values?.map((el) => ({ value: el.value, label: el.label }))
    setPcoStatus((prev) => ({ ...prev, status }))
  }

  function handlePagination(type) {
    window.scrollTo({
      top: 250,
    })

    setPagination((state) => ({
      ...state,
      page: {
        first: 1,
        prev: state.page - 1,
        next: state.page + 1,
      }[type],
    }))

    setUpdateFilter(true)
  }

  const clearFilters = () => {
    setParams({})

    forms.setValue('nameOrDocument', '')
    handleParams({ name: null, document: null })
    forms.setValue('start', null)
    forms.setValue('end', null)
    history.replace({
      search: '',
    })
    handleStatusPcoSelect(null)
  }

  async function fetchStatus() {
    setStatuses((state) => ({ ...state, loading: true, data: [] }))
    const { limit, page } = pagination
    const status = pcoStatus?.status?.map((el) => el.value)

    const requestParams = {
      limit,
      page,
      status,
      ...params,
    }

    try {
      const response = await getAllClients({ requestParams })

      const data = response?.proposals?.map((item) => {
        const color = handleStatusPcoColor(
          item?.attempts?.[0]?.status.description
        )
        const RENOVATION_STATUS = {
          DERIVACAO: 'Derivação',
          DADOS_INVALIDO: 'Dados inválidos',
        }

        const status =
          RENOVATION_STATUS[item?.attempts?.[0]?.status.description] ||
          capitalize(item?.attempts?.[0]?.status.description)

        return {
          document: formatCPF(item.document),
          name: item?.name?.toUpperCase(),
          contract: item.contract_number,
          susep: item?.attempts?.[0]?.susep,
          broker: item?.attempts?.[0]?.broker_name?.toUpperCase(),
          date: moment(item?.attempts?.[0]?.created_at).format('DD/MM/YYYY'),
          final_date: moment(item?.attempts?.[0]?.final_date_contract).format(
            'DD/MM/YYYY'
          ),
          status: () => (
            <Status backgroundColor={color} textColor={'#FFFFFF'}>
              {status}
            </Status>
          ),
          actions: () => (
            <Flex>
              <Action
                data-testid="abrir-proposta"
                size="small"
                onClick={() => {
                  history.push(`/renovacao/${item?.id}`)
                }}
              >
                <Tip size="15px" name="Abrir proposta" icon="user">
                  Abrir proposta
                </Tip>
              </Action>
              <Allow roles={[USER_ROLES.ADMINISTRATOR]}>
                <Action
                  data-testid="alterar-status"
                  size="small"
                  onClick={() => {
                    history.push(`/alterar-status/${item?.id}`)
                  }}
                >
                  <Tip size="15px" name="Alterar status" icon="edit">
                    Alterar status
                  </Tip>
                </Action>
              </Allow>
            </Flex>
          ),
        }
      })

      const end =
        response?.pagination.total_pages === response?.pagination.current_page

      setStatuses((state) => ({
        ...state,
        data,
      }))
      setPagination((state) => ({ ...state, end }))
    } catch (error) {
      console.error(error)
    } finally {
      setStatuses((state) => ({ ...state, loading: false }))
    }
  }

  async function sendCsv(e) {
    setFileStatus((state) => ({ ...state, loading: true }))

    const file = e.target.files[0]

    if (!file.name.match(/\.(csv)$/)) {
      setFileStatus({ loading: false, error: 'Formato inválido.' })
      return
    }

    setStatuses((state) => ({ ...state, loading: true }))

    const formData = new FormData()
    formData.append('file', file)

    try {
      await uploadCSV(formData)
      await fetchStatus()
    } catch (error) {
      setFileStatus((state) => ({
        ...state,
        error: 'Erro no envio! Tente novamente.',
      }))
    } finally {
      setFileStatus((state) => ({
        ...state,
        loading: false,
      }))
    }
  }

  const handleQueryStatus = (element) => {
    const select = {
      NEGADO: 'INAPTO',
      REPROVADO: 'INAPTO',
      DERIVACAO: 'DERIVAÇÃO',
    }

    if (select[element]) {
      return { value: element, label: select[element] }
    }

    return { value: element, label: element }
  }

  useEffect(() => {
    dataLayer.push({
      event: 'step_change',
      etapa: '/renovacao',
    })

    const query = qs.parse(window.location.search, {
      ignoreQueryPrefix: true,
    })

    const queryParams = {}

    if (Array.isArray(query.status) && query.status.length > 0) {
      const status = query.status.reduce((acc, el) => {
        if (!STATUS_PCO.includes(el)) {
          return acc
        }

        const pcoStatus = handleQueryStatus(el)
        acc.push(pcoStatus)

        return acc
      }, [])

      setPcoStatus((prev) => ({ ...prev, status }))
    } else if (
      typeof query?.status === 'string' &&
      STATUS_PCO.includes(query?.status)
    ) {
      let pcoStatus = []

      pcoStatus.push(handleQueryStatus(query?.status))

      setPcoStatus((prev) => ({ ...prev, status: pcoStatus }))
    }

    if (
      query?.startFinalDateContractPeriod &&
      moment(query?.startFinalDateContractPeriod).isValid()
    ) {
      const initDate = moment(query.startFinalDateContractPeriod).toDate()
      queryParams.startFinalDateContractPeriod = dateFormat(
        initDate,
        'yyyy-MM-dd'
      )
      forms.setValue('start', initDate)
    }
    if (
      query?.finalDateContractPeriod &&
      moment(query?.finalDateContractPeriod).isValid()
    ) {
      const finalDate = moment(query.finalDateContractPeriod).toDate()
      queryParams.finalDateContractPeriod = dateFormat(finalDate, 'yyyy-MM-dd')
      forms.setValue('end', finalDate)
    }

    if (query?.name) {
      forms.setValue('nameOrDocument', query?.name)
      queryParams.name = query?.name
    }

    if (query?.document) {
      const document = setCpfMask(query?.document)

      forms.setValue('nameOrDocument', document)
      queryParams.document = query?.document
    }

    if (
      query?.status?.length > 0 ||
      query?.name ||
      query?.document ||
      query?.finalDateContractPeriod ||
      query?.startFinalDateContractPeriod
    ) {
      setStatuses((prev) => ({ ...prev, filtered: true }))
    }

    setParams((prev) => ({ ...prev, ...queryParams }))
    setUpdateFilter(true)
  }, []) //eslint-disable-line

  useEffect(() => {
    if (updateFilter) {
      fetchStatus()
      setUpdateFilter(false)
    }
  }, [updateFilter]) //eslint-disable-line

  useEffect(() => {
    const query = window.location.search

    if (!query) {
      setStatuses((prev) => ({ ...prev, filtered: false }))
    }
  }, [updateFilter])

  const handleCpfNameInput = (e) => {
    const document = e.replace(/\D/g, '')
    const isNumber = new RegExp('^[0-9]+$')

    if (isNumber.test(document)) {
      setNameOrDocLength(14)
      return setCpfMask(document)
    }

    setNameOrDocLength(150)
    return e
  }

  return (
    <Container>
      <AccordionRenovation />

      <Allow roles={[USER_ROLES.ADMINISTRATOR, USER_ROLES.ADVISER]}>
        <Label>Submeter análise</Label>
        <Flex
          flexDirection={['column', 'row']}
          width="100%"
          justifyContent="space-between"
        >
          <FormContext {...forms}>
            <File
              isLoading={filesStatus.loading}
              error={filesStatus.error}
              label="Selecionar arquivo"
              name="csv"
              onChange={sendCsv}
              accept=".csv"
              data-testid="renovation-csv"
            />
          </FormContext>
        </Flex>
      </Allow>

      <FormContext {...forms}>
        <Filters
          data-gtm-type="form"
          data-gtm-name="buscar-por"
          onSubmit={onFilterSubmit}
        >
          <Label>Buscar por</Label>
          <FiltersContainer>
            <Input
              name="nameOrDocument"
              label="Nome ou CPF"
              placeholder="Nome ou CPF"
              maxLength={nameOrDocLength}
              onChange={(e) => handleCpfNameInput(e.target.rawValue)}
              data-testid="filtro-nameOrDocument"
            />
            <Multiselect
              name="selectStatus"
              options={STATUS_PCO_OPTIONS}
              placeholder="Selecione um status"
              onChange={handleStatusPcoSelect}
              selectedvalue={pcoStatus?.status}
              data-testid="filtro-selectStatus"
            />
            <Datepicker
              name="start"
              selected={forms.watch('start')}
              startDate={forms.watch('start')}
              endDate={forms.watch('end')}
              maxDate={forms.watch('end')}
              placeholderText="Data inicial"
              data-testid="filtro-start"
              selectsStart
            />

            <Datepicker
              name="end"
              selected={forms.watch('end')}
              startDate={forms.watch('start')}
              endDate={forms.watch('end')}
              minDate={forms.watch('start')}
              placeholderText="Data final"
              data-testid="filtro-end"
              selectsEnd
            />
            <Button color="gray">Buscar</Button>
            <FilterButton>
              {statuses.filtered && (
                <button data-testid="clear-filters" onClick={clearFilters}>
                  Limpar filtros
                </button>
              )}
            </FilterButton>
          </FiltersContainer>
        </Filters>
      </FormContext>

      <Grid mt="32px">
        <Label>Resultados</Label>
        <Overflow>
          <Table
            isLoading={statuses.loading}
            data={statuses.data}
            columns={[
              {
                header: 'CPF',
                acessor: 'document',
              },
              {
                header: 'Nome Completo',
                acessor: 'name',
              },
              {
                header: 'Data Final',
                acessor: 'final_date',
              },
              {
                header: 'Data de Inclusão',
                acessor: 'date',
              },
              {
                header: 'Contrato Vigente',
                acessor: 'contract',
              },
              {
                header: 'SUSEP',
                acessor: 'susep',
              },
              {
                header: 'Corretor',
                acessor: 'broker',
              },
              {
                header: 'Status PCO',
                acessor: 'status',
              },
              {
                header: '',
                acessor: 'actions',
              },
            ]}
          />
        </Overflow>
        {!statuses.loading && !!statuses.data.length && (
          <Pagination
            handlePagination={handlePagination}
            pagination={pagination}
          />
        )}
      </Grid>
    </Container>
  )
}

List.properties = {
  metatags: {
    title: 'Renovação',
  },
}
