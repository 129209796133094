import { useMutation, useQuery } from 'react-query'

import {
  getAdditionalKilometers,
  getVehicleCategorys,
  getVehiclePrices,
  updatePriceTable,
} from 'services/apiV2/prices'

export function useGetVehiclesPrices(params, options) {
  return useQuery(['vehiclesPrices', params], () => getVehiclePrices(params), {
    staleTime: 30 * 1000, //30 seconds
    refetchOnWindowFocus: false,
    ...options,
  })
}

export function useGetVehiclesCategorys(params, options) {
  return useQuery(
    ['vehiclesCategorys', params],
    () => getVehicleCategorys(params),
    {
      staleTime: 60 * 1000 * 10, //10 minutes
      refetchOnWindowFocus: false,
      ...options,
    }
  )
}

export function useGetAdditionalKilometers(options) {
  return useQuery('additionalKilometers', () => getAdditionalKilometers(), {
    staleTime: 60 * 1000 * 10, //10 minutes
    refetchOnWindowFocus: false,
    ...options,
  })
}

export function useUpdatePriceTable(options) {
  return useMutation(
    () => {
      return updatePriceTable()
    },
    { ...options }
  )
}
