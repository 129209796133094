import { format, parseISO, isMatch } from 'date-fns'
import { ptBR } from 'date-fns/locale'

export const handleDateIsValid = (date) => {
  if (!isMatch(date, 'dd/MM/yyyy')) {
    return ''
  }

  return date
}

export const formatBRDate = (date) => {
  if (date) {
    const parsedDate = parseISO(date)

    return format(parsedDate, 'dd/MM/yyyy')
  }

  return ''
}

export const formatPtBRDate = (date) => {
  return format(date, 'dd/MM/yyyy')
}

export const formatPublishDate = (date) => {
  if (!date) {
    return
  }

  const publishDateFormatted = format(new Date(date), " d ' de ' MMMM yyyy", {
    locale: ptBR,
  })

  return publishDateFormatted
}
