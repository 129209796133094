export const ORDER_STATUSES = {
  preapp: 'Lead',
  pending: 'Preenchimento da proposta',
  offer: 'Oferta',
  product: 'Dados do carro escolhido',
  perfil: 'Dados do perfil',
  ordered: 'Em análise',
  derivation: 'Em análise',
  approved: 'Aprovado Crédito',
  denied: 'Recusado crédito',
  drivers: 'Condutores adicionais',
  terms: 'Aceite do termo',
  payment: 'Pagamento',
  reservation: 'Em reserva',
  done: 'Pré Check-in',
  canceled: 'Cancelado',
  expired_cart: 'Cartão expirado',
  send_cnh: 'Envio de CNH',
  cnh_sent: 'Analisando CNH',
  cnh_approved: 'CNH Aprovada',
  cnh_rejected: 'CNH Rejeitada',
  send_selfie: 'Enviando selfie',
  selfie: 'Selfie enviado',
  reanalyze_risk_analysis: 'Em Reanálise',
  pending_risk_analysis: 'Validando dados',
  approved_risk_analysis: 'Pedido Finalizado',
  denied_risk_analysis: 'Cancelado',
  approved_zendesk: 'Pedido Finalizado',
  canceled_zendesk: 'Cancelado',
}

export const STATUS = Object.freeze({
  PREAPP: 'preapp',
  PENDING: 'pending',
  OFFER: 'offer',
  PRODUCT: 'product',
  PERFIL: 'perfil',
  DERIVATION: 'derivation',
  APPROVED: 'approved',
  SEND_CNH: 'send_cnh',
  CNH_SENT: 'cnh_sent',
  CNH_APPROVED: 'cnh_approved',
  CNH_REJECTED: 'cnh_rejected',
  SEND_SELFIE: 'send_selfie',
  SELFIE: 'selfie',
  PENDING_RISK_ANALYSIS: 'pending_risk_analysis',
  DENIED_RISK_ANALYSIS: 'denied_risk_analysis',
  DRIVERS: 'drivers',
  RESERVATION: 'reservation',
  TERMS: 'terms',
  PAYMENT: 'payment',
  DONE: 'done',
  DENIED: 'denied',
  EXPIRED_CART: 'expired_cart',
  REANALYZE_RISK_ANALYSIS: 'reanalyze_risk_analysis',
  APPROVED_ZENDESK: 'approved_zendesk',
  CANCELED_ZENDESK: 'canceled_zendesk',
  CANCELED: 'canceled'
})

export const ORDER_STEP_NUMBER = {
  preapp: 1,
  pending: 2,
  offer: 3,
  product: 4,
  perfil: 5,
  ordered: 6,
  derivation: 6,
  approved: 7,
  denied: 8,
  drivers: 9,
  terms: 10,
  payment: 11,
  done: 12,
  canceled_zendesk: 14,
  approved_zendesk: 14,
  expired_cart: 13,
}

export const UNIFIED_STATUSES = {
  ordered: 'derivation',
}

export const CANCEL_STATUS = [
  'offer',
  'product',
  'perfil',
  'ordered',
  'derivation',
  'approved',
  'denied',
  'drivers',
  'reservation',
  'terms',
]

export const RESET_BIRTH_DATE = Object.freeze('1900-01-01')
