import React from 'react'

import { internal } from './data'

import { Container, Group, Item } from './styles'

export default function Sitemap() {
  return (
    <Container>
      <Group>
        {internal.map(({ text, path, gtmName }, index) => (
          <Item
            data-gtm-type="click"
            data-gtm-clicktype="link"
            data-gtm-name={gtmName}
            href={path}
            key={index}
            target="_blank"
          >
            {text}
          </Item>
        ))}
      </Group>
    </Container>
  )
}
