import styled from 'styled-components'
import media from 'styled-media-query'
import { rem } from 'polished'
import { Container as HelpContainer } from 'components/Help/styles'

export const Container = styled.div`
  padding: 24px;
  overflow: hidden;

  ${media.lessThan('small')`
      padding: 30px 10px;
  `}

  ${HelpContainer} {
    width: 16px;
    height: 16px;
  }
`

export const Content = styled.div`
  margin-top: ${rem('24px')};
  padding-bottom: 40px;
  min-height: 50vh;
`

export const WrappLoading = styled.div`
  margin: 2rem 0;

  display: flex;
  justify-content: center;
  align-items: center;
`

export const Filters = styled.form`
  margin-bottom: 50px;

  ${media.greaterThan('large')`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    
    > div {
      &:not(:last-of-type) {
        margin-right: 30px;
      }
    }

    > button {
      margin-left: 20px;
    }
  `}

  ${media.lessThan('large')`
    margin-bottom: 20px;

    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(2, 1fr);
  `}

  > button {
    border-radius: 8px;

    ${media.lessThan('large')`
      grid-column: 1 / span 2;

      text-align: center;
    `}
  }

  > div {
    ${media.greaterThan('large')`
      width: ${rem('300px')};
    `}
  }
`

export const Error = styled.p`
  padding-top: 20px;

  font-size: 13px;
  color: ${({ theme }) => theme.colors.danger};
  font-family: ${({ theme }) => theme.fonts.bold};

  ${media.lessThan('large')`
    font-size: 11px;
  `}
`
