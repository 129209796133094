import styled, { css } from 'styled-components'
import media from 'styled-media-query'

export const Wrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
  `}
`

export const InputContainer = styled.div`
  ${({ theme }) => css`
    width: 100%;
    box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.1);
    background-color: ${theme.colors.white};
    border-radius: ${theme.borderRadius.medium};
    height: 48px;

    ${media.greaterThan('huge')`
      height: 50px;
    `}

    &:focus-within {
      box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.2);
    }
  `}
`

export const Label = styled.label`
  ${({ theme }) => css`
    color: ${theme.colors.black};
    font-size: ${theme.fonts.sizes.small};
    margin-bottom: ${theme.spacings.xsmall};
    font-weight: 600;
  `}
`

export const Input = styled.input`
  ${({ theme }) => css`
    border: none;
    background: transparent;
    width: 100%;
    height: 100%;
    color: ${theme.colors.primary};
    font-size: ${theme.fonts.sizes.xsmall};
    font-weight: 600;

    outline: none;

    padding: 0 ${theme.spacings.xsmall};

    &::placeholder {
      color: ${theme.colors.newGray[30]};
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    &[type='number'] {
      -moz-appearance: textfield;
    }
  `}
`

export const Error = styled.p`
  position: absolute;
  bottom: -18px;
  left: 0;

  padding: 6px 20px 0;

  font-size: 14px;
  font-family: ${({ theme }) => theme.fonts.medium};

  color: ${({ theme }) => theme.colors.danger};

  ${media.lessThan('small')`
    position: static;
    
    font-size:12px;
  `}
`
