import styled, { css } from 'styled-components'
import * as LoadingStyles from 'components/Loading/styles'

export const Container = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.newGray[60]};
    font-size: ${theme.fonts.sizes.small};
    font-weight: 700;
    margin-bottom: ${theme.spacings.xsmall};
    text-align: left;

    display: flex;
    align-items: center;
    gap: ${theme.spacings.xsmall};

    ${LoadingStyles.Loading} {
      width: auto;

      > div {
        background: ${theme.colors.newGray[60]};
      }
    }
  `}
`
