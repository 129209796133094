import React, { useState, useCallback, useEffect } from 'react'
import { FormContext, useForm } from 'react-hook-form'
import { STATE_OPTIONS, USER_ROLES } from 'helpers'
import { Button, CheckboxSelect, Loading } from 'components'
import { Header, VehicleCard } from './components'
import { useCurrentUser } from 'hooks'
import { useParams } from 'hooks'

import {
  useGetStockBrands,
  useGetStockCategorys,
  useGetStockVehicles,
} from 'services/hooks/stock'

import * as S from './styles'

const DEFAULT_FILTER = { value: 'sp', label: 'São Paulo' }
export default function Stock() {
  const form = useForm()
  const user = useCurrentUser()
  const { params, setParams } = useParams()

  const dataLayer = window.dataLayer || []

  const stockParams = {
    state: params.state ? params.state : DEFAULT_FILTER.value,
    ...params,
  }

  const [error, setError] = useState('')

  const [selected, setSelected] = useState({
    state: null,
    brand: null,
    category: null,
  })

  const {
    data: categoriesOptions,
    isLoading: categoriesLoading,
    isError: isErrorCategories,
  } = useGetStockCategorys(
    {
      ...stockParams,
      state: stockParams.state === 'all' ? null : stockParams.state,
    },
    {
      onError: (error) => {
        setError(error.message)
      },
    }
  )

  const {
    data: brandsOptions,
    isLoading: brandLoading,
    isError: isErrorBrands,
  } = useGetStockBrands(
    {
      category: stockParams?.category,
      state: stockParams.state === 'all' ? null : stockParams.state,
    },
    {
      onError: (error) => {
        setError(error.message)
      },
    }
  )

  const {
    data: stockVehicles,
    isLoading: stockVehiclesLoading,
    isError: isErrorStockVehicles,
  } = useGetStockVehicles(
    {
      ...stockParams,
      state: stockParams.state === 'all' ? null : stockParams.state,
    },
    {
      onSuccess: (data) => {
        dataLayer.push({
          event: 'resultado_busca',
          'nome-servico': 'consulta:estoque',
          tipo_busca: `estado-${params?.state} marca-${
            params?.brand ? params.brand : 'todas'
          } categoria-${params.category ? params.category : 'todas'}`,
          retorno: 'sucesso',
          descricao: data?.pagination?.items_per_page,
        })

        dataLayer.push({
          event: 'solicitacao_servico',
          nome_servico: 'estoque',
          tipo_servico: 'busca',
          retorno: 'sucesso',
          estado: params.state || '',
          marca: params.brand || '',
          categoria: params.category || '',
          susep: user[user.role]?.susep || '',
          descricao: 'estoque retornando com sucesso',
        })
      },
      onError: (error) => {
        dataLayer.push({
          event: 'solicitacao_servico',
          nome_servico: 'estoque',
          tipo_servico: 'busca',
          retorno: 'erro',
          estado: params.state || '',
          marca: params.brand || '',
          categoria: params.category || '',
          susep: user[user.role]?.susep || '',
          descricao: 'erro ao realizar busca de estoque',
          erro: {
            codigo: error.status,
            servico: 'busca-estoque',
            mensagem: error.message,
          },
        })

        dataLayer.push({
          event: 'resultado_busca',
          'nome-servico': 'consulta:estoque',
          tipo_busca: `estado-${params?.state} marca-${
            params?.brand ? params.brand : 'todas'
          } categoria-${params.category ? params.category : 'todas'}`,
          retorno: 'erro',
        })

        setError(error.message)
      },
    }
  )

  const onSubmit = form.handleSubmit((values) => {
    const params = {
      state: values.state?.value || 'all',
      brand: values.brand?.value || null,
      category: values.category?.value || null,
    }

    setParams(params)
  })

  const handleFilters = (name, selected) => {
    setSelected((state) => ({ ...state, [name]: selected }))
    return selected
  }

  const setInitialValue = useCallback(() => {
    form.setValue('state', DEFAULT_FILTER)
    setSelected((prev) => ({ ...prev, state: DEFAULT_FILTER }))
    setParams({ state: DEFAULT_FILTER.value, brand: null, category: null })
  }, []) // eslint-disable-line

  useEffect(() => {
    dataLayer.push({
      event: 'step_change',
      etapa: '/estoque',
    })
    setInitialValue()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleClearFilters = () => {
    if (USER_ROLES.ADMINISTRATOR === user.role) {
      setParams({ brand: null, category: null, state: 'all' })
      form.setValue([{ brand: '' }, { category: '' }, { state: '' }])

      const clearAllFilters = {
        state: null,
        brand: null,
        category: null,
      }

      setSelected(clearAllFilters)

      return
    }

    const clearOnlyBrandAndCategory = {
      state: DEFAULT_FILTER.value,
      brand: null,
      category: null,
    }

    setParams({ state: DEFAULT_FILTER.value, brand: null, category: null })
    setSelected(clearOnlyBrandAndCategory)
    form.setValue([{ brand: '' }, { category: '' }, { state: DEFAULT_FILTER }])
  }

  return (
    <S.Container>
      <Header />

      <FormContext {...form}>
        <S.Filters>
          <CheckboxSelect
            name="state"
            options={STATE_OPTIONS}
            placeholder="Estado"
            selected={selected.state}
            onChange={({ name, selected }) => handleFilters(name, selected)}
          />
          <CheckboxSelect
            name="brand"
            options={brandsOptions}
            placeholder="Marca"
            selected={selected.brand}
            onChange={({ name, selected }) => handleFilters(name, selected)}
            isLoading={brandLoading}
          />
          <CheckboxSelect
            name="category"
            options={categoriesOptions}
            placeholder="Categoria"
            selected={selected.category}
            onChange={({ name, selected }) => handleFilters(name, selected)}
            isLoading={categoriesLoading}
          />
          <Button onClick={onSubmit}>Buscar</Button>
          {(selected.state || selected.brand || selected.category) && (
            <Button type="button" link onClick={handleClearFilters}>
              Limpar filtros
            </Button>
          )}
        </S.Filters>
      </FormContext>

      {(isErrorCategories || isErrorBrands || isErrorStockVehicles) && (
        <S.Error>{error}</S.Error>
      )}

      {!isErrorCategories && !isErrorBrands && !isErrorStockVehicles && (
        <>
          <S.Content>
            {stockVehiclesLoading ? (
              <S.WrappLoading>
                <Loading size={'8px'} color={'#000'} />
              </S.WrappLoading>
            ) : (
              <>
                <VehicleCard vehicle={stockVehicles} />
              </>
            )}
          </S.Content>
        </>
      )}
    </S.Container>
  )
}

Stock.properties = {
  metatags: {
    title: 'Estoque',
  },
}
