import React, { useEffect, useState } from 'react'

import { database } from 'services/firebase'
import { onValue, ref } from 'firebase/database'

import { Container, Subtitle, Text, Title } from './styles'

export default function Maintenance() {
  const [message, setMessage] = useState(``)

  const getMaintenanceMessage = () => {
    const query = ref(database, 'web/config/omni/maintenance/message')
    onValue(query, (snap) => {
      if (snap.exists()) {
        setMessage(snap.val().replaceAll('\n', '<br />'))
      }
    })
  }

  const fallbackMessage =
    '<br /> Fique tranquilo, estamos deixando tudo ainda melhor pra você.<br /><br />Em 24h estará funcionando normalmente.'

  useEffect(() => {
    getMaintenanceMessage()
  }, [])

  return (
    <Container>
      <Title>Calma ;)</Title>
      <Subtitle>tá tudo bem.</Subtitle>
      <Text dangerouslySetInnerHTML={{ __html: message || fallbackMessage }} />
    </Container>
  )
}

Maintenance.properties = {
  title: 'Manutenção',
  hideHeader: true,
}
