import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

import { getCoupons } from 'services'

import { Table, Button } from 'components'

import { Container, Head, Error, Overflow, Content } from './styles'

export default function List() {
  const [coupons, setCoupons] = useState({
    loading: true,
    error: '',
    data: [],
  })

  const fetchAllCoupons = async () => {
    try {
      await getCoupons()

      setCoupons((prev) => ({
        ...prev,
        data: [
          {
            code: 'SOUMODERNO',
            start: '10/05/2020',
            end: '30/05/2020',
            actions: () => (
              <Link to="/cupons/1">
                <Button size="small">Editar</Button>
              </Link>
            ),
          },
        ],
      }))
    } catch (error) {
      setCoupons((prev) => ({ ...prev, error: error.message }))
    } finally {
      setCoupons((prev) => ({ ...prev, loading: false }))
    }
  }

  useEffect(() => {
    fetchAllCoupons()
  }, [])

  return (
    <Container>
      <Head>
        <Link to="/cupons/novo">
          <Button>Cadastrar novo cupom</Button>
        </Link>
      </Head>

      <Content>
        {coupons.error ? (
          <Error>{coupons.error}</Error>
        ) : (
          <Overflow>
            <Table
              isLoading={coupons.loading}
              columns={[
                {
                  header: 'Código',
                  acessor: 'code',
                },
                {
                  header: 'Data inicial',
                  acessor: 'start',
                },
                {
                  header: 'Data final',
                  acessor: 'end',
                },
                {
                  header: '',
                  acessor: 'actions',
                },
              ]}
              data={coupons.data}
            />
          </Overflow>
        )}
      </Content>
    </Container>
  )
}

List.properties = {
  metatags: {
    title: 'Cupons',
  },
}
