import { rem } from 'polished'
import styled from 'styled-components'
import media from 'styled-media-query'


export const Tip = styled.div`
  background-color: ${({ theme }) => theme.colors.gray.medium};
  min-width: ${rem('160px')};
  position: absolute;
  text-align: center;
  top: ${rem('-40px')};
  left: 50%;
  transform: translateX(-50%);
  border-radius: 8px;
  padding: 8px 5px;

  visibility: hidden;
  opacity: 0;

  transition: opacity 0.5s ease;

  &::after {
    content: '';
    display: block;
    position: absolute;
    background-color: ${({ theme }) => theme.colors.gray.medium};

    width: 12px;
    height: 12px;

    left: 50%;
    transform: translateX(-50%) rotate(45deg);
  }

  p {
    color: #fbfbfb;
    font-size: 11px;
    font-weight: 400;
    font-family: ${({ theme }) => theme.fonts.book};
  }
`

export const Container = styled.table`
  width: 100%;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  border-collapse: collapse;
  position: relative;
`

export const Color = styled.div`
  display: flex;
  justify-content: center;

  cursor: pointer;
  position: relative;

  &:hover ${Tip} {
    visibility: visible;
    opacity: 1;
  }

  span {
    width: ${rem('18px')};
    height: ${rem('18px')};
    border-radius: 50%;
    border: 1px solid rgba(0, 0, 0, 0.3);
    display: block;
    background: ${({ color }) => color};
  }
`

export const TH = styled.th`
  border-bottom: 1px solid #eff0f0;
  text-align: left;
  position: relative;
  cursor: pointer;
  padding: 5px 10px;

  font-family: ${({ theme }) => theme.fonts.medium};
  font-size: 14px;
  font-weight: 450;
  color: ${({ theme }) => theme.colors.gray.davys};

  transition: background 0.5s ease;
  text-transform: uppercase;

  &:hover {
    background: ${({ theme }) => theme.colors.gray.light};

    ${Tip} {
      visibility: visible;
      opacity: 1;
    }
  }
`

export const TD = styled.td`
  border-bottom: 1px solid #eff0f0;
  padding: 4px 0;

  font-family: ${({ theme }) => theme.fonts.medium};
  font-size: 14px;
  font-weight: 450;
  color: ${({ theme }) => theme.colors.gray.davys};

  cursor: pointer;
  position: relative;

  &:hover ${Tip} {
    visibility: visible;
    opacity: 1;
  }
`
export const ModalContainer = styled.div`
  padding: 0 16px;
  width: 100%;

  ${media.lessThan('small')`
    padding: 0px;
  `}
`

export const ModalHeader = styled.header`
  font-family: ${({ theme }) => theme.fonts.medium};
  color: ${({ theme }) => theme.colors.gray.crayola};

  display: grid;
  grid-template-columns: 1.5fr 1fr 0.5fr;
  gap: 66px;

  ${media.lessThan('medium')`
    display: grid;
    grid-template-columns: 1fr 0.2fr;
    
    gap: 8px;
  `}
`

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`

export const ModalTitle = styled.h2`
  text-align: left;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 36px;
  font-size: 24px;

  ${media.lessThan('small')`
  font-size: 1.125rem;
  line-height: 24px;
  
  `}
`

export const ModalSubtitle = styled.h6`
  text-align: left;
  font-size: 0.875rem;
  font-weight: normal;
  color: inherit;
  margin-bottom: 4px;
`
export const Tag = styled.div`
  padding: 4px 16px;
  color: ${({ theme }) => theme.colors.white};
  background-color: #267634;
  border-radius: 13px;
  display: inline-block;
`

export const VehicleColor = styled.div`
  display: flex;
  align-items: center;
  gap: 14px;

  ${media.lessThan('medium')`
    grid-column: 1;
  `}

  span {
    display: inline-block;
  }

  ${Color} {
    cursor: initial;
  }
`

export const CloseButton = styled.button`
  border: none;
  background-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.white};
  font-size: 1rem;
  border-radius: 50%;

  width: 24px;
  height: 24px;

  display: flex;
  align-items: center;
  justify-content: center;

  justify-self: end;

  cursor: pointer;

  ${media.lessThan('medium')`
    grid-row: 1;
    grid-column: 2;
  `}
`

export const Line = styled.div`
  margin-top: 20px;
  border-bottom: 1px solid #c7bdbd;
`

export const ModalBody = styled.div`
  h4 {
    text-align: left;
    color: ${({ theme }) => theme.colors.gray.crayola};
    font-weight: 600;
    font-size: 1.125rem;
    line-height: 24px;
    margin-top: 24px;
    margin-bottom: 16px;
  }
`

export const EndOfPlate = styled.div`
  margin: 32px 0;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  justify-content: space-between;
  gap: 32px;

  font-family: ${({ theme }) => theme.fonts.medium};
  color: ${({ theme }) => theme.colors.gray.crayola};

  ${media.lessThan('medium')`
    margin: 16px 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 8px;
  `}
`
export const PlateContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

   p {
    text-align: left;
    color: inherit;
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 24px;
  }

  span {
    font-size: 1rem;
    line-height: 22px;
    font-weight: 400;
  }

  img {
    margin-right: 8px;
  }
`

export const Error = styled.h6`
  color: ${({ theme }) => theme.colors.danger} ;
  padding: 0.5rem 0;
  font-size: 1rem;
  font-weight: normal ;
`
