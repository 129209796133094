import styled, { css } from 'styled-components'
import media from 'styled-media-query'
import { NavLink } from 'react-router-dom'
import { darken } from 'polished'

export const Container = styled.header`
  ${({ theme }) => css`
    position: relative;
    z-index: ${theme.layers.header};

    height: 80px;

    display: flex;
    justify-content: space-between;

    background-color: ${theme.colors.white};
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
  `}
`

export const Left = styled.div`
  display: flex;
  height: 80px;
`

const frameModifiers = {
  center: () => css`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  `,
  left: (theme) => css`
    padding: ${theme.spacings.xsmall} ${theme.spacings.small};
    border-right: ${theme.border.thin} solid rgba(0, 0, 0, 0.05);
    height: 100%;
    width: 250px;
    display: flex;
    align-items: center;
    justify-content: center;

    ${media.lessThan('huge')`
      width: 230px;
    `}
  `,
}

export const Frame = styled.div`
  ${({ theme, align }) => css`
    cursor: pointer;
    ${frameModifiers[align](theme)}
  `}
`

export const SVG = styled.img`
  height: 80px;
`
export const MenuNav = styled.nav`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 0 ${theme.spacings.small};
  `}
`
export const StyledLink = styled(NavLink)`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    position: relative;
    font-weight: 600;
    font-size: ${theme.fonts.sizes.xsmall};
    line-height: 19px;
    padding: 0 ${theme.spacings.small};
    color: rgba(126, 126, 126, 0.8);
    font-family: ${theme.fonts.bold};
    height: 100%;

    &.active-link {
      color: ${theme.colors.side};
      &::after {
        content: '';
        width: 100%;
        position: absolute;
        height: 2px;
        bottom: 0;
        background-color: ${theme.colors.side};
        animation: hoverAnimation 0.2s forwards;

        @keyframes hoverAnimation {
          from {
            width: 0;
            left: 50%;
          }
          to {
            width: 100%;
            left: 0;
          }
        }
      }
    }

    &:hover {
      color: ${theme.colors.side};
      &::after {
        content: '';
        width: 100%;
        position: absolute;
        height: 2px;
        bottom: 0;
        background-color: ${theme.colors.side};
        animation: hoverAnimation 0.2s forwards;

        @keyframes hoverAnimation {
          from {
            width: 0;
            left: 50%;
          }
          to {
            width: 100%;
            left: 0;
          }
        }
      }
    }
  `}
`

export const Right = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.colors.white};

  justify-content: flex-end;

  ${media.lessThan('small')`
    width: 100%;
    justify-content: space-between;
  `}
`

export const User = styled.div`
  ${({ theme }) => css`
    border: ${theme.border.thin} solid rgba(0, 0, 0, 0.05);
    border-top: none;
    border-bottom: none;

    ${media.lessThan('small')`
      border: none;
    `}
  `}
`

export const NotificationWrapper = styled.div`
  ${({ theme }) => css`
    padding: ${theme.spacings.small};
    border: ${theme.border.thin} solid rgba(0, 0, 0, 0.05);
    border-top: none;
    border-bottom: none;

    ${media.lessThan('large')`
    border: none;
    padding:  ${theme.spacings.xsmall};
  `}
  `}
`

export const ToggleMenu = styled.div`
  ${({ theme }) => css`
    height: 100%;
    padding: 0 20px;

    display: flex;
    align-items: center;
    justify-content: center;
    color: ${theme.colors.black};

    border-left: 1px solid rgba(0, 0, 0, 0.05);
    user-select: none;
    cursor: pointer;

    ${media.lessThan('small')`
      border: none;
    `}

    transition: color 0.3s, background-color 0.3s;

    > svg {
      color: ${theme.colors.primary};
    }

    &:hover {
      background-color: ${theme.colors.primary};
      color: ${theme.colors.white};

      > svg {
        color: ${theme.colors.white};
      }
    }
  `}
`

export const Text = styled.p`
  ${({ theme }) => css`
    margin-left: 10px;

    font-family: ${theme.fonts.bold};
    font-size: ${theme.fonts.sizes.small};
    font-weight: 600;

    ${media.lessThan('medium')`
    display: none;
  `}
  `}
`

export const DropdownLabel = styled.p`
  ${({ theme }) => css`
    font-weight: 600;
    font-size: ${theme.fonts.sizes.xxsmall};
    line-height: 1.5;
    color: ${theme.colors.black};
  `}
`
export const Label = styled.span`
  ${({ theme }) => css`
    color: ${theme.colors.newGray[60]};
  `}
`

export const DropdownLabelWrapper = styled.div`
  ${({ theme }) => css`
    border-bottom: 1px solid ${theme.colors.newGray[10]};
    padding: ${theme.spacings.xsmall};
  `}
`

export const DropdownButton = styled.button`
  ${({ theme }) => css`
    border: none;
    background: transparent;
    width: 100%;
    color: ${theme.colors.primary};
    cursor: pointer;
    padding: ${theme.spacings.xsmall};
    border-bottom: 1px solid ${theme.colors.newGray[10]};

    font-weight: 600;
    font-size: ${theme.fonts.sizes.xxsmall};
    line-height: 1.5;
    display: flex;
    justify-content: flex-start;

    &:hover {
      color: ${darken('.06', theme.colors.primary)};
    }
  `}
`
