import React from 'react'
import { Flex, Icon } from 'components'

import * as S from './styles'

function Alert({ children }) {
  return (
    <S.Card>
      <S.CardWrapper>
        <Flex alignItems="center" justifyContent="center">
          <Icon size="18px" name="alert-circle" color="#232323" />
        </Flex>
        <S.Text>{children}</S.Text>
      </S.CardWrapper>
    </S.Card>
  )
}

export default Alert
