import styled from 'styled-components'
import media from 'styled-media-query'
import { rem, darken } from 'polished'

import { colors } from 'styles'

const handleButtonColor = (color) => {
  const button = {
    primary: colors.primary,
    success: colors.green,
    error: colors.orange,
  }
  return button[color]
}

export const WrapperButton = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0 ${rem(25)};

  ${media.greaterThan('medium')`
      padding: 0 ${rem(100)};
    `}
`
export const ErrorMessage = styled.div`
  align-self: center;
  margin-bottom: ${rem('20px')};

  span {
    font-size: ${rem('8px')};
    font-family: ${({ theme }) => theme.fonts.medium};

    color: ${({ theme }) => theme.colors.danger};
  }

  ${media.greaterThan('medium')`
      padding: 0 ${rem(100)};

      span {
        font-size: ${rem('16px')};  
      }
    `}
`

export const Button = styled.button`
  width: 100%;
  height: ${rem(60)};

  display: flex;
  justify-content: center;
  align-items: center;

  border: none;
  cursor: pointer;

  border-radius: 300px;

  background: ${({ color }) => handleButtonColor(color)};
  color: #fff;

  font-size: 1.25rem;
  line-height: 25px;
  font-weight: 500;
  transition: background 0.5s ease;

  &:disabled {
    background: ${({ color }) => handleButtonColor(color)};
    cursor: not-allowed;

    &:hover {
      background: ${({ color }) => handleButtonColor(color)};
    }
  }

  &:hover {
    background: ${({ color }) => darken('.06', handleButtonColor(color))};
  }
`
