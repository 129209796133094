import { useQuery } from 'react-query'
import { getVehicleCategorys } from 'services/apiV2/prices'
import {
  getDeliveryPlaces,
  getStockVehicle,
  getVehicleBrands,
} from 'services/apiV2/stock'

export function useGetStockCategorys(params, options) {
  return useQuery(
    ['stockCategorys', params],
    () => getVehicleCategorys(params),
    {
      staleTime: 60 * 1000 * 10, //10 minutes
      refetchOnWindowFocus: false,
      ...options,
    }
  )
}

export function useGetStockBrands(params, options) {
  return useQuery(['stockBrands', params], () => getVehicleBrands(params), {
    staleTime: 60 * 1000 * 10, //10 minutes
    refetchOnWindowFocus: false,
    ...options,
  })
}

export function useGetStockVehicles(params, options) {
  return useQuery(['stockVehicles', params], () => getStockVehicle(params), {
    refetchOnWindowFocus: false,
    ...options,
  })
}

export function useGetDeliveryPlaces(options) {
  return useQuery(['deliveryPlaces'], () => getDeliveryPlaces(), {
    refetchOnWindowFocus: false,
    staleTime: 60 * 60 * 10, //10 minutes
    ...options,
  })
}
