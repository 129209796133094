import styled, { css } from 'styled-components'
import media from 'styled-media-query'

export const Container = styled.div`
  ${({ theme }) => css`
    min-height: 100vh;
    background-color: ${theme.colors.primary};
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: ${theme.spacings.small};


    ${media.lessThan('medium')`
      padding: ${theme.spacings.xsmall};
    `}
  `}
`

export const Title = styled.h1`
  ${({ theme }) => css`
    color: ${theme.colors.white};
    line-height: 1;
    text-align: center;
    font-size: ${theme.fonts.sizes.xhuge};
    margin: 0;
    font-weight: normal;

    ${media.lessThan('medium')`
      font-size: ${theme.fonts.sizes.xxlarge};
    `}

    > span {
      font-family: ${theme.fonts.bold};
    }
  `}
`

export const Image = styled.img`
  ${({ theme }) => css`
    margin-top: calc(${theme.spacings.xxlarge} * 2);
    width: 100%;
    max-width: 900px;
  `}
`
