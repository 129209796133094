export const STATUS_PCO_OPTIONS = [
  { value: 'APROVADO', label: 'APROVADO' },
  { value: 'RENOVADO', label: 'RENOVADO' },
  { value: 'DERIVACAO', label: 'DERIVAÇÃO' },
  { value: 'PENDENTE', label: 'PENDENTE' },
  { value: 'NEGADO', label: 'INAPTO' },
  { value: 'ERRO', label: 'ERRO' },
]

export const EDIT_STATUS_PCO_OPTIONS = [
  { value: 'APROVADO', label: 'APROVADO' },
  { value: 'PENDENTE', label: 'PENDENTE' },
  { value: 'REPROVADO', label: 'REPROVADO' },
]

export const STATUS_PCO = [
  'ERRO',
  'PENDENTE',
  'NEGADO',
  'APROVADO',
  'REPROVADO',
  'DERIVACAO',
  'DERIVAÇÃO',
  'RENOVADO',
]
