import { useMutation, useQuery } from 'react-query'
import {
  getNewsTags,
  getOneNews,
  updateNews,
  uploadDesktopImages,
  uploadMobileImages,
  createNews,
  getHighlightedNews,
  getLastNews,
  getFavoriteNews,
  favoriteNews,
  removefavoriteNews,
  deleteNews,
} from 'services/apiV2/news'

export function useGetFavoriteNews(options) {
  return useQuery('favorite_news', () => getFavoriteNews(), {
    staleTime: 1000 * 60, // 1 minute
    refetchOnWindowFocus: false,
    ...options,
  })
}

export function useGetHighlightedNews() {
  return useQuery('highlighted_news', () => getHighlightedNews(), {
    staleTime: 1000 * 60, // 1 minute
    refetchOnWindowFocus: false,
  })
}

export function useGetLastNews(params, options) {
  return useQuery(['last_news', params], () => getLastNews(params), {
    staleTime: 1000 * 60, // 1 minute
    refetchOnWindowFocus: false,
    ...options,
  })
}

export function useTagsNews() {
  return useQuery('news_tags', () => getNewsTags(), {
    staleTime: 1000 * 60 * 10, // 10 minutes
    refetchOnWindowFocus: false,
  })
}

export function useGetOneNews(id, options) {
  return useQuery(['news', id], () => getOneNews(id), {
    staleTime: 1000 * 60, // 1 minute
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    ...options,
  })
}

export function useRemoveFavoriteNews(options) {
  return useMutation((newsId) => removefavoriteNews(newsId), { ...options })
}

export function useDeleteNews(options) {
  return useMutation((newsId) => deleteNews(newsId), { ...options })
}

export function useCreateFavoriteNews(options) {
  return useMutation((newsId) => favoriteNews(newsId), { ...options })
}

export function useUploadDesktopImages(options) {
  return useMutation((images) => uploadDesktopImages(images), { ...options })
}

export function useUploadMobileImages(options) {
  return useMutation((images) => uploadMobileImages(images), { ...options })
}

export function useCreateNewsMutation(options) {
  return useMutation((data) => createNews(data), { ...options })
}

export function useUpdateNewsMutation(options) {
  return useMutation((payload) => updateNews(payload), { ...options })
}
