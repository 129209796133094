import styled, { css } from 'styled-components'
import media from 'styled-media-query'

export const Container = styled.div`
  width: 100%;
`

export const Main = styled.div`
  position: relative;

  padding: 0 24px;
  width: 100%;
`

export const Title = styled.p`
  ${({ theme }) => css`
    padding-right: ${theme.spacings.xxsmall};
    color: ${theme.colors.primary};
    font-family: ${theme.fonts.bold};
    font-size: 12px;
    white-space: nowrap;
  `}
`

export const Hidden = styled.div`
  ${({ theme }) => css`
    width: 250px;
    position: absolute;
    right: 0;

    ${media.lessThan('small')`
      left: 0;
    `}

    flex-direction: column;
    background-color: ${theme.colors.white};

    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.2);
  `}
`

export const Animate = styled.div`
  transition: 0.3s;

  > svg {
    color: ${({ theme }) => theme.colors.primary};
  }
`

const wrapperModifiers = {
  open: () => css`
    opacity: 1;
    pointer-events: auto;
    visibility: visible;
    transform: translateY(0);
  `,
  close: () => css`
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transform: translateY(-2rem);
  `,
}

export const Content = styled.div`
  ${({ isShown, theme }) => css`
    transition: 0.3s;

    ${isShown &&
    css`
      box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.2);
      background: ${theme.colors.white};
      transition: 0.3s;

      ${Animate} {
        transform: rotate(180deg);
        transition: 0.3s;
      }
    `}

    ${Hidden} {
      transition: transform 0.2s ease-in, opacity 0.3s;

      ${isShown && wrapperModifiers.open()}
      ${!isShown && wrapperModifiers.close()}
    }
  `}
`

export const Item = styled.div`
  height: 80px;

  display: flex;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;

  ${({ hasBorder }) =>
    hasBorder &&
    css`
      border: 1px solid rgba(0, 0, 0, 0.05);
      border-left: none;
      border-right: none;
    `}

  ${media.lessThan('medium')`
      justify-content: center;
      padding: 0;
  `}
`

export const Name = styled.p`
  padding-right: 60px;

  text-align: right;
  font-family: ${({ theme }) => theme.fonts.book};
  color: ${({ theme }) => theme.colors.gray.medium};

  ${media.lessThan('medium')`
    padding-right: 5px;
    text-align: center;
    font-size: 13px;
  `}
`
