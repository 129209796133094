import React from 'react'
import { AlertModal } from 'components'

import * as S from './styles'

function ConfirmModal({ isVisible, onClose, onClick, isLoading }) {
  return (
    <AlertModal
      isVisible={isVisible}
      isLoading={isLoading}
      actions={{
        primary: {
          onClick,
          label: 'Confirmar',
        },
        secondary: {
          onClick: onClose,
          label: 'Cancelar',
        },
      }}
    >
      <S.Content>
        <S.Title>Desejar salvar as alterações?</S.Title>
        <S.Text>
          Caso confirme e prossiga, você será deslogado da plataforma e
          precisará inserir novamente suas credenciais atualizadas. Se estiver
          em outra aba, recomendamos que feche e reinicie o trabalho.
        </S.Text>
      </S.Content>
    </AlertModal>
  )
}

export default ConfirmModal
