import { rem } from 'polished'
import styled from 'styled-components'
import media from 'styled-media-query'

export const TableContainer = styled.div`
  margin-top: ${rem('24px')};
  overflow-x: auto;
  overflow-y: hidden;
  padding-bottom: 40px;

  ${media.lessThan('small')`

    > table {
      width: ${rem('900px')};;
    }
  `}
`

export const ErrorMessage = styled.p`
  padding-top: 20px;

  font-size: 13px;
  color: ${({ theme }) => theme.colors.danger};
  font-family: ${({ theme }) => theme.fonts.bold};

  ${media.lessThan('large')`
    font-size: 11px;
  `}
`

export const WrappLoading = styled.div`
  margin: 2rem 0;

  display: flex;
  justify-content: center;
  align-items: center;
`
