import styled from 'styled-components'

export const Card = styled.div`
  width: 100%;
  padding: 1rem;
  border-radius: 8px;
  background: ${({ bgColor }) => bgColor};
  margin-bottom: 1.2rem;
`
export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`
export const Text = styled.div`
  font-size: 14px;
  line-height: 16px;
  font-family: ${({ theme }) => theme.fonts.bold};
  color: ${({ color }) => color}; ;
`
