import React, { useState, useEffect } from 'react'
import { FormContext, useForm } from 'react-hook-form'
import { Filters, Header, PriceTable } from './components'
import { useGetVehiclesPrices } from 'services/hooks/prices'

import * as S from './styles'
import { useCurrentUser } from 'hooks'

export default function Prices() {
  const user = useCurrentUser()

  const dataLayer = window.dataLayer || []
  const DEFAULT_STATE = { value: 'sp', label: 'São Paulo' }
  const DEFAULT_MONTH = {
    value: 24,
    label: '24 meses',
  }

  const [selected, setSelected] = useState({
    months: DEFAULT_MONTH,
    state: DEFAULT_STATE,
    category: null,
  })
  const [params, setParams] = useState({
    state: DEFAULT_STATE.value,
    months: DEFAULT_MONTH.value,
  })

  useEffect(() => {
    dataLayer.push({
      event: 'step_change',
      etapa: '/tabela-de-precos',
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const {
    data: vehiclesPrices,
    isLoading: isLoadingVehiclesPrices,
    error,
  } = useGetVehiclesPrices(params, {
    onSuccess: (data) => {
      dataLayer.push({
        event: 'resultado_busca',
        'nome-servico': 'consulta:tabela-de-preco',
        tipo_busca: `estado-${params?.state} periodo-${
          params?.months
        } categoria-${params.category ? params.category : 'todas'}`,
        retorno: 'sucesso',
        descricao: data?.pagination?.items_per_page,
      })

      dataLayer.push({
        event: 'solicitacao_servico',
        nome_servico: 'tabela-precos',
        tipo_servico: 'busca',
        retorno: 'sucesso',
        estado: params.state || '',
        periodo: params.months || '',
        categoria: params.category || '',
        susep: user[user.role]?.susep || '',
        descricao: 'busca realizada com sucesso',
      })
    },
    onError: (error) => {
      dataLayer.push({
        event: 'resultado_busca',
        'nome-servico': 'consulta:tabela-de-preco',
        tipo_busca: `estado-${params?.state} periodo-${
          params?.months
        } categoria-${params.category ? params.category : 'todas'}`,
        retorno: 'erro',
      })

      dataLayer.push({
        event: 'solicitacao_servico',
        nome_servico: 'tabela-precos',
        tipo_servico: 'busca',
        retorno: 'erro',
        estado: params.state || '',
        periodo: params.months || '',
        categoria: params.category || '',
        susep: user[user.role]?.susep || '',
        erro: {
          codigo: error.status,
          servico: 'busca-tabela-precos',
          mensagem: error.message,
        },
        descricao: 'busca realizada com sucesso',
      })
    },
  })

  const forms = useForm({
    defaultValues: { state: DEFAULT_STATE, months: DEFAULT_MONTH },
  })

  const onFilterSubmit = forms.handleSubmit((values) => {
    const params = {
      state: values?.state?.value || DEFAULT_STATE.value,
      months: values?.months?.value || DEFAULT_MONTH.value,
      category: values?.category?.value || null,
    }

    setParams((prev) => ({ ...prev, ...params }))
  })

  const setInitialValue = () => {
    setParams({
      state: DEFAULT_STATE.value,
      months: DEFAULT_MONTH.value,
    })

    forms.setValue('state', DEFAULT_STATE)
    forms.setValue('months', DEFAULT_MONTH)

    setSelected({
      state: DEFAULT_STATE,
      months: DEFAULT_MONTH,
    })
  }

  const clearFilters = () => {
    setSelected({
      state: null,
      months: null,
      category: null,
    })

    forms.setValue([{ months: null }, { category: null }, { state: null }])
    setInitialValue()
  }

  const handleFilters = (name, selected) => {
    setSelected((state) => ({ ...state, [name]: selected }))
    return selected
  }

  return (
    <S.Container>
      <FormContext {...forms}>
        <Header
          error={error?.message}
          isLoading={isLoadingVehiclesPrices}
          tableUpdated={vehiclesPrices?.lastUpdateDate}
        />

        <Filters
          params={params}
          selected={selected}
          defaultState={DEFAULT_STATE}
          defaultMonth={DEFAULT_MONTH}
          handleFilters={handleFilters}
          clearFilters={clearFilters}
          onSubmit={onFilterSubmit}
        />

        <PriceTable
          data={vehiclesPrices?.data}
          error={error?.message}
          isLoading={isLoadingVehiclesPrices}
          packages={vehiclesPrices?.columns}
          selected={selected}
        />
      </FormContext>
    </S.Container>
  )
}

Prices.properties = {
  metatags: {
    title: 'Preços',
  },
}
