import api from 'services'
import axios from 'axios'
import { AppError } from 'utils'

export async function setLogin({ payload }) {
  try {
    const response = await api.post(
      '/cx_authentication/v2/platform_login',
      payload
    )
    saveToken(response.data)
    return response.data
  } catch (error) {
    const { message } = error.response.data

    if (error.response.status === 401 || 404 || 429) throw Error(message)

    throw Error(
      'Ops! Erro ao realizar o login. Tente novamente em alguns instantes.'
    )
  }
}

export async function resetPassowrd(params) {
  try {
    const response = await api.post(
      '/cx_authentication/reset_password_platform',
      params
    )
    return response.data
  } catch (error) {
    throw new AppError(
      'Ops, Erro ao solicitar troca de senha! Verifique se a informação solicitada foi digitada de forma correta.',
      error.response.status
    )
  }
}

export async function getAllUsers(params) {
  try {
    const response = await api.get('/cf_user/users', { params })

    return response.data
  } catch (error) {
    throw Error(
      'Ops! Erro ao encontrar usuários. Tente novamente em alguns instantes.'
    )
  }
}

export async function refreshAuth({ refresh_token }) {
  try {
    const payload = {
      grant_type: 'refresh_token',
      refresh_token: refresh_token,
    }

    const response = await axios.post(
      `https://securetoken.googleapis.com/v1/token?key=${process.env.REACT_APP_APIKEY}`,
      payload
    )

    saveToken({ firebase_token: response.data })
    return response.data
  } catch (error) {
    return error.response.data
  }
}

export const saveToken = ({ firebase_token: { id_token, refresh_token } }) => {
  localStorage.setItem('token', id_token)
  localStorage.setItem('refresh_token', refresh_token)
}

export async function editUser(id, data) {
  try {
    const response = await api.put(`/cf_user/users/${id}`, data)

    return response
  } catch (error) {
    throw Error(
      'Houve uma instabilidade ao alterar seu usuário. Tente novamente mais tarde ou entre em contato com a Porto Seguro.'
    )
  }
}

export async function getUserById(id) {
  try {
    const { data } = await api.get(`cf_user/users/${id}`)

    return data
  } catch (error) {
    throw Error(
      'Ops! Erro ao encontrar usuário. Tente novamente em alguns instantes.'
    )
  }
}

export async function editUserEmail(id, email) {
  try {
    const response = await api.patch(`/cf_user/users/${id}/update_email`, email)

    return response
  } catch (error) {
    throw Error(
      'Ops! Erro ao alterar seu e-mail. Tente novamente em alguns instantes.'
    )
  }
}

export async function editProfile(data) {
  try {
    const response = await api.put('/cf_user/users/profile', data)

    return response
  } catch (error) {
    throw new Error(
      error.response.data.message ||
        'Ops! Ocorreu um erro ao alterar seu perfil. Tente novamente em alguns instantes.'
    )
  }
}

export async function getProfile() {
  try {
    const response = await api.get('/cf_user/users/profile')

    const data = {
      name: response?.data?.customer?.fullName,
      document: response?.data?.customer?.cpf?.number,
      email: response?.data?.customer?.email,
      cellphone: response?.data?.customer?.cellphone,
    }

    return data
  } catch (error) {
    throw new Error(
      error.response.data.message ||
        'Ops! Erro ao buscar os dados do seu perfil. Tente novamente em alguns instantes.'
    )
  }
}
