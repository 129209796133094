import styled, { css } from 'styled-components'
import { NavLink } from 'react-router-dom'

import { rgba, rem } from 'polished'

import { layers } from 'styles'

export const Container = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: ${layers.offcanvas};

  display: flex;
  justify-content: flex-end;
`
export const Overlay = styled.div`
  top: 0;
  left: 0;
  opacity: 1;
  width: 100%;
  height: 100%;
  position: fixed;
  display: block;
  background-color: ${rgba('#000', 0.4)};
`

export const Box = styled.div`
  width: 350px;
  z-index: 5;
  background-color: ${({ theme }) => theme.colors.primary};
  overflow-y: auto;
  padding-bottom: ${rem('32px')};
`

export const Head = styled.div`
  height: 85px;

  padding: 0 20px;

  display: flex;
  align-items: center;

  border-bottom: 1px solid #5260e3;

  > button {
    margin-left: auto;
  }
`

export const Body = styled.div``

export const Nav = styled.nav``

export const Item = styled(NavLink)`
  ${({ theme }) => css`
    all: unset;

    padding: ${theme.spacings.medium};
    display: block;

    color: ${theme.colors.white};
    cursor: pointer;
    transition: 0.3s;
    font-size: ${theme.fonts.sizes.xsmall};
    border-bottom: ${theme.border.thin} solid #5260e3;
    font-family: ${theme.fonts.bold};

    &:hover {
      transition: 0.3s;
      background-color: #5260e3;
    }

    &.active-link {
      color: ${theme.colors.secondary};
    }
  `}
`
