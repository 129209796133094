import styled, { css } from 'styled-components'

export const CardWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`

export const Card = styled.div`
  ${({ theme }) => css`
    width: 100%;
    padding: 1rem;
    border-radius: 8px;
    background: ${theme.colors.gray.light};
    margin-bottom: 1.2rem;
  `}
`

export const Text = styled.div`
  ${({ theme }) => css`
    font-size: 14px;
    line-height: 16px;
    font-family: ${theme.fonts.bold};
    color: ${theme.colors.gray.dark};
  `}
`
