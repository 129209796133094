import styled, { css } from 'styled-components'
import media from 'styled-media-query'

export const Main = styled.main`
  ${({ theme }) => css`
    margin: 40px 0;
    padding-right: 60px;
    padding-left: 60px;

    ${media.lessThan('large')`
      padding-right: 20px;
      padding-left: 20px;
    `}
  `}
`

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 0.8fr;
  align-items: center;
  background-color: #fff;
  box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.05);
  border-radius: 9px;
  overflow: hidden;

  ${media.greaterThan('medium')`
    max-width: 1200px;
    margin: 0 auto;
    height: 600px;
  `}


  ${media.lessThan('medium')`
    grid-template-columns: 1fr;
  `}
`

export const Content = styled.div`
  max-height: 600px;

  padding: 30px 50px;

  display: flex;
  flex-direction: column;
  justify-content: center;

  ${media.lessThan('large')`
    padding: 32px 24px;
  `}
`

export const Image = styled.img`
  ${media.lessThan('medium')`
  `}
`

export const Title = styled.h4`
  font-weight: 600;
  font-size: 24px;
  line-height: 33px;
  line-height: 1.5;
  font-family: ${({ theme }) => theme.fonts.book};

  color: #0223d2;

  ${media.lessThan('medium')`
    font-size: 16px;
  `}
`

export const HighlightTitle = styled.h1`
  font-weight: 700;
  font-size: 40px;
  line-height: 0.5;
  font-family: ${({ theme }) => theme.fonts.book};

  color: #000000;

  ${media.lessThan('medium')`
    font-size: 24px;
    line-height: 1;
  `}
`

export const Subtitle = styled.p`
  font-weight: 400;
  font-size: 24px;
  line-height: 1.5;
  max-width: 450px;
  font-family: ${({ theme }) => theme.fonts.book};

  color: #485558;

  ${media.lessThan('medium')`
    font-size: 16px;
    line-height: 1.5;
  `}
`
