import styled, { css } from 'styled-components'
import { rem } from 'polished'
import { fonts, colors } from 'styles'

export const Container = styled.div`
  position: relative;
`

export const Checkbox = styled.div`
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
`
export const Check = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 14px;
  width: 14px;
  border: 1px solid #1b2a2e;
  background-color: ${({ theme }) => theme.colors.white};

  &::after {
    content: '';
    position: absolute;
    opacity: 0;
    left: 50%;
    top: 50%;
    width: 3px;
    height: 7px;
    border: solid ${({ theme }) => theme.colors.newPrimary};
    border-width: 0 2px 2px 0;
    transition: opacity 0.3s ease;
    transform: rotate(45deg) translate(-115%, -25%);
  }
`

export const Input = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;

  :checked + ${Check} {
    background-color: ${({ theme }) => theme.colors.white};
    border-color: ${({ theme }) => theme.colors.newPrimary};

    &::after {
      opacity: 1;
    }
  }
`

export const Label = styled.label`
  font-size: 12px;
  line-height: 14px;
  position: absolute;
  color: ${({ theme }) => theme.colors.gray.neutral};
  font-family: ${({ theme }) => theme.fonts.bold};
  top: ${rem('15px')};
  left: ${rem('11px')};
  opacity: 0;
  transition: top 0.2s ease;
  overflow: hidden;

  ${({ selected }) =>
    selected &&
    css`
      top: ${rem('8px')};
      opacity: 1;
    `}
`
export const WrapLoading = styled.div`
  position: absolute;
  right: 35px;
  top: 50%;
  transform: translateY(-50%);
`
export const Loading = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3px;
  position: relative;

  div {
    width: 5px;
    height: 5px;
    background: #000;
    border-radius: 50%;
    animation: dots 1s infinite linear;
  }

  div:nth-child(1) {
    animation-delay: 0s;
  }
  div:nth-child(2) {
    animation-delay: 0.2s;
  }
  div:nth-child(3) {
    animation-delay: 0.5s;
  }
  div:nth-child(4) {
    animation-delay: 0.8s;
  }

  @keyframes dots {
    0% {
      transform: translateY(3px);
    }
    50% {
      transform: translateY(-3px);
    }
    100% {
      transform: translateY(3px);
    }
  }
`

export const styles = {
  container: (provided) => ({
    ...provided,
    height: '100%',
  }),
  control: (provided, { isDisabled }) => ({
    ...provided,
    fontFamily: fonts.medium,
    border: isDisabled ? `1.5px solid #BABFC0` : `1.5px solid #737373`,
    backgroundColor: isDisabled ? '#BABFC0' : 'transparent',
    borderRadius: '8px',
    minHeight: '50px',
    fontSize: '12px',
    height: '100%',
    pointerEvents: 'all',
    cursor: isDisabled ? 'not-allowed' : 'pointer',

    '@media only screen and (max-width: 1366px)': {
      minHeight: '48px',
    },
  }),
  placeholder: (provided) => ({
    ...provided,
    fontSize: '12px',
    top: '50%',
  }),
  option: (provided, state) => ({
    ...provided,
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    fontSize: '14px',
    lineHeight: '16px',
    fontFamily: fonts.medium,
    backgroundColor: !state.isSelected ? colors.white : colors.newPrimary,
    color: !state.isSelected ? colors.gray.neutral : colors.white,
    padding: '0.8rem',
    paddingLeft: '1rem',
    paddingRight: '1rem',

    '&:hover': {
      backgroundColor: colors.newPrimary,
      color: colors.white,
      cursor: 'pointer',
    },
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: '8px',
    paddingTop: '1.2rem',
    paddingBottom: '2px',
  }),
  singleValue: (provided) => ({
    ...provided,
    top: '70%',
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: 'none',
  }),
  loadingIndicator: (provided) => ({
    ...provided,
  }),
}
