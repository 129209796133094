import styled from 'styled-components'
import media from 'styled-media-query'
import { rem, rgba } from 'polished'
import { motion } from 'framer-motion'

export const Row = styled.div`
  position: relative;
  z-index: 20;
`

export const Item = styled.div`
  align-self: center;

  ${media.lessThan('large')`
    &:not(:last-of-type) {
      padding-bottom: 10px;
    }
  `}
`

export const Card = styled.div`
  &:not(:last-of-type) {
    margin-bottom: 20px;
  }
`

export const Content = styled(motion.div)`
  position: relative;
  z-index: 10;

  padding: 20px;

  background-color: #f1f1f1;
`

export const Container = styled.div`
  ${Row} {
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    ${media.greaterThan('large')`
      grid-template-columns: repeat(${({ length }) => length + 1}, 1fr);
    `}
  }
`

export const Head = styled.div`
  margin-bottom: 20px;

  font-size: ${rem('12px')};
  color: ${({ theme }) => theme.colors.gray.dark};
  font-family: ${({ theme }) => theme.fonts.bold};

  ${Row} {
    padding: 0 20px;
  }
`

export const Body = styled.div`
  font-size: ${rem('14px')};
  color: ${({ theme }) => theme.colors.gray.medium};
  font-family: ${({ theme }) => theme.fonts.medium};

  ${media.lessThan('large')`
    font-size: ${rem('12px')};
  `}

  ${Row} {
    padding: 20px;

    background-color: #fff;
    box-shadow: 0px 0px 20px ${rgba('#000', 0.05)};

    ${Item} {
      &:last-of-type {
        ${media.lessThan('large')`
          grid-column: 1 / 2 span;
        `}

        > button {
          ${media.lessThan('large')`
            width: 100%;
            
            text-align: center;
          `}
        }
      }
    }
  }
`
