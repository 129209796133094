import React from 'react'
import { Flex, Help, TableBasic } from 'components'
import { ADDITIONAL_KILOMETERS_TABLE_COLUMNS } from 'helpers'
import { useGetAdditionalKilometers } from 'services/hooks/prices'

import * as S from './styles'

function Header({ isLoading, error, tableUpdated }) {
  const {
    data: additionalKilometers,
    isLoading: isLoadingAdditionalKilometers,
    error: errorAdditionalKilometers,
    isError: isErrorAdditionalKilometers,
  } = useGetAdditionalKilometers()

  return (
    <S.Container>
    <Flex flexDirection="column" alignItems="flex-start">
      <S.TitleWrapper alignItems="center">
        <S.Title>Preços</S.Title>
        <Help
          title="Tabela de preços"
          testid="help-button"
          subtitle="Essa é a tabela de preços! Nela você encontra o portfólio completo de veículos disponíveis por região e seus valores de acordo com o período e a quilometragem escolhida pelo cliente."
        />
      </S.TitleWrapper>
      

        <S.KmAdditionalWrapper>
          {!isLoadingAdditionalKilometers && (
            <>
              <S.KmAdditional>Quilometragem adicional</S.KmAdditional>

              <Help
                title="Quilometragem adicional"
                subtitle="Caso a quilometragem consumida ultrapasse o plano contratado,
                novos pacotes são oferecidos para adicionar ao veículo.
                Os valores estão listados abaixo, conforme as categorias."
              >
                <Flex justifyContent="center" align="center" my={4}>
                  {isErrorAdditionalKilometers && (
                    <S.ErrorMessage>
                      {errorAdditionalKilometers.message}
                    </S.ErrorMessage>
                  )}
                  {!isErrorAdditionalKilometers && (
                    <TableBasic
                      columns={ADDITIONAL_KILOMETERS_TABLE_COLUMNS}
                      data={additionalKilometers}
                    />
                  )}
                </Flex>
              </Help>
            </>
          )}
        </S.KmAdditionalWrapper>

        {!isLoading && !error && (
        <S.Update data-testid="updated_at">
          Tabela atualizada em {tableUpdated}
        </S.Update>
      )}
      </Flex>
    </S.Container>
  )
}

export default Header
