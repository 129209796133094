import api from 'services'
import { AppError } from 'utils'

export async function getClientOldContracts(params) {
  try {
    const { data } = await api.get(`/cr_renovation/contracts/history`, {
      params,
    })

    return data
  } catch (error) {
    throw Error(
      'Houve uma instabilidade no sistema. Tente novamente mais tarde ou entre em contato com a Porto Seguro.'
    )
  }
}

export async function getAllClients({ requestParams = {} }) {
  try {
    const response = await api.get('/cr_renovation/status', {
      params: {
        ...requestParams,
      },
    })

    return response.data
  } catch (error) {
    throw Error(
      'Houve uma instabilidade no sistema. Tente novamente mais tarde ou entre em contato com a Porto Seguro.'
    )
  }
}

export async function getRenovationDetailed(id) {
  try {
    const response = await api.get(`/cr_renovation/detailed/${id}`)

    return response.data
  } catch (error) {
    const err = {
      isLocked: error.response.status === 423,
      message:
        'Ops! Erro ao encontrar a proposta. Tente novamente em alguns instantes.',
    }

    throw err
  }
}

export async function uploadClients(data) {
  try {
    const response = await api.post('/cr_renovation/upload', data)

    return response.data
  } catch (error) {
    console.error(error)
    throw Error(
      'Houve uma instabilidade no sistema. Tente novamente mais tarde ou entre em contato com a Porto Seguro.'
    )
  }
}

export async function getStatusCount(params) {
  try {
    const { data } = await api.get('/cr_renovation/status/count', {
      params,
    })

    const order = {
      PENDENTES: 1,
      DERIVADOS: 2,
      ERROS: 3,
      NEGADOS: 4,
      REPROVADOS: 5,
      INAPTOS: 6,
      APROVADOS: 7,
      RENOVADOS: 8,
      TOTAL: 9,
    }

    const status = data
      ?.map((status) => ({
        ...status,
        order: order[status.type],
      }))
      .sort((a, b) => a.order - b.order)

    return status
  } catch (error) {
    throw new AppError(
      'Houve uma instabilidade ao buscar os status das renovações. Tente novamente mais tarde ou entre em contato com a Porto Seguro.',
      error.response.status
    )
  }
}
