import styled, { css } from 'styled-components'
import { generateMedia } from 'styled-media-query'

export const customMedia = generateMedia({
  huge: '1440px',
  xlarge: '1365px',
  large: '1170px',
  medium: '768px',
  small: '450px',
})

const mediaMatchModifiers = {
  lessThan: (size) => css`
    ${customMedia.lessThan(size)` display: block `}
  `,

  greaterThan: (size) => css`
    ${customMedia.greaterThan(size)` display: block `}
  `,
}

export default styled.div`
  ${({ lessThan, greaterThan }) => css`
    display: none;

    ${!!lessThan && mediaMatchModifiers.lessThan(lessThan)}
    ${!!greaterThan && mediaMatchModifiers.greaterThan(greaterThan)}
  `}
`
