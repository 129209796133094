export const REANALYSIS_STEPS = {
  finished: { color: '#0027F4', icon: 'check-circle' },
  processing: { color: '#000000', icon: 'refresh-cw' },
  error: { color: '#000000', icon: 'x' },
  waiting: { color: '#BBBBBB', icon: '' },
}

export const REANALYSIS_STATUS = {
  ERRO: {
    message: 'Falha no envio',
    color: '#323F43',
    bgColor: '#EABC00',
    icon: 'alert-triangle',
  },
  NEGADO: {
    message: 'Cliente negado',
    color: '#fff',
    bgColor: '#E16C00',
    icon: 'x-octagon',
  },
  APROVADO: {
    message: 'Atualização concluída',
    color: '#fff',
    bgColor: '#018F11',
    icon: 'check-circle',
  },
  DERIVACAO: {
    message: 'Derivado: Cliente enviado para análise.',
    color: '#fff',
    bgColor: '#7B4DE7',
    icon: 'help-circle',
  },
}
