import React from 'react'

import { Container, Content, Overlay, Title } from './styles'

export default function Modal({ open = false, children, title }) {
  return (
    <Container showModal={open}>
      <Overlay showModal={open} />
      <Content showModal={open}>
        <Title>
          <h2>{title}</h2>
        </Title>
        {children}
      </Content>
    </Container>
  )
}
