import { useOutsideClick } from 'hooks'
import React, { useRef, useState } from 'react'

import * as S from './styles'

function CustomerDropdown({ children, title }) {
  const [isOpen, setIsOpen] = useState(false)
  const dropdownRef = useRef()

  const toggleIsOpen = () => {
    setIsOpen((prev) => !prev)
  }

  const CloseDropdown = () => {
    setIsOpen(false)
  }

  useOutsideClick(dropdownRef, () => {
    CloseDropdown()
  })
  return (
    <>
      <S.Wrapper ref={dropdownRef}>
        <S.Title onClick={toggleIsOpen}>{title}</S.Title>
        <S.Dropdown isOpen={isOpen}>{children}</S.Dropdown>
      </S.Wrapper>
    </>
  )
}

export default CustomerDropdown
