import { useMutation, useQuery } from 'react-query'
import { editProfile, getProfile } from 'services/apiV2/users'

export function useGetUser(id, options) {
  return useQuery(['user', id], () => getProfile(id), {
    refetchOnWindowFocus: false,
    ...options,
  })
}

export function useEditProfile(options) {
  return useMutation((data) => editProfile(data), { ...options })
}
