import React, { createContext, useState, useEffect } from 'react'
import { database } from 'services/firebase'
import { onValue, ref } from 'firebase/database'

export const ModalContext = createContext({})

export function ModalProvider({
  openOnReanalyse,
  clientId,
  fetchProposalData,
  children,
}) {
  const [openModal, setOpenModal] = useState(false)
  const [currentModal, setCurrentModal] = useState('')
  const [reanalyzeData, setReanalyseData] = useState({})

  const [hasResult, setHasResult] = useState('')
  const [hasReanalysisError, setHasReanalysisError] = useState('')

  const handleOpenModal = (modal) => {
    setOpenModal(true)
    setCurrentModal(modal)
  }
  const handleCloseModal = () => {
    setOpenModal(false)
    setCurrentModal('')
  }

  const handleFetchClientDetails = () => {
    fetchProposalData()
    setOpenModal(false)
    setCurrentModal('')
  }

  useEffect(() => {
    if (openOnReanalyse) {
      setOpenModal(true)
      setCurrentModal('ReanalysisModal')
    }
  }, [openOnReanalyse])

  useEffect(() => {
    if (openModal) {
      document.body.style.overflow = 'hidden'
    }
    return () => (document.body.style.overflow = 'unset')
  }, [openModal])

  useEffect(() => {
    if (currentModal === 'ReanalysisModal') {
      const query = ref(database, `renovation/proposal/${clientId}`)
      onValue(query, (snap) => {
        if (snap.exists()) {
          setReanalyseData(snap.val())
        }
      })
      return () => ref.off()
    }
  }, [currentModal, clientId])

  useEffect(() => {
    if (reanalyzeData.status === 'done' && hasResult === '') {
      if (reanalyzeData.result === 'ERRO') {
        setHasReanalysisError(reanalyzeData.result)
      } else {
        setCurrentModal('FinishedReanalysisModal')
        setHasResult(reanalyzeData.result)
      }
    }
  }, [reanalyzeData.status, hasResult, reanalyzeData.result])

  return (
    <ModalContext.Provider
      value={{
        openModal,
        currentModal,
        handleOpenModal,
        handleCloseModal,
        reanalyzeData,
        hasResult,
        hasReanalysisError,
        clientId,
        handleFetchClientDetails,
      }}
    >
      {children}
    </ModalContext.Provider>
  )
}
