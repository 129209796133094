import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'

import { Button, Menu, Notification } from 'components'
import { useCurrentUser } from 'hooks'
import { encryptTextWithKey, getSucursal, updateCustomData } from 'utils'

import { Main, Content } from './styles'
import { useSelector } from 'react-redux'
import Modal from 'components/Modals/Simple'
import { useHistory } from 'react-router-dom'

export default function Default({ children }) {
  const history = useHistory()
  const { showUpdateUserModal } = useSelector((state) => state.users)

  const { properties = {} } = children.type
  const { metatags = {} } = properties
  const user = useCurrentUser()

  const baseTitle = 'Painel Comercial'

  const branch = user.adviser?.branches || user.broker?.branch || ''
  const sucursal = getSucursal(branch)
  const showModal =
    showUpdateUserModal && history.location.pathname !== '/editar-perfil'

  useEffect(() => {
    updateCustomData({
      page: {
        title: metatags.title,
        susep: user.broker?.susep || '',
      },
      campaign: 'corretores',
      product: 'carro-por-assinatura',
      user: {
        id_usuario: encryptTextWithKey(user.infos?.document, 'chave') || '',
        sucursal,
        perfil: user.role,
      },
      visit: {
        visitor_id: user.id,
      },
    })
  }, [metatags, user, sucursal])

  return (
    <>
      <Helmet>
        {metatags.title ? (
          <title>
            {metatags.title} - {baseTitle}
          </title>
        ) : (
          <title>{baseTitle}</title>
        )}
        {/* <script
          src="//cdn.pn.vg/sites/20f4b130-1ac0-421f-8383-3845ec2139b6.js"
          async
        /> */}

        <script
          src="https://cdn-ukwest.onetrust.com/scripttemplates/otSDKStub.js"
          type="text/javascript"
          charset="UTF-8"
          data-domain-script={process.env.REACT_APP_ONETRUST_KEY}
        />

        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{ __html: 'function OptanonWrapper() {}' }}
        />
      </Helmet>

      <Notification />

      <Menu />

      <Main>
        <Content>{children}</Content>

        <Modal title="Atenção" open={showModal}>
          <p>
            Para mantermos nossos dados atualizados, solicitamos que os usuários
            atualizem seus dados cadastrais. Por favor, verifique se as
            informações estão corretas.
          </p>
          <Button onClick={() => history.push('/editar-perfil')}>
            Atualizar meus dados
          </Button>
        </Modal>
      </Main>
    </>
  )
}
