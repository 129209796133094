import { customMedia } from 'components/MediaMatch'
import styled, { css } from 'styled-components'
import media from 'styled-media-query'

export const Container = styled.div`
  ${({ theme }) => css`
    height: calc(100vh - 80px);

    display: flex;
    flex-direction: column;

    ${customMedia.greaterThan('xlarge')`
      display: flex;
      flex-direction: row;
    `}
    padding: 0 0 ${theme.spacings.small};
  `}
`

export const Content = styled.div`
  ${({ theme }) => css`
    flex: 1;
    display: flex;
    flex-direction: column;

    padding: 0 ${theme.spacings.small};

    ${media.lessThan('huge')`
    overflow-y: auto;

    ::-webkit-scrollbar {
      width: 0px;
    }
  `}
  `}
`

export const Title = styled.h1`
  ${({ theme }) => css`
    font-weight: 600;
    font-size: ${theme.fonts.sizes.xlarge};
    line-height: 1;
    margin-bottom: ${theme.spacings.small};

    color: ${theme.colors.black};
  `}
`
export const Overflow = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;

  ${media.greaterThan('huge')`  
    overflow-y: auto;

    ::-webkit-scrollbar {
      width: 0px;
    }
  `}
`

export const OverflowMobile = styled.div`
  height: 100%;

  ${media.lessThan('medium')`
    overflow-y: auto;

    ::-webkit-scrollbar {
      width: 0px;
    }
  `}
`

export const Filters = styled.div`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacings.medium};

    display: grid;
    grid-template-columns: 1.5fr 1fr 1fr 1fr;
    align-items: center;
    grid-gap: ${theme.spacings.small};

    ${media.lessThan('large')`
      grid-template-columns: 1fr 1fr 1fr 1fr;
    `}

    ${media.lessThan('medium')`
      margin-bottom: ${theme.spacings.xsmall};

      grid-template-columns: 1fr 1fr;
      grid-gap: ${theme.spacings.xsmall};
    `}
  `}
`

export const Error = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.fonts.sizes.xsmall};
    color: ${({ theme }) => theme.colors.danger};
    font-family: ${({ theme }) => theme.fonts.bold};

    ${media.lessThan('large')`
      font-size: ${theme.fonts.sizes.xxsmall};
    `}
  `}
`

export const WrapperIconButton = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    gap: ${theme.spacings.xxsmall};
  `}
`

export const CardContainer = styled.div`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: 1fr;
    gap: ${theme.spacings.xxsmall};
  `}
`

export const Message = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.newGray[90]};
    font-weight: 600;
    font-size: ${theme.fonts.sizes.xsmall};
    line-height: 18px;
  `}
`

export const SusepTip = styled.div`
  ${({ theme }) => css`
    .susepTooltip {
      border-radius: ${theme.borderRadius.medium};
    }

    &:hover {
      text-decoration: underline;
    }
  `}
`
