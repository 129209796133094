export const CUSTOMERS_TABLE_HEADER = [
  {
    header: 'CPF',
    acessor: 'document',
  },
  {
    header: 'Nome',
    acessor: 'name',
  },
  {
    header: 'Telefone',
    acessor: 'phone',
  },
  {
    header: 'E-mail',
    acessor: 'email',
  },
  {
    header: 'Data da Proposta',
    acessor: 'createdAt',
  },
  {
    header: 'Contrato',
    acessor: 'contract',
  },
  {
    header: 'SUSEP',
    acessor: 'susep',
  },
  {
    header: 'Sucursal',
    acessor: 'branch',
  },
  {
    header: 'Situação',
    acessor: 'status',
  },
  {
    header: 'Ação',
    acessor: 'actions',
  },
]

export const DELIVERY_PLACE_TABLE_COLUMNS = [
  {
    header: 'Estado',
    acessor: 'state',
  },
  {
    header: 'CAPS',
    acessor: 'name',
  },
  {
    header: 'Endereço',
    acessor: 'address',
  },
]

export const ADDITIONAL_KILOMETERS_TABLE_COLUMNS = [
  {
    header: 'Categoria',
    acessor: 'category',
  },
  {
    header: 'Valor/1000 KM',
    acessor: 'value',
  },
]