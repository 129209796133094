import React, { useState } from 'react'

import { Flex } from 'components'
import * as S from './styles'

export default function Help({ title, subtitle, testid, children, clicktype }) {
  const [isOpen, setIsOpens] = useState(false)

  return (
    <S.Container>
      <S.CustomIcon
        size="16px"
        name="help-circle"
        color="#C7C7C7"
        data-gtm-click="click"
        data-gtm-clicktype={clicktype || "button"}
        data-gtm-name="duvida"
        data-gtm-subname={title}
        data-testid={testid}
        onClick={() => setIsOpens(true)}
      />

      <S.Tip isOpen={isOpen}>
        <Flex flexDirection="column" alignItems="flex-start">
          <S.Title>{title}</S.Title>
          <S.Text>{subtitle}</S.Text>
        </Flex>
        <Flex
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          {children}
        </Flex>
        <S.CustomFlex justifyContent="flex-end">
          <S.Button
            data-testid="close-help"
            data-gtm-type="click"
            data-gtm-clicktype="button"
            data-gtm-name="entendi!"
            onClick={() => setIsOpens(false)}
          >
            Entendi!
          </S.Button>
        </S.CustomFlex>
      </S.Tip>
    </S.Container>
  )
}
