import React, { useState } from 'react'
import { Icon } from 'components'

import * as S from './styles'

function Pagination({ pagination, onPagination, isLoading }) {
  const pages = Array.from({ length: pagination?.total_pages }, (_, index) => {
    return index + 1
  })

  const maxNumberPages = 5

  const [showPages, setShowPages] = useState({
    initialPage: 1,
    finalPage: maxNumberPages,
  })

  const nextPage = Number(pagination?.current_page + 1)
  const prevPage = Number(pagination?.current_page - 1)

  const handlePagination = (page) => {
    if (page >= showPages.finalPage && page < pagination?.total_pages) {
      setShowPages((prevPages) => ({
        initialPage: prevPages.initialPage + 1,
        finalPage: pages?.length < 5 ? pages?.length : prevPages.finalPage + 1,
      }))
    }

    if (page > 1 && page <= showPages.initialPage) {
      setShowPages((prevPages) => ({
        initialPage: prevPages.initialPage - 1,
        finalPage: pages?.length < 5 ? pages?.length : prevPages.finalPage - 1,
      }))
    }

    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })

    onPagination(page)
  }

  return (
    <>
      {!isLoading && pagination?.items_per_page !== 0 && (
        <S.Container>
          <S.PaginationButton
            type="button"
            disabled={pagination?.current_page < 2}
            onClick={() => {
              handlePagination(prevPage)
            }}
          >
            <Icon name="chevron-left" />
          </S.PaginationButton>
          {pages
            ?.slice(showPages.initialPage - 1, showPages.finalPage)
            ?.map((page) => {
              const currentPage = page === pagination?.current_page

              return (
                <S.PaginationItem
                  key={page}
                  currentPage={currentPage}
                  onClick={() => {
                    handlePagination(page)
                  }}
                >
                  {page}
                </S.PaginationItem>
              )
            })}

          <S.PaginationButton
            type="button"
            disabled={pagination?.current_page === pagination?.total_pages}
            onClick={() => {
              handlePagination(nextPage)
            }}
          >
            <Icon name="chevron-right" />
          </S.PaginationButton>
        </S.Container>
      )}
    </>
  )
}

export default Pagination
