import React from 'react'
import { Input, Tip } from 'components'
import { USER_ROLES } from 'helpers'

import * as S from './styles.js'

function EditForm({ user, handleOpenModal, error }) {
  return (
    <S.Container>
      <S.Fields
        m="30px 0"
        gridRowGap="20px"
        gridColumnGap="40px"
        gridTemplateColumns={['1fr', '1fr 1fr', '1fr 1fr 1fr']}
      >
        <Input name="name" label="Nome" placeholder="Digite o nome" />
        <S.InputWrapper>
          <Input
            name="email"
            label="E-mail"
            placeholder="Digite o seu e-mail"
            disabled
          />
          <S.Action
            data-testid="alterar-status"
            size="small"
            onClick={!error && !user.error && handleOpenModal}
          >
            <Tip size="15px" name="Alterar e-mail" icon="edit">
              Alterar e-mail
            </Tip>
          </S.Action>
        </S.InputWrapper>
        <Input
          label="CPF"
          name="document"
          placeholder="Digite o CPF"
          mask="###.###.###-##"
          numericOnly
        />

        <Input name="cellphone" placeholder="Celular" label="Celular" phone />

        <S.InputWithTooltip>
          <Input
            name="branches"
            label="Sucursal"
            placeholder="Digite a Sucursal"
            toUpperCase
          />
          {user.role === USER_ROLES.ADVISER && (
            <Tip size="10px" name="info" icon="info">
              Separe as sucursais por{' '}
              <b>
                vírgula
                <br />
                Ex: 23, 34, 45
              </b>
            </Tip>
          )}
        </S.InputWithTooltip>
      </S.Fields>
    </S.Container>
  )
}

export default EditForm
