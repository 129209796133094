import styled from 'styled-components'
import media from 'styled-media-query'

export const Container = styled.div`
`

export const ErrorMessage = styled.p`
  padding-top: 20px;

  font-size: 13px;
  color: ${({ theme }) => theme.colors.danger};
  font-family: ${({ theme }) => theme.fonts.bold};

  ${media.lessThan('large')`
    font-size: 11px;
  `}
`

export const Tips = {
  Container: styled.div`
    max-width: 300px;
  `,
  Label: styled.p`
    font-size: 14px;
    margin-bottom: 10px;
  `,
  Title: styled.p`
    font-size: 14px;
    margin-bottom: 5px;
  `,
  Text: styled.p`
    font-size: 14px;
    margin-bottom: 5px;
  `,
}

export const Actions = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: ${({ theme }) => theme.spacings.xxlarge};

  ${media.lessThan('medium')`
    gap: ${({ theme }) => theme.spacings.xsmall};
    flex-direction: column;
  `}
`
