import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Dropdown, Allow, Offcanvas, MediaMatch, Icon } from 'components'
import { useAuthentication, useCurrentUser, useOffcanvas } from 'hooks'
import { USER_ROLES } from 'helpers'
import ModalRefreshTable from './ModalRefreshTable'
import { useUpdatePriceTable } from '../../services/hooks/prices'
import { queryClient } from 'services/queryClient'

import * as S from './styles'

export default function Menu() {
  const history = useHistory()
  const dataLayer = window.dataLayer || []

  const { toggle } = useOffcanvas()
  const currentUser = useCurrentUser()

  const [isOpen, setIsOpen] = useState(false)

  const { isLoading: isUpdatingPriceTable, mutateAsync } = useUpdatePriceTable({
    onSuccess: () => {
      queryClient.invalidateQueries('vehiclesPrices')
    },
  })

  const toggleModal = () => {
    setIsOpen((prevState) => !prevState)
  }

  const user = {
    name: currentUser?.infos?.name,
    branch: currentUser[currentUser.role]?.branch,
    susep: currentUser[currentUser.role]?.susep,
    role: currentUser.role,
  }

  const DROPDOWN_ROLES = {
    administrator: {
      label: 'Administrador',
    },
    broker: {
      label: 'Corretor',
      infos: {
        label: 'SUSEP',
        value: user.susep,
      },
    },
    adviser: {
      label: 'Consultor',
      infos: {
        label: 'Sucursal',
        value: user.branch,
      },
    },
  }

  const items = [
    {
      testid: 'item-menu-',
      label: 'Painel',
      path: '/',
    },
    {
      testid: 'item-menu-',
      label: 'Clientes',
      path: '/clientes',
    },
    {
      testid: 'item-menu-',
      label: 'Estoque',
      path: '/estoque',
    },
    {
      testid: 'item-menu-precos',
      label: 'Preços',
      path: '/precos',
    },
    {
      testid: 'item-menu-',
      label: 'Usuários',
      path: '/usuarios',
      roles: [USER_ROLES.ADMINISTRATOR],
    },
    {
      testid: 'item-menu-',
      label: 'Gerar minha LP',
      path: '/corretor',
      roles: [USER_ROLES.BROKER],
    },
    {
      testid: 'item-menu-',
      label: 'Leads',
      path: '/distribuicao',
      roles: [USER_ROLES.ADMINISTRATOR],
    },
    {
      testid: 'item-menu-renovacao',
      label: 'Renovação',
      path: '/renovacao',
    },
    {
      testid: 'item-menu-indicadores',
      label: 'Indicadores',
      path: '/indicadores',
    },
    {
      label: 'Novidades',
      path: '/novidades',
    },
  ]

  const { isAuthenticated, logout } = useAuthentication()

  const handleLogout = () => {
    logout()

    dataLayer.push({
      event: 'solicitacao_servico',
      nome_servico: 'perfil',
      tipo_servico: 'logoff',
      susep: user?.susep || '',
      retorno: 'sucesso',
      descricao: 'usuário deslogado',
    })
  }

  const openOffcanvas = () => {
    toggle()

    dataLayer.push({
      event: 'modal',
      action: 'open',
      name: 'menu-lateral',
    })
  }

  return (
    <>
      <S.Container>
        <MediaMatch lessThan="small">
          {!isAuthenticated && (
            <S.Frame
              data-gtm-type="click"
              data-gtm-clicktype="logo"
              data-gtm-name="carro-por-assinatura"
              onClick={() => history.push('/')}
              align="center"
            >
              <S.SVG
                src="/images/logo/cf-assinatura-logo.svg"
                alt="Carro por assinatura Porto Bank"
              />
            </S.Frame>
          )}
        </MediaMatch>
        <MediaMatch greaterThan="small">
          <S.Left>
            <S.Frame
              data-gtm-type="click"
              data-gtm-clicktype="logo"
              data-gtm-name="carro-por-assinatura"
              onClick={() => history.push('/')}
              align="left"
            >
              <S.SVG
                src="/images/logo/cf-assinatura-logo.svg"
                alt="Carro por assinatura Porto Bank"
              />
            </S.Frame>
          </S.Left>
        </MediaMatch>
        <MediaMatch greaterThan="xlarge">
          {isAuthenticated && (
            <S.MenuNav>
              {items.map((item, index) => (
                <Allow key={index} roles={item.roles}>
                  <S.StyledLink
                    exact
                    to={item.path}
                    data-gtm-type="click"
                    data-gtm-clicktype="button"
                    data-gtm-name={item.label.toLowerCase()}
                    data-gtm-subname="menu"
                    activeClassName="active-link"
                  >
                    {item.label}
                  </S.StyledLink>
                </Allow>
              ))}
            </S.MenuNav>
          )}
        </MediaMatch>

        <S.Right>
          {isAuthenticated && (
            <S.User
              data-gtm-type="click"
              data-gtm-clicktype="icone"
              data-gtm-name="perfil"
            >
              <Dropdown
                title={<Icon name="user" color="#0027F4" size="24px" />}
              >
                <>
                  <S.DropdownLabelWrapper>
                    <S.DropdownLabel>
                      <S.Label>{DROPDOWN_ROLES[user.role].label}: </S.Label>
                      {user.name}
                    </S.DropdownLabel>
                    {DROPDOWN_ROLES[user.role].infos && (
                      <S.DropdownLabel>
                        <S.Label>
                          {DROPDOWN_ROLES[user.role].infos.label}:{' '}
                        </S.Label>
                        {DROPDOWN_ROLES[user.role].infos.value}
                      </S.DropdownLabel>
                    )}
                  </S.DropdownLabelWrapper>

                  <S.DropdownButton
                    color="primary"
                    type="button"
                    onClick={() => {
                      history.push('/editar-perfil')
                    }}
                  >
                    <p>Editar perfil</p>
                  </S.DropdownButton>

                  <Allow roles={[USER_ROLES.ADMINISTRATOR]}>
                    <S.DropdownButton
                      color="primary"
                      type="button"
                      onClick={() => {
                        toggleModal()
                        mutateAsync()
                      }}
                      isLoading={isUpdatingPriceTable}
                    >
                      <p>Atualizar dados da operação</p>
                    </S.DropdownButton>
                  </Allow>

                  <S.DropdownButton
                    data-gtm-type="click"
                    data-gtm-clicktype="button"
                    data-gtm-name="sair"
                    onClick={handleLogout}
                  >
                    Sair
                  </S.DropdownButton>
                </>
              </Dropdown>
            </S.User>
          )}
          {isAuthenticated && (
            <MediaMatch lessThan="xlarge">
              <S.ToggleMenu
                data-testid="button-open-menu"
                data-gtm-type="click"
                data-gtm-name="menu"
                data-gtm-clicktype="button"
                onClick={openOffcanvas}
              >
                <Icon name="menu" />
                <S.Text>Menu</S.Text>
              </S.ToggleMenu>
            </MediaMatch>
          )}
        </S.Right>
      </S.Container>
      <Offcanvas items={items} />
      <ModalRefreshTable isOpen={isOpen} toggleModal={toggleModal} />
    </>
  )
}
