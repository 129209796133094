import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Allow, Grid, Select, Textarea } from 'components'
import { CANCEL_OPTIONS, CANCEL_STATUS, STATUS, USER_ROLES } from 'helpers'

import { useApproveOrder, useCancelOrder } from 'services/hooks/orders'
import { cancel, denied } from './validation'
import { StatusModal } from '..'

import * as S from '../../styles'

const APPROVE_BUTTON_COLORS = {
  idle: 'primary',
  loading: 'primary',
  error: 'danger',
  success: 'success',
}

const CANCEL_BUTTON_COLORS = {
  idle: 'danger',
  loading: 'danger',
  error: 'danger',
  success: 'success',
}

export default function Status({ order }) {
  const [isVisible, setIsVisible] = useState({
    cancelModal: false,
    approveModal: false,
  })

  const history = useHistory()
  const approveOrder = useApproveOrder()
  const cancelOrder = useCancelOrder({
    onSuccess: (data) => {
      history.push(`/pedidos/${data?._id}`)
    },
  })

  const handleOpenApproveModal = () => {
    setIsVisible((prev) => ({ ...prev, approveModal: true }))
  }

  const handleCloseApproveModal = () => {
    setIsVisible((prev) => ({ ...prev, approveModal: false }))
  }

  const handleOpenCancelModal = () => {
    setIsVisible((prev) => ({ ...prev, cancelModal: true }))
  }

  const handleCloseCancelModal = () => {
    setIsVisible((prev) => ({ ...prev, cancelModal: false }))
  }

  const handleSubmitCancelOrder = (values) => {
    const data = {
      ...values,
      order_id: order.id,
    }

    cancelOrder.mutate(data)

    handleCloseCancelModal()
  }

  const handleSubmitApproveCredit = (values) => {
    const data = {
      ...values,
      order_id: order.id,
    }
    approveOrder.mutate(data)

    handleCloseApproveModal()
  }

  return (
    <>
      {[STATUS.DENIED].includes(order.status) && (
        <Allow roles={[USER_ROLES.ADMINISTRATOR]}>
          <S.Dialog>
            <Grid
              my={4}
              alignItems="center"
              gridTemplateColumns={['1fr', '1fr 0.6fr']}
              gridGap="24px"
              justifySelf="center"
            >
              <S.Label>Aprovar crédito</S.Label>

              <S.CustomButton
                disabled={approveOrder.isSuccess || approveOrder.isError}
                isLoading={approveOrder.isLoading}
                color={APPROVE_BUTTON_COLORS[approveOrder.status]}
                onClick={handleOpenApproveModal}
              >
                Aprovar crédito
              </S.CustomButton>
            </Grid>
          </S.Dialog>
        </Allow>
      )}

      {CANCEL_STATUS.includes(order.status) && (
        <S.Dialog>
          <Grid
            my={4}
            justifyContent="space-between"
            alignItems="center"
            gridTemplateColumns={['1fr', '1fr 0.6fr']}
            gridGap="24px"
          >
            <S.Label>Resetar proposta</S.Label>

            <S.CustomButton
              disabled={cancelOrder.isSuccess || cancelOrder.isError}
              isLoading={cancelOrder.isLoading}
              color={CANCEL_BUTTON_COLORS[cancelOrder.status]}
              onClick={handleOpenCancelModal}
            >
              Resetar proposta
            </S.CustomButton>
          </Grid>
        </S.Dialog>
      )}

      <StatusModal
        flag="Resetar proposta"
        title="A proposta atual será cancelada e uma nova será atrelada a esse cliente. Deseja prosseguir ?"
        isVisible={isVisible.cancelModal}
        onSubmit={handleSubmitCancelOrder}
        onClose={handleCloseCancelModal}
        validationSchema={cancel}
      >
        <Select
          name="reason"
          options={CANCEL_OPTIONS}
          placeholder="Selecione o motivo"
        />
      </StatusModal>

      <StatusModal
        flag="Aprovar crédito"
        title="Por favor, justifique a razão da aprovação. Ela será vinculada ao seu usuário e dados do seu dispositivo para auditoria posterior."
        isVisible={isVisible.approveModal}
        onSubmit={handleSubmitApproveCredit}
        onClose={handleCloseApproveModal}
        validationSchema={denied}
      >
        <Textarea name="reason" placeholder="Justificativa" />
      </StatusModal>
    </>
  )
}
