import { createStore, createEvent, createEffect } from 'effector'
import { useStore } from 'effector-react'

import { getOrderDetails } from 'services'
import { ORDER_STEP_NUMBER, STATUS } from 'helpers'

const STATUS_NOT_SHOW_DONE = [
  STATUS.PENDING,
  STATUS.PREAPP,
  STATUS.OFFER,
  STATUS.PRODUCT,
  STATUS.PERFIL,
  STATUS.DENIED,
]

const store = createStore({
  currentStep: 1,
  userData: {},
  loading: false,
  steps: {
    step1: {
      isVisible: false,
    },
    step2: {
      isVisible: false,
    },
    step3: {
      isVisible: false,
    },
    analysis: {
      isVisible: false,
    },
    done: { isVisible: false },
    denied: { isVisible: false },
  },
})

const setCurrentStep = createEvent()
const setUserData = createEvent()
const setLoading = createEvent()
const resetStepCounter = createEvent()

const fetchUserData = createEffect({
  handler: async (id) => {
    try {
      const response = await getOrderDetails(id)

      setCurrentStep(ORDER_STEP_NUMBER[response.status])

      return response
    } catch (err) {
      throw err
    }
  },
})

store
  .on(setCurrentStep, (state, currentStep) => {
    return { ...state, currentStep }
  })
  .on(setUserData, (state, userData) => {
    return { ...state, userData }
  })
  .on(setLoading, (state, loading) => {
    return typeof loading === 'boolean' && { ...state, loading }
  })
  .on(resetStepCounter, (state) => {
    return {
      ...state,
      steps: {
        step1: {
          isVisible: true,
        },
        step2: {
          isVisible: false,
        },
        step3: {
          isVisible: false,
        },
        analysis: {
          isVisible: false,
        },
        done: {
          isVisible: false,
        },
        denied: {
          isVisible: false,
        },
      },
    }
  })
  .on(fetchUserData.pending, (state, pending) => {
    return { ...state, loading: pending ? true : false }
  })
  .on(fetchUserData.doneData, (state, userData) => ({
    ...state,
    loading: false,
    userData,
    currentStep: ORDER_STEP_NUMBER[userData.status],
    steps: {
      step1: {
        isVisible:
          ORDER_STEP_NUMBER[userData.status] === ORDER_STEP_NUMBER.pending ||
          ORDER_STEP_NUMBER[userData.status] === ORDER_STEP_NUMBER.preapp,
      },
      step2: {
        isVisible:
          ORDER_STEP_NUMBER[userData.status] === ORDER_STEP_NUMBER.offer,
      },
      step3: {
        isVisible:
          ORDER_STEP_NUMBER[userData.status] === ORDER_STEP_NUMBER.product,
      },
      analysis: {
        isVisible:
          ORDER_STEP_NUMBER[userData.status] === ORDER_STEP_NUMBER.perfil,
      },
      done: {
        isVisible: !STATUS_NOT_SHOW_DONE.some(
          (status) => status === userData.status
        ),
      },
      denied: {
        isVisible:
          ORDER_STEP_NUMBER[userData.status] === ORDER_STEP_NUMBER.denied,
      },
    },
  }))
  .on(fetchUserData.fail, (state, payload) => ({
    ...state,
    loading: false,
  }))

export function useStepper() {
  return {
    store: useStore(store),
    setCurrentStep,
    setUserData,
    fetchUserData,
    resetStepCounter,
  }
}
