import React, { useState, useEffect } from 'react'
import { Redirect, Link, useHistory } from 'react-router-dom'
import { FormContext, useForm } from 'react-hook-form'

import { createBroker } from 'services'
import { fetchBranchBySusep } from 'services/apiV2/susep'

import { useAuthentication, useNotification } from 'hooks'

import { Input, Button } from 'components'

import { Container, Box, Title, Fields, Message } from './styles'

import validationSchema from './validation'

import { auth } from 'services/firebase'
import { signInAnonymously } from 'firebase/auth'

import api from 'services'

export default function SignUp() {
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const [infos, setInfos] = useState({
    error: '',
    loading: false,
    fulfilled: false,
  })

  const dataLayer = window.dataLayer || []

  const form = useForm({
    validationSchema,
  })

  const history = useHistory()

  const notification = useNotification()

  const { isAuthenticated } = useAuthentication()

  const getAnonymouslyToken = () => {
    signInAnonymously(auth).then(
      ({ user }) => (api.defaults.headers.fireToken = `${user.accessToken}`)
    )
  }
  useEffect(() => {
    getAnonymouslyToken()

    dataLayer.push({
      event: 'step_change',
      etapa: 'cadastrar-corretor',
    })
  }, []) //eslint-disable-line

  const fetchBrokerInfos = async (event) => {
    const validation = await form.triggerValidation('broker.susep')

    if (!validation) return

    try {
      setInfos({
        error: '',
        loading: true,
        fulfilled: false,
      })

      const data = await fetchBranchBySusep(form.watch('broker.susep'))

      if (!data.branch.number)
        throw Error('Não existe nenhum corretor associado a esta SUSEP.')

      setInfos((state) => ({ ...state, fulfilled: true }))

      form.setValue([
        {
          broker: {
            branch: data.branch.number || '',
          },
        },
      ])
    } catch (error) {
      setInfos((state) => ({ ...state, error: error.message }))
    } finally {
      setInfos((state) => ({ ...state, loading: false }))
    }
  }

  const onSubmit = form.handleSubmit(async ({ broker }) => {
    try {
      setError('')
      setLoading(true)

      const data = {
        broker: {
          ...broker,
          cellphone: broker.cellphone.replace(/\s/g, ''),
        },
      }

      await createBroker(data)

      notification.open({
        type: 'success',
        content:
          'Cadastrado com sucesso, utilize os dados cadastrados para acessar o sistema.',
      })

      dataLayer.push({
        event: 'cadastro',
        nome_servico: 'susep',
        tipo_servico: 'cadastro-corretor',
        susep: broker.susep,
        retorno: 'sucesso',
      })

      history.push('/login')
    } catch (error) {
      setError(error.message)

      dataLayer.push({
        event: 'cadastro',
        nome_servico: 'susep',
        tipo_servico: 'cadastro-corretor',
        susep: broker.susep,
        retorno: 'erro',
        descricao: 'erro ao cadastrar corretor',
        erro: {
          codigo: error.status,
          servico: 'cadastro de corretor',
          mensagem: error.message,
        },
      })
    } finally {
      setLoading(false)
    }
  })

  useEffect(() => {
    if (!form.watch('broker.susep')) {
      form.setValue([
        {
          broker: {
            susep: '',
            name: '',
            email: '',
            document: '',
            cellphone: '',
            branch: '',
            password: '',
          },
        },
      ])

      return setInfos({
        error: '',
        loading: false,
        fulfilled: false,
      })
    }
  }, [form.watch('broker.susep')]) // eslint-disable-line

  if (isAuthenticated)
    return (
      <Redirect
        to={{
          pathname: '/',
        }}
      />
    )

  return (
    <Container>
      <Box>
        <Title>Cadastro de corretor</Title>

        <FormContext {...form}>
          <Input
            name="broker.susep"
            label="SUSEP"
            placeholder="Digite a SUSEP"
            onBlur={fetchBrokerInfos}
            toUpperCase
          />

          {infos.loading && (
            <Message color="gray">
              Aguarde, buscando dados do corretor...
            </Message>
          )}

          {infos.error && <Message color="danger">{infos.error}</Message>}

          <Fields hidden={!infos.fulfilled}>
            <Input
              name="broker.name"
              label="Nome"
              placeholder="Digite o nome"
            />

            <Input
              name="broker.email"
              label="E-mail"
              placeholder="Digite o seu e-mail"
            />

            <Input
              label="CPF"
              name="broker.document"
              placeholder="Digite o CPF"
              mask="###.###.###-##"
              numericOnly
            />
            <Input
              name="broker.cellphone"
              placeholder="Celular"
              label="Celular"
              phone
            />

            <Input
              name="broker.branch"
              label="Sucursal"
              placeholder="Digite a Sucursal (opcional)"
              disabled
              numericOnly
            />

            <Input
              name="broker.password"
              type="password"
              label="Senha"
              placeholder="Digite a senha"
            />

            <Button block isLoading={loading} onClick={onSubmit}>
              Cadastrar-se
            </Button>
          </Fields>

          <Link
            to="/login"
            data-gtm-type="click"
            data-gtm-clicktype="link"
            data-gtm-name="voltar-para-o-login"
          >
            <Button link block>
              Voltar para o login
            </Button>
          </Link>

          {error && <Message color="danger">{error}</Message>}
        </FormContext>
      </Box>
    </Container>
  )
}

SignUp.properties = {
  metatags: {
    title: 'Cadastro',
  },
}
