import styled, { css } from 'styled-components'
import media from 'styled-media-query'
import { rem } from 'polished'
import styledMap from 'styled-map'
import * as tipStyles from 'components/Tip/styles'

import colors from 'styles/colors'

import { componentLoader } from 'styles/helpers'

const colorTheme = styledMap('color', {
  ...colors,
  default: colors.gray.medium,
})

export const Options = styled.select`
  all: unset;

  width: 100%;
  height: 44px;
  padding: 0 3.4rem 0 1.4rem;

  display: flex;
  align-items: center;

  cursor: pointer;
  border-radius: 8px;
  box-sizing: border-box;
  font-size: ${rem('12px')};

  font-family: ${({ theme }) => theme.fonts.bold};

  ${media.greaterThan('huge')`
    height: 50px;

    font-size: ${rem('14px')};
  `}

  ${media.lessThan('small')`
    font-size: ${rem('10px')};
  `}

  > option {
    color: ${colors.primary};
  }
`

export const Option = styled.option`
  &:disabled {
    color: ${({ theme }) => theme.colors.gray.light};
  }
`

export const Container = styled.div`
  position: relative;

  ${tipStyles.Mark} {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: -2px;
  }

  ${Options} {
    color: ${colorTheme};
    border: 2px solid ${colorTheme};
  }

  ${({ disabled }) =>
    disabled &&
    css`
      ${Options} {
        color: ${({ theme }) => theme.colors.gray.light};
        border-color: ${({ theme }) => theme.colors.gray.light};
      }
    `}

  > svg {
    position: absolute;
    right: 1.4rem;
    top: 50%;
    transform: translateY(-50%);

    height: 18px;

    color: ${colorTheme};
    pointer-events: none;

    ${media.lessThan('small')`
      height: 14px;
    `}
  }
`

export const Loader = styled.div`
  ${componentLoader({
    visible: true,
    primary: true,
  })}

  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 22px;
`

export const Error = styled.p`
  position: absolute;
  bottom: -18px;
  left: 0;

  padding: 6px 20px 0;

  font-size: ${rem('12px')};
  color: ${({ theme }) => theme.colors.danger};
  font-family: ${({ theme }) => theme.fonts.medium};

  ${media.lessThan('small')`
    position: static;
    
    font-size: ${rem('8px')};
  `}
`
