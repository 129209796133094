import React from 'react'
import * as S from './styles'

function IconButton({
  children,
  color = 'primary',
  size = 'medium',
  ...props
}) {
  return (
    <S.Container {...props} color={color} size={size}>
      {children}
    </S.Container>
  )
}

export default IconButton
