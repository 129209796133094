import React from 'react'

import * as S from './styles'

export default function Basic({ data, columns }) {
  return (
    <S.TableContainer>
      <S.Table>
        <S.Thead>
          <S.Tr>
            {columns.map((column) => (
              <S.Th key={column.acessor}>{column.header}</S.Th>
            ))}
          </S.Tr>
        </S.Thead>

        <S.Tbody>
          {data?.map((row, index) => (
            <S.Tr key={index}>
              {columns.map((column, index) => (
                <S.Td key={index}>{row[column.acessor]}</S.Td>
              ))}
            </S.Tr>
          ))}
        </S.Tbody>
      </S.Table>
    </S.TableContainer>
  )
}
