import { Icon, Loading } from 'components'
import { useParams } from 'hooks'
import React, { useState } from 'react'
import { useTagsNews } from 'services/hooks/News'
import * as S from './styles'

const FilterDropdown = () => {
  const [isOpen, setIsOpen] = useState(false)
  const { params, setParams } = useParams()

  const handleChangeActiveFilter = (label) => {
    setActiveFilter(label)
  }
  const clearFilter = { value: null, label: 'Todos' }

  const { data, isLoading } = useTagsNews()

  const value = data?.find((news) => news.value === params.tag)

  const [activeFilter, setActiveFilter] = useState('Todos')

  const toggleDropdown = () => {
    !isLoading && setIsOpen(!isOpen)
  }

  return (
    <S.Wrapper isOpen={isOpen}>
      <S.Title onClick={toggleDropdown}>
        <S.FilterButton type="button">
          <span>Filtrar</span>
          {isLoading ? (
            <Loading size="2px" color="white" />
          ) : (
            <Icon name="chevron-down" />
          )}
        </S.FilterButton>
      </S.Title>
      {!isLoading && (
        <>
          <S.Content aria-hidden={!isOpen}>
            <S.DropdownContent>
              <S.DropdownHeader>
                <span>Filtros</span>
                <Icon name="alert-circle" />
                <S.CloseIcon onClick={toggleDropdown}>
                  <Icon name="x" size={20} />
                </S.CloseIcon>
              </S.DropdownHeader>
              <S.NavItems>
                {[clearFilter, ...data]?.map((tag) => (
                  <S.Items
                    key={tag.value}
                    activeFilter={
                      !!value
                        ? value?.label === tag.label
                        : activeFilter === tag.label
                    }
                    onClick={() => {
                      setParams({ tag: tag.value })
                      toggleDropdown()
                      handleChangeActiveFilter(tag.label)
                    }}
                  >
                    {tag.label}
                  </S.Items>
                ))}
              </S.NavItems>
            </S.DropdownContent>
          </S.Content>
          <S.Overlay onClick={toggleDropdown} aria-hidden={!isOpen} />
        </>
      )}
    </S.Wrapper>
  )
}

export default FilterDropdown
