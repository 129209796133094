import api, { getRoleIdByName } from 'services'

export async function createUser(payload) {
  const { role } = payload
  const {
    name,
    email,
    document,
    password,
    susep,
    registry,
    branches,
    branch,
    cellphone,
  } = payload[role]

  const selectedRoleId = await getRoleIdByName(
    {
      administrator: 'administrator',
      broker: 'broker',
      adviser: 'adviser',
    }[role]
  )

  const defaultData = {
    fullName: name,
    email: email.trim(),
    cpf: {
      number: document,
    },
    role: selectedRoleId,
    password,
    cellphone,
    isUpToDate: true,
  }

  const PAYLOAD_PARSED = {
    administrator: {
      ...defaultData,
      registry,
    },

    broker: {
      ...defaultData,
      susep,
      branch,
    },

    adviser: {
      ...defaultData,
      branch: branches?.[0],
      branches,
    },
  }

  try {
    const response = await api.post('/integracao/users', PAYLOAD_PARSED[role])
    return response.data
  } catch (error) {
    if (
      error?.response?.data?.message[0]?.messages[0]?.message ===
      'Email already taken.'
    )
      throw Error('Este e-mail já foi cadastrado no sistema.')

    if (
      error?.response?.data?.message[0]?.messages[0]?.message?.includes(
        'Broker already created'
      )
    ) {
      throw Error('Essa SUSEP já foi cadastrada no sistema.')
    }

    throw Error(
      'Houve uma instabilidade no sistema. Tente novamente mais tarde ou entre em contato com a Porto Seguro.'
    )
  }
}

export async function getUser(id) {
  try {
    const response = await api.get('/integracao/users', {
      params: {
        id,
      },
    })

    return response.data
  } catch (error) {
    console.error(error)
    throw Error(
      'Houve uma instabilidade no sistema. Tente novamente mais tarde ou entre em contato com a Porto Seguro.'
    )
  }
}

export async function getBrokers() {
  try {
    const response = await api.get('/integracao/users', {
      params: {
        'role.type': 'broker',
        _limit: 0,
      },
    })

    return response.data.map((user) => {
      const { id, email, role, customer, broker } = user

      return {
        id,
        email,
        role,
        name: customer?.fullName || '',
        document: customer?.cpf?.number || '',
        susep: broker?.susep,
        brokerId: broker?.id,
      }
    })
  } catch (error) {
    throw Error(
      'Ops! Erro ao encontrar usuários. Tente novamente em alguns instantes.'
    )
  }
}

export async function getBroker(susep) {
  try {
    const response = await api.get(`/integracao/users`, {
      params: {
        'broker.susep': susep || '',
      },
    })

    return response.data
  } catch (error) {
    console.error(error)
    throw error
  }
}

export async function forgotPassword(payload) {
  const PAYLOAD_PARSE = {
    user: {
      email: payload.email,
    },
  }

  console.log(PAYLOAD_PARSE)

  // try {
  //   const response = await api.post('/users/forgot-password', PAYLOAD_PARSE)
  //   return response.data
  // } catch (error) {
  //   throw Error(
  //     'Houve uma instabilidade no sistema. Tente novamente mais tarde ou entre em contato com a Porto Seguro.'
  //   )
  // }
}
