import styled, { css } from 'styled-components'
import styledMap from 'styled-map'

export const Header = styled.header`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    gap: ${theme.spacings.xxsmall};
    margin-bottom: ${theme.spacings.small};
  `}
`
export const TitleWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    gap: ${theme.spacings.xsmall};
  `}
`

export const Title = styled.h1`
  ${({ theme }) => css`
    font-size: ${theme.fonts.sizes.huge};
    font-family: ${({ theme }) => theme.fonts.book};
    margin: 0;
  `}
`

export const Text = styled.p`
  ${({ theme }) => css`
    color: ${styledMap('color', {
      ...theme.colors,
      gray: theme.colors.gray.dark,
    })};

    > span {
      text-decoration: underline;
      cursor: pointer;
    }
  `}
`

export const Loader = styled.div`
  background-image: url('/images/loader.svg');
  background-size: contain;
  width: 24px;
  height: 24px;
`

export const Content = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    gap: ${theme.spacings.small};
  `}
`
