import { rem } from 'polished'
import styled from 'styled-components'
import media from 'styled-media-query'

import { Content as Modal } from 'components/Modals/Simple/styles'

export const Container = styled.div`
  margin-top: ${rem('50px')};
  width: 100%;
`

export const Content = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-gap: 1rem;
  padding: 0;
  margin-top: ${rem('32px')};

  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.14), 0px 3px 4px rgba(0, 0, 0, 0.12),
    0px 1px 8px rgba(0, 0, 0, 0.2);
  background: #fff;
  border-radius: 10px;

  ${media.lessThan('small')`
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: 1fr;
    padding: 0.75rem;
  `}
`

export const Image = styled.div`
  min-height: ${rem('160px')};
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  img {
    width: 100%;
  }
`

export const Wrapper = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-gap: 0.5;

  ${media.lessThan('small')`
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: 1fr;
  `}

  ${Modal} {
    max-height: none;
    top: 50%;
    transform: translate(50%, -50%);

    max-width: 780px;
    width: 90%;
  }
`

export const Delivery = styled.div`
  display: flex;
  flex-direction: column;
`

export const Model = styled.h4`
  font-family: ${({ theme }) => theme.fonts.medium};
  font-weight: 450;
  font-size: 1.2rem;
  color: ${({ theme }) => theme.colors.gray.crayola};
  text-transform: uppercase;

  ${media.greaterThan('huge')`
    font-size: 1.5rem;
  `}

  ${media.lessThan('small')`
    font-size: 14px;
  `}
`
export const Info = styled.p`
  font-family: ${({ theme }) => theme.fonts.medium};
  font-weight: 450;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.gray.crayola};
  margin-top: 5px;

  ${media.greaterThan('huge')`
    font-size: 14px;
  `}
`

export const PriceContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
  height: 100%;
  padding: ${rem('18px')};
`

export const VehicleContainer = styled(PriceContainer)`
  align-items: start;
`

export const Price = styled.h3`
  font-family: ${({ theme }) => theme.fonts.book};
  font-size: 1.5rem;
  color: ${({ theme }) => theme.colors.gray.crayola};
  font-weight: 400;
  line-height: ${rem('32px')};
`
