import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { Head, Content, FlexButtons } from './styles'

import { Button, Flex, Allow } from 'components'
import { ScrollButton } from '../common'

import { ModalContext } from 'contexts'

import { USER_ROLES } from 'helpers'

export default function DetailsHeader({
  clientName,
  contractStatus,
  isLoading,
}) {
  const { handleOpenModal } = useContext(ModalContext)

  const history = useHistory()

  const handleRouteNavigation = () => {
    if (history.action === 'PUSH') {
      history.goBack()
      return
    }

    history.push('/renovacao')
  }

  const scrollToBottom = () => {
    window.scroll({
      top: document.body.offsetHeight,
      behavior: 'smooth',
    })
  }

  const handleReanalysisButton = () => {
    const status = ['APROVADO', 'DERIVACAO', 'RENOVADO']
    if (!contractStatus || status.includes(contractStatus)) {
      return
    }

    return (
      <Allow roles={[USER_ROLES.ADMINISTRATOR]}>
        <Button
          onClick={() => handleOpenModal('ConfimationModal')}
          color="gray"
        >
          Solicitar reanálise
        </Button>
      </Allow>
    )
  }

  return (
    <Head>
      <Flex justifyContent="space-between" flexDirection={['column', 'row']}>
        <Content>
          {!isLoading && (
            <>
              <span>Proposta de Renovação</span>
              <h2>{clientName}</h2>
              <span>
                Status: <strong>{contractStatus}</strong>
              </span>
            </>
          )}
        </Content>

        <FlexButtons>
          <Button onClick={handleRouteNavigation}>Voltar</Button>
          {handleReanalysisButton()}
          <ScrollButton icon="arrow-down" action={scrollToBottom} />
        </FlexButtons>
      </Flex>
    </Head>
  )
}
