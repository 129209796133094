import React from 'react'

import * as S from './styles'

export default function Error() {
  return (
    <S.Container>
      <S.Title>
        <span> Ops, desvio na via principal</span> <br />
        Essa página não está disponível.
      </S.Title>
      <S.Image alt="Painel comercial - Carro por assinatura Porto Bank" src="/images/404.svg" />
    </S.Container>
  )
}
