const spacings = {
  xxsmall: '8px',
  xsmall: '16px',
  small: '24px',
  medium: '32px',
  large: '40px',
  xlarge: '48px',
  xxlarge: '56px',
}

export default spacings
