import styled, { css } from 'styled-components'
import media from 'styled-media-query'

export const Container = styled.table`
  ${({ theme }) => css`
    width: 100%;
    text-align: left;
    border-collapse: collapse;
    table-layout: auto;
    border-top: 3px solid #cccccc;

    > thead {
      background: ${theme.colors.white};
      font-size: ${theme.fonts.sizes.xsmall};

      font-weight: 600;
      line-height: 1;
      font-family: ${theme.fonts.bold};
      color: ${theme.colors.newGray[50]};
      border: ${theme.border.hairline} solid ${theme.colors.newGray[10]};

      ${media.lessThan('large')`
        font-size: ${theme.fonts.sizes.xxsmall};
      `}

      th {
        padding: ${theme.spacings.small} ${theme.spacings.xxsmall};
      }
    }

    > tbody {
      font-family: ${theme.fonts.book};
      color: ${theme.colors.newGray[90]};

      font-size: ${theme.fonts.sizes.xsmall};
      ${media.lessThan('large')`
      font-size: ${theme.fonts.sizes.xxsmall};
      `}

      tr {
        background-color: ${theme.colors.white};
        border: ${theme.border.hairline} solid ${theme.colors.newGray[10]};

        td {
          padding: ${theme.spacings.small} ${theme.spacings.xxsmall};

          > button {
            &:not(:last-of-type) {
              margin-right: ${theme.spacings.small};
            }
          }
        }
      }
    }
  `}
`
