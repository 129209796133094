import styled, { css } from 'styled-components'

const tagModifiers = {
  normal: (theme) => css`
    padding: ${theme.spacings.xxsmall} ${theme.spacings.xsmall};
    font-size: ${theme.fonts.sizes.xsmall};
  `,
  small: (theme) => css`
    padding: calc(${theme.spacings.xxsmall} / 2) ${theme.spacings.xsmall};
    font-size: ${theme.fonts.sizes.xxsmall};
  `,
}

export const Tag = styled.div`
  ${({ theme, size }) => css`
    background: ${theme.colors.primary};
    border-radius: ${theme.borderRadius.pill};

    color: ${theme.colors.white};
    font-weight: 400;

    ${!!size && tagModifiers[size](theme)}
  `}
`
