import styled, { css } from 'styled-components'
import media from 'styled-media-query'
import { rgba, rem } from 'polished'
import styledMap from 'styled-map'

import { colors } from 'styles'

export const Container = styled.div`
  margin: 30px 0;

  ${media.lessThan('large')`
    padding: 20px;
  `}
`

export const Box = styled.div`
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0px 0px 50px ${rgba('#000', 0.1)};

  ${media.greaterThan('large')`
    width: 390px;
    margin: 0 auto;
    padding: 50px 30px;
  `}

  ${media.lessThan('large')`
    padding: 30px 20px;
  `}

  button {
    margin-top: 50px;
  }
`

export const Title = styled.h1`
  margin: 0;
  padding-bottom: 30px;

  font-size: ${rem('15px')};
  color: ${({ theme }) => theme.colors.gray.medium};

  ${media.lessThan('large')`
    font-size: ${rem('13px')};
  `}
`

export const Fields = styled.div`
  margin-top: 20px;

  ${({ hidden }) =>
    hidden &&
    css`
      display: none;
    `}

  > div {
    margin-bottom: 20px;
  }
`

export const Message = styled.p`
  margin-top: 20px;

  text-align: center;
  font-size: ${rem('11px')};
  font-family: ${({ theme }) => theme.fonts.bold};
  color: ${styledMap('color', {
    ...colors,
    gray: colors.gray.medium,
  })};

  ${media.lessThan('large')`
    font-size: ${rem('9px')};
  `}
`
