import styled from 'styled-components'
import media from 'styled-media-query'

export const Container = styled.div`
  ${media.lessThan('large')`
    margin-bottom: 2rem;
  `}
`

export const Title = styled.p`
  display: block;

  margin-bottom: 10px;

  font-size: 12px;
  color: ${({ theme }) => theme.colors.side};
  font-family: ${({ theme }) => theme.fonts.book};
`

export const Link = styled.a`
  text-decoration: none;
`

export const Box = styled.div`
  text-align: ${({ align }) => align};
`
