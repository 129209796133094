import React from 'react'
import { CheckboxSelect, Icon, Flex, Loading } from 'components'

import { EDIT_STATUS_PCO_OPTIONS } from 'helpers'
import * as S from './styles'
import { useHistory } from 'react-router'

export default function EditCmponent({
  selected,
  isLoading,
  userLoading,
  client,
  hasError,
  handleFilters,
  handleSubmit,
}) {
  const history = useHistory()

  const handleRouteNavigation = () => {
    if (history.action === 'PUSH') {
      history.goBack()
      return
    }

    history.push('/renovacao')
  }

  return (
    <S.Container>
      <Flex alignItems="center" justifyContent="space-between">
        <S.Title>Alterar status</S.Title>

        <S.Goback onClick={handleRouteNavigation}>
          <Icon color={'#FFF'} size={'20px'} name="arrow-left" />
        </S.Goback>
      </Flex>

      {hasError ? (
        <S.Error>{hasError}</S.Error>
      ) : (
        <S.Dialog>
          {!userLoading ? (
            <>
              <S.Content>
                <p>{client?.name}</p>
                <S.Status type={client?.status?.description}>
                  {client?.status?.description}
                </S.Status>
              </S.Content>

              <S.WrapInput>
                <CheckboxSelect
                  name="status"
                  options={EDIT_STATUS_PCO_OPTIONS}
                  placeholder="Status PCO"
                  selected={selected}
                  onChange={({ selected }) => handleFilters(selected)}
                />
              </S.WrapInput>
              <S.Button
                disabled={!selected}
                onClick={handleSubmit}
                color="primary"
              >
                {isLoading ? <Loading size={'8px'} color={'#FFF'} /> : 'Enviar'}
              </S.Button>
            </>
          ) : (
            <S.Loader />
          )}
        </S.Dialog>
      )}
    </S.Container>
  )
}
