import React, { useState } from 'react'
import { FormContext, useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Input } from 'components'

import { useCurrentUser, useNotification } from 'hooks'
import { useEditProfile, useGetUser } from 'services/hooks/user'
import { queryClient } from 'services/queryClient'
import { handleLogoutToCurrentUser } from 'store/reducers/users/middlewares'
import validationSchema from './validation'
import Box from './components/Box'

import ConfirmModal from './components/ConfirmModal'
import * as S from './styles'

export default function Profile() {
  const [isOpen, setIsOpen] = useState(false)
  const [forormValues, setFormValues] = useState({})
  const { id } = useCurrentUser()
  const user = useSelector((state) => state.users)
  const history = useHistory()

  const handleToggleModal = () => {
    setIsOpen((prevState) => !prevState)
  }

  const form = useForm({
    validationSchema,
  })

  const { isLoading } = useGetUser(id, {
    onSuccess(data) {
      form.reset(data)
    },
    onError: (error) => {
      notification.open({
        type: 'danger',
        content: error.message,
      })
    },
  })

  const dispatch = useDispatch()

  const notification = useNotification()

  const {
    mutate,
    isLoading: isSubmitting,
    error,
    isError,
  } = useEditProfile({
    onSuccess() {
      dispatch(handleLogoutToCurrentUser())
      queryClient.invalidateQueries(['user', id])

      notification.open({
        type: 'success',
        content: 'Usuário alterado com sucesso!',
      })
    },
  })

  const handleConfirmSubmit = () => {
    mutate(forormValues)
  }

  const onSubmit = form.handleSubmit((values) => {
    setFormValues({
      name: values?.name,
      email: values?.email,
      cellphone: values?.cellphone.replace(/\s/g, ''),
      password: values?.password?.old,
      new_password: values?.password?.new,
      new_password_confirmation: values?.password?.confirmation,
    })

    handleToggleModal()
  })

  if (isLoading) {
    return <S.Loader />
  }

  const onInputChange = (value) => {
    if (!value) {
      return
    }

    return value.toLowerCase()
  }

  return (
    <>
      <S.Container>
        <FormContext {...form}>
          <S.Content>
            <Box title="Editar dados cadastrais">
              <S.Form>
                <Input
                  label="CPF"
                  name="document"
                  mask="###.###.###-##"
                  disabled
                  numericOnly
                  placeholder="Digite o CPF"
                />
                <Input label="Nome" name="name" placeholder="Digite o nome" />
                <Input
                  label="E-mail"
                  name="email"
                  type="text"
                  placeholder="Digite o e-mail"
                  onChange={(e) => onInputChange(e.target.value)}
                />

                <Input
                  name="cellphone"
                  placeholder="Celular"
                  label="Celular"
                  phone
                />
              </S.Form>
            </Box>
            <Box title="Alterar senha">
              <S.Form>
                <Input
                  label="Senha atual"
                  name="password.old"
                  type="password"
                  autoComplete="off"
                  placeholder="Digite a senha atual"
                />
                <Input
                  label="Senha nova"
                  name="password.new"
                  type="password"
                  placeholder="Digite a senha nova"
                />
                <Input
                  label="Confirmar senha nova"
                  name="password.confirmation"
                  type="password"
                  placeholder="Digite novamente sua senha nova"
                />
              </S.Form>
              {isError && <S.Error>{error?.message}</S.Error>}
            </Box>
          </S.Content>
        </FormContext>

        <S.Actions isUpToDate={user.isUpToDate}>
          {user.isUpToDate && (
            <Button color="gray" onClick={() => history.goBack()}>
              Voltar
            </Button>
          )}

          <Button color="success" onClick={onSubmit} isLoading={isSubmitting}>
            Salvar alterações
          </Button>
        </S.Actions>
      </S.Container>
      <ConfirmModal
        isLoading={isSubmitting}
        isVisible={isOpen}
        onClose={handleToggleModal}
        onClick={handleConfirmSubmit}
      />
    </>
  )
}

Profile.properties = {
  metatags: {
    title: 'Editar perfil',
  },
}
