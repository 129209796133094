import React, { useEffect, useState } from 'react'
import { FormContext, useForm } from 'react-hook-form'
import { Accordion, Button, File, Flex, Grid, Icon, Input } from 'components'
import { useCurrentUser } from 'hooks'
import { removeTelephoneMask } from 'utils'
import {
  useCreateLandingPage,
  useGetBrokerLandingPage,
} from 'services/hooks/brokers'
import validationSchema from './validation'

import * as S from './styles'
export default function Brokers() {
  const { setValue, formState, ...form } = useForm({ validationSchema })
  const user = useCurrentUser()
  const dataLayer = window.dataLayer || []

  const [previewImage, setPreviewImage] = useState('')
  const [wasCopied, setWasCopied] = useState(false)

  const link = `${process.env.REACT_APP_LANDING_URL}/corretor?susep=${user.broker.susep}`

  const { data, isLoading } = useGetBrokerLandingPage(user?.broker?.susep, {
    onSuccess: (data) => {
      setPreviewImage(data.logo)
      setValue('email', data.email)
      setValue('name', data.name)
      setValue('whatsapp_number', data.whatsapp_number)
      setValue('phone', data.phone)
    },
  })

  const {
    mutate,
    isLoading: isLoadingMutation,
    isSuccess,
    error,
    isError,
  } = useCreateLandingPage()

  const hasFormErrors = Object.keys(form.errors).length > 0
  const hasData = Object.keys(data || {}).length > 0

  const handleDelete = () => {
    setPreviewImage('')
    setValue('logo', '')
  }

  const handleChange = (event) => {
    const imageFile = event.target.files[0]

    setPreviewImage(URL.createObjectURL(imageFile))
  }

  const onSubmit = form.handleSubmit((values) => {
    if (values?.phone && values.phone.length !== 12) {
      form.setError('phone', 'notMatch', 'Por favor, insira um número válido')
      return
    }

    const data = {
      ...values,
      whatsapp_number: removeTelephoneMask(values?.whatsapp_number),
      phone: removeTelephoneMask(values?.phone),
    }

    const formData = new FormData()

    formData.append('file', data.logo[0])
    formData.append('name', data.name)
    formData.append('email', data.email)
    formData.append('whatsapp_number', data.whatsapp_number)

    if (values.phone) {
      formData.append('phone', data.phone)
    }

    mutate(formData)
  })

  const handleCopyTopClipboard = () => {
    setWasCopied(true)
    navigator.clipboard.writeText(link)

    setTimeout(() => {
      setWasCopied(false)
    }, 5000)
  }

  useEffect(() => {
    dataLayer.push({
      event: 'step_change',
      etapa: '/gerar-minha-lp',
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <FormContext {...form}>
        <S.Header>
          <S.LabelSM>
            Olá, {user?.infos?.name}. Neste espaço você pode configurar sua
            Landing Page personalizada!
          </S.LabelSM>
          {isLoading ? (
            <S.LabelSM>Aguarde, estamos carregando as informações.</S.LabelSM>
          ) : (
            hasData && (
              <S.Link
                href={link}
                target="_blank"
                data-gtm-click="click"
                data-gtm-clicktype="link"
                data-gtm-name="acesse-sua-landing-page-clicando-aqui"
              >
                Acesse sua Landing Page clicando aqui
              </S.Link>
            )
          )}
        </S.Header>
        <S.Content>
          <Accordion
            shouldOpen={form.errors?.logo?.message || hasData}
            title="Passo 1: Envie o logo da sua corretora"
          >
            <Flex flexDirection="column" alignItems="flex-start">
              <File
                name="logo"
                label="Selecionar imagem"
                accept="image/jpg,image/jpeg,image/png"
                error={form.errors?.logo?.message}
                onChange={handleChange}
                data-testid="lp-logo"
              />
              {previewImage && (
                <S.Box data-testid="logo-preview">
                  <Flex alignItems="center" justifyContent="space-between">
                    <S.Label>
                      {data?.logo ? 'Logo atual:' : 'Preview da imagem:'}
                    </S.Label>
                    <S.Label
                      onClick={handleDelete}
                      data-gtm-click="click"
                      data-gtm-clicktype="button"
                      data-gtm-name="remover-image"
                    >
                      [X] Remover imagem
                    </S.Label>
                  </Flex>
                  <S.Wrap>
                    <S.Logo src={previewImage} alt="Logo corretora" />
                  </S.Wrap>
                </S.Box>
              )}
            </Flex>
          </Accordion>
          <Accordion
            shouldOpen={hasFormErrors || hasData}
            title="Passo 2: Preencha suas informações"
          >
            <Grid
              gridTemplateColumns={['1fr', '1fr 1fr', '1fr 1fr 1fr']}
              gridGap="1.5rem"
            >
              <Input
                name="name"
                placeholder="Nome da corretora"
                label="Nome da corretora"
              />

              <Input
                name="email"
                placeholder="E-mail para contato"
                label="E-mail para contato"
              />
              <Input
                name="whatsapp_number"
                placeholder="Número do Whatsapp"
                label="Número do Whatsapp"
                phone
              />
              <Input
                name="phone"
                placeholder="Telefone Fixo (opcional)"
                label="Telefone Fixo (opcional)"
                phone
              />
            </Grid>
          </Accordion>
          <S.Dialog>
            <S.Title>
              Passo 3: Envie a imagem para sua Landing Page e compartilhe com
              seus clientes!
            </S.Title>

            <Grid mt="16px">
              {isSuccess && (
                <S.Success data-testid="success-message">
                  Prontinho, agora é só você copiar o link e enviar para seus
                  clientes
                  <br />
                  conhecerem e contratarem o Carro por Assinatura Porto Bank.
                </S.Success>
              )}

              {isError && (
                <S.Error data-testid="request-message-error">
                  {error?.message}
                </S.Error>
              )}

              <S.ButtonWrap>
                {isSuccess && (
                  <S.CopyButton
                    data-testid="copy-button"
                    onClick={handleCopyTopClipboard}
                  >
                    {wasCopied && (
                      <Icon color="#FFF" size="16px" name="check-circle" />
                    )}
                    <span>{!wasCopied ? 'Copiar Link' : 'Link copiado!'}</span>
                  </S.CopyButton>
                )}

                {previewImage && !isSuccess && (
                  <Flex flexDirection="column" alignItems="flex-start">
                    <Button
                      isLoading={isLoadingMutation}
                      onClick={onSubmit}
                      disabled={isError}
                      color="success"
                      data-testid="button-send"
                    >
                      Enviar
                    </Button>

                    <p>
                      * Clicando em enviar, você concorda em compartilhar suas
                      informações de contato na Landing Page para realização de
                      orçamento e comercialização do serviço Carro por
                      Assinatura Porto Bank com clientes.
                    </p>
                  </Flex>
                )}
              </S.ButtonWrap>
            </Grid>
          </S.Dialog>
        </S.Content>
      </FormContext>
    </>
  )
}

Brokers.properties = {
  metatags: {
    title: 'Gerar minha LP',
  },
}
