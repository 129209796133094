import React, { useState } from 'react'
import { TableBasic, DefaultModal, Help, Flex } from 'components'
import { DELIVERY_PLACE_TABLE_COLUMNS } from 'helpers'
import { useGetDeliveryPlaces } from 'services/hooks/stock'
import { useNotification } from 'hooks'

import * as S from './styles'

export default function Header() {
  const notification = useNotification()
  const { data, isLoading, isError } = useGetDeliveryPlaces({
    onError: (error) => {
      notification.open({
        type: 'danger',
        content: error.message,
      })
    },
  })
  const [isOpen, setIsOpen] = useState(false)

  const handleCloseModal = () => {
    setIsOpen(false)
  }

  const handleOpenModal = () => {
    if (!isError) {
      setIsOpen(true)
    }
  }

  return (
    <>
      <S.Header>
        <S.TitleWrapper alignItems="center">
          <S.Title>Estoque</S.Title>
          <Help
            title="Estoque Carro por Assinatura"
            subtitle="Esta é a sua tela de estoque. Nela, você pode consultar o estoque de
          veículos disponíveis da tabela de preços, para esclarecer ao cliente
          quanto aos prazos para entrega."
          />
        </S.TitleWrapper>
        <Flex>
          <S.Text>
            Para consultar os pontos de entrega dos veículos, clique{' '}
            <span onClick={handleOpenModal}>aqui</span>
          </S.Text>
        </Flex>

        <DefaultModal
          title="Pontos de entrega"
          isOpen={isOpen}
          onClose={handleCloseModal}
          background="primary"
          color="light"
        >
          <Flex alignItems="center" justifyContent="center">
            {isLoading ? (
              <S.Loader />
            ) : (
              <S.Content>
                <S.Text color="white">
                  O local de entrega será escolhido automaticamente de acordo
                  com a localização física do veículo e o ponto mais próximo do
                  CEP de contratação do cliente.
                </S.Text>
                <TableBasic
                  columns={DELIVERY_PLACE_TABLE_COLUMNS}
                  data={data}
                />
              </S.Content>
            )}
          </Flex>
        </DefaultModal>
      </S.Header>
    </>
  )
}
