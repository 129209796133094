const fonts = {
  book: 'CircularStd-Book',
  medium: 'CircularStd-Medium',
  bold: 'CircularStd-Bold',
  black: 'CircularStd-Black',
  sizes: {
    xxxsmall: '10px',
    xxsmall: '12px',
    xsmall: '14px',
    small: '16px',
    medium: '18px',
    large: '20px',
    xlarge: '24px',
    xxlarge: '28px',
    huge: '32px',
    xhuge: '40px',
  },
}

export default fonts
