import styled, { css } from 'styled-components'
import media from 'styled-media-query'
import { rem } from 'polished'

import { colors } from 'styles'

const handleContractSituation = (situation) => {
  const color = {
    Cancelado: {
      bg: colors.orange,
      color: '#FFF',
      border: 'none',
    },
    Vigente: {
      bg: colors.newPrimary,
      color: '#FFF',
      border: 'none',
    },
  }
  if (color[situation]) {
    return color[situation]
  }

  return {
    bg: 'transparent',
    color: colors.gray.davys,
    border: '2px solid #c4c4c4',
  }
}

export const Tip = styled.div`
  background-color: ${({ isCopied, theme }) =>
    !isCopied ? theme.colors.gray.medium : '#00689A'};
  width: ${({ isCopied }) => (!isCopied ? rem('150px') : rem('100px'))};
  position: absolute;
  text-align: center;
  top: ${rem('-25px')};
  border-radius: 8px;
  padding: 7px 0;

  visibility: hidden;
  opacity: 0;

  transition-property: opacity;
  transition-delay: 0.2s;

  &::after {
    content: '';
    display: block;
    position: absolute;
    background-color: ${({ isCopied, theme }) =>
      !isCopied ? theme.colors.gray.medium : '#00689A'};

    width: 12px;
    height: 12px;

    left: 50%;
    transform: translateX(-50%) rotate(45deg);
  }

  span {
    color: #fbfbfb;
    font-size: 14px;
    line-height: 1rem;
    font-weight: bold;
  }
`

export const Wapper = styled.div`
  width: ${rem('160px')};
`

export const Container = styled.table`
  width: 100%;

  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  text-align: left;
  font-family: ${({ theme }) => theme.fonts.bold};
  color: ${({ theme }) => theme.colors.gray.davys};

  border-collapse: collapse;
  border-style: hidden;
  border-radius: 8px;
  box-shadow: 0 0 0 2px #eff0f0;

  margin-top: 10px;

  ${media.lessThan('small')`
    font-size: 12px;
  `}
`
export const THead = styled.thead``
export const TBody = styled.tbody`
  tr {
    border: 2px solid #eff0f0;
  }
`

export const TH = styled.th`
  height: ${rem('50px')};
  padding: 0 1rem;
  border: 2px solid #eff0f0;
  font-weight: 450;
`
export const TR = styled.tr``

export const Span = styled.span`
  position: absolute;
  top: 50%;
  left: 1rem;
  border-radius: 5px;
  transform: translateY(-50%);
  font-weight: 500;

  background: ${({ color }) => handleContractSituation(color).bg};
  color: ${({ color }) => handleContractSituation(color).color};

  ${media.lessThan('small')`
    left: ${rem('10px')};
  `}

  &:nth-child(1) {
    text-transform: uppercase;
    padding: 4px 10px;

    border: ${({ color }) => handleContractSituation(color).border};

    border-radius: 3px;
  }

  &:nth-child(2) {
    opacity: 0;
    visibility: hidden;
  }
`
export const TD = styled.td`
  position: relative;
  height: ${rem('50px')};
  padding: 0 1rem;
  border: 2px solid #eff0f0;

  span {
    font-weight: 500;
  }

  ${({ hasMarker }) =>
    hasMarker &&
    css`
      span {
        color: ${({ theme }) => theme.colors.orange};
      }

      &::after {
        content: '';
        display: block;
        position: absolute;
        border-top: 10px solid transparent;
        border-right: 10px solid ${({ theme }) => theme.colors.orange};
        left: 0;
        bottom: 0;
        transform: rotate(90deg);
      }
    `}

  ${({ hasHover }) =>
    hasHover &&
    css`
      &:hover {
        cursor: pointer;
      }
    `}

  &:hover ${Span} {
    &:nth-child(1) {
      opacity: 0;
      visibility: hidden;
    }

    &:nth-child(2) {
      opacity: 1;
      visibility: visible;
    }
  }

  &:hover ${Tip} {
    visibility: visible;
    opacity: 1;
  }
`
