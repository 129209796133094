import React from 'react'
import { FormContext, useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { Button } from 'components'
import { useNotification } from 'hooks'
import { Editor, Input, InputSelect } from '../components'
import { useTagsNews, useCreateNewsMutation } from 'services/hooks/News'
import { queryClient } from 'services/queryClient'
import validationSchema from './validation'

import * as S from './styles'

function CreateNews() {
  const notification = useNotification()
  const history = useHistory()
  const { data: tagOptions, isLoading } = useTagsNews()

  const createNews = useCreateNewsMutation({
    onSuccess: () => {
      notification.open({
        type: 'success',
        content: `Novidade criada com sucesso!`,
      })

      queryClient.invalidateQueries('last_news')
      history.push('/novidades')
    },
    onError: () => {
      notification.open({
        type: 'danger',
        content: `Ocorreu um erro ao criar sua novidade, por favor tente novamente.`,
      })
    },
  })

  const { handleSubmit, ...form } = useForm({ validationSchema })

  const onSubmit = (values) => {
    const data = {
      title: values.title,
      subTitle: values?.subTitle,
      content: values.content,
      tags: [values.tags.value],
    }
   
    createNews.mutate(data)
  }
  return (
    <S.Container>
      <S.Content>
        <S.Header>
          <S.Title>Nova publicação</S.Title>
          <S.Alert>* Campos obrigatórios</S.Alert>
        </S.Header>

        <FormContext {...form}>
          <S.Form onSubmit={handleSubmit(onSubmit)}>
            <S.InputWrapper>
              <S.Fieldset>
                <S.Label htmlFor="title">Título da publicação*</S.Label>
                <Input name="title" placeholder="Escreva aqui" />
              </S.Fieldset>

              <S.Fieldset>
                <S.Label htmlFor="subTitle">Subtítulo da publicação</S.Label>
                <Input name="subTitle" placeholder="Escreva aqui" />
              </S.Fieldset>

              <S.Fieldset>
                <S.Label htmlFor="tags">Categoria*</S.Label>
                <InputSelect
                  name="tags"
                  options={tagOptions}
                  placeholder="Categoria"
                  isLoading={isLoading}
                />
              </S.Fieldset>
            </S.InputWrapper>

            <S.DescriptionWrapper>
              <S.Fieldset>
                <S.Label htmlFor="content">Descrição*</S.Label>

                <Editor name="content" />
              </S.Fieldset>
            </S.DescriptionWrapper>
            <S.ButtonWrapper>
              <Button
                type="button"
                color="gray"
                onClick={() => {
                  history.goBack()
                }}
              >
                Voltar
              </Button>
              <Button type="submit" isLoading={createNews.isLoading}>
                Publicar
              </Button>
            </S.ButtonWrapper>
          </S.Form>
        </FormContext>
      </S.Content>
    </S.Container>
  )
}

export default CreateNews

CreateNews.properties = {
  metatags: {
    title: 'Criar novidade',
  },
}
