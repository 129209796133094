// import api from 'services'

export async function getCoupons() {
  // try {
  //   const response = await api.get('/coupons')
  //   return response.data
  // } catch (error) {
  //   throw Error(
  //     'Houve uma instabilidade no sistema. Tente novamente mais tarde ou entre em contato com a Porto Seguro.'
  //   )
  // }
}

export async function createCoupon(payload) {
  const { code, start, end } = payload

  const PAYLOAD_PARSED = {
    code,
    start,
    end,
  }

  console.log(PAYLOAD_PARSED)

  // try {
  //   const response = await api.post('/coupons', PAYLOAD_PARSED)

  //   return response.data
  // } catch (error) {
  //   throw Error(
  //     'Houve uma instabilidade no sistema. Tente novamente mais tarde ou entre em contato com a Porto Seguro.'
  //   )
  // }
}
