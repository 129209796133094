import React from 'react'

import { Button, Grid } from 'components'

import { Container, Title, Link, Box } from './styles'

export default function Infos() {
  function openChat() {
    window.open(
      `https://prime.altubots.com/chats/portoassistencia/12303a025257b4f3fc9f70cab339b450/index.html`,
      '',
      'width=750,height=650'
    )
  }

  return (
    <Container>
      <Grid
        justifyItems="center"
        alignItems="end"
        gridTemplateColumns={['1fr 1fr', '1fr 1fr 1fr 1fr']}
        gridRowGap="20px"
        mb="20px"
      >
        <Box>
          <Title>Atendimento digital</Title>
          <Link
            href="https://carrofacilporto.zendesk.com/hc/pt-br"
            target="_blank"
          >
            <Button
              borderRadius="9px"
              boxShadow="none"
              py="15px"
              px={['10px', '25px']}
              color="gray"
              fontSize="12px"
              outline
              gtmName="tem-duvidas-clique-aqui"
            >
              Tem dúvidas? Clique aqui
            </Button>
          </Link>
        </Box>
        <Box align="left">
          <Button
            borderRadius="9px"
            boxShadow="none"
            py="15px"
            px="25px"
            color="side"
            fontSize="12px"
            onClick={openChat}
            outline
            gtmName="chat"
          >
            Fale conosco
          </Button>
        </Box>
      </Grid>
    </Container>
  )
}
