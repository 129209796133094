import React, { useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { FormContext, useForm } from 'react-hook-form'

import { createCoupon } from 'services'

import { Grid, Input, Datepicker, Button } from 'components'

import { Container, Content, Form, Actions, Error } from './styles'

export default function Edit() {
  const history = useHistory()

  const form = useForm()

  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const [date, setDate] = useState({
    start: null,
    end: null,
  })

  const onSubmit = form.handleSubmit(async (values) => {
    try {
      setError('')
      setLoading(true)

      await createCoupon({ ...values, ...date })

      history.push('/cupons')
    } catch (error) {
      setError(error.message)
    } finally {
      setLoading(false)
    }
  })

  return (
    <Container>
      <Content>
        <FormContext {...form}>
          <Form onSubmit={onSubmit}>
            <Grid
              m="30px 0 60px 0"
              gridColumnGap="40px"
              gridRowGap="20px"
              gridTemplateColumns={['1fr', 'repeat(3, 1fr)']}
            >
              <Input
                name="code"
                label="Cupom"
                placeholder="Digite aqui o código do cupom"
                toUpperCase
              />

              <Datepicker
                selected={date.start}
                startDate={date.start}
                endDate={date.end}
                placeholderText="Data inicial"
                onChange={(start) => setDate((prev) => ({ ...prev, start }))}
                selectsStart
              />

              <Datepicker
                selected={date.end}
                startDate={date.start}
                endDate={date.end}
                minDate={date.start}
                onChange={(end) => setDate((prev) => ({ ...prev, end }))}
                placeholderText="Data final"
                selectsEnd
              />
            </Grid>

            <Actions>
              <Button onClick={onSubmit} isLoading={loading} color="success">
                Salvar novo cupom
              </Button>

              <Link to="/cupons">
                <Button color="gray">Voltar</Button>
              </Link>
            </Actions>
          </Form>

          {error && <Error>{error}</Error>}
        </FormContext>
      </Content>
    </Container>
  )
}

Edit.properties = {
  metatags: {
    title: 'Cupons',
  },
}
