import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Icon, IconButton } from 'components'
import CustomerDropdown from '../CustomerDropdown'

import * as S from './styles'

function CustomerCard({ data, isLoading, onOpenDrawer, onMessageToCustomer }) {
  const [showAlert, setShowAlert] = useState(false)

  const history = useHistory()

  const handleCopyTopClipboard = (value) => {
    navigator.clipboard.writeText(value)
    setShowAlert(true)

    setTimeout(() => {
      setShowAlert(false)
    }, 1000)
  }

  if (isLoading) {
    return <S.Message>Carregando...</S.Message>
  }

  if (!isLoading && !data.length) {
    return <S.Message>Nenhum registro encontrado</S.Message>
  }

  return (
    <>
      {data?.map((customer, index) => (
        <S.Container key={index}>
          <S.Row>{customer.name}</S.Row>
          <S.Row>CPF: {customer.document}</S.Row>
          <S.Row color="primary">Tel: {customer.phone}</S.Row>
          <S.Row>
            <span>E-mail: {customer.email}</span>
            <S.IconWrapper>
              <Icon
                name="copy"
                size="20px"
                onClick={() => handleCopyTopClipboard(customer.email)}
              />
            </S.IconWrapper>
          </S.Row>
          <S.Row>SUSEP: {customer?.susepNumber}</S.Row>
          {customer.contract && <S.Row>Contrato: {customer?.contract}</S.Row>}

          {customer.representative && (
            <S.Row>Representante: {customer.representative}</S.Row>
          )}
          {customer.branch && <S.Row>Sucursal: {customer.branch}</S.Row>}

          <S.Footer>
            {customer.status()}

            <CustomerDropdown title={<Icon name="more-vertical" size="24px" />}>
              <S.DropdownItem
                data-gtm-type="click"
                data-gtm-clicktype="link"
                data-gtm-name="enviar-link-web"
                onClick={() => {
                  onMessageToCustomer({
                    id: customer.id,
                    name: customer.name,
                  })
                }}
              >
                <IconButton color="secondary" size="small">
                  <Icon name="link" size="12px" />
                </IconButton>
                <p>Enviar link web</p>
              </S.DropdownItem>
              <S.DropdownItem
                data-gtm-type="click"
                data-gtm-clicktype="link"
                data-gtm-name="ver-mais-dados"
                onClick={() => {
                  history.push(`/clientes/${customer.id}`)
                }}
              >
                <IconButton color="secondary" size="small">
                  <Icon name="eye" size="12px" />
                </IconButton>
                <p>Ver mais dados</p>
              </S.DropdownItem>
              {/* <S.DropdownItem
                data-gtm-type="click"
                data-gtm-clicktype="link"
                data-gtm-name="preencher-cadastro"
                disabled={customer.disableEndRegistrationButton}
                onClick={() => {
                  !customer.disableEndRegistrationButton &&
                    history.push(`/pedidos/${customer.id}`)
                }}
              >
                <IconButton
                  color="secondary"
                  size="small"
                  disabled={customer.disableEndRegistrationButton}
                >
                  <Icon name="clipboard" size="12px" />
                </IconButton>
                <p>Preencher cadastro</p>
              </S.DropdownItem> */}
              {/* <S.DropdownItem
                onClick={() => {
                  onOpenDrawer(customer.codeStatus)
                }}
              >
                <IconButton color="secondary" size="small">
                  <Icon name="smartphone" size="12px" />
                </IconButton>
                <p>Ver etapa no app</p>
              </S.DropdownItem> */}
            </CustomerDropdown>
          </S.Footer>
          {showAlert && <S.Alert>E-mail copiado</S.Alert>}
        </S.Container>
      ))}
    </>
  )
}

export default CustomerCard
