import { useQuery } from 'react-query'
import { getOrdersResume } from 'services/apiV2/orders'

export function useOrdersResume(params, options) {
  return useQuery(['ordersResume', params], () => getOrdersResume(params), {
    staleTime: 30 * 1000, //30 seconds
    refetchOnWindowFocus: false,
    ...options,
  })
}
