import React, { useEffect, useState } from 'react'
import { List, arrayMove, arrayRemove } from 'react-movable'
import { Button, Flex, Icon, Searchable, Tip } from 'components'
import { useDebounce, useNotification } from 'hooks'
import {
  getPositions,
  updateBroker,
  addBrokerToQueue,
  updateBrokerPositions,
  removeBrokerFromQueue,
  fetchAllBrokers,
} from 'services'

import * as S from './styles'
export default function Distribution() {
  const [list, setList] = useState([])
  const [loading, setLoading] = useState(false)
  const [options, setOptions] = useState({
    data: [],
    error: '',
  })
  const [susep, setSusep] = useState({ id: '', value: '' })
  const [currentPositions, setCurrentPositions] = useState({})
  const updateBrokerDebounce = useDebounce(susep, 500)
  const notification = useNotification()

  async function updateInfo(index, key, value) {
    setList((state) =>
      state.map((item, i) => {
        if (index === i) {
          item[key] = value
          return item
        } else {
          return item
        }
      })
    )

    if (value) {
      const item = { id: list[index].id, value }

      setSusep(item)
    }
  }

  async function updatePositions() {
    setLoading(true)
    try {
      const nextQueue = list.map((item) => {
        const { position, susep } = item

        return {
          susep,
          position,
        }
      })

      await updateBrokerPositions({
        nextQueue,
        currentPositions,
      })
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  async function updateSusep({ id, value }) {
    await updateBroker(id, { limit: value })
  }

  async function onSubmit(event, { action }) {
    setOptions((state) => ({ ...state, error: '' }))

    if (action === 'select-option') {
      setLoading(true)
      try {
        const { value, id } = event
        const deletable = value?.substring(0, 2) !== 'CF'

        const obj = [
          {
            susep: value,
            quantity: 0,
            today: 0,
            position: list.length,
            deletable,
            id,
          },
        ]

        const repeated = list.find((item) => item.susep === event.value)

        if (repeated) {
          throw Error('SUSEP já está na lista.')
        }

        setList((state) => {
          const arr = [...state, ...obj]

          return arr
        })

        await addBrokerToQueue(value)
      } catch (error) {
        setOptions((state) => ({ ...state, error: error.message }))
      } finally {
        setLoading(false)
      }
    }
  }

  async function removeBroker(value, index) {
    if (value.deletable) {
      try {
        setLoading(true)
        setList(arrayRemove(list, index))
        const status = await removeBrokerFromQueue(value.susep)

        if (status === 200) {
          notification.open({
            type: 'success',
            content: `SUSEP ${value.susep} removida com sucesso!`,
          })
        }
      } catch (error) {
        notification.open({
          type: 'danger',
          content: error.message,
        })
      } finally {
        setLoading(false)
      }
    }
  }

  function moveItems({ oldIndex, newIndex }) {
    const newPositions = arrayMove(list, oldIndex, newIndex)

    const nextPositions = newPositions.map((item, index) => ({
      ...item,
      position: index + 1,
    }))

    setList(nextPositions)
  }

  async function setBaseOptions() {
    setLoading(true)

    try {
      const response = await fetchAllBrokers()

      const positions = await getPositions()

      const {
        data: { nextPositions, currentPositions },
        brokers,
      } = positions

      const list = nextPositions
        .map((item) => {
          const { susep, position } = item

          const broker = brokers.find((item) => item.susep === susep)
          const deletable = susep?.substring(0, 2) !== 'CF'

          return {
            id: broker?.id,
            susep,
            position,
            today: broker?.counting,
            quantity: broker?.limit,
            deletable,
          }
        })
        .sort((a, b) => {
          return a.position - b.position
        })

      const data = response
        .filter((user) => user.susep)
        .map((user) => ({
          value: user.susep,
          label: user.susep,
          id: user.id,
        }))

      setCurrentPositions(currentPositions)
      setOptions((state) => ({ ...state, data }))
      setList(list)
    } catch (error) {
      setOptions((state) => ({ ...state, error: error.message, data: [] }))
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (susep.value) {
      updateSusep(susep)
    }
  }, [updateBrokerDebounce]) //eslint-disable-line

  useEffect(() => {
    setBaseOptions()
  }, [])

  return (
    <S.Container>
      {loading && <S.Loader />}
      <S.Dialog>
        <Flex alignItems="center">
          <S.Label>Distribuição de Leads</S.Label>
          <Tip
            size="16px"
            name="distribution"
            icon="help-circle"
            color="#C7C7C7"
            className="distributionTooltip"
            place="bottom"
          >
            <p>
              A distribuição de leads é uma funcionalidade para equilibrar novos
              clientes entre as SUSEPs disponíveis, colocando em ordem de
              prioridade as que devem receber mais. O campo “quantidade diária”
              é o número de leads que devem entrar por dia em cada SUSEP. Em
              comparação, o campo “quantidade hoje” mostra o número de leads já
              alocados até o momento atual.
            </p>
          </Tip>
        </Flex>
        <S.Filter>
          <Searchable
            options={options.data}
            placeholder="Procurar SUSEP"
            isClearable
            onChange={onSubmit}
            isLoading={loading}
            error={options.error}
          />

          <Button onClick={updatePositions} color="success">
            Confirmar mudanças de posição
          </Button>
        </S.Filter>

        <S.Header>
          <S.Title>Posição</S.Title>
          <S.Title>SUSEP</S.Title>
          <S.Title>Quantidade Diária</S.Title>
          <S.Title>Quantidade hoje</S.Title>
          <S.Title></S.Title>
        </S.Header>
        <List
          values={list}
          onChange={moveItems}
          renderList={({ children, props }) => (
            <S.Table {...props}>{children}</S.Table>
          )}
          renderItem={({ value, props, index, isDragged }) => (
            <S.Item dragged={isDragged} {...props}>
              <S.Column>#{index + 1}</S.Column>
              <S.Column>{value.susep}</S.Column>
              <S.Column>
                <S.Input
                  value={value.quantity}
                  type="text"
                  onChange={(e) =>
                    updateInfo(index, 'quantity', e.target.value)
                  }
                />
              </S.Column>
              <S.Column>{value.today}</S.Column>
              <S.Column>
                <S.Frame
                  disabled={!value.deletable}
                  onClick={() => removeBroker(value, index)}
                >
                  <Icon color="#fff" size="15px" name="trash" />
                </S.Frame>
              </S.Column>
            </S.Item>
          )}
        />
      </S.Dialog>
    </S.Container>
  )
}

Distribution.properties = {
  metatags: {
    title: 'Leads',
  },
}
