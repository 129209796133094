import React from 'react'
import { Icon } from 'components'

import * as S from './styles'

function StatusDrawer({ isOpen, onClose }) {
  return (
    <S.FilterInfo isOpen={isOpen}>
      <S.Header>
        <h2> Filtros</h2>
      </S.Header>
      <S.IconWrapper>
        <Icon name="x" onClick={onClose} />
      </S.IconWrapper>
      <S.Content>
        <p>
          Os filtros refletem a etapa da jornada que o cliente está no momento
          de aquisição de um novo produto.
        </p>
        <p>
          Você consegue ver quantos clientes estão em cada etapa e o momento da
          etapa também.
        </p>
      </S.Content>
    </S.FilterInfo>
  )
}

export default StatusDrawer
