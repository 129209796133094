import styled from 'styled-components'
import media from 'styled-media-query'
import { rem } from 'polished'
import { Button as CustomButton } from 'components'
import { handleStatusPcoColor } from 'utils'

export const Container = styled.div`
  padding: 40px 50px;

  ${media.lessThan('small')`
    padding: 40px 1rem;
  `}
`

export const Content = styled.div`
  margin-bottom: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  p {
    font-size: 1rem;
    line-height: 1.25rem;
    font-weight: 450;
    color: rgba(0, 0, 0, 0.5438);
    font-family: ${({ theme }) => theme.fonts.bold};
    text-transform: uppercase;
  }
`

export const Status = styled.span`
  padding: 10px 20px;

  color: #fff;
  border-radius: 30px;
  white-space: nowrap;
  font-size: 12px;
  font-family: ${({ theme }) => theme.fonts.bold};
  background-color: ${({ type }) => handleStatusPcoColor(type)};

  ${media.lessThan('large')`
    padding: 8px 10px;

    font-size: 10px;
  `};
`

export const Title = styled.h1`
  font-size: ${rem('32px')};
  font-family: ${({ theme }) => theme.fonts.book};
  margin: 0;
`
export const Dialog = styled.div`
  margin: 2rem 0;

  padding: 30px;

  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  background: #fff;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
`

export const WrapInput = styled.div`
  width: 28rem;

  ${media.lessThan('small')`
    width: 100%;
  `}
`

export const Button = styled(CustomButton)`
  margin-top: 2rem;
  align-self: flex-end;

  &:disabled {
    background-color: ${({ theme }) => theme.colors.gray.light};
    cursor: not-allowed;
  }
`

export const Goback = styled.button`
  width: 48px;
  height: 48px;

  display: flex;
  justify-content: center;
  align-items: center;

  background: ${({ theme }) => theme.colors.primary};
  color: #fff;
  border-radius: 50%;
  border: none;
  cursor: pointer;
`

export const Loader = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: ${({ theme }) => theme.layers.modal};

  content: '';
  background-color: rgba(0, 0, 0, 0.8);

  &:before {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    width: 50px;
    height: 50px;

    content: '';
    background-image: url('/images/loader.svg');
    background-size: contain;
  }
`

export const Error = styled.p`
  font-size: 18px;
  font-family: ${({ theme }) => theme.fonts.medium};
  margin-top: 1rem;
  color: ${({ theme }) => theme.colors.danger};
`
