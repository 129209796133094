import * as yup from 'yup'

export const denied = yup.object().shape({
  reason: yup
    .string()
    .required('Por favor digite uma justificativa')
    .max(250, 'Digite uma justificativa até 250 caracteres'),
})

export const cancel = yup.object().shape({
  reason: yup.string().required('Por favor, escolha um motivo'),
})
