import React, { useState, useEffect } from 'react'

import { toOptions, handleBrlValue, convertToUsdValue } from 'utils'
import { Dialog } from '../common'

import { Grid, Input, Select, Loading, Datepicker } from 'components'
import { WrapperButton, ErrorMessage, Button } from './styles'

import { PROPOSAL_KM_AND_PERIOD_OPTIONS } from 'helpers'

export default function Proposal({
  forms,
  onSubmit,
  proposal,
  isSendingProposal,
  hasProposalError,
  hasSuccess,
  createProposalButton,
}) {
  const [options, setOptions] = useState({
    categories: [],
    vehicles: [],
    plans: [],
    periods: [],
    bundles: [],
    colors: [],
    endPlates: [],
  })
  const [selected, setSelected] = useState({
    category: null,
    vehicle: null,
    plan: null,
    period: null,
    bundle: null,
    color: null,
    endPlate: null,
  })

  function setPeriods(value) {
    setOptions((state) => ({
      ...state,
      bundles: [],
    }))
    setSelected((state) => ({ ...state, period: value, bundles: null }))
    forms.setValue([
      { 'proposal.bundle': null },
      { 'proposal.additionalPeriod': '' },
    ])
  }

  function setBundles() {
    forms.setValue([
      { 'proposal.additionalPeriod': '' },
      {
        'proposal.suggestedValue': handleBrlValue(
          proposal?.car?.installment_value
        ),
      },
    ])

    const bundles = PROPOSAL_KM_AND_PERIOD_OPTIONS?.reduce((acc, el) => {
      if (el.month !== parseInt(selected.period)) {
        return acc
      }

      return el.milages
        .sort((a, b) => a.km - b.km)
        .map(({ km }) => ({
          value: `${km * 1000}`,
          label: `${km * 1000} Km`,
        }))
    }, [])

    const options = toOptions(bundles, 'value', 'label')

    setOptions((state) => ({ ...state, bundles: options }))
  }

  function reniewSameCar() {
    const { car } = proposal

    const periods = PROPOSAL_KM_AND_PERIOD_OPTIONS?.map((el) => ({
      value: `${el.month}`,
      label: `${el.month} meses`,
    }))

    const options = {
      categories: [],
      vehicles: [
        {
          value: car?.version,
          label: car?.version,
        },
      ],
      plans: [
        {
          value: 'Convencional',
          label: 'Convencional',
        },
      ],
      periods,
    }

    setOptions((state) => ({
      ...state,
      ...options,
    }))

    forms.setValue([
      { 'proposal.category': null },
      { 'proposal.vehicle': car?.version },
      { 'proposal.chassi': car?.chassi },
      { 'proposal.plan': 'Convencional' },
      { 'proposal.period': null },
      { 'proposal.bundle': null },
      { 'proposal.color': car?.color },
      { 'proposal.plate': car?.plate },
      { 'proposal.tabelPrice': handleBrlValue(car?.installment_value) },
      { 'proposal.finalPriceFormated': '' },
      { 'proposal.additionalPeriod': '' },
      { 'proposal.suggestedValue': handleBrlValue(car?.installment_value) },
    ])

    forms.clearError([
      'proposal.category',
      'proposal.vehicle',
      'proposal.plan',
      'proposal.period',
      'proposal.bundle',
      'proposal.color',
      'proposal.plate',
      'proposal.tabelPrice',
      'proposal.finalPriceFormated',
    ])
  }

  useEffect(() => {
    reniewSameCar()
  }, []) //eslint-disable-line

  useEffect(() => {
    selected.period && setBundles()
  }, [selected.period]) //eslint-disable-line

  const handleBrlCurrency = (value) => {
    if (!value.target.rawValue) {
      return
    }

    return handleBrlValue(value.target.rawValue)
  }

  const handleSuggestedValue = (value) => {
    const additionalPeriod = value
    const tabelPrice = convertToUsdValue(forms.watch('proposal.tabelPrice'))
    const period = forms.watch('proposal.period')

    const regularContract = tabelPrice * period
    const bonusPeriod = parseInt(period) + parseInt(additionalPeriod)
    const suggestedPrice = regularContract / bonusPeriod

    forms.setValue('proposal.suggestedValue', handleBrlValue(suggestedPrice))

    return value
  }

  return (
    <Dialog title="5. Proposta">
      <Grid
        gridGap="30px"
        gridTemplateColumns={['1fr', '1fr 1fr', '1fr 1fr 1fr']}
      >
        <Select
          name="proposal.period"
          options={options.periods}
          placeholder="Período Contrato"
          onChange={({ target }) => setPeriods(target.value)}
          disabled={options.periods.length < 1}
        />
        <Select
          name="proposal.bundle"
          options={options.bundles}
          placeholder="Pacote KM"
          onChange={({ target }) =>
            setSelected((state) => ({ ...state, bundle: target.value }))
          }
          disabled={!selected.period}
        />

        <Input name="proposal.color" label="Cor" placeholder="Cor" disabled />
        <Input
          name="proposal.plate"
          label="Placa"
          placeholder="Placa"
          disabled
        />

        <Input
          name="proposal.additionalPeriod"
          label="Período Adicional"
          placeholder="Período Adicional"
          onChange={(e) => handleSuggestedValue(e.target.rawValue)}
          numericOnly
        />
        <Datepicker
          name="proposal.init_date_contract"
          selected={forms.watch('proposal.init_date_contract')}
          startDate={forms.watch('proposal.init_date_contract')}
          placeholderText="Data início do contrato"
          // onChange={(start) => {
          //   console.log('start', start)
          //   setDate(start)
          // }}
          clearable={false}
        />
        <Input
          name="proposal.suggestedValue"
          label="Valor Sugerido"
          placeholder="Valor Sugerido"
          disabled
        />
        <Input
          name="proposal.tabelPrice"
          label="Valor Atual"
          placeholder="Valor Atual"
          disabled
        />

        <Input
          name="proposal.finalPriceFormated"
          label="Valor Final"
          placeholder="Valor Final"
          onBlur={(e) =>
            forms.setValue('proposal.finalPriceFormated', handleBrlCurrency(e))
          }
        />
      </Grid>

      <Grid mt={5} mb={2}>
        <WrapperButton>
          <ErrorMessage>
            <span>{hasProposalError}</span>
          </ErrorMessage>
          <Button
            onClick={onSubmit}
            disabled={isSendingProposal || hasSuccess || hasProposalError}
            color={createProposalButton.color}
          >
            {isSendingProposal ? (
              <Loading size={'10px'} color={'#FFF'} />
            ) : (
              <>{createProposalButton.text}</>
            )}
          </Button>
        </WrapperButton>
      </Grid>
    </Dialog>
  )
}
