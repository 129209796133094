import { useMutation } from 'react-query'
import { approveOrder, cancelOrder } from 'services/apiV2/orders'

export function useCancelOrder(options) {
  return useMutation(
    (payload) => {
      return cancelOrder(payload)
    },
    { ...options }
  )
}

export function useApproveOrder(options) {
  return useMutation(
    (payload) => {
      return approveOrder(payload)
    },
    { ...options }
  )
}
