import styled, { css } from 'styled-components'
import { Icon, Flex } from 'components'
import { rem, darken } from 'polished'

import media from 'styled-media-query'

export const Container = styled.div`
  position: relative;
  color: ${({ theme }) => theme.colors.white};
`

export const CustomIcon = styled(Icon)`
  cursor: pointer;
`

export const CustomFlex = styled(Flex)`
  margin-top: ${rem('32px')};
`

export const Tip = styled.div`
  padding: ${rem('24px')};

  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.primary};
  position: absolute;
  top: 2rem;
  left: -2.1rem;
  z-index: 2;
  width: ${rem('900px')};

  visibility: hidden;
  opacity: 0;

  transition: opacity 0.3s ease;

  ${media.lessThan('large')`
      width: ${rem('600px')};
    `}

    ${media.lessThan('medium')`
      width: ${rem('300px')};
    `}  

    ${media.lessThan('small')`
        width: ${rem('256px')};
        left: -7.1rem;
    `}

  ${({ isOpen }) =>
    isOpen &&
    css`
      visibility: visible;
      opacity: 1;
    `}

  &::before {
    content: '';
    display: block;
    position: absolute;

    width: ${rem('36px')};
    height: ${rem('36px')};
    border-radius: 8px;
    background-color: ${({ theme }) => theme.colors.primary};

    left: 2.5rem;
    top: -0.8rem;

    ${media.lessThan('small')`
        left: 7.5rem;
    `}

    transform: translateX(-50%) rotate(45deg);
  }
`
export const Title = styled.h3`
  font-size: 1rem;
  font-family: ${({ theme }) => theme.fonts.bold};
`
export const Text = styled.p`
  font-size: 1rem;
  line-height: 1.2rem;
  font-weight: 450;
  margin-top: 8px;
`
export const Button = styled.button`
  display: inline-block;
  border: none;
  cursor: pointer;
  padding: 0.5rem ${rem('40px')};
  border-radius: 20px;
  background-color: ${({ theme }) => theme.colors.white};
  font-size: 18px;
  font-family: ${({ theme }) => theme.fonts.bold};
  color: ${({ theme }) => theme.colors.gray.dark};
  transition: background 0.3s ease;

  &:hover {
    background-color: ${({ theme }) => darken('.6', theme.colors.gray.dark)};
    color: ${({ theme }) => theme.colors.white};
  }
`
