import api from 'services'
import { formatISO, setHours, setMinutes, setSeconds } from 'date-fns'
import { AppError } from 'utils'

const formatDate = (date) => date && formatISO(date)

export async function createOrder({ user, representative }) {
  try {
    const response = await api.post('/integracao/orders/create/dash', {
      user,
      representative,
    })

    return response.data
  } catch (error) {
    throw new AppError(
      'Houve uma instabilidade no sistema. Tente novamente mais tarde ou entre em contato com a Porto Seguro.',
      error.response.status
    )
  }
}

export async function fetchOrders(params, pagination) {
  if (params.end) {
    params.end = setHours(params.end, 23)
    params.end = setMinutes(params.end, 59)
    params.end = setSeconds(params.end, 59)
  }

  if (params.start) {
    params.start = setHours(params.start, 0)
    params.start = setMinutes(params.start, 0)
    params.end = setSeconds(params.end, 0)
  }

  let PARAMS_PARSED = {
    status: params.status,
    'customer.fullName_contains': params.name,
    'customer.cpf.number_contains': params.document,
    createdAt_gte: formatDate(params.start),
    createdAt_lt: formatDate(params.end),
    _limit: pagination?.limit,
    _start: pagination?.start,
  }

  Object.keys(PARAMS_PARSED).forEach(
    (key) => PARAMS_PARSED[key] == null && delete PARAMS_PARSED[key]
  )

  const parseItem = (item) => {
    const {
      id,
      customer = {},
      representative = {},
      status = 'pending',
      protheus,
      createdAt,
    } = item

    return {
      id,
      status,
      createdAt,
      contract: protheus?.contract || '',
      representative: {
        name: representative?.customer?.fullName || '',
        email: representative?.customer?.email || '',
        role: representative?.role?.type || '',
        documents: {
          cpf: representative?.customer?.cpf?.number || '',
        },
        broker: {
          susep: representative?.broker?.susep || '',
          branch: representative?.broker?.branch || '',
        },
      },
      infos: {
        name: customer?.fullName || '',
        email: customer?.email || '',
        phone: customer?.cellphone || '',
        documents: {
          cpf: customer?.cpf?.number || '',
        },
        address: {
          zipcode: customer?.defaultAddress?.cep || '',
          street: customer?.defaultAddress?.street || '',
          neighborhood: customer?.defaultAddress?.neighborhood || '',
          city: customer?.defaultAddress?.city || '',
          state: customer?.defaultAddress?.state || '',
          complement: customer?.defaultAddress?.complement || '',
        },
      },
    }
  }

  try {
    const response = await api.get('/integracao/orders', {
      params: PARAMS_PARSED,
    })
    const count = response.headers['x-doc-count']

    return {
      orders: response.data.map((item) => parseItem(item)),
      count,
    }
  } catch (error) {
    console.error(error)
    throw Error(
      'Houve uma instabilidade no sistema. Tente novamente mais tarde ou entre em contato com a Porto Seguro.'
    )
  }
}

export async function sendMessageToCustomer(payload) {
  try {
    const response = await api.post(
      '/integracao/orders/backend/send-order',
      payload
    )
    return response.data
  } catch (error) {
    throw new AppError(
      'Houve uma instabilidade no sistema. Tente novamente mais tarde ou entre em contato com a Porto Seguro.',
      error.response.status
    )
  }
}

export async function fetchOrdersReport(params) {
  if (params.end) {
    params.end = setHours(params.end, 23)
    params.end = setMinutes(params.end, 59)
    params.end = setSeconds(params.end, 59)
  }

  if (params.start) {
    params.start = setHours(params.start, 0)
    params.start = setMinutes(params.start, 0)
    params.end = setSeconds(params.end, 0)
  }

  const PARAMS_PARSED = {
    status: params.status,
    'customer.fullName_contains': params.name,
    'customer.cpf.number_contains': params.document,
    createdAt_gte: formatDate(params.start),
    createdAt_lt: formatDate(params.end),
    _limit: 0,
  }

  Object.keys(PARAMS_PARSED).forEach(
    (key) => PARAMS_PARSED[key] == null && delete PARAMS_PARSED[key]
  )

  try {
    const response = await api.get('/integracao/orders', {
      params: PARAMS_PARSED,
      headers: {
        Accept: 'text/csv',
      },
      responseType: 'blob',
      timeout: 60000,
    })
    return response.data
  } catch (error) {
    throw Error(
      'Houve uma instabilidade no sistema ao gerar relatório. Tente novamente mais tarde ou entre em contato com a Porto Seguro.'
    )
  }
}

export async function getOrderDetails(hash) {
  try {
    const response = await api.get(`/integracao/orders/${hash}`)

    return response.data
  } catch (error) {
    throw new Error(
      error?.response?.data ||  'Houve uma instabilidade no sistema. Tente novamente mais tarde ou entre em contato com a Porto Seguro.'
    )
  }
}

export async function getOrdersByUser(document) {
  try {
    const response = await api.get(
      `/integracao/orders?customer.cpf.number=${document}`,
      {
        headers: {
          authorization: '',
        },
      }
    )

    return response.data
  } catch (error) {
    throw Error(
      'Houve uma instabilidade no sistema. Tente novamente mais tarde ou entre em contato com a Porto Seguro.'
    )
  }
}

export async function updateOrder(hash, data) {
  try {
    const response = await api.put(`/integracao/orders/${hash}`, data)

    return response.data
  } catch (error) {
    throw new AppError(
      'Houve uma instabilidade no sistema. Tente novamente mais tarde ou entre em contato com a Porto Seguro.',
      error.response.status
    )
  }
}

export async function manualApproval(hash, data) {
  try {
    const response = await api.put(
      `/integracao/orders/manual-approval/${hash}`,
      data
    )

    return response.data
  } catch (error) {
    console.error(error)
    throw error
  }
}

export async function isEligibleOfferProduct({ cep, id }) {
  const payload = {
    order: {
      id: id,
    },
    cep,
  }

  try {
    const response = await api.post(`/integracao/orders/offer`, payload)

    const status = response.data.decision

    return status
  } catch (error) {
    console.error(error)
    throw new AppError(
      'Houve uma instabilidade no sistema. Tente novamente mais tarde ou entre em contato com a Porto Seguro.',
      error.response.status
    )
  }
}

export async function checkDiscount({ registration, id }) {
  try {
    const payload = {
      order: { id },
      registration,
    }

    const response = await api.post(
      `/integracao/orders/backend/check-for-discount`,
      payload
    )

    return response.data
  } catch (error) {
    console.error(error)
    throw Error(
      'Houve uma instabilidade no sistema. Tente novamente mais tarde ou entre em contato com a Porto Seguro.'
    )
  }
}

export async function getUserAnalysis(id) {
  try {
    const payload = {
      order: { id },
    }

    const response = await api.post(`/integracao/orders/analyse`, payload)

    return response.data
  } catch (error) {
    console.error(error)
    throw Error(
      'Houve uma instabilidade no sistema. Tente novamente mais tarde ou entre em contato com a Porto Seguro.'
    )
  }
}
