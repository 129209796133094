import styled, { css } from 'styled-components'
import media from 'styled-media-query'
import { rem } from 'polished'
import { Button } from 'components'

export const Container = styled.div`
  padding: 40px 50px;

  ${media.lessThan('large')`
    padding: 20px;
  `}
`

export const Filters = styled.form`
  margin-bottom: 24px;
  display: grid;
  grid-gap: 16px;
  grid-template-columns: 1fr;

  ${media.greaterThan('medium')`
    margin-bottom: 48px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  `}

  > button {
    border-radius: 8px;
  }

  > div {
    ${media.greaterThan('medium')`
      width: ${rem('300px')};
    `}
  }
`

export const SearchButton = styled(Button)`
  ${({ theme }) => css`
    padding: ${theme.spacings.xsmall} ${theme.spacings.xsmall};
  `}
`

export const Content = styled.div``

export const TableContainer = styled.div`
  margin-top: ${rem('24px')};
  overflow-x: auto;
  overflow-y: hidden;
  padding-bottom: 40px;

  ${media.lessThan('small')`

    > table {
      width: ${rem('900px')};;
    }
  `}
`

export const Error = styled.p`
  font-size: ${rem('12px')};
  font-family: ${({ theme }) => theme.fonts.medium};

  color: ${({ theme }) => theme.colors.danger};

  ${media.lessThan('small')`
    font-size: ${rem('8px')};
  `}
`

export const Action = styled.div`
  width: 40px;
  height: 40px;

  display: flex;
  justify-content: center;
  align-items: center;

  background: ${({ theme }) => theme.colors.primary};
  color: #fff;
  border-radius: 15px;

  :not(:first-of-type) {
    margin-left: 10px;
  }
`

export const Loader = styled.div`
  position: fixed;
  width: 100vw;
  height: 0;
  top: 0;
  z-index: ${({ theme }) => theme.layers.modal};
  overflow: hidden;

  ${({ showLoading }) =>
    showLoading &&
    css`
      height: 100vh;
    `}
`

export const Overlay = styled.div`
  top: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  display: block;
  z-index: -1;
  transition: opacity 0.5s ease;
  visibility: hidden;

  ${({ showLoading }) =>
    showLoading &&
    css`
      opacity: 1;
      z-index: 2;
      visibility: visible;

      background-color: rgba(0, 0, 0, 0.75);
      border-radius: 0.25rem;
    `}
`

export const WrapLoading = styled.div`
  z-index: 3;
  visibility: hidden;
  opacity: 0;
  position: fixed;
  max-height: 80vh;
  top: 50%;
  right: 50%;
  transition: opacity 0.5s ease;
  transform: translate(50%, -50%);

  ${({ showLoading }) =>
    showLoading &&
    css`
      opacity: 1;
      visibility: visible;
    `}
`
