import React from 'react'
import ReactTooltip from 'react-tooltip'

import { Icon } from 'components'

import { Container, Mark } from './styles'

export default function Tip({
  children,
  name,
  icon,
  size,
  onClick,
  color,
  padding,
  ...props
}) {
  return (
    <Container p={padding} onClick={onClick}>
      <Mark data-tip data-for={name}>
        <Icon color={color} size={size} name={icon} />
      </Mark>

      <ReactTooltip {...props} id={name} effect="solid" className="customStyles">
        {children}
      </ReactTooltip>
    </Container>
  )
}
