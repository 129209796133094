import { format as dateFormat, parseISO } from 'date-fns'
import { INDICATORS_ERROR } from 'helpers'
import api from 'services'
import { AppError, setTelephoneMask, formatCPF } from 'utils'

export async function getAllOrders({ requestParams = {} }) {
  try {
    const response = await api.get('/cf_order/list', {
      params: {
        ...requestParams,
      },
    })

    const formattedData = response.data.data.map((order) => {
      const {
        id,
        status,
        createdAt,
        representative,
        protheus,
        customer: { cpf, fullName, cellphone, email },
      } = order

      const createdDate = parseISO(createdAt)

      return {
        id: id,
        document: formatCPF(cpf.number),
        name: fullName,
        contract: protheus?.contract,
        phone: setTelephoneMask(cellphone),
        email,
        createdAt: dateFormat(createdDate, 'dd/MM/yyyy'),
        susep: representative?.broker?.susep,
        branch: representative?.broker?.branch,
        representative: representative?.customer?.fullName,
        status,
      }
    })

    return { pagination: response.data.pagination, data: formattedData }
  } catch (error) {
    throw new AppError(
      'Ocorreu um erro ao encontrar as orders. Tente novamente mais tarde.',
      error.response.status
    )
  }
}

export async function customerAssociation(data) {
  try {
    const response = await api.patch('/cf_order/update_representative', data)

    return response.data
  } catch (error) {
    throw Error(
      'Ocorreu um erro ao tentar associar o cliente. Tente novamente mais tarde.'
    )
  }
}

export async function cancelOrder(payload) {
  try {
    const { data } = await api.post('/cf_order/cancel_and_clone', payload)

    return data
  } catch (error) {
    throw Error('Ocorreu um erro ao tentar alterar a proposta.')
  }
}

export async function approveOrder(payload) {
  try {
    const response = await api.post('/cf_order/approve', payload)

    return response
  } catch (error) {
    throw new Error('Ocorreu um erro ao tentar aprovar o crédito da proposta.')
  }
}

export async function getStatus() {
  try {
    const { data } = await api.get('/cf_order/status')

    const parseArrayToObject = (data) => {
      const obj = {}

      Object.entries(data).forEach(([_, value]) => {
        obj[value.code] = {
          order: value.order,
          label: value.label,
          img: value.stepImage,
          description: value.stepDescription,
          bgColor: value.background_color,
          textColor: value.text_color,
        }
      })

      return obj
    }

    const formattedStatus = parseArrayToObject(data)

    return formattedStatus
  } catch (error) {
    throw new Error('Ocorreu um erro ao buscar os status')
  }
}

export async function getGroupStatus() {
  try {
    const { data } = await api.get('/cf_order/group_status')

    const clearFilter = {
      title: 'Todos',
    }

    const groupStatus =
      data?.map((status) => ({
        title: status.label,
        links: Object.entries(status.child_status).map(([, value]) => ({
          label: value.label,
          value: value.code,
        })),
      })) || []

    return [clearFilter, ...groupStatus]
  } catch (error) {
    throw new Error('Ocorreu um erro ao buscar os grupos de status.')
  }
}

export async function getOrdersResume(params) {
  try {
    const { data } = await api.get('/cf_order/resume', { params })

    const formattedData = data?.map((order) => ({
      label: order.label,
      child_status: order.child_status.map((status) => ({
        name: status.label,
        number: status.total,
        color: status.background_color,
        code: status.code,
      })),
    }))

    const parseArrayToObject = (array) => {
      const obj = {}

      // eslint-disable-next-line no-unused-expressions
      array?.forEach((order) => {
        order.child_status.map((status) => (obj[status.code] = status.total))
      })

      return obj
    }

    const total = parseArrayToObject(data)

    const dataLayer = {
      event: 'status-de-contrato',
      lead: total.preapp,
      'dados-do-carro-escolhido': total.product,
      recusado: total.denied,
      pagamento: total.payment,
      'preenchimento-da-proposta': total.pending,
      'em-analise': total.ordered,
      'condutores-adicionais': total.drivers,
      'pedido-finalizado': total.done,
      'dados-do-perfil': total.perfil,
      aprovado: total.approved,
      'aceite-do-termo': total.terms,
      'cartao-expirado': total.expired_cart,
      retorno: 'sucesso',
      descricao: 'Retorno de resumo ok',
    }

    return { ordersResume: formattedData, dataLayer }
  } catch (error) {
    const message = INDICATORS_ERROR[error.response.data.message]

    throw new Error(
      message ||
        'Houve uma instabilidade no sistema ao buscar os status de contratos. Tente novamente mais tarde ou entre em contato com a Porto Seguro.'
    )
  }
}
