import styled from 'styled-components'
import media from 'styled-media-query'

export const Container = styled.div`
  ${media.lessThan('small')`
    padding-bottom: 2rem;

    grid-template-columns: 1fr;
  `}
`

export const Group = styled.div`
  &:last-of-type {
    text-align: right;
  }

  ${media.lessThan('small')`
    padding-top: 1rem;

    &:last-of-type {
      text-align: left;
    }
  `}
`

export const Item = styled.a`
  display: block;

  cursor: pointer;
  transition: 0.15s;
  font-size: 0.84rem;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.gray.medium};
  font-family: ${({ theme }) => theme.fonts.medium};

  ${media.lessThan('small')`
    font-size: .74rem;
  `}

  &:not(:last-of-type) {
    margin-bottom: 1rem;
  }

  &:hover {
    opacity: 0.6;
    transition: 0.15s;
  }
`
