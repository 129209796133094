import styled from 'styled-components'
import { rem } from 'polished'

export const Tip = styled.div`
  background-color: ${({ isCopied, theme }) =>
    !isCopied ? theme.colors.gray.medium : '#00689A'};
  width: ${({ isCopied }) => (!isCopied ? rem('150px') : rem('100px'))};
  position: absolute;
  text-align: center;
  top: ${rem('-40px')};
  border-radius: 8px;
  padding: 7px 0;

  visibility: hidden;
  opacity: 0;

  transition-property: opacity;
  transition-delay: 0.4s;

  &::after {
    content: '';
    display: block;
    position: absolute;
    background-color: ${({ isCopied, theme }) =>
      !isCopied ? theme.colors.gray.medium : '#00689A'};

    width: 12px;
    height: 12px;

    left: 50%;
    transform: translateX(-50%) rotate(45deg);
  }

  span {
    color: #fbfbfb;
    font-size: 14px;
    line-height: 1rem;
    font-weight: bold;
  }
`

export const Clipboard = styled.div`
  width: 25px;
  height: 25px;

  right: 0.8rem;
  top: 50%;
  transform: translateY(-50%);

  position: absolute;
  z-index: 2;
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;

  &:hover ${Tip} {
    visibility: visible;
    opacity: 1;
  }
`
