import styled, { css } from 'styled-components'
import media from 'styled-media-query'

const modalModifiers = {
  open: () => css`
    opacity: 1;
  `,

  close: () => css`
    opacity: 0;
    pointer-events: none;
  `,
}

export const Wrapper = styled.div`
  ${({ isOpen, theme }) => css`
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.75);
    z-index: ${theme.layers.modal};
    transition: opacity 300ms ease-in-out;
    ${isOpen && modalModifiers.open()}
    ${!isOpen && modalModifiers.close()}
  `}
`

const backgrounds = {
  white: (theme) => css`
    background-color: ${theme.colors.white};
  `,
  primary: (theme) => css`
    background-color: ${theme.colors.primary};
  `,
}

export const ModalBody = styled.div`
  ${({ theme, background = 'white' }) => css`
    border-radius: ${theme.borderRadius.large};
    padding: ${theme.spacings.small};
    max-width: 85vw;
    max-height: 90vh;
    width: max-content;
    overflow: auto;

    ${!!background && backgrounds[background](theme)}
  `}
`
export const Title = styled.h2`
  ${({ theme }) => css`
    font-family: ${theme.fonts.bold};

    ${media.lessThan('large')`
      font-size: ${theme.fonts.sizes.small}};
    `}
  `}
`

const colors = {
  light: (theme) => css`
    color: ${theme.colors.white};
  `,
  dark: (theme) => css`
    color: ${theme.colors.gray.dark};
  `,
}

export const Header = styled.header`
  ${({ theme, color = 'dark' }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: ${theme.spacings.small};
    margin-bottom: ${theme.spacings.xsmall};

    ${!!color && colors[color](theme)}
  `}
`
export const IconWrapper = styled.div`
  ${({ theme }) => css`
    cursor: pointer;
    margin-top: ${theme.spacings.xxsmall};
  `}
`
