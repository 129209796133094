import React from 'react'
import { pascalCase, paramCase } from 'change-case'
import * as FeatherIcon from 'react-feather'
import PropTypes from 'prop-types'

/**
 * @param {Object} props
 * @param {string} props.name
 * @param {string} props.size
 * @param {string} props.color
 */
export default function Icon({ name, ...props }) {
  const SelectedIcon = FeatherIcon[pascalCase(name)] || React.Fragment

  return <SelectedIcon {...props} />
}

Icon.propTypes = {
  ...FeatherIcon.AlertCircle.propTypes,
  name: PropTypes.oneOf(Object.keys(FeatherIcon).map(paramCase)),
}
