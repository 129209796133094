import React, { useRef } from 'react'
import { X } from 'react-feather'
import { useLockBodyScroll, useOutsideClick } from 'hooks'

import * as S from './styles'

function Default({ isOpen, onClose, children, title, background, color }) {
  const contentRef = useRef()

  useLockBodyScroll(isOpen)
  useOutsideClick(contentRef, onClose)

  return (
    <S.Wrapper isOpen={isOpen}>
      <S.ModalBody isOpen={isOpen} ref={contentRef} background={background}>
        <S.Header color={color}>
          <S.Title>{title}</S.Title>
          <S.IconWrapper onClick={onClose}>
            <X size="24px" />
          </S.IconWrapper>
        </S.Header>
        {children}
      </S.ModalBody>
    </S.Wrapper>
  )
}

export default Default
