import { DateRange as DateRangePicker } from 'react-date-range'
import styled, { css } from 'styled-components'
import media from 'styled-media-query'
import * as Tip from 'components/Tip/styles'

export const Wrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
  `}
`

export const Label = styled.label`
  ${({ theme }) => css`
    color: ${theme.colors.black};
    font-size: ${theme.fonts.sizes.small};
    margin-bottom: ${theme.spacings.xsmall};
    font-weight: 600;
  `}
`
export const Content = styled.div`
  ${({ theme }) => css`
    height: 48px;

    ${media.greaterThan('huge')`
      height: 50px;
    `}

    display: grid;
    grid-template-columns: 1fr 24px;
    gap: calc(${theme.spacings.xxsmall} / 2);
    padding: 0 ${theme.spacings.xsmall};
  `}
`

export const Dropdown = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.black};
    border-radius: ${theme.borderRadius.medium};
    background-color: ${theme.colors.white};
    border: ${theme.border.hairline} solid ${theme.colors.primary};
    padding: ${theme.spacings.small};
    position: absolute;
    z-index: ${theme.layers.aside};
    margin-top: 8px;

    ${media.greaterThan('medium')`
      left: 0;
    `}

    ${media.lessThan('medium')`
      padding: ${theme.spacings.xsmall};

      left: 50%;
      transform: translate(-50%, 0);
    `}
  `}
`

export const DateRange = styled(DateRangePicker)`
  &.rdrMonthAndYearWrapper {
    align-items: center;
    height: 60px;
  }

  .rdrWeekDay {
    padding-right: 8px;
  }

  ${media.lessThan('small')`
      &.rdrCalendarWrapper {
        font-size: 10px;
      }

      &.rdrMonth {
        padding: none;
      }
    `}
`

export const ButtonContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: flex-end;

    gap: ${theme.spacings.small};
  `}
`

const DropdownModifiers = {
  open: () => css`
    opacity: 1;
    visibility: visible;
  `,
  close: () => css`
    opacity: 0;
    visibility: hidden;
  `,
}

export const Container = styled.div`
  ${({ theme, isOpen }) => css`
    position: relative;
    height: 48px;

    ${media.greaterThan('huge')`
      height: 50px;
    `}
    background: ${theme.colors.white};
    box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.1);

    border-radius: ${theme.borderRadius.medium};
    border: none;

    &:focus-within {
      box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.2);
    }

    ${Dropdown} {
      transition: transform 0.2s ease-in, opacity 0.3s ease-in;

      ${isOpen && DropdownModifiers.open()}
      ${!isOpen && DropdownModifiers.close()}
    }
  `}
`

export const Input = styled.input`
  ${({ theme }) => css`
    width: 100%;
    height: 100%;
    border: none;
    background: ${theme.colors.white};

    outline: none;

    color: ${theme.colors.primary};
    transition: 0.3s;
    font-size: ${theme.fonts.sizes.xsmall};
    font-weight: 600;

    &::placeholder {
      color: ${theme.colors.newGray[30]};
      font-size: ${theme.fonts.sizes.xsmall};
    }
  `}
`
export const IconWrapper = styled.div`
  ${({ theme, isOpen }) => css`
    display: flex;
    align-items: center;
    justify-content: center;

    > svg {
      width: 24px;
      height: 24px;

      color: ${theme.colors.newGray[100]};
      ${isOpen &&
      css`
        color: ${theme.colors.primary};
      `}
    }

    ${Tip.Mark} {
      padding: 0;
    }
  `}
`
export const Error = styled.p`
  font-weight: 600;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.danger};
  font-family: ${({ theme }) => theme.fonts.bold};

  ${media.lessThan('medium')`
    font-size: 11px;
  `}
`
