import React, { useEffect, useState } from 'react'

import { Grid } from 'components'

import { Choice, Dialog, Success, Failure } from '../../styles'

export default function Switches({ order }) {
  const [info, setInfo] = useState({})

  useEffect(() => {
    const { customer, coupon, termsAcceptance } = order

    setInfo({
      termsAcceptance: termsAcceptance?.accepted,
      hasDiscounts: coupon,
      isBroker: customer?.user?.broker?.id,
    })
  }, [order])

  return (
    <>
      <Dialog>
        <Grid
          my={4}
          gridTemplateColumns={['1fr', '1fr 1fr', '1fr 1fr 1fr']}
          gridGap="30px"
        >
          <Choice>Aceitou os termos?</Choice>
          <Success active={info.termsAcceptance}>Sim</Success>
          <Failure active={!info.termsAcceptance}>Não</Failure>
        </Grid>
      </Dialog>
      <Dialog>
        <Grid
          my={4}
          gridTemplateColumns={['1fr', '1fr 1fr', '1fr 1fr 1fr']}
          gridGap="30px"
        >
          <Choice>Cupom Aplicado?</Choice>
          <Success active={info.hasDiscounts}>Sim</Success>
          <Failure active={!info.hasDiscounts}>Não</Failure>
        </Grid>
      </Dialog>
      <Dialog>
        <Grid
          my={4}
          gridTemplateColumns={['1fr', '1fr 1fr', '1fr 1fr 1fr']}
          gridGap="30px"
        >
          <Choice>Funcionário ou corretor?</Choice>
          <Success active={info.isBroker}>Sim</Success>
          <Failure active={!info.isBroker}>Não</Failure>
        </Grid>
      </Dialog>
    </>
  )
}
