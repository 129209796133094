import React, { useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'

import {
  Bottom,
  ModalTextContent,
  ReanalysisContent,
  ReanalysisDescription,
  Animate,
} from './styles'

import { Button, Flex, Icon, SimpleModal as Modal } from 'components'
import { ScrollButton, Button as RenewButton, MessageCard } from '../common'

import { ModalContext } from 'contexts'
import { REANALYSIS_STEPS } from 'helpers'
import { procedureAsyncRanalyse } from 'services'

export default function Footer({ hasError }) {
  const history = useHistory()
  const [reanalyzeError, setReanalyseError] = useState('')
  const [reanalyzeLoading, setReanalyseLoading] = useState(false)

  const {
    handleOpenModal,
    openModal,
    handleCloseModal,
    currentModal,
    reanalyzeData,
    hasResult,
    hasReanalysisError,
    clientId,
    handleFetchClientDetails,
  } = useContext(ModalContext)

  const handleProcedureReanalyse = async () => {
    try {
      setReanalyseLoading(true)
      await procedureAsyncRanalyse(clientId)
      handleOpenModal('ReanalysisModal')
    } catch (error) {
      setReanalyseError(error.message)
    } finally {
      setReanalyseLoading(false)
    }
  }

  const FinishedReanalysisModal = () => {
    return (
      <Modal
        title="Solicitação de reanálise de crédito"
        open={openModal && currentModal === 'FinishedReanalysisModal'}
      >
        {hasResult && <MessageCard type={hasResult} />}

        <Flex justifyContent="flex-end">
          <RenewButton
            action={handleFetchClientDetails}
            label={'Fechar'}
            theme="gray"
          />
        </Flex>
      </Modal>
    )
  }

  const ConfimationModal = () => {
    return (
      <Modal
        title="Solicitação de reanálise de crédito"
        open={openModal && currentModal === 'ConfimationModal'}
      >
        <ModalTextContent>
          Esta ação pode afetar o score do usuário. Você deseja prosseguir?
        </ModalTextContent>
        {reanalyzeError && <MessageCard type="ERRO" message={reanalyzeError} />}
        <Flex mt="50px" justifyContent="space-between">
          <RenewButton
            action={handleCloseModal}
            label={'Voltar'}
            theme="gray"
          />

          <RenewButton
            label={'Prosseguir'}
            action={handleProcedureReanalyse}
            loading={reanalyzeLoading}
          />
        </Flex>
      </Modal>
    )
  }

  const ReanalysisModal = () => {
    return (
      <Modal
        title="Solicitação de reanálise de crédito"
        open={openModal && currentModal === 'ReanalysisModal'}
      >
        <ModalTextContent>
          Reanálise em andamento (aproximadamente de 40 segundos a 1 minuto.)
        </ModalTextContent>

        <ReanalysisContent>{handleReanalysisData()}</ReanalysisContent>
        {hasReanalysisError && (
          <>
            <MessageCard type={hasReanalysisError} />
            <Flex justifyContent="flex-end">
              <RenewButton
                action={handleFetchClientDetails}
                label={'Fechar'}
                theme="gray"
              />
            </Flex>
          </>
        )}
      </Modal>
    )
  }

  const handleReanalysisData = () => {
    return reanalyzeData?.steps
      ?.sort((a, b) => a.index - b.index)
      .map((el) => {
        return (
          <Flex alignItems="center" key={el.step}>
            <Animate shouldAnimate={el.innerStatus === 'processing'}>
              {REANALYSIS_STEPS[el.innerStatus]?.icon && (
                <Icon
                  key={el.index}
                  name={REANALYSIS_STEPS[el.innerStatus]?.icon}
                  color={REANALYSIS_STEPS[el.innerStatus]?.color}
                  size="18px"
                />
              )}
            </Animate>
            <ReanalysisDescription
              textColor={REANALYSIS_STEPS[el.innerStatus]?.color}
            >
              {el.label}
            </ReanalysisDescription>
          </Flex>
        )
      })
  }

  const scrollToTop = () => {
    window.scroll({
      top: 0,
      behavior: 'smooth',
    })
  }

  const handleRouteNavigation = () => {
    if (history.action === 'PUSH') {
      history.goBack()
      return
    }

    history.push('/renovacao')
  }

  return (
    <Bottom>
      <Flex justifyContent="space-between" alignItems="center">
        <Button onClick={handleRouteNavigation}>Voltar</Button>
        {!hasError && <ScrollButton icon="arrow-up" action={scrollToTop} />}
      </Flex>

      {ConfimationModal()}
      {ReanalysisModal()}
      {FinishedReanalysisModal()}
    </Bottom>
  )
}
