import styled from 'styled-components'
import media from 'styled-media-query'
import { rem } from 'polished'

export const Container = styled.div`
  padding: 40px 50px;

  ${media.lessThan('large')`
    padding: 20px;
  `}
`

export const Content = styled.div`
  padding: 30px;

  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
`

export const Form = styled.form``

export const Actions = styled.p`
  ${media.greaterThan('large')`
    display: flex;
    justify-content: space-between;
  `}

  ${media.lessThan('large')`
    > button {
      width: 100%;

      text-align: center;

      &:not(:last-of-type) {
        margin-bottom: 20px;
      }
    }
  `}
`

export const Error = styled.p`
  padding-top: 20px;

  font-size: ${rem('13px')};
  color: ${({ theme }) => theme.colors.danger};
  font-family: ${({ theme }) => theme.fonts.bold};

  ${media.lessThan('large')`
    font-size: ${rem('11px')};
  `}
`
