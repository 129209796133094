import * as ModalStyles from 'components/Modals/Alert/styles'

import { rem } from 'polished'
import styled, { css } from 'styled-components'
import media from 'styled-media-query'
import { grid, layout, space } from 'styled-system'

export const Wrapper = styled.main`
  height: calc(100vh - 80px);
  width: 100%;
`

export const Container = styled.section`
  ${() => css`
    padding: 40px 50px;
    ${media.lessThan('large')`
        padding: 20px;
    `}

    animation-name: slide;
    animation-duration: 1s;
    transform: translateY(-50%);
    animation-fill-mode: forwards;

    @keyframes slide {
      from {
        opacity: 0;
      }

      to {
        transform: translateY(0);
        opacity: 1;
      }
    }
  `}
`

export const Content = styled.div`
  ${ModalStyles.Dialog} {
    ${media.greaterThan('large')`
      padding: 60px ;
  `}
  }
`

export const Box = styled.div`
  padding: 30px;

  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
`

export const Label = styled.p`
  font-size: ${rem('14px')};
  font-family: ${({ theme }) => theme.fonts.bold};
  color: ${({ theme }) => theme.colors.gray.medium};

  ${media.lessThan('large')`
    font-size: ${rem('12px')};
  `}
`

export const Form = styled.form``

export const Fields = styled.div`
  ${grid}
  ${layout}
  ${space}

  display: grid;

  ${({ hidden }) =>
    hidden &&
    css`
      display: none;
    `}
`

export const Actions = styled.p`
  ${media.greaterThan('large')`
    display: flex;
    justify-content: space-between;
  `}

  ${media.lessThan('large')`
    > button {
      width: 100%;

      text-align: center;

      &:not(:last-of-type) {
        margin-bottom: 20px;
      }
    }
  `}
`

export const Error = styled.p`
  padding-top: 20px;

  font-size: ${rem('13px')};
  color: ${({ theme }) => theme.colors.danger};
  font-family: ${({ theme }) => theme.fonts.bold};

  ${media.lessThan('large')`
    font-size: ${rem('11px')};
  `}
`

export const ModalBody = styled.div`
  margin: 16px 0;
`

export const LabelModal = styled.p`
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.gray.davys};

  ${media.lessThan('small')`
    font-size: ${rem('14px')};
  `}

  margin-bottom: 1rem;
`
