export const CANCEL_OPTIONS = [
  {
    value: 'Alterar dados pessoais',
    label: 'Alterar dados pessoais',
  },
  {
    value: 'Alterar dados do plano (Modelo, período, pacote, etc)',
    label: 'Alterar dados do plano (Modelo, período, pacote, etc)',
  },
  {
    value: 'outros',
    label: 'Outros',
  },
]
