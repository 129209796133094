import { rem } from 'polished'
import styled from 'styled-components'
import media from 'styled-media-query'

export const Error = styled.p`
  position: absolute;
  bottom: -18px;
  left: 0;

  padding: 6px 20px 0;

  font-size: ${rem('14px')};
  font-family: ${({ theme }) => theme.fonts.medium};

  color: ${({ theme }) => theme.colors.danger};

  ${media.lessThan('small')`
    position: static;
    
    font-size: ${rem('12px')};
  `}
`
