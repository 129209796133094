import React from 'react'
import { TextareaComponent, TextareaGroup, Error } from './styles'
import { useFormContext, ErrorMessage, Controller } from 'react-hook-form'

export default function Textarea({ name, ...props }) {
  const { errors, control } = useFormContext()

  return (
    <TextareaGroup>
      <Controller
        control={control}
        name={name}
        as={<TextareaComponent {...props} />}
      />

      <ErrorMessage errors={errors} name={name} as={<Error />} />
    </TextareaGroup>
  )
}
