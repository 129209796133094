import React, { useEffect, useRef, useState } from 'react'
import { useFormContext } from 'react-hook-form'

import { Container, Error, Input, Label } from './styles'

export default function File({
  onChange,
  error,
  isLoading,
  label,
  name,
  file,
  ...props
}) {
  const containerRef = useRef()

  const { register } = useFormContext() || {}

  const [hasLabel, setHasLabel] = useState(label)

  useEffect(() => {
    if (!file) {
      setHasLabel(label)
    }
  }, [file, label])

  function handleChange(e) {
    if (e.target.files.length < 1) {
      return
    }

    setHasLabel(e.target.files[0]?.name)
    onChange && onChange(e)
  }

  function setClick(e) {
    const input = e.target.querySelector('input')

    input && input.click()
  }

  return (
    <Container isLoading={isLoading} ref={containerRef} onClick={setClick}>
      <Input
        {...props}
        name={name}
        onChange={handleChange}
        type="file"
        ref={register}
        data-gtm-click="click"
        data-gtm-clicktype="button"
        data-gtm-name="image"
      />
      <Label onClick={() => containerRef.current.click()}>{hasLabel}</Label>
      <Error>{error}</Error>
    </Container>
  )
}
