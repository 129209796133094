import styled from 'styled-components';
import media from 'styled-media-query';
import { Container as ContainerSelect } from 'components/Forms/CheckboxSelect/styles'
import { rem } from 'polished';
import { Button as CustomButton } from 'components'



export const Filters = styled.form`
  margin: 1.2rem 0;

  display: grid;
  grid-gap: 1rem;
  grid-template-columns: 1fr;

  ${media.greaterThan('large')`
    display: grid;
    align-items: center;
    grid-template-columns: ${({ isAdmin }) =>
      isAdmin
        ? '1fr 1fr 1fr 1fr 60px 1fr'
        : 'repeat(auto-fill, minmax(280px, 1fr))'};

    > div {
      &:not(:last-of-type) {
        margin-right: 30px;
      }
    }

  `}

  ${media.lessThan('small')`
    margin-bottom: 20px;

    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(1, 1fr);
  `}

  ${ContainerSelect} {
    width: 100%;
  }
`

export const FilterButton = styled.div`
  display: flex;
  justify-content: center;

  button {
    cursor: pointer;

    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: ${({ theme }) => theme.colors.gray.medium};

    width: ${rem('120px')};
    white-space: nowrap;

    border: none;
    background-color: transparent;

    &:hover {
      text-decoration: underline;
    }
  }
`

export const Button = styled(CustomButton)`
  border-radius: 8px;
  height: 50px;

  &:disabled {
    background-color: ${({ theme }) => theme.colors.gray.light};
    cursor: not-allowed;
  }
`
