import { Error } from 'components'
import React from 'react'
import * as S from './styles'

export default function Page404() {
  return (
    <S.Container>
      <Error />
    </S.Container>
  )
}

Page404.properties = {
  metatags: {
    title: 'Ops, algo deu errado',
  },
}
