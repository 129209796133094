import React, { useRef } from 'react'
import { useLockBodyScroll, useOutsideClick } from 'hooks'
import Icon from 'components/Icon'
import * as S from './styles'

function Drawer({ isOpen, handleCloseDrawer, children, backgroundColor }) {
  useLockBodyScroll(isOpen)
  const drawerRef = useRef()

  useOutsideClick(drawerRef, () => {
    handleCloseDrawer()
  })

  return (
    <S.Overlay isOpen={isOpen}>
      <S.Container ref={drawerRef} isOpen={isOpen} backgroundColor={backgroundColor}>
        <S.IconWrapper onClick={handleCloseDrawer}>
          <Icon name="x" />
        </S.IconWrapper>

        {children}
      </S.Container>
    </S.Overlay>
  )
}

export default Drawer
