import React from 'react'

import { Loading } from './styles'

export default function LoadingComponent({ size, color }) {
  return (
    <Loading size={size} color={color}>
      <div />
      <div />
      <div />
      <div />
    </Loading>
  )
}
