import React, { useState, useEffect } from 'react'
import { Container, Title, Arrow, Content, Wrap } from './styles'

export default function Accordion({ title, shouldOpen, children }) {
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    if (shouldOpen) {
      setIsOpen(true)
    }
  }, [shouldOpen])

  return (
    <Container>
      <Title onClick={() => setIsOpen(!isOpen)}>
        <span>{title}</span>

        <Arrow
          data-gtm-click="click"
          data-gtm-clicktype="accordion"
          data-gtm-name={title}
          isOpen={isOpen}
        />
      </Title>

      <Content isOpen={isOpen}>
        <Wrap>{children}</Wrap>
      </Content>
    </Container>
  )
}
