import React, { useState, useEffect } from 'react'

import { TableContainer, Error, Label } from './styles'
import { Dialog, Table, Button } from '../common'

import { Grid, Input } from 'components'
import { handleDateIsValid, handleBrlValue } from 'utils'

import { getClientOldContracts } from 'services'

export default function Contract({ forms, contract, document }) {
  const [oldContractData, setOldContractData] = useState([])
  const [hasError, sethasError] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    forms.setValue([
      { 'contract.branch': contract?.branch },
      { 'contract.type': contract?.description_contract_type },
      { 'contract.number': contract?.contract_number },
      { 'contract.plan': contract?.contract_plan },
      {
        'contract.date_initial': handleDateIsValid(
          contract?.start_date_contract
        ),
      },
      {
        'contract.date_final': handleDateIsValid(contract?.final_date_contract),
      },
      { 'contract.situation_description': contract?.situation_description },
      { 'contract.period': contract?.period },
    ])
  }, [contract]) // eslint-disable-line

  const handleFetchOldContracts = async () => {
    try {
      setIsLoading(true)
      const response = await getClientOldContracts({
        document,
        branch: contract.branch,
      })

      const data = response?.map((el) => {
        let situation = ''
        if (el?.situation_description === 'Vigente') {
          situation = `${el.situation_description} até ${el.final_date_contract}`
        } else {
          situation = el?.situation_description
        }

        const table = {
          plan: el?.contract_plan,
          period: el?.period,
          situation: el?.situation_description,
          contract: {
            situation: situation,
            number: el?.contract_number,
          },
          veicle: `${el?.version} - ${handleBrlValue(el?.installment_value)}`,
          type: el?.description_contract_type,
        }

        return table
      })

      setOldContractData(data)
    } catch (error) {
      sethasError(error.message)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Dialog title="1. Contrato">
      <Grid
        my={1}
        gridTemplateColumns={['1fr', '1fr 1fr', '1fr 1fr 1fr']}
        gridGap="16px"
        data-testid="contrat-detailed"
      >
        <Input 
          name="contract.branch"
          placeholder="Filial do Contrato"
          label="Filial do Contrato"
          disabled
        />
        <Input
          name="contract.type"
          placeholder="Tipo de Contrato"
          label="Tipo de Contrato"
          disabled
        />

        <Input
          name="contract.number"
          placeholder="Número"
          label="Número"
          disabled
        />
        <Input
          name="contract.plan"
          placeholder="Plano"
          label="Plano"
          disabled
        />
        <Input
          name="contract.date_initial"
          placeholder="Início Contrato"
          label="Início Contrato"
          mask="##/##/####"
          disabled
        />
        <Input
          name="contract.date_final"
          placeholder="Término Contrato"
          label="Término Contrato"
          mask="##/##/####"
          disabled
        />
        <Input
          name="contract.situation_description"
          placeholder="Situação"
          label="Situação"
          disabled
        />
        <Input
          name="contract.period"
          placeholder="Vigência"
          label="Vigência"
          disabled
        />
      </Grid>

      <TableContainer>
        {oldContractData.length === 0 ? (
          <Button
            action={handleFetchOldContracts}
            label="Visualizar histórico de contratos"
            loading={isLoading}
          />
        ) : (
          <Label>Histórico de contratos</Label>
        )}
        {hasError && <Error>{hasError}</Error>}
        <Table
          data={oldContractData}
          showTable={!isLoading && oldContractData.length > 0}
          columns={[
            {
              header: 'Plano',
              acessor: 'plan',
            },
            {
              header: 'Meses',
              acessor: 'period',
            },
            {
              header: 'Contrato',
              acessor: 'contract',
              hasHover: true,
            },
            {
              header: 'Tipo - Veículo',
              acessor: 'veicle',
            },
            {
              header: 'Tipo do contrato',
              acessor: 'type',
              hasMarker: true,
            },
          ]}
        />
      </TableContainer>
    </Dialog>
  )
}
