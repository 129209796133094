import React from 'react'

import * as S from './styles'

function Box({ title, children }) {
  return (
    <S.Container>
      <S.Label>{title}</S.Label>
      {children}
    </S.Container>
  )
}

export default Box
