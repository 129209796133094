import { useQuery } from 'react-query'
import { getStatusCount } from 'services/apiV2/renovation'

export function useStatusCount(params, options) {
  return useQuery(
    ['statusRenovationCount', params],
    () => getStatusCount(params),
    {
      staleTime: 60 * 1000 * 10, //10 minutes
      refetchOnWindowFocus: false,
      ...options,
    }
  )
}
