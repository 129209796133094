import React, { useState } from 'react'
import { Loading, IndicatorCard } from 'components'
import { useStatusCount } from 'services/hooks/renovation'
import { handleStatusPcoColor } from 'utils'
import { useNotification, useParams } from 'hooks'

import * as S from './styles'

export function Accordion() {
  const [isOpen, setIsOpen] = useState(false)
  const notification = useNotification()
  const dataLayer = window.dataLayer || []

  const { params } = useParams()

  const requestParams = {
    startDatePeriod: params?.startFinalDateContractPeriod,
    finalDatePeriod: params?.finalDateContractPeriod,
  }

  const { data, isLoading } = useStatusCount(requestParams, {
    enabled: isOpen,
    onSuccess: () => {
      dataLayer.push({
        event: 'solicitacao_servico',
        nome_servico: 'status-renovacoes',
        tipo_servico: 'busca',
        retorno: 'sucesso',
        descricao: 'busca retornada com sucesso',
        status: params?.status,
        'data-inicial': params?.startFinalDateContractPeriod,
        'data-final': params?.finalDateContractPeriod,
      })
    },
    onError: (error) => {
      dataLayer.push({
        event: 'solicitacao_servico',
        nome_servico: 'status-renovacoes',
        tipo_servico: 'busca',
        retorno: 'sucesso',
        descricao: 'busca retornada com sucesso',
        status: params?.status,
        'data-inicial': params?.startFinalDateContractPeriod,
        'data-final': params?.finalDateContractPeriod,
        erro: {
          codigo: error.status,
          mensagem: error.message,
          servico: 'erro ao buscar os dados das renovações',
        },
      })

      notification.open({
        type: 'danger',
        content: error.message,
      })
    },
  })

  const handleToggleAccordion = () => {
    setIsOpen((prevState) => !prevState)
  }

  return (
    <S.Container>
      <S.Title onClick={handleToggleAccordion}>
        <span>Status das renovações</span>

        <S.Arrow isOpen={isOpen} />
      </S.Title>

      <S.Content isOpen={isOpen}>
        {isLoading ? (
          <S.WrappLoading>
            <Loading size={'10px'} color={'#000'} />
          </S.WrappLoading>
        ) : (
          <S.Wrap>
            {data?.map((el) => {
              const color = handleStatusPcoColor(el.type)

              return (
                <IndicatorCard
                  key={el.type}
                  name={el.type}
                  number={el.total}
                  color={color}
                />
              )
            })}
          </S.Wrap>
        )}
      </S.Content>
    </S.Container>
  )
}
