import styled from 'styled-components'
import media from 'styled-media-query'
import { rem } from 'polished'

export const Container = styled.div`
  padding: 40px 50px;

  ${media.lessThan('small')`
    padding: 30px 1rem;
  `}
`

export const Overflow = styled.div`
  width: 100%;
  overflow-x: auto;
`

export const Label = styled.span`
  margin-bottom: 10px;

  display: block;

  font-family: ${({ theme }) => theme.fonts.bold};
  color: ${({ theme }) => theme.colors.gray.medium};
  font-size: 14px;
`

export const FilterButton = styled.div`
  display: flex;
  justify-content: center;

  button {
    cursor: pointer;

    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: ${({ theme }) => theme.colors.gray.medium};

    width: ${rem('120px')};
    white-space: nowrap;

    border: none;
    background-color: transparent;

    &:hover {
      text-decoration: underline;
    }
  }
`

export const Filters = styled.form`
  margin-top: 32px;

  font-size: ${rem('12px')};
`

export const FiltersContainer = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: repeat(6, 1fr);
  gap: 24px;

  ${media.lessThan('large')`
    grid-template-columns: 1fr;
    gap: 16px
  `}

  > button {
    max-height: 50px;
    height: 100%;
    border-radius: 8px;
  }
`

export const Action = styled.div`
  width: 40px;
  height: 40px;

  display: flex;
  justify-content: center;
  align-items: center;

  background: ${({ theme }) => theme.colors.primary};
  color: #fff;
  border-radius: 15px;

  :not(:first-of-type) {
    margin-left: 10px;
  }
`
