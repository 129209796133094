import styled, { css } from 'styled-components'
import { rem, darken } from 'polished'

import media from 'styled-media-query'

export const Header = styled.header`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;

    ${media.lessThan('small')`
      flex-direction: column;
      gap: ${theme.spacings.small};
    `}

    background: ${theme.colors.newPrimary};
    padding: ${theme.spacings.xlarge} ${theme.spacings.large};
    color: ${theme.colors.white};
    margin-bottom: ${theme.spacings.large};
  `}
`
export const LabelSM = styled.label`
  ${({ theme }) => css`
    font-size: ${theme.fonts.sizes.xsmall};
    line-height: 1rem;
  `}
`

export const Container = styled.div`
  ${({ theme }) => css`
    padding: ${theme.spacings.large};

    ${media.lessThan('small')`
      padding: ${theme.spacings.small};
    `}
  `}
`

export const Content = styled.section`
  ${({ theme }) => css`
    padding-inline: ${theme.spacings.large};

    ${media.lessThan('small')`
    padding-inline:  ${theme.spacings.small};
    `}
  `}
`

export const Link = styled.a`
  ${({ theme }) => css`
    color: ${theme.colors.white};
    font-size: ${theme.fonts.sizes.xsmall};
    text-decoration: underline;
    letter-spacing: -0.3px;

    cursor: pointer;

    &:hover {
      color: ${darken('.06', theme.colors.white)};
    }
  `}
`

export const Box = styled.div`
  ${({ theme }) => css`
    justify-self: end;
    margin-top: ${theme.spacings.small};
    max-width: ${rem('400px')};
  `}
`

export const Wrap = styled.div`
  height: ${rem('240px')};
  width: 100%;
  display: flex;
`

export const Label = styled.p`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacings.xsmall};
    color: ${theme.colors.primary};
    font-family: ${theme.fonts.bold};
    font-size: ${theme.fonts.sizes.xsmall};

    &:last-child {
      cursor: pointer;
    }
  `}
`

export const Logo = styled.img`
  max-width: 100%;
  margin: auto;
  max-height: 100%;
  height: auto;
`

export const Dialog = styled.div`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacings.xlarge};
    margin-top: ${theme.spacings.xsmall};

    padding: ${theme.spacings.medium};

    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
    background: ${theme.colors.white};
    border-radius: 5px;
  `}
`

export const Title = styled.span`
  ${({ theme }) => css`
    padding-block: ${theme.spacings.medium};
    color: ${theme.colors.gray.jet};
    font-family: ${theme.fonts.bold};
    font-size: ${theme.fonts.sizes.small};
    line-height: ${rem('20px')};
  `}
`

export const Success = styled.p`
  ${({ theme }) => css`
    margin-top: ${theme.spacings.small};
    font-family: ${theme.fonts.bold};
    font-size: ${theme.fonts.sizes.small};
    color: ${theme.colors.gray.davys};
    line-height: ${rem('20px')};
  `}
`
export const Error = styled.p`
  ${({ theme }) => css`
    margin-top: ${theme.spacings.small};
    font-family: ${theme.fonts.medium};
    font-size: ${theme.fonts.sizes.xsmall};
    color: ${theme.colors.danger};
    line-height: ${rem('12px')};
  `}
`

export const ButtonWrap = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: ${theme.spacings.xsmall};

    grid-column-start: 2;

    p {
      font-size: ${theme.fonts.sizes.xxsmall};
      line-height: 14px;

      color: ${theme.colors.gray.medium};
      margin-top: ${theme.spacings.xsmall};

      max-width: 300px;
      width: 100%;
    }
  `}
`

export const CopyButton = styled.button`
  ${({ theme }) => css`
    box-sizing: border-box;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: ${theme.spacings.xsmall};
    border-radius: 90px;
    color: ${theme.colors.white};
    background-color: ${theme.colors.primary};
    padding: ${theme.spacings.xsmall} ${theme.spacings.xxlarge};
    cursor: pointer;

    span {
      font-size: ${theme.fonts.sizes.xsmall};
      font-family: ${theme.fonts.bold};
      color: ${theme.colors.white};
    }

    &:hover {
      transition: background-color 0.3s;
      background-color: ${darken('.06', theme.colors.primary)};
    }
  `}
`
