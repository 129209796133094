import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { FormContext, useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'

import { getOrderDetails, customerAssociation } from 'services'
import { Button, SimpleModal as Modal, Flex } from 'components'

import { Container, Loader, Error } from './styles'
import { Brokers, Drivers, Main, Status, Switches } from './components'
import { useNotification } from 'hooks'
import { useCurrentUser } from 'hooks'

export default function View() {
  const params = useParams()
  const forms = useForm()
  const history = useHistory()
  const { role } = useCurrentUser()
  const notification = useNotification()

  const [order, setOrder] = useState({})
  const [broker, setBroker] = useState('')
  const [loading, setLoading] = useState(false)
  const [associationLoading, setAssociationLoading] = useState(false)
  const [error, setError] = useState('')

  const [openModal, setOpenModal] = useState(false)

  const handleRouteNavigation = () => {
    if (history.action === 'PUSH') {
      history.goBack()
      return
    }

    history.push('/clientes')
  }

  const fetchUserData = async () => {
    setLoading(true)
    try {
      const response = await getOrderDetails(params.id)

      setOrder(response)
      setBroker(response?.representative?.broker?.susep)
    } catch (error) {
      if (error.message === 'Usuário não autorizado') {
        history.push('/404')
      }
      setError(error.message)
    } finally {
      setLoading(false)
    }
  }

  const sendCustomerAssociation = async () => {
    setAssociationLoading(true)
    try {
      await customerAssociation({ orderId: params.id })
      setOpenModal(false)

      notification.open({
        type: 'success',
        content: 'Status da proposta foi alterado com sucesso.',
      })
    } catch (error) {
      notification.open({
        type: 'danger',
        content: error.message,
      })
      history.push('/clientes')
    } finally {
      setTimeout(() => {
        setAssociationLoading(false)
      }, 3000)
    }
  }

  useEffect(() => {
    window.scroll({
      top: 0,
      behavior: 'smooth',
    })
    fetchUserData()
  }, []) //eslint-disable-line

  useEffect(() => {
    if (broker?.toUpperCase() === 'CFWHATS' && role === 'broker') {
      setOpenModal(true)
    }
  }, [broker, role])

  useEffect(() => {
    if (openModal) {
      document.body.style.overflow = 'hidden'
    }
    return () => (document.body.style.overflow = 'unset')
  }, [openModal])

  const ChangeBrokerModal = () => {
    return (
      <Modal
        title="Associação de Cliente (Atendimento via Whatsappp)"
        open={openModal}
      >
        <p>Deseja associar este cliente a sua SUSEP?</p>
        <Flex mt="50px" justifyContent="space-between">
          <Button color="gray" onClick={() => history.push('/clientes')}>
            Voltar
          </Button>
          <Button
            onClick={sendCustomerAssociation}
            isLoading={associationLoading}
          >
            Confirmar
          </Button>
        </Flex>
      </Modal>
    )
  }

  return (
    <Container>
      {loading && <Loader />}
      {error && <Error>{error}</Error>}
      <FormContext {...forms}>
        <Main order={order} forms={forms} />
        <Drivers order={order} />
        <Switches order={order} />
        <Status order={order} />
        <Brokers />
      </FormContext>
      <Button
        onClick={() => {
          handleRouteNavigation()
        }}
      >
        Voltar
      </Button>

      {openModal && ChangeBrokerModal()}
    </Container>
  )
}

View.properties = {
  metatags: {
    title: 'Clientes',
  },
}
