import React, { useState } from 'react'
import { Icon } from 'components'

import { Clipboard, Tip } from './styles'
export default function ClipboardCopy({ inputValue, name }) {
  const [clipboard, setClipboard] = useState(false)

  const handleCopyTopClipboard = () => {
    setClipboard(!clipboard)
    navigator.clipboard.writeText(inputValue)

    setTimeout(() => {
      setClipboard(false)
    }, 2500)
  }

  return (
    <Clipboard data-tip data-for={name} onClick={handleCopyTopClipboard}>
      <Icon color="#555" size="18px" name="copy" />
      <Tip isCopied={clipboard}>
        <span>{!clipboard ? 'Clique para copiar' : 'Copiado!'}</span>
      </Tip>
    </Clipboard>
  )
}
