import styled from 'styled-components'
import media from 'styled-media-query'

export const Container = styled.div`
  background-color: #fafafa;
`

export const Wrapper = styled.div`
  margin: auto;
  padding: 20px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  ${media.lessThan('large')`
    padding: 2rem 15px;

    align-items: start;
    flex-direction: column;
  `}
`

export const Left = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);

  ${media.lessThan('large')`
    padding-top: 2rem;

    grid-template-columns: 1fr;

    order: 2;
  `};
`

export const Item = styled.a`
  display: block;

  cursor: pointer;
  transition: 0.15s;
  font-size: 0.83rem;
  text-decoration: none;
  text-transform: uppercase;
  font-family: ${({ theme }) => theme.fonts.bold};
  color: ${({ theme }) => theme.colors.gray.medium};

  &:not(:last-of-type) {
    margin-bottom: 1rem;
  }

  ${media.lessThan('large')`
    order: 1;

    font-size: .73rem;
  `}

  &:hover {
    opacity: 0.6;
    transition: 0.15s;
  }
`

export const Right = styled.div``

export const Text = styled.p`
  font-size: 0.75rem;
  font-family: ${({ theme }) => theme.fonts.book};
  color: ${({ theme }) => theme.colors.gray.light};

  ${media.lessThan('large')`
    font-size: .65rem;
  `}
`
