import React from 'react'
import Select from 'react-select'

import { styles, Error, Container } from './styles'

export default function Searchable({ options, error, ...props }) {
  return (
    <Container>
      <Select
        {...props}
        options={options}
        styles={styles}
        noOptionsMessage={() => 'Nenhuma opção'}
        isSearchable
      />
      {!!error && <Error>{error}</Error>}
    </Container>
  )
}
