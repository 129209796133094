import React, { useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Icon } from 'components'

import * as S from './styles'

export const Action = ({ order }) => {
  const [isOpen, setIsOpen] = useState(false)
  const history = useHistory()
  const dropdownRef = useRef()

  return (
    <>
      <S.Wrapper isOpen={isOpen}>
        <S.Title onClick={() => setIsOpen(!isOpen)}>
          <Icon name="more-horizontal" color="black" size={32} />
        </S.Title>

        <S.Content aria-hidden={!isOpen} ref={dropdownRef}>
          <S.ActionButton
            data-gtm-type="click"
            data-gtm-clicktype="link"
            data-gtm-name="ver-mais-dados"
            onClick={() => {
              history.push(`/clientes/${order.id}`)
            }}
          >
            Ver mais dados
          </S.ActionButton>
        </S.Content>
      </S.Wrapper>
      <S.Overlays isOpen={isOpen} onClick={() => setIsOpen(false)} />
    </>
  )
}

export default Action
