import styled, { css } from 'styled-components'
import { Link as LinkProvider } from 'react-router-dom'
import media from 'styled-media-query'

export const Link = styled(LinkProvider)`
  ${({ theme }) => css`
    border: ${theme.border.thin} solid ${theme.colors.newGray[20]};
    border-radius: ${theme.borderRadius.medium};

    display: flex;
    flex-direction: column;
    gap: ${theme.spacings.xxsmall};

    padding: ${theme.spacings.small} 0;
    padding-left: ${theme.spacings.xxlarge};

    ${media.lessThan('medium')`
    padding: ${theme.spacings.xsmall};
    justify-content: center;
  `}
  `}
`

export const Container = styled.div`
  ${({ theme }) => css`
    border: ${theme.border.thin} solid ${theme.colors.newGray[20]};
    border-radius: ${theme.borderRadius.medium};

    display: flex;
    flex-direction: column;
    gap: ${theme.spacings.xxsmall};

    padding: ${theme.spacings.small} 0;
    padding-left: ${theme.spacings.xxlarge};

    ${media.lessThan('medium')`
    padding: ${theme.spacings.xsmall};
    justify-content: center;
  `}
  `}
`

export const Name = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.newGray[80]};
    font-weight: 400;
    font-size: ${theme.fonts.sizes.small};
    line-height: 1;
  `}
`

export const Number = styled.span`
  ${({ theme }) => css`
    color: ${theme.colors.gray.dark};
    font-weight: 400;
    font-size: 36px;
    line-height: 1;
  `}
`

export const Line = styled.div`
  ${({ color }) => css`
    width: 93px;
    height: 7px;
    border-radius: 33px;
    background: ${color};
  `}
`
