import React, { useState, useEffect, useCallback } from 'react'
import { FormContext, useForm } from 'react-hook-form'
import { useParams } from 'react-router'
import { useNotification } from 'hooks'

import EditCmponent from './view'
import { changeClientStatus, getRenovationDetailed } from 'services'

export default function EditStatus() {
  const forms = useForm()
  const params = useParams()
  const notification = useNotification()

  const [selected, setSelected] = useState(null)
  const [client, setClient] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [userLoading, setUserLoading] = useState(false)
  const [hasError, setHasError] = useState('')

  const fetchClient = useCallback(async () => {
    setUserLoading(true)
    try {
      const response = await getRenovationDetailed(params.id)

      setClient(response)
    } catch (err) {
      setHasError(err.message)
    } finally {
      setUserLoading(false)
    }
  }, [params.id])

  const handleFilters = (selected) => {
    setSelected(selected)
    return selected
  }

  const handleSubmit = forms.handleSubmit(async ({ status }) => {
    setIsLoading(true)
    try {
      const values = {
        id: params.id,
        data: status.value,
      }

      await changeClientStatus(values)
      await fetchClient()

      notification.open({
        type: 'success',
        content: 'Status da proposta foi alterado com sucesso.',
      })
      forms.setValue('status', '')
      setSelected(null)
    } catch (error) {
      notification.open({
        type: 'danger',
        content: error.message,
      })
    } finally {
      setIsLoading(false)
    }
  })

  useEffect(() => {
    fetchClient()
  }, [fetchClient])

  return (
    <FormContext {...forms}>
      <EditCmponent
        selected={selected}
        handleFilters={handleFilters}
        handleSubmit={handleSubmit}
        isLoading={isLoading}
        userLoading={userLoading}
        client={client}
        hasError={hasError}
      />
    </FormContext>
  )
}
EditStatus.properties = {
  metatags: {
    title: 'Renovação',
  },
}