import { REHYDRATE } from 'redux-persist'

/**
 * @param {string} reducer Reducer name to rehydrate
 */
export function resetLoadingAndErrorState(reducer) {
  return {
    [REHYDRATE]: (state, action) => {
      if (action.payload)
        return {
          ...action.payload[reducer],
          isLoading: false,
          error: '',
        }

      return state
    },
  }
}
