import { darken } from 'polished'
import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'

export const Container = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;

    background: ${theme.colors.white};
    border-radius: ${theme.borderRadius.medium};
    padding: ${theme.spacings.small};
    border: ${theme.border.thin} solid ${theme.colors.newGray[10]};
  `}
`
export const Header = styled.header`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: ${theme.spacings.xxsmall};

    > div {
      display: flex;
      align-items: center;
      gap: ${theme.spacings.xsmall};
    }
  `}
`

export const PublishDate = styled.span`
  ${({ theme }) => css`
    font-size: ${theme.fonts.sizes.xxsmall};
    font-weight: 600;
    color: ${theme.colors.newGray[80]};
  `}
`

export const Content = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: ${theme.spacings.xxsmall};
  `}
`
export const Title = styled(Link)`
  ${({ theme }) => css`
    font-size: ${theme.fonts.sizes.small};
    color: ${theme.colors.primary};
    line-height: 1.5;
    font-weight: 700;

    &:hover {
      color: ${darken('0.08', theme.colors.primary)};
    }
  `}
`

export const Description = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.fonts.sizes.xsmall};
    font-weight: 400;
    color: ${theme.colors.newGray[80]};
    line-height: 1.5;
    word-break: break-word;

    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    overflow: hidden;
    width: 100%;
  `}
`

export const ReadMoreButton = styled(Link)`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    cursor: pointer;

    border: 0;
    background: transparent;

    color: ${theme.colors.primary};
    font-weight: 600;
    font-size: ${theme.fonts.sizes.xsmall};

    > svg {
      color: ${theme.colors.primary};
      width: 18px;
      height: 18px;
    }
  `}
`

export const Message = styled.p`
  ${({ theme }) => css`
    margin-top: ${theme.spacings.medium};

    text-align: center;
    font-size: ${theme.fonts.sizes.small};
    font-family: ${theme.fonts.bold};
    color: ${theme.colors.gray.medium}
  `}
`
export const Loader = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;

  &:before {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    width: 50px;
    height: 50px;

    content: '';
    background-image: url('/images/loader.svg');
    background-size: contain;
  }
`
