import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useFormContext } from 'react-hook-form'

import './types'

import { Container, Input, Pin, Label } from './styles'

/**
 * @param {ChoiceProps} props
 */
export default function Choice({
  name,
  type,
  checked,
  label,
  invertColor,
  ...props
}) {
  const { register, unregister } = useFormContext() || {}

  useEffect(() => {
    return () => unregister(name)
  }, [unregister, name])

  return (
    <Container>
      <Input
        {...props}
        name={name}
        type={type}
        ref={register}
        defaultChecked={checked}
        invertColor={invertColor}
        data-gtm-type="click"
        data-gtm-clicktype="radio"
        data-gtm-name={label.toLowerCase()}
      />

      <Pin />

      <Label>{label}</Label>
    </Container>
  )
}

Choice.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  label: PropTypes.string,
}
