export const updateCustomData = (data) => {
  let { customData = { ...data } } = window

  if (data.page) {
    const oldPage = customData.page

    const page = {
      ...oldPage,
      ...data.page,
    }

    customData.page = page
  }

  if (data.user) {
    const oldUser = customData.user

    const user = {
      ...oldUser,
      ...data.user,
    }

    customData.user = user
  }

  if (!customData.site) {
    customData.site = {
      portal: 'lp',
      versao: '1.2',
      brand: 'porto-seguro',
    }
  }

  window.customData = customData

  return window.customData
}
