import styled, { css } from 'styled-components'
import media from 'styled-media-query'
import { rem } from 'polished'
import { grid, layout, space } from 'styled-system'

export const Container = styled.div`
  padding: 40px 50px;

  ${media.lessThan('large')`
    padding: 20px;
  `}
`

export const Content = styled.div``

export const Box = styled.div`
  padding: 30px;

  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
`

export const Label = styled.p`
  font-size: ${rem('14px')};
  font-family: ${({ theme }) => theme.fonts.bold};
  color: ${({ theme }) => theme.colors.gray.medium};

  ${media.lessThan('large')`
    font-size: ${rem('12px')};
  `}
`

export const Form = styled.form``

export const Fields = styled.div`
  ${grid}
  ${layout}
  ${space}

  display: grid;

  ${({ hidden }) =>
    hidden &&
    css`
      display: none;
    `}
`

export const Actions = styled.div`
    display: flex;
    justify-content: space-between;

  ${media.lessThan('medium')`
    gap: 16px;
    flex-direction: column;
  `}
`

export const Error = styled.p`
  padding-top: 20px;

  font-size: ${rem('13px')};
  color: ${({ theme }) => theme.colors.danger};
  font-family: ${({ theme }) => theme.fonts.bold};

  ${media.lessThan('large')`
    font-size: ${rem('11px')};
  `}
`
