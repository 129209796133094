import React from 'react'
import { Controller } from 'react-hook-form'
import {
  Container,
  Input,
  Checkbox,
  styles,
  Label,
  Check,
  Loading,
  WrapLoading,
} from './styles'

import Select, { components } from 'react-select'

export default function CheckboxSelect({
  options,
  placeholder,
  onChange,
  name,
  disabled,
  selected,
  isLoading,
  multi = false,
  ...props
}) {
  const handleChange = (selected) => {
    if (typeof onChange === 'function') return onChange({ name, selected })

    return selected
  }

  const Option = (props) => {
    return (
      <components.Option {...props}>
        <Checkbox>
          <Input
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
          />
          <Check />
        </Checkbox>
        <label>{props.label}</label>
      </components.Option>
    )
  }

  const MultiValue = (props) => {
    return (
      <components.MultiValue {...props}>
        <span>{props.data.label}</span>
      </components.MultiValue>
    )
  }

  return (
    <Container>
      <Controller
        name={name}
        onChange={([selected]) => handleChange(selected)}
        as={
          <Select
            {...props}
            inputId={name}
            options={options}
            styles={styles}
            hideSelectedOptions={false}
            closeMenuOnSelect={!multi}
            isSearchable={false}
            isClearable={multi}
            isMulti={multi}
            isDisabled={disabled}
            noOptionsMessage={() => 'Nenhuma opção'}
            placeholder={placeholder}
            components={{ Option, MultiValue }}
          />
        }
      />

      {isLoading && (
        <WrapLoading>
          <Loading>
            <div />
            <div />
            <div />
            <div />
          </Loading>
        </WrapLoading>
      )}

      <Label selected={selected} htmlFor={name}>
        {placeholder}
      </Label>
    </Container>
  )
}
