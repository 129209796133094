import styled, { css } from 'styled-components'
import { colors, fonts } from 'styles'

export const Container = styled.div`
  ${({ theme }) => css`
    position: relative;
  `}
`
export const styles = {
  container: (provided) => ({
    ...provided,
    height: '100%',
  }),
  control: (provided) => ({
    ...provided,
    fontFamily: fonts.medium,
    border: `2px solid ${colors.gray.medium}`,
    backgroundColor: 'transparent',
    borderRadius: '8px',
    minHeight: '50px',
    fontSize: '14px',
    height: '100%',
    '@media only screen and (max-width: 1366px)': {
      minHeight: '44px',
    },
    '&:hover': {
      borderColor: colors.gray.medium,
    },
  }),
  placeholder: (provided) => ({
    ...provided,
    fontSize: '12px',
  }),
  option: (provided) => ({
    ...provided,
    fontSize: '12px',
    fontFamily: fonts.medium,
  }),
}
