import styled, { css } from 'styled-components'

export const TableContainer = styled.div`
  ${({ theme }) => css`
    border-radius: ${theme.borderRadius.medium};
    border: ${theme.border.hairline} solid rgba(0, 0, 0, 0.2);
    overflow: auto;
  `}
`

export const Table = styled.table`
  ${({ theme }) => css`
    border-collapse: collapse;
    text-align: center;

    tbody tr:last-child td:first-child {
      border-bottom-left-radius: ${theme.borderRadius.medium};
    }

    tbody tr:last-child td:last-child {
      border-bottom-right-radius: ${theme.borderRadius.medium};
    }
  `}
`

export const Thead = styled.thead``

export const Tr = styled.tr`
  ${({ theme }) => css`
    &:not(:last-child) {
      border-bottom: ${theme.border.hairline} solid rgba(0, 0, 0, 0.2);
    }
  `}
`

export const Th = styled.th`
  ${({ theme }) => css`
    padding: ${theme.spacings.xsmall};

    font-family: ${theme.fonts.bold};
    background: ${theme.colors.side};
    color: ${theme.colors.white};

    &:not(:last-child) {
      border-right: ${theme.border.hairline} solid rgba(0, 0, 0, 0.2);
    }

    &:first-child {
      border-top-left-radius: ${theme.borderRadius.medium};
    }

    &:last-child {
      border-top-right-radius: ${theme.borderRadius.medium};
    }
  `}
`
export const Tbody = styled.tbody``

export const Td = styled.td`
  ${({ theme }) => css`
    background-color: ${theme.colors.white};
    color: ${theme.colors.gray.dark};
    font-family: ${theme.fonts.bold};
    font-size: ${theme.fonts.sizes.xsmall};

    &:not(:last-child) {
      border-right: ${theme.border.hairline} solid rgba(0, 0, 0, 0.2);
    }

    &:first-child {
      text-align: left;
    }
    padding: ${theme.spacings.xsmall};
  `}
`

export const Message = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.white};
    font-weight: 600;
    font-size: ${theme.fonts.sizes.xsmall};
    line-height: 18px;
    width: 70vw;
  `}
`