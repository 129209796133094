export const links = [
  {
    path: 'https://www.consumidor.gov.br/pages/principal/?1590168868276',
    text: 'Procon',
  },
  {
    path: '/files/termos/termos.pdf',
    text: 'Termos de uso',
  },
  {
    path: 'https://www.portoseguro.com.br/politica-de-privacidade',
    text: 'Políticas de Privacidade',
  },
]
