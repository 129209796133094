import styled from 'styled-components'

export const Loading = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  position: relative;

  div {
    width: ${({ size }) => size};
    height: ${({ size }) => size};
    background: ${({ color }) => color};
    border-radius: 50%;
    animation: dots 1s infinite linear;
  }

  div:nth-child(1) {
    animation-delay: 0s;
  }
  div:nth-child(2) {
    animation-delay: 0.2s;
  }
  div:nth-child(3) {
    animation-delay: 0.5s;
  }
  div:nth-child(4) {
    animation-delay: 0.8s;
  }

  @keyframes dots {
    0% {
      transform: translateY(3px);
    }
    50% {
      transform: translateY(-3px);
    }
    100% {
      transform: translateY(3px);
    }
  }
`
