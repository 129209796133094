import * as yup from 'yup'

import { validatePhone } from 'utils'

const schema = yup.object().shape({
  name: yup
    .string()
    .required('Por favor, insira o nome')
    .trim()
    .transform((curr, orig) => (orig === '' ? undefined : curr))
    .notOneOf([''], 'Por favor, insira o nome'),

  email: yup
    .string()
    .trim()
    .required('Por favor, insira o email')
    .transform((curr, orig) => (orig === '' ? undefined : curr))
    .notOneOf([''], 'Por favor, insira o email')
    .email('Por favor, insira um e-mail válido'),
  cellphone: yup
    .string()
    .required('Por favor insira o celular')
    .test('validateQuantity', 'Por favor insira um celular válido', (value) =>
      validatePhone(value)
    ),
  password: yup.object().shape(
    {
      old: yup
        .string()
        .trim()
        .when('new', {
          is: (value) => !!value,
          then: yup.string().required('Por favor, insira a senha antiga'),
        })
        .transform((curr, orig) => (orig === '' ? undefined : curr))
        .notOneOf([''], 'Por favor, insira a senha antiga')
        .min(6, 'Por favor, a senha deve conter no mínimo 6 caracteres'),
      new: yup
        .string()
        .when('old', {
          is: (value) => !!value,
          then: yup
            .string()
            .required('Por favor, insira a nova senha')
            .notOneOf(
              [yup.ref('old')],
              'Por favor, insira uma senha diferente de sua senha antiga'
            ),
        })
        .transform((curr, orig) => (orig === '' ? undefined : curr))
        .min(6, 'Por favor, a senha deve conter no mínimo 6 caracteres'),
      confirmation: yup
        .string()
        .when('new', {
          is: (value) => !!value,
          then: yup.string().required('Por favor, confirme sua nova senha'),
        })
        .transform((curr, orig) => (orig === '' ? undefined : curr))
        .min(6, 'Por favor, a senha deve conter no mínimo 6 caracteres')
        .oneOf([yup.ref('new')], 'As senhas não conferem'),
    },
    ['new', 'old']
  ),
})

export default schema
