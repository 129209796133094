import styled, { css } from 'styled-components'
import media from 'styled-media-query'
import { rem } from 'polished'

export const Container = styled.div`
  margin-top: 50px;

  display: flex;
  align-items: center;
  justify-content: center;

  ${media.lessThan('large')`
    margin-top: 30px
  `}
`

export const Item = styled.button.attrs((props) => ({
  disabled: props.disabled,
}))`
  all: unset;

  cursor: pointer;
  font-size: ${rem('13px')};
  font-family: ${({ theme }) => theme.fonts.bold};
  color: ${({ theme }) => theme.colors.gray.medium};

  &:not(:last-of-type) {
    margin-right: 20px;
  }

  ${({ first }) =>
    first &&
    css`
      width: 8px;
      height: 8px;

      display: inline-block;

      opacity: 0.4;
      transition: 0.3s;
      background-image: url('/images/icons/double-left-arrow.svg');
      background-size: contain;

      &:hover {
        opacity: 1;
      }
    `}

  ${({ current }) =>
    current &&
    css`
      width: 30px;
      height: 30px;

      display: flex;
      align-items: center;
      justify-content: center;

      color: #fff;
      border-radius: 100%;
      background-color: ${({ theme }) => theme.colors.primary};
    `}

${({ action }) =>
    action &&
    css`
      transition: 0.3s;

      &:disabled {
        opacity: 0.5;

        &:hover {
          cursor: not-allowed;
          color: ${({ theme }) => theme.colors.gray.light};
        }
      }

      &:hover {
        transition: 0.3s;
        color: ${({ theme }) => theme.colors.primary};
      }
    `}
`
