export const isValidCPF = (cpf) => {
  if (typeof cpf !== 'string') return false
  cpf = cpf.replace(/[^\d]+/g, '')
  if (cpf.length !== 11 || !!cpf.match(/(\d)\1{10}/)) return false

  const newCpf = cpf.split('')

  const validator = newCpf
    .filter((digit, index, array) => index >= array.length - 2 && digit)
    .map((el) => +el)

  const toValidate = (pop) =>
    newCpf
      .filter((digit, index, array) => index < array.length - pop && digit)
      .map((el) => +el)

  const rest = (count, pop) =>
    ((toValidate(pop).reduce((soma, el, i) => soma + el * (count - i), 0) *
      10) %
      11) %
    10

  return !(rest(10, 2) !== validator[0] || rest(11, 1) !== validator[1])
}

export const formatCPF = (cpf) => {
  if (cpf.length < 11) {
    return cpf
  }

  let value = cpf
    .replace(/\D/g, '')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1')

  return value
}
