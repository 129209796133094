import styled from 'styled-components'
import media from 'styled-media-query'
import { rgba } from 'polished'

export const Container = styled.div`
  ${media.greaterThan('large')`
    display: flex;
    justify-content: space-between;
  `}
`

export const Content = styled.div`
  padding: 40px 50px;

  flex: 1;

  ${media.lessThan('large')`
    padding: 20px;
  `}
`

export const Dialog = styled.div`
  padding: 30px;
  margin-bottom: 20px;

  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
`

export const Label = styled.p`
  color: ${({ theme }) => theme.colors.gray.medium};
  font-family: ${({ theme }) => theme.fonts.bold};
  font-size: 14px;
`

export const Loader = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: ${({ theme }) => theme.layers.modal};

  content: '';
  background-color: ${rgba('#000', 0.8)};

  &:before {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    width: 50px;
    height: 50px;

    content: '';
    background-image: url('/images/loader.svg');
    background-size: contain;
  }
`
