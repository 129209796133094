import { useHistory, useLocation } from 'react-router-dom'
import qs from 'qs'

export function useParams() {
  const location = useLocation()
  const history = useHistory()

  const indicatorsParams = new URLSearchParams(location.search)

  const params = qs.parse(indicatorsParams.toString(), {
    ignoreQueryPrefix: true,
  })

  const setParams = (params) => {
    Object.keys(params).forEach((key) => {
      if (!params[key]) {
        indicatorsParams.delete(key)

        history.replace({
          pathname: location.pathname,
          search: indicatorsParams.toString(),
        })

        return
      }

      indicatorsParams.set(key, params[key])
    })

    history.push({
      pathname: location.pathname,
      search: indicatorsParams.toString(),
    })
  }

  return { params, setParams }
}
