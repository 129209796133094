import React from 'react'

import { Flex } from 'components'

import { Infos, Sitemap, Bottom } from './components'
import { Container, Content, Wrapper } from './styles'

export default function Footer() {
  return (
    <Container>
      <Content>
        <Wrapper>
          <Infos />
          <Sitemap />
        </Wrapper>
        <Flex pl="15px">
          <button
            id="ot-sdk-btn"
            className="ot-sdk-show-settings"
            data-gtm-type="click"
            data-gtm-clicktype="button"
            data-gtm-name="configurações dos cookies"
          >
            Configurações dos cookies
          </button>
        </Flex>
      </Content>
      <Bottom />
    </Container>
  )
}
