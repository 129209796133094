import styled from 'styled-components'
import media from 'styled-media-query'
import { rem } from 'polished'

export const Container = styled.div`
  padding: 40px 50px;

  ${media.lessThan('large')`
    padding: 20px;
  `}
`

export const Head = styled.div`
  padding-bottom: 40px;

  ${media.lessThan('large')`
    padding-bottom: 20px;

    button {
      width: 100%;
    }
  `}
`

export const Error = styled.p`
  font-size: ${rem('13px')};
  color: ${({ theme }) => theme.colors.danger};
  font-family: ${({ theme }) => theme.fonts.bold};

  ${media.lessThan('large')`
    font-size: ${rem('11px')};
  `}
`

export const Overflow = styled.div`
  ${media.lessThan('large')`
    overflow-x: auto;

    > table {
      width: 900px;
    }
  `}
`

export const Content = styled.div``
