import styled from 'styled-components'
import media from 'styled-media-query'

export const Container = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const Title = styled.h1`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 50px;
`

export const Subtitle = styled.p`
  color: ${({ theme }) => theme.colors.info};
  font-size: 18px;
`

export const ErrorMessage = styled.p`
  padding-top: 20px;

  font-size: 13px;
  color: ${({ theme }) => theme.colors.danger};
  font-family: ${({ theme }) => theme.fonts.bold};

  ${media.lessThan('large')`
    font-size: 11px;
  `}
`
