import React, { useEffect } from 'react'

import { Dialog } from '../common'

import { Grid, Input } from 'components'
import { handleBrlValue } from 'utils'

export default function Vehicle({ car, forms }) {
  useEffect(() => {
    forms.setValue([
      { 'vehicle.category': car?.category_code },
      { 'vehicle.model': car?.version },
      { 'vehicle.chassi': car?.chassi },
      { 'vehicle.model_year': car?.model_year },
      { 'vehicle.plate': car?.plate },
      { 'vehicle.color': car?.color },
      {
        'vehicle.installment_value_formated': handleBrlValue(
          car?.installment_value
        ),
      },
      { 'vehicle.franchise': car?.franchise },
      {
        'vehicle.additional_mileage_formated': handleBrlValue(
          car?.additional_mileage_value
        ),
      },
      { 'vehicle.mileage': car?.mileage },
    ])
  }, [car]) // eslint-disable-line

  return (
    <Dialog title="3. Veículo">
      <Grid
        my={1}
        gridTemplateColumns={['1fr', '1fr 1fr', '1fr 1fr 1fr']}
        gridGap="16px"
        data-testid="bloco-veiculo"
      >
        <Input
          name="vehicle.category"
          placeholder="Categoria"
          label="Categoria"
          disabled
        />
        <Input
          name="vehicle.model"
          placeholder="Modelo"
          label="Modelo"
          disabled
        />
        <Input
          name="vehicle.chassi"
          placeholder="Chassi"
          label="Chassi"
          disabled
        />

        <Input
          name="vehicle.model_year"
          placeholder="Ano/Modelo"
          label="Ano/Modelo"
          mask="####/####"
          disabled
        />
        <Input
          name="vehicle.plate"
          placeholder="Placa"
          label="Placa"
          disabled
        />
        <Input name="vehicle.color" placeholder="Cor" label="Cor" disabled />

        <Input
          name="vehicle.installment_value_formated"
          placeholder="Parcela"
          label="Parcela"
          disabled
        />
        <Input
          name="vehicle.franchise"
          placeholder="Franquia/Coparticipação do Cliente"
          label="Franquia/Coparticipação do Cliente"
          disabled
        />
        <Input
          name="vehicle.additional_mileage_formated"
          placeholder="KM Adicional"
          label="KM Adicional"
          disabled
        />
        <Input
          name="vehicle.mileage"
          placeholder="Pacote KM"
          label="Pacote KM"
          disabled
        />
      </Grid>
    </Dialog>
  )
}
