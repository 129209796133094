import styled, { css } from 'styled-components'

export const Container = styled.ul`
  ${({ theme }) => css`
    width: 100%;
    display: flex;
    flex-direction: column;

    background: ${theme.colors.white};
    border-radius: ${theme.borderRadius.small};

    border: ${theme.border.thin} solid ${theme.colors.newGray[10]};

    padding: ${theme.spacings.xsmall};
    list-style: none;
  `}
`

export const Row = styled.li`
  ${({ theme, color }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;


    font-weight: 600;

    font-size: ${theme.fonts.sizes.xxsmall};
    line-height: 17px;

    width: 100%;

    color: ${theme.colors.newGray[90]};
    ${!!color &&
    css`
      color: ${theme.colors.primary};
    `}

    border-bottom: ${theme.border.thin} solid ${theme.colors.newGray[20]};
    padding: ${theme.spacings.xxsmall} 0;
  `}
`

export const Footer = styled.footer`
  ${({ theme }) => css`
    padding: ${theme.spacings.xxsmall} 0;

    display: flex;
    align-items: center;
    justify-content: space-between;
  `}
`

export const Alert = styled.div`
  ${({ theme }) => css`
    position: fixed;
    z-index: ${theme.layers.aside};

    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    padding: ${theme.spacings.xxsmall} 0;
    width: 130px;

    font-weight: 600;

    font-size: ${theme.fonts.sizes.xxsmall};

    line-height: 1.5;
    opacity: 0.95;

    color: ${theme.colors.white};
    background: ${theme.colors.newGray[30]};
    border-radius: ${theme.borderRadius.medium};
    text-align: center;
  `}
`

export const IconWrapper = styled.div`
  ${({ theme }) => css`
    > svg {
      cursor: pointer;
      color: ${theme.colors.primary};
    }
  `}
`

export const DropdownItem = styled.div`
  ${({ theme, disabled }) => css`
    display: flex;
    align-items: center;
    gap: ${theme.spacings.xxsmall};

    cursor: pointer;

    > p {
      font-weight: 600;
      font-size: ${theme.fonts.sizes.xxxsmall};
      line-height: 1.5;
      color: ${theme.colors.white};
    }

    ${disabled &&
    css`
      cursor: not-allowed;

      > p {
        font-weight: 600;

        font-size: ${theme.fonts.sizes.xxxsmall};
        line-height: 1.5;
        color: ${theme.colors.newGray[50]};
      }

      > svg {
        cursor: not-allowed;
      }
    `}
  `}
`

export const Message = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.newGray[90]};
    font-weight: 600;
    font-size: ${theme.fonts.sizes.xsmall};
    line-height: 18px;
  `}
`
