import styled, { css } from 'styled-components'
import media from 'styled-media-query'

export const Bottom = styled.div`
  margin-bottom: 60px;
  margin-top: 50px;

  ${media.lessThan('large')`
    margin-bottom: 30px;
    margin-top: 20px;
  `}
`

export const ModalTextContent = styled.p`
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.gray.davys};

  margin-bottom: 1rem;
`

export const ReanalysisContent = styled.div`
  margin: 2.5rem 0;
`
export const Animate = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ shouldAnimate }) =>
    shouldAnimate &&
    css`
      animation: loading 2s linear infinite;

      @keyframes loading {
        0% {
          transform: rotate(0);
        }
        100% {
          transform: rotate(360deg);
        }
      }
    `}
`

export const ReanalysisDescription = styled.span`
  margin-left: 0.85rem;
  font-weight: bold;
  font-size: 16px;
  line-height: 35px;

  color: ${({ textColor }) => textColor};
`
