import styled from 'styled-components'
import media from 'styled-media-query'

export const Container = styled.footer`
  position: relative;
`

export const Content = styled.div`
  background-color: #fafafa;
`

export const Box = styled.div``

export const Wrapper = styled.div`
  padding: 4rem 1rem 2rem;

  display: grid;
  grid-template-columns: 1.5fr 1fr;

  ${media.lessThan('small')`
    padding: 3rem 15px 1rem;
    
    display: block;
  `}
`
