import styled from 'styled-components'
import media from 'styled-media-query'
import { rem } from 'polished'

export const Actions = styled.div`
  display: flex;
  justify-content: space-between;

  ${media.lessThan('medium')`
    gap: 16px;
    flex-direction: column;
  `}
`

export const ErrorMessage = styled.p`
  padding-top: 20px;

  font-size: ${rem('13px')};
  color: ${({ theme }) => theme.colors.danger};
  font-family: ${({ theme }) => theme.fonts.bold};

  ${media.lessThan('large')`
    font-size: ${rem('11px')};
  `}
`
