import { darken } from 'polished'
import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'
import media from 'styled-media-query'

export const Container = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    width: 100%;

    ${media.greaterThan('medium')`
      background: ${theme.colors.white};
      border: ${theme.border.thin} solid ${theme.colors.newGray[10]};
      padding: ${theme.spacings.small};
    `}

    border-radius: ${theme.borderRadius.medium};
  `}
`

export const Header = styled.header`
  ${({ theme }) => css`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    > div {
      display: flex;
      align-items: center;
      gap: ${theme.spacings.xsmall};
    }
  `}
`

export const PublishDate = styled.span`
  ${({ theme }) => css`
    font-size: ${theme.fonts.sizes.xsmall};
    font-weight: 600;
    color: ${theme.colors.newGray[80]};
  `}
`

export const Content = styled.div`
  ${({ theme }) => css`
    position: relative;
    display: grid;
    grid-template-areas:
      'title'
      'subtitle'
      'image'
      'description';

    gap: ${theme.spacings.xxsmall};

    ${media.lessThan('medium')` 
      grid-template-areas:
      'image'
      'title'
      'subtitle';
    `}
  `}
`
export const Title = styled(Link)`
  ${({ theme }) => css`
    grid-area: title;

    font-size: ${theme.fonts.sizes.xlarge};

    color: ${theme.colors.primary};
    line-height: 1.5;
    font-weight: 700;

    &:hover {
      color: ${darken('0.08', theme.colors.primary)};
    }

    ${media.lessThan('medium')`
      font-size: ${theme.fonts.sizes.small};
    `}
  `}
`

export const Subtitle = styled.strong`
  ${({ theme }) => css`
    grid-area: subtitle;

    font-size: ${theme.fonts.sizes.small};
    color: ${theme.colors.black};
    line-height: 1.5;

    ${media.lessThan('medium')`
      font-size: ${theme.fonts.sizes.xsmall};
    `}
  `}
`

export const Image = styled.img`
  ${({ theme }) => css`
    grid-area: image;
    border-radius: ${theme.borderRadius.medium};
    width: 100%;
    height: 200px;

    ${media.lessThan('medium')`
      height: 150px;
    `}

    object-fit: cover;
  `}
`
export const Description = styled.p`
  ${({ theme }) => css`
    grid-area: description;

    font-size: ${theme.fonts.sizes.small};
    color: ${theme.colors.newGray[80]};
    font-weight: 400;
    line-height: 1.5;
    word-break: break-word;

    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    display: -webkit-box;
    overflow: hidden;
    width: 80%;
  `}
`
