export function toOptions(array, value, label) {
  const options = array?.map((item) => {
    return {
      value: item[value],
      label: item[label],
    }
  })

  return options
}
