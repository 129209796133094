import { useMutation, useQuery } from 'react-query'
import {
  createLandingPageBroker,
  getBrokerContactDetails,
} from 'services/api/brokers'

export function useGetBrokerLandingPage(susep, options) {
  return useQuery(
    ['brokerLandingPage', susep],
    () => getBrokerContactDetails(susep),
    {
      staleTime: 60 * 1000, //60 seconds
      refetchOnWindowFocus: false,
      retry: false,
      ...options,
    }
  )
}

export function useCreateLandingPage(options) {
  return useMutation((formData) => createLandingPageBroker(formData), {
    ...options,
  })
}
