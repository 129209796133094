import styled, { css } from 'styled-components'
import { rem } from 'polished'

export const Container = styled.div`
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  background: #fff;
  border-radius: 5px;
  margin-bottom: 32px;
`
export const Title = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: ${rem('30px')};

  span {
    color: ${({ theme }) => theme.colors.gray.jet};
    font-family: ${({ theme }) => theme.fonts.bold};
    font-size: 16px;
    line-height: 20px;
  }
`

export const Arrow = styled.div`
  border: solid ${({ theme }) => theme.colors.gray.jet};
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 5px;

  transform: rotate(45deg);
  transition: transform 1s ease;

  ${({ isOpen }) =>
    isOpen &&
    css`
      transform: rotate(225deg);
    `}
`

export const Content = styled.div`
  ${({ isOpen }) => css`
    overflow: hidden;
    max-height: 0;
    opacity: 0;
    transition: max-height 0.7s ease, opacity 0.9s ease;

    ${isOpen &&
    css`
      max-height: 1330px;
      opacity: 1;
    `}
  `}
`

export const Wrap = styled.div`
  ${({ theme }) => css`
    margin: ${theme.spacings.medium} auto;
    max-width: 1100px;
    display: grid;
    gap: ${theme.spacings.medium};
    grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
    justify-content: center;

    padding: ${theme.spacings.small};
  `}
`

export const WrappLoading = styled.div`
  margin: ${rem('45px')} 0;

  display: flex;
  justify-content: center;
  align-items: center;
`
