import React, { useState, useEffect, useMemo, useRef } from 'react'
import { format, parse, parseISO, subDays } from 'date-fns'
import { FormContext, useForm } from 'react-hook-form'
import { useHistory, useLocation } from 'react-router-dom'
import { saveAs } from 'file-saver'
import ReactTooltip from 'react-tooltip'

import { debounce } from 'lodash'

import { sendMessageToCustomer, fetchOrdersReport } from 'services'

import { useNotification, useCurrentUser } from 'hooks'
import { USER_ROLES, CUSTOMERS_TABLE_HEADER, UNIFIED_STATUSES } from 'helpers'
import { useGetAllOrders, useGetStatus } from 'services/hooks/customers'

import {
  encryptTextWithKey,
  removeSpecialCharacters,
  formatPtBRDate,
  setCpfMask,
} from 'utils'

import {
  Table,
  Button,
  Input,
  Allow,
  Pagination,
  Icon,
  MediaMatch,
  DateRangePicker,
  Drawer,
  Status,
} from 'components'

import {
  StatusFilter,
  DrawerContent,
  CustomerCard,
  Actions,
} from './components'

import * as S from './styles'
import { useParams } from 'hooks'

const INDICATORS_ROUTE = '/indicadores'
export default function List() {
  const dateNow = new Date()
  const lastWeek = subDays(dateNow, 7)
  const location = useLocation()
  const isNumber = new RegExp('^[0-9]+$')

  const contentRef = useRef()
  const desktopRef = useRef()
  const mobileRef = useRef()
  const { params, setParams } = useParams()

  const [selectedStatus, setSelectedStatus] = useState('')

  const [isOpen, setIsOpen] = useState(false)

  const customersParams = new URLSearchParams(location.search)

  const [pagination, setPagination] = useState({
    limit: 25,
    end: false,
  })

  const [report, setReport] = useState({
    loading: false,
  })

  const dataLayer = window.dataLayer || []

  const form = useForm()
  const history = useHistory()
  const user = useCurrentUser()
  const notification = useNotification()

  const { data: status, isLoading: isLoadingStatus } = useGetStatus()

  const requestParams = {
    start: params.start,
    end: params.end,
    status: params.status,
    limit: pagination.limit,
    page: params.page,
    search_term: params.search_term,
  }

  const {
    data: orders,
    isLoading: isLoadingOrders,
    error,
    isError,
  } = useGetAllOrders(requestParams, {
    onSuccess: (data) => {
      const end = data.pagination.total_pages === data.pagination.current_page

      const search = Object.keys(params).join(',')

      dataLayer.push({
        event: 'resultado_consulta',
        tipo_busca: search,
        status: params.status || '',
        termo_consulta: encryptTextWithKey(params.document, 'chave') || '',
        data_inicial: params.start,
        data_final: params.end,
        retorno: 'sucesso',
        descricao: 'Busca retornada com sucesso',
      })

      dataLayer.push({
        event: 'solicitacao_servico',
        nome_servico: `periodo:${params.start}/${params.end}`,
        tipo_servico: 'aplicar',
        retorno: 'sucesso',
        susep: user[user.role]?.susep || '',
        descricao: 'filtro de período retornado com sucesso',
      })

      dataLayer.push({
        event: 'resultado_busca',
        tipo_servico: 'aplicar',
        retorno: 'sucesso',
        susep: user[user.role]?.susep || '',
        sucursal: user[user.role]?.branch || '',
        descricao: 'busca retornada com sucesso',
      })

      setPagination((state) => ({ ...state, end }))
    },
    onError: (error) => {
      dataLayer.push({
        event: 'solicitacao_servico',
        nome_servico: `periodo:${params.start}/${params.end}`,
        tipo_servico: 'aplicar',
        retorno: 'erro',
        susep: user[user.role]?.susep || '',
        descricao: 'erro ao encontrar as orders',
        erro: {
          codigo: error.status,
          servico: 'listagem de orders',
          mensagem: error.message,
        },
      })

      dataLayer.push({
        event: 'resultado_busca',
        tipo_servico: 'aplicar',
        retorno: 'sucesso',
        susep: user[user.role]?.susep || '',
        sucursal: user[user.role]?.branch || '',
        descricao: 'erro ao encontrar as orders',
        erro: {
          codigo: error.status,
          servico: 'listagem de orders',
          mensagem: error.message,
        },
      })
    },
  })

  const customers = useMemo(() => {
    if (orders?.data && status) {
      return orders?.data?.map((order) => {
        const disableEndRegistrationButton = !(
          status[order.status]?.order < status.approved?.order
        )

        const orderStatus = UNIFIED_STATUSES[order.status] || order.status

        return {
          ...order,
          codeStatus: order.status,
          disableEndRegistrationButton,
          susepNumber: order.susep,
          susep: () => (
            <S.SusepTip data-tip data-for={order.id}>
              {order.susep}
              <ReactTooltip
                id={order.id}
                effect="solid"
                place="top"
                className="susepTooltip"
              >
                Representante: {order.representative}
              </ReactTooltip>
            </S.SusepTip>
          ),
          status: () => (
            <Status
              backgroundColor={status[orderStatus]?.bgColor}
              textColor={status[orderStatus]?.textColor}
            >
              {status[orderStatus]?.label}
            </Status>
          ),
          actions: () => (
            <Actions
              order={order}
              onMessageToCustomer={handleMessageToCustomer}
              disableEndRegistrationButton={disableEndRegistrationButton}
            />
          ),
        }
      })
    }

    return []
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orders?.data, status])

  const onInputChange = debounce((value) => {
    const valueFormatted = removeSpecialCharacters(value)

    if (isNumber.test(valueFormatted)) {
      return valueFormatted.length === 11
        ? setParams({ search_term: valueFormatted })
        : form.setError('search_term', 'notMatch', 'Verifique o CPF')
    }

    if (!isNumber.test(valueFormatted) && valueFormatted.length > 0) {
      setParams({ search_term: value.toLowerCase() })
      return
    }

    setParams({ search_term: null })
  }, 1000)

  const handlePeriodFilter = form.handleSubmit((values) => {
    if (values.period) {
      const [startDate, endDate] = values.period.split('-')

      const period = {
        start: parse(startDate, 'dd/MM/yyyy', new Date()),
        end: parse(endDate.trim(), 'dd/MM/yyyy', new Date()),
      }

      const start = format(period.start, 'yyyy-MM-dd')
      const end = format(period.end, 'yyyy-MM-dd')

      setParams({ start, end })
    } else {
      customersParams.delete('start')
      customersParams.delete('end')

      history.replace({
        pathname: location.pathname,
        search: customersParams.toString(),
      })
    }
  })

  function handlePagination(type) {
    desktopRef.current.scrollTo({ top: 0, behavior: 'smooth' })
    contentRef.current.scrollTo({ top: 0, behavior: 'smooth' })
    mobileRef.current.scrollTo({ top: 0, behavior: 'smooth' })

    const pagination = {
      first: 1,
      prev: Number(params.page) - 1,
      next: Number(params.page) + 1,
    }

    window.scrollTo({
      top: 250,
    })

    setParams({ page: pagination[type] })
  }

  const handleMessageToCustomer = async ({ id, name }) => {
    try {
      notification.open({
        type: 'warning',
        content: 'Aguarde, o link está sendo enviado.',
      })

      await sendMessageToCustomer({
        order: {
          id,
        },
      })

      notification.open({
        type: 'success',
        content: `Link enviado para o e-mail de ${name} com sucesso!`,
      })

      dataLayer.push({
        event: 'solicitacao_servico',
        nome_servico: 'email-cadastrado',
        tipo_servico: 'enviar-link-web',
        susep: user[user.role]?.susep || '',
        sucursal: user[user.role]?.branch || '',
        retorno: 'sucesso',
        descricao: 'link enviado para o e-mail',
      })
    } catch (error) {
      notification.open({
        type: 'danger',
        content: 'Ocorreu um erro ao enviar o link.',
      })

      dataLayer.push({
        event: 'solicitacao_servico',
        nome_servico: 'email-cadastrado',
        tipo_servico: 'enviar-link-web',
        susep: user[user.role]?.susep || '',
        sucursal: user[user.role]?.branch || '',
        retorno: 'erro',
        descricao: 'ocorreu um erro ao enviar o link.',
        erro: {
          servico: 'enviar link web',
          codigo: error.status,
          mensagem: error.message,
        },
      })
    }
  }

  const handleOrdersReport = async () => {
    try {
      setReport({
        loading: true,
      })

      const query = {
        ...params,
        start: params.start
          ? parse(params.start, 'yyyy-MM-dd', new Date())
          : null,
        end: params?.end ? parse(params?.end, 'yyyy-MM-dd', new Date()) : null,
      }

      const report = await fetchOrdersReport(query)

      saveAs(report, `${user.infos.name} - Clientes.csv`)
    } catch (error) {
      notification.open({
        type: 'danger',
        content: error.message,
      })
    } finally {
      setReport({
        loading: false,
      })
    }
  }

  const handleOpenDrawer = (status) => {
    setSelectedStatus(status)
    setIsOpen(true)
  }

  const handleCloseDrawer = () => {
    setIsOpen(false)
  }

  useEffect(() => {
    const queryParams = {}

    if (params?.start && params?.end) {
      const initDate = parse(params.start, 'yyyy-MM-dd', new Date())
      const endDate = parse(params.end, 'yyyy-MM-dd', new Date())

      const formattedStartDate = formatPtBRDate(initDate)
      const formattedEndDate = formatPtBRDate(endDate)

      queryParams.start = params.start
      queryParams.end = params.end

      form.setValue('period', `${formattedStartDate} - ${formattedEndDate}`)
    }
    if (
      !params?.start &&
      !params?.end &&
      location?.state?.from !== INDICATORS_ROUTE
    ) {
      const formattedStartDate = formatPtBRDate(lastWeek)
      const formattedEndDate = formatPtBRDate(dateNow)

      queryParams.start = format(lastWeek, 'yyyy-MM-dd')
      queryParams.end = format(dateNow, 'yyyy-MM-dd')

      form.setValue('period', `${formattedStartDate} - ${formattedEndDate}`)
    }

    if (params?.search_term) {
      form.setValue(
        'search_term',
        isNumber.test(params.search_term)
          ? setCpfMask(params.search_term.replace(/[^A-Za-z0-9]+/g, ''))
          : params.search_term
      )
      queryParams.search_term = params.search_term
    }

    if (params?.page) {
      queryParams.page = params.page
    } else {
      queryParams.page = 1
    }

    setParams(queryParams)

    dataLayer.push({
      event: 'step_change',
      etapa: '/clientes',
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <S.Container>
      <StatusFilter />
      <S.Content ref={contentRef}>
        <MediaMatch greaterThan="xlarge">
          <S.Title>Clientes</S.Title>
        </MediaMatch>

        <S.Overflow ref={desktopRef}>
          <S.Filters data-gtm-type="form" data-gtm-name="buscar-por">
            <FormContext {...form}>
              <Input
                name="search_term"
                label="Nome, CPF ou E-mail"
                placeholder="Busque por Nome, CPF ou E-mail"
                onChange={(e) => onInputChange(e.target.value)}
                rightIcon={<Icon name="search" />}
                gtmName="busca-nome-cpf-email"
              />

              <DateRangePicker
                name="period"
                placeholder="Selecionar período"
                label="Período selecionado"
                onClick={handlePeriodFilter}
                initialState={() => {
                  const start = params.start ? parseISO(params.start) : dateNow
                  const end = params.end ? parseISO(params.end) : lastWeek

                  return {
                    startDate: start,
                    endDate: end,
                    key: 'selection',
                  }
                }}
              />

              <Allow roles={[USER_ROLES.ADMINISTRATOR, USER_ROLES.ADVISER]}>
                <Button
                  variant="outline"
                  onClick={handleOrdersReport}
                  isLoading={report.loading}
                  disabled={!customers.length}
                >
                  Gerar relatório
                </Button>
              </Allow>

              {/* <Button
                testid="renovation-search-button"
                color="primary"
                onClick={() => history.push('/pedidos/novo')}
                gtmName="novo cliente"
              >
                <S.WrapperIconButton>
                  <Icon name="plus" size="18px" />
                  <span>Novo cliente</span>
                </S.WrapperIconButton>
              </Button> */}
            </FormContext>
          </S.Filters>

          <S.OverflowMobile ref={mobileRef}>
            {isError ? (
              <S.Error>{error.message}</S.Error>
            ) : (
              <>
                <MediaMatch greaterThan="medium">
                  <Table
                    isLoading={isLoadingStatus || isLoadingOrders}
                    columns={CUSTOMERS_TABLE_HEADER}
                    data={customers}
                  />
                </MediaMatch>
                <MediaMatch lessThan="medium">
                  <S.CardContainer>
                    <CustomerCard
                      data={customers}
                      isLoading={isLoadingStatus || isLoadingOrders}
                      onOpenDrawer={handleOpenDrawer}
                      onMessageToCustomer={handleMessageToCustomer}
                    />
                  </S.CardContainer>
                </MediaMatch>
                {!!customers.length && !isLoadingOrders && (
                  <Pagination
                    handlePagination={handlePagination}
                    pagination={{ ...pagination, page: Number(params.page) }}
                  />
                )}
              </>
            )}
          </S.OverflowMobile>
        </S.Overflow>
      </S.Content>

      <Drawer
        isOpen={isOpen}
        handleCloseDrawer={handleCloseDrawer}
        backgroundColor="#323F43"
      >
        <DrawerContent status={status?.[selectedStatus]} />
      </Drawer>
    </S.Container>
  )
}

List.properties = {
  metatags: {
    title: 'Clientes',
  },
}
