const colors = {
  primary: '#0027F4',
  newPrimary: '#0019FF',
  secondary: '#00E0FF',
  info: '#00689A',
  side: '#0084FF',
  success: '#1EC83D',
  warning: '#FFCD00',
  danger: '#EA2A00',
  dangerDark: '#D34141',
  orange: '#F97D00',
  green: '#018F11',
  white: '#FFFFFF',
  gray: {
    dark: '#232323',
    medium: '#737373',
    light: '#C7C7C7',
    davys: '#555555',
    crayola: '#323F43',
    jet: '#393939',
    neutral: '#1B2A2E',
  },
  newGray: {
    100: '#1B2A2E',
    90: '#323F43',
    80: '#485558',
    60: '#767F82',
    50: '#8C9497',
    40: '#A4AAAC',
    30: '#BABFC0',
    20: '#D1D4D5',
    10: '#EFF0F0',
    5: '#F4F4F5',
  },
  black: '#000000',
}
export default colors
