import styled from 'styled-components'

export const Button = styled.button`
  width: 48px;
  height: 48px;

  display: flex;
  justify-content: center;
  align-items: center;

  background: ${({ theme }) => theme.colors.primary};
  color: #fff;
  border-radius: 50%;
  border: none;
  cursor: pointer;
`

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`
