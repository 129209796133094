import React from 'react'

import { Alert, Grid } from 'components'
import { handleBrlValue } from 'utils'

import Table from '../Table'
import * as S from './styles'

export default function Card({ vehicle }) {
  const renderCards = () => {
    return vehicle?.data?.map((el, id) => (
      <S.Content key={id}>
        <Grid
          alignItems="center"
          gridGap="0.5rem"
          gridTemplateColumns={['50% 50%', '40% 1fr']}
        >
          <S.Image>
            {el.cover && (
              <img src={el.cover} alt={`Foto do carro ${el.model}`} />
            )}
          </S.Image>

          <div>
            <S.Model>{el.model}</S.Model>
            <S.Info>{el.category}</S.Info>
          </div>
        </Grid>
        <S.Wrapper>
          <div>
            <Table
              data={{
                model: el.model,
                category: el.category,
                delivery: el.delivery,
              }}
              vehicleId={el.id}
            />
          </div>

          <S.PriceContainer>
            <S.Info>A partir de:</S.Info>
            <S.Price>{handleBrlValue(el.installment)}</S.Price>
          </S.PriceContainer>
        </S.Wrapper>
      </S.Content>
    ))
  }

  return (
    <S.Container>
      {vehicle.data?.length === 0 ? (
        <Alert>Nenhum registro encontrado.</Alert>
      ) : (
        renderCards()
      )}
    </S.Container>
  )
}
