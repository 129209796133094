import React from 'react'

import { Container } from './styles'

export default function Default({ isLoading, columns, data }) {
  return (
    <Container>
      <thead>
        <tr>
          {columns.map((column, index) => (
            <th key={index}>{column.header}</th>
          ))}
        </tr>
      </thead>

      <tbody>
        {(isLoading || data.length < 1) && (
          <tr>
            <td colSpan={columns.length}>
              {isLoading ? 'Carregando...' : 'Nenhum registro encontrado.'}
            </td>
          </tr>
        )}

        {data.map((row, index) => (
          <tr key={index}>
            {columns.map((column, index) => (
              <td key={index}>
                {typeof row[column.acessor] === 'function'
                  ? row[column.acessor]()
                  : row[column.acessor]}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </Container>
  )
}

Default.defaultProps = {
  columns: [],
  data: [],
}
