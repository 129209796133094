import * as yup from 'yup'
import { isBeforeDate, isAfterDate, isValidCPF } from 'utils'
import { differenceInYears, parse } from 'date-fns'

const date = new Date()
date.setFullYear(date.getFullYear() - 90)

const checkFullName = (name) => {
  const rawName = name.trim()

  const fullNameRegex = /^[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]+$/

  const splitName = rawName.split(' ')

  return splitName?.length >= 2 && fullNameRegex.test(rawName)
}

const validateAge = (age) => {
  if (!age || age.length < 10) {
    return
  }

  const date = parse(age, 'dd/MM/yyyy', new Date())

  const result = differenceInYears(new Date(), date) >= 18

  return result
}

export default yup.object().shape({
  document: yup
    .string()
    .required('Por favor, insira o CPF')
    .test('document-test', 'Por favor insira um CPF válido', (value) =>
      isValidCPF(value)
    ),
  name: yup
    .string()
    .ensure()
    .required('Por favor, insira o nome')
    .test('checkFullName', 'Por favor insira o nome completo', (value) =>
      checkFullName(value)
    ),
  cellphone: yup
    .string()
    .required('Por favor, insira o celular')
    .min('13', 'Por favor, insira um celular válido')
    .max('13', 'Por favor, insira um celular válido'),
  email: yup
    .string()
    .required('Por favor insira o e-mail')
    .email('Por favor insira um e-mail válido'),
  cep: yup
    .string()
    .required('Por favor, insira o CEP')
    .min('8', 'Por favor, insira um CEP válido'),
  birthdate: yup
    .string()
    .required('Por favor, insira a data de nascimento')
    .min('8', 'Por favor, insira uma data de nascimento válida')
    .test('validateEmission', 'Por favor, insira uma data válida', (value) =>
      isBeforeDate(value)
    )
    .test('validateBirthdate', 'Por favor, insira uma data válida', (value) =>
      isAfterDate(value, date)
    )
    .test('validateAge', 'Por favor, insira uma data válida', (value) =>
      validateAge(value)
    )
    .nullable(),
})
