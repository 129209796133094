import React, { useState, useEffect } from 'react'
import { Redirect, Link, useHistory } from 'react-router-dom'
import { FormContext, useForm } from 'react-hook-form'

import { useAuthentication } from 'hooks'

import { Choice, Input, Button, AlertModal, Footer } from 'components'

import {
  Container,
  Content,
  Banner,
  Title,
  Box,
  Text,
  Type,
  Form,
  Muted,
  Register,
  Error,
  ModalTitle,
  ModalText,
  ModalBody,
} from './styles'

import * as validationSchema from './validation'

export default function Login() {
  const dataLayer = window.dataLayer || []
  const history = useHistory()

  const [loginType, setLoginType] = useState('employee')

  const [visible, setVisible] = useState(false)

  const form = useForm({
    validationSchema: validationSchema[loginType],
  })

  const { isLoading, error, isAuthenticated, login } = useAuthentication()

  const onSubmit = form.handleSubmit((values) => {
    login(values)
  })

  useEffect(() => {
    setLoginType(form.watch('type'))
  }, [form.watch('type')]) //eslint-disable-line

  function getStorageItem(key) {
    const data = localStorage.getItem(key)

    return JSON.parse(data)
  }

  function setStorageItem(key, value) {
    const data = JSON.stringify(value)
    return localStorage.setItem(key, data)
  }

  useEffect(() => {
    const data = getStorageItem('portobank@login_modal')
    const isNotVisible = data?.isNotVisible || false

    if (!!isNotVisible) {
      setVisible(false)
      return
    }

    setVisible(true)
  }, [])

  useEffect(() => {
    dataLayer.push({
      event: 'step_change',
      etapa: 'login',
    })
  }, []) //eslint-disable-line

  const onInputChange = (value) => {
    if (!value) {
      return
    }

    return value.toLowerCase()
  }

  if (isAuthenticated)
    return (
      <Redirect
        to={{
          pathname: '/',
        }}
      />
    )

  return (
    <Container>
      <Content>
        <Banner>
          <Title>
            Olá, seja bem-vindo! Faça a diferença com seus clientes.
          </Title>
        </Banner>

        <Box>
          <Text>Para acessar, infome seus dados abaixo:</Text>

          <FormContext {...form}>
            <Type>
              <Choice
                name="type"
                label="Funcionário"
                type="radio"
                value="employee"
                checked
              />

              <Choice
                name="type"
                label="Corretor"
                type="radio"
                value="broker"
              />
            </Type>

            <Form onSubmit={onSubmit}>
              {loginType === 'employee' && (
                <>
                  <Input
                    name="employee.email"
                    type="email"
                    label="E-mail"
                    onChange={(e) => onInputChange(e.target.value)}
                    placeholder="Digite seu e-mail"
                  />

                  <Input
                    name="employee.password"
                    type="password"
                    label="Senha"
                    placeholder="Digite sua senha"
                  />
                </>
              )}

              {loginType === 'broker' && (
                <>
                  <Input
                    name="broker.susep"
                    type="text"
                    label="SUSEP"
                    placeholder="Digite sua SUSEP"
                    toUpperCase
                  />

                  <Input
                    name="broker.password"
                    type="password"
                    label="Senha"
                    placeholder="Digite sua senha"
                  />

                  <Muted>
                    Primeiro acesso? Insira os mesmos dados utilizados na
                    criação da ficha cadastral.
                  </Muted>

                  <Register>
                    Ainda não tem acesso?{' '}
                    <Link
                      to="/cadastro"
                      data-gtm-type="click"
                      data-gtm-clicktype="link"
                      data-gtm-name="clique-aqui"
                    >
                      Clique aqui
                    </Link>
                  </Register>
                </>
              )}

              <Button onClick={onSubmit} isLoading={isLoading} block>
                Acessar
              </Button>

              <Register align="center">
                Esqueceu a senha?
                <Link
                  to="/esqueci-senha"
                  data-gtm-type="click"
                  data-gtm-clicktype="link"
                  data-gtm-name="esqueceu a senha? clique aqui"
                >
                  Clique aqui
                </Link>
              </Register>
            </Form>
          </FormContext>

          {error && <Error>{error}</Error>}
        </Box>
      </Content>
      <AlertModal
        isVisible={visible}
        actions={{
          primary: {
            onClick: () => {
              history.push('/cadastro')
              setStorageItem('portobank@login_modal', {
                isNotVisible: true,
              })
            },
            label: 'Atualizar cadastro',
          },
          secondary: {
            onClick: () => {
              setStorageItem('portobank@login_modal', {
                isNotVisible: true,
              })
              setVisible(false)
            },
            label: 'Entendi',
          },
        }}
      >
        <ModalBody>
          <ModalTitle>Olá Corretor(a)</ModalTitle>
          <ModalText>
            Para acessar a nova tela de acompanhamento do Carro por Assinatura é
            preciso atualizar seu cadastro e gerar um novo login com senha.
            Basta clicar no botão abaixo e atualizar seu cadastro. Em caso de
            dúvidas, entre em contato com seu consultor de vendas.
          </ModalText>
        </ModalBody>
      </AlertModal>
      <Footer />
    </Container>
  )
}

Login.properties = {
  metatags: {
    title: 'Login',
  },
}
