import { colors } from 'styles'

export const handleStatusPcoColor = (type) => {
  const statusColors = {
    RENOVADO: colors.green,
    RENOVADOS: colors.green,
    APROVADO: colors.success,
    APROVADOS: colors.success,
    PENDENTE: colors.warning,
    PENDENTES: colors.warning,
    ERRO: colors.danger,
    ERROS: colors.danger,
    DERIVACAO: colors.info,
    DERIVADOS: colors.info,
    INAPTOS: colors.gray.medium,
    TOTAL: 'transparent',
  }

  if (!statusColors[type]) {
    return colors.gray.jet
  }

  return statusColors[type]
}
