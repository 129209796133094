import React from 'react'
import { Loading } from 'components'
import { Button as RenewButton } from './styles'

export default function Button({ action, theme, label, loading }) {
  return (
    <RenewButton onClick={action} btnColor={theme}>
      {!loading ? (
        <span>{label}</span>
      ) : (
        <Loading size={'8px'} color={'#FFF'} />
      )}
    </RenewButton>
  )
}
