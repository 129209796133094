import styled, { css } from 'styled-components'

const dropdownModifiers = {
  open: () => css`
    opacity: 1;
    pointer-events: auto;
    transform: translateY(0);
  `,
  close: () => css`
    opacity: 0;
    pointer-events: none;
    transform: translateX(2rem);
  `,
}

export const Dropdown = styled.div`
  ${({ theme, isOpen }) => css`
    display: flex;
    flex-direction: column;
    gap: ${theme.spacings.xsmall};

    position: absolute;
    width: max-content;

    color: ${theme.colors.black};
    padding: ${theme.spacings.small};

    bottom: 4px;
    right: 0;
    margin-right: ${theme.spacings.large};

    border-radius: 12px 12px 0px 12px;
    background: ${theme.colors.primary};
    color: ${theme.colors.white};

    &::after {
      content: '';
      position: absolute;
      border-right: 16px solid transparent;
      border-left: none;
      border-bottom: 16px solid ${theme.colors.primary};
      bottom: 0;
      right: -16px;

      border-radius: 0 0 ${theme.borderRadius.small} 0;
    }

    transition: transform 0.2s ease-in, opacity 0.3s;

    ${isOpen && dropdownModifiers.open()}
    ${!isOpen && dropdownModifiers.close()}
  `}
`

export const Title = styled.div`
  ${({ theme }) => css`
    cursor: pointer;

    position: relative;
    display: flex;
    align-items: center;
    > svg {
      color: ${theme.colors.primary};
    }
  `}
`
export const Wrapper = styled.div`
  position: relative;
  width: max-content;
`
