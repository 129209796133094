import React, { useState } from 'react'
import { FormContext, useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { debounce } from 'lodash'
import { Icon, Input, Allow } from 'components'

import { FilterDropdown, NewsCards, Pagination } from '../components'
import { useGetLastNews } from 'services/hooks/News'
import { USER_ROLES } from 'helpers'

import * as S from './styles'
import { useParams } from 'hooks'
function List() {
  const history = useHistory()
  const { params, setParams } = useParams()

  const [pagination, setPagination] = useState({ page: 1, limit: 10 })

  const form = useForm({ defaultValues: { keyword: params.term } })

  const handlePagination = (page) => {
    setPagination((prevPagination) => ({ ...prevPagination, page }))
  }

  const { data: lastNews, isLoading: isLoadingLastNews } = useGetLastNews({
    ...params,
    ...pagination,
  })

  const onInputChange = debounce((value) => {
    setParams({ term: value || null })

    handlePagination(1)
  }, 1000)

  const goToCreateNews = () => {
    return history.push('/cadastro-novidades')
  }

  return (
    <S.Container>
      <S.Content>
        <S.Header>
          <S.Title>Novidades</S.Title>
          <FormContext {...form}>
            <Input
              placeholder="Palavra-chave"
              name="keyword"
              label="Palavra chave"
              onChange={(e) => onInputChange(e.target.value)}
              rightIcon={<Icon name="search" />}
            />

            <FilterDropdown />
          </FormContext>
        </S.Header>

        <Allow roles={[USER_ROLES.ADMINISTRATOR]}>
          <S.CreateNewsButton size="small" onClick={goToCreateNews}>
            Criar novidade
          </S.CreateNewsButton>
        </Allow>

        <S.Main>
          <S.NewsContainer isLoading={isLoadingLastNews}>
            <NewsCards data={lastNews?.data} isLoading={isLoadingLastNews} />
            <Pagination
              pagination={lastNews?.pagination}
              onPagination={handlePagination}
              isLoading={isLoadingLastNews}
            />
          </S.NewsContainer>
        </S.Main>
      </S.Content>
    </S.Container>
  )
}

export default List

List.properties = {
  metatags: {
    title: 'Novidades',
  },
}
