import styled, { css } from 'styled-components'
import media from 'styled-media-query'
import * as InputStyles from '../../../components/Forms/Input/styles'
import * as DropwdownStyles from '../components/FilterDropdown/styles'

import { Button } from 'components'

export const Container = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Content = styled.div`
  ${({ theme }) => css`
    max-width: 1440px;
    width: 100%;
    padding: ${theme.spacings.small};

    ${media.lessThan('medium')`
      padding-inline: ${theme.spacings.xsmall};
    `}
  `}
`

export const Header = styled.header`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: 2fr 2fr 0.5fr;
    align-items: center;
    gap: ${theme.spacings.small};
    margin-bottom: ${theme.spacings.small};

    ${media.lessThan('medium')`
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr;
      gap: ${theme.spacings.xsmall};
    `}

    ${InputStyles.Container} {
      width: 100%;

      ${media.lessThan('medium')`
        grid-column: 1/3;
        grid-row: 2;
      `}
    }

    ${DropwdownStyles.Wrapper} {
      justify-self: end;
    }
  `}
`

export const CreateNewsButton = styled(Button)`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacings.xsmall};

    ${media.lessThan('medium')`
        width: 100%;
        font-size: ${theme.fonts.sizes.xsmall};
    `}
  `}
`

export const Divider = styled.hr`
  ${({ theme }) => css`
    margin: ${theme.spacings.large} 0;
    height: 1px;
    background: ${theme.colors.newGray[20]};
    border: 0;

    ${media.lessThan('medium')`
      margin: ${theme.spacings.small} 0;
    `}
  `}
`

export const Title = styled.h2`
  ${({ theme }) => css`
    color: ${theme.colors.black};
    font-weight: 600;
    font-size: ${theme.fonts.sizes.xlarge};
  `}
`

export const Main = styled.div``

export const Nav = styled.nav`
  display: flex;
  align-items: center;

  ${media.greaterThan('medium')`
    display: none;
  `}
`

export const NewsContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: ${theme.spacings.xxsmall};
  `}
`