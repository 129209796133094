import React from 'react'

import * as S from './styles'

export default function Pagination({ pagination, handlePagination }) {
  return (
    <S.Container>
      {pagination.page > 2 && (
        <S.Item first onClick={() => handlePagination('first')} />
      )}
      <S.Item
        action="true"
        disabled={pagination.page < 2}
        onClick={() => handlePagination('prev')}
        data-gtm-type="click"
        data-gtm-clicktype="button"
        data-gtm-name="anterior"
      >
        Anterior
      </S.Item>

      {pagination.page > 1 && (
        <S.Item onClick={() => handlePagination('prev')}>
          {pagination.page - 1}
        </S.Item>
      )}

      <S.Item
        current
        data-gtm-type="click"
        data-gtm-clicktype="button"
        data-gtm-name={`página-${pagination.page}`}
      >
        {pagination.page}
      </S.Item>

      {!pagination.end && (
        <S.Item onClick={() => handlePagination('next')}>
          {pagination.page + 1}
        </S.Item>
      )}

      <S.Item
        action="true"
        disabled={pagination.end}
        onClick={() => handlePagination('next')}
        data-gtm-type="click"
        data-gtm-clicktype="button"
        data-gtm-name="próximo"
      >
        Próxima
      </S.Item>
    </S.Container>
  )
}
