export const internal = [
  {
    path: 'https://www.carroporassinaturaporto.com.br/o-que-somos',
    text: 'O que somos?',
    gtmName: 'O-que-somos',
  },
  {
    path: 'https://assinatura.carroporassinaturaporto.com.br',
    text: 'Descubra qual é o seu carro',
    gtmName: 'descubra-qual-e-o-seu-carro',
  },
  {
    path: 'https://www.carroporassinaturaporto.com.br/vantagens',
    gtmName: 'vale-a-pena-ter-um-carro-fácil',
    text: 'Vale a pena ter um serviço Carro por Assinatura?',
  },
  {
    path: 'https://www.carroporassinaturaporto.com.br/faq',
    text: 'Perguntas frequentes',
    gtmName: 'perguntas-frequentes',
  },
]
