import { rgba } from 'polished'
import styled, { css } from 'styled-components'

export const Title = styled.div`
  ${({ theme }) => css`
    position: relative;
    color: ${theme.colors.white};
    display: flex;
    align-items: center;

    cursor: pointer;
  `}
`

export const Overlays = styled.div`
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  visibility: hidden;
  opacity: 0;

  ${({ isOpen }) =>
    isOpen &&
    css`
       {
        opacity: 1;
        visibility: visible;
      }
    `}
`

export const Content = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;

    z-index: ${theme.layers.modal};

    background: ${theme.colors.primary};
    border-radius: ${theme.borderRadius.large};
    width: max-content;

    position: absolute;
    top: -24px;
    right: 40px;

    padding: ${theme.spacings.small};

    &::before {
      content: '';
      position: absolute;
      border-right: 16px solid transparent;
      border-left: 16px solid transparent;
      border-bottom: 16px solid ${theme.colors.primary};
      top: 32px;
      right: -16px;
      transform: rotate(90deg);
    }
  `}
`

const wrapperModifiers = {
  open: () => css`
    opacity: 1;
    pointer-events: auto;
    transform: translateY(0);
  `,
  close: () => css`
    opacity: 0;
    pointer-events: none;
    transform: translateX(2rem);
  `,
}

export const Wrapper = styled.div`
  ${({ isOpen }) => css`
    position: relative;
    width: max-content;

    ${Content} {
      transition: transform 0.2s ease-in, opacity 0.3s;

      ${isOpen && wrapperModifiers.open()}
      ${!isOpen && wrapperModifiers.close()}
    }
  `}
`

export const ActionButton = styled.button`
  ${({ theme }) => css`
    background: transparent;
    color: ${theme.colors.white};
    font-size: ${theme.fonts.sizes.xsmall};
    font-family: ${theme.fonts.book};
    border: 0;
    padding: ${theme.spacings.xsmall} 0;
    font-weight: 600;
    line-height: 1.5;

    cursor: pointer;

    display: flex;
    justify-content: flex-start;

    &:hover {
      color: ${theme.colors.secondary};
    }

    &:disabled {
      cursor: not-allowed;
      color: ${theme.colors.newGray[60]};
    }
  `}
`
