import api from 'services'

export async function fetchVehicleCategory() {
  try {
    const response = await api.get(`/cr_product/list_vehicle_categories`)

    return response.data
  } catch (error) {
    throw Error(
      'Houve uma instabilidade no sistema, tente novamente em alguns minutos.'
    )
  }
}

export async function fetchVehicle({ category, state }) {
  try {
    const response = await api.get(`/cr_product/list_models_info`, {
      params: {
        category,
        state,
      },
    })

    return response.data
  } catch (error) {
    throw Error(
      'Houve uma instabilidade no sistema, tente novamente em alguns minutos.'
    )
  }
}
export async function fetchVehicleColor({ code, branch }) {
  try {
    const response = await api.get(`/cr_product/list_model_colors`, {
      params: {
        ...code,
        branch,
      },
    })

    if (!response.data.length) {
      return
    }

    const newData = response.data.reduce((acc, element) => {
      if (!element.balancesbyaddress.length) {
        return acc
      }

      acc.push({
        ...element,
        balancesbyaddress: element.balancesbyaddress.map((el) => ({
          chassiNumber: el.chassiNumber,
          vehiclePlate: el.vehiclePlate,
          finalPlate: el.vehiclePlate.slice(-1),
        })),
      })

      return acc
    }, [])

    return newData
  } catch (error) {
    throw Error(
      'Houve uma instabilidade no sistema, tente novamente em alguns minutos.'
    )
  }
}

export async function creatRenovationProposal({ proposal }) {
  try {
    const response = await api.post(
      `/cr_renovation/generate_contract`,
      proposal
    )

    return response.data
  } catch (error) {
    throw Error(
      'Houve uma instabilidade no sistema, tente novamente em alguns minutos.'
    )
  }
}

export async function changeClientStatus({ id, data }) {
  try {
    const response = await api.patch(
      `/cr_proposal/change_status_proposal/${id}`,
      { status: data }
    )

    return response.data
  } catch (error) {
    console.error(error)
    throw Error(
      'Ops! Erro ao realizar o login. Tente novamente em alguns instantes.'
    )
  }
}
