import { useSelector, useDispatch } from 'react-redux'

import { toggleOffcanvas, toggleNotification } from 'store/reducers/layout'

export function useOffcanvas() {
  const dispatch = useDispatch()
  const { offcanvas } = useSelector((state) => state.layout)

  const toggle = () => {
    dispatch(toggleOffcanvas())
  }

  return {
    toggle,
    visible: offcanvas,
  }
}

export function useNotification() {
  const dispatch = useDispatch()
  const { notification } = useSelector((state) => state.layout)
  const { type, content, visible } = notification

  const open = ({ type, content, autoClose = true, duration = 3000 }) => {
    dispatch(
      toggleNotification({
        type,
        content,
        visible: true,
      })
    )

    if (autoClose) setTimeout(close, duration)
  }

  const close = () => {
    dispatch(
      toggleNotification({
        type: '',
        content: '',
        visible: false,
      })
    )
  }

  return {
    open,
    close,
    type,
    content,
    visible,
  }
}
