export const setCpfMask = (cpf) => {
  const document = cpf.replace(/[^\d]/g, '')
  const formated = document.replace(
    /(\d{3})(\d{3})(\d{3})(\d{2})/,
    '$1.$2.$3-$4'
  )

  return formated
}

export const setTelephoneMask = (tel) => {
  if(!tel) {
    return 
  }

  return tel.replace(/[^\d]/g, '').replace(/(\d{2})(\d{5})(\d)/, '$1 $2 $3')
}
