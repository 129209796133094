import * as yup from 'yup'

export default yup.object().shape({
  proposal: yup.object().shape({
    period: yup.string().required('Por favor selecione o periodo'),
    bundle: yup.string().required('Por favor selecione o pacote'),
    additionalPeriod: yup
      .number()
      .transform((value, original) => (original === '' ? null : value))
      .max(10, 'Por favor adicione no máximo 10 meses adicionais')
      .nullable(true),
    init_date_contract: yup
      .date()
      .required('Por favor selecione a data inicial do contrato'),
    suggestedValue: yup.string().nullable(true),
    finalPriceFormated: yup
      .string()
      .required('Por favor adicione o valor final'),
  }),
})
