import styled, { css } from 'styled-components'

const containerAnimation = {
  open: () => css`
    animation-name: open;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;

    @keyframes open {
      from {
        opacity: 0;
        transform: translateX(700px);
      }
      to {
        transform: translateX(0);
        opacity: 1;
      }
    }
  `,
  close: () => css`
    animation-name: close;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;

    @keyframes close {
      from {
        transform: translateX(0);
        opacity: 1;
      }
      to {
        opacity: 0;
        transform: translateX(700px);
      }
    }
  `,
}

export const Container = styled.div`
  ${({ theme, isOpen, backgroundColor }) => css`
    padding: ${theme.spacings.small};
    z-index: ${theme.layers.modal};
    position: absolute;
    
    top: 0;
    right: 0;
    bottom: 0;
    background-color: ${backgroundColor};

    display: flex;
    flex-direction: column;
    overflow-y: auto;

    ${isOpen && containerAnimation.open()}
    ${!isOpen && containerAnimation.close()}
  `}
`

const overlayModifiers = {
  open: () => css`
    opacity: 1;
    pointer-events: visible;
  `,

  close: () => css`
    transition: opacity 1s ease-in-out;
    opacity: 0;
    pointer-events: none;
  `,
}

export const Overlay = styled.div`
  ${({ theme, isOpen }) => css`
    position: fixed;
    inset: 0;
    z-index: ${theme.layers.modal};
    background-color: rgba(0, 0, 0, 0.48);

    ${isOpen && overlayModifiers.open()}
    ${!isOpen && overlayModifiers.close()}
  `}
`

export const IconWrapper = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.white};
    cursor: pointer;

    > svg {
      width: 32px;
      height: 32px;
    }
  `}
`
