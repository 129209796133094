import React, { useEffect, useState } from 'react'

import { Grid, Input } from 'components'
import { getProductDetail } from 'services'
import { MARITAL_STATUSES, OCCUPATIONS, DELIVERY_TIME } from 'helpers'

import { formatBRDate, formatCPF } from 'utils'

import { Dialog, Label, Text } from '../../styles'

export default function Main({ order, forms }) {
  const [isLoading, setLoading] = useState(false)

  const fetchColor = async () => {
    setLoading(true)
    let details = []
    if (order.color) {
      try {
        details = await getProductDetail({
          brand: order.product?.vehicle?.model?.brand?.code,
          model: order.product?.vehicle?.model?.code,
          version: order.product?.vehicle?.code,
        })

        const { color: colorCode } = order

        const color = details.find((item) => item.color.code === colorCode)
          ?.color.title

        forms.setValue([{ color }])
      } catch (error) {
        console.error(error)
        forms.setError('color', 'formError', 'Erro ao carregar')
      }
    }
    setLoading(false)
  }

  useEffect(() => {
    if (order?.customer) {
      let availablePlates = '0123456789'

      const {
        customer,
        createdAt,
        summary,
        product,
        package: pkg,
        plan,
        plate: basePlate,
        reservations,
      } = order

      const income = customer?.income?.toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      })

      const activeReservation = reservations?.find(
        (reservation) => !reservation.deletedAt
      )

      const address =
        customer?.defaultAddress?.street &&
        `${customer?.defaultAddress?.street}, ${customer?.defaultAddress?.number}, ${customer?.defaultAddress?.city} - ${customer?.defaultAddress?.state}`

      if (basePlate) {
        for (let i = 0; i < basePlate.length; i++) {
          availablePlates = availablePlates.replace(basePlate[i], '')
        }
      }

      forms.setValue([
        { fullName: customer?.fullName },
        { gender: customer?.gender },
        { email: customer?.email },
        { occupation: OCCUPATIONS[customer?.occupation] },
        { maritalStatus: MARITAL_STATUSES[customer?.marital] },
        { company: customer?.company },
        { income },
        { rg: customer?.rg?.number },
        { cpf: formatCPF(customer?.cpf?.number) },
        { birthDate: formatBRDate(customer?.birthdate) },
        { createdAt: formatBRDate(createdAt) },
        { motherName: customer?.motherName },
        { cnhEmission: formatBRDate(customer?.cnh?.emission) },
        { cnhExpiration: formatBRDate(customer?.cnh?.expiration) },
        { cnhNumber: customer?.cnh?.number },
        { firstCNH: formatBRDate(customer?.cnh?.firstCNH) },
        { cellphone: customer?.cellphone },
        { zipcode: customer?.defaultAddress?.cep },
        { address },
        { category: product?.vehicle?.category?.title },
        { vehicle: product?.vehicle?.title },
        { plate: availablePlates?.split('')?.join(',') },
        { mileage: pkg?.mileage },
        { months: pkg?.months },
        { plan: plan?.title },
        { baseValue: summary?.baseCost?.formattedValue },
        { grievance: summary?.grievance?.formattedValue },
        { discounts: summary?.discount?.formattedValue },
        { payedValue: summary?.cost?.formattedValue },
        { deliveryTime: DELIVERY_TIME[activeReservation?.deliveryCode] },
      ])

      fetchColor()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order])

  return (
    <>
      <Dialog>
        <Label>Preenchimento de Dados</Label>

        <Grid
          my={4}
          gridTemplateColumns={['1fr', '1fr 1fr', '1fr 1fr 1fr']}
          gridGap="30px"
        >
          <Input
            name="fullName"
            placeholder="Nome Completo"
            label="Nome Completo"
            disabled
          />
          <Input name="gender" placeholder="Sexo" label="Sexo" disabled />
          <Input name="email" placeholder="E-mail" label="E-mail" disabled />
          <Input
            name="occupation"
            placeholder="Ocupação"
            label="Ocupação"
            disabled
          />
          <Input
            name="maritalStatus"
            placeholder="Estado Civil"
            label="Estado Civil"
            disabled
          />
          <Input
            name="company"
            placeholder="Empresa"
            label="Empresa"
            disabled
          />
          <Input name="income" placeholder="Renda" label="Renda" disabled />
          <Input name="rg" placeholder="RG" label="RG" disabled />
          <Input name="cpf" placeholder="CPF" label="CPF" disabled />
          <Input
            name="createdAt"
            placeholder="Data da Proposta"
            label="Data da Proposta"
            date
            disabled
          />
          <Input
            name="birthDate"
            placeholder="Data de nascimento"
            label="Data de nascimento"
            date
            disabled
          />
          <Input
            name="motherName"
            placeholder="Nome da mãe"
            label="Nome da mãe"
            disabled
          />
          <Input
            name="cnhEmission"
            placeholder="Data de emissão da CNH"
            label="Data de emissão da CNH"
            date
            disabled
          />
          <Input
            name="cnhExpiration"
            placeholder="Data de validade da CNH"
            label="Data de validade da CNH"
            date
            disabled
          />
          <Input
            name="cnhNumber"
            placeholder="Numero da CNH"
            label="Numero da CNH"
            disabled
          />
          <Input
            name="firstCNH"
            placeholder="Data da emissão da 1° CNH"
            label="Data da emissão da 1° CNH"
            date
            disabled
          />
          <Input
            name="cellphone"
            placeholder="Celular"
            label="Celular"
            phone
            disabled
          />
          <Input
            name="zipcode"
            placeholder="CEP"
            label="CEP"
            mask="#####-###"
            disabled
          />
          <Input
            name="address"
            placeholder="Endereço"
            label="Endereço"
            disabled
          />
          <Input
            name="complement"
            placeholder="Complemento"
            label="Complemento"
            disabled
          />
          <Input
            name="category"
            placeholder="Categoria Escolhida"
            label="Categoria Escolhida"
            disabled
          />
        </Grid>
        <Label>Dados do carro escolhido</Label>
        <Grid
          my={4}
          gridTemplateColumns={['1fr', '1fr 1fr', '1fr 1fr 1fr']}
          gridGap="30px"
        >
          <Input
            name="vehicle"
            placeholder="Carro Escolhido"
            label="Carro Escolhido"
            disabled
          />
          <Input
            name="plate"
            placeholder="Placa final que o cliente quer"
            label="Placa final que o cliente quer"
            disabled
          />
          <Input
            name="color"
            placeholder="Cor escolhida"
            label="Cor escolhida"
            isLoading={isLoading}
            disabled
          />
          <Input
            name="deliveryTime"
            placeholder="Prazo de entrega (Aquisição)"
            label="Prazo de entrega (Aquisição)"
            disabled
            tip={{
              size: '12px',
              name: 'deliveryTime',
              color: '#333',
              icon: 'info',
              children: () => (
                <Text>
                  O prazo de entrega exibido aqui é uma informação que o cliente
                  selecionou no momento da escolha do carro durante o fluxo de
                  Aquisição.
                </Text>
              ),
            }}
          />
        </Grid>
        <Label>Dados do plano</Label>
        <Grid
          my={4}
          gridTemplateColumns={['1fr', '1fr 1fr', '1fr 1fr 1fr']}
          gridGap="30px"
        >
          <Input
            name="mileage"
            placeholder="Pacote de km"
            label="Pacote de KM"
            disabled
          />
          <Input
            name="months"
            placeholder="Duração em meses"
            label="Duração em meses"
            disabled
          />
          <Input name="plan" placeholder="Plano" label="Plano" disabled />
        </Grid>
      </Dialog>
      <Dialog>
        <Label>Pagamento</Label>
        <Grid
          mt={4}
          gridTemplateColumns={['1fr', '1fr 1fr', '1fr 1fr 1fr']}
          gridGap="30px"
        >
          <Input
            name="baseValue"
            placeholder="Valor base"
            label="Valor base"
            disabled
          />
          <Input
            name="discounts"
            placeholder="Descontos"
            label="Descontos"
            disabled
          />
          <Input
            name="grievance"
            placeholder="Agravos"
            label="Agravos"
            disabled
          />
          <Input
            name="payedValue"
            placeholder="Valor pago"
            label="Valor pago"
            disabled
          />
        </Grid>
      </Dialog>
    </>
  )
}
