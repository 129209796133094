import { Alert } from 'components'
import React from 'react'
import { handleBrlValue } from 'utils'

import { Container, THead, TBody, TD, TH, TR, Tip } from './styles'

export default function Simple({ data, columns }) {
  const rederTableHead = () => {
    return (
      <TR>
        {columns.map((el, index) => (
          <TH key={index} showToolTip={!!el.total}>
            <span>
              {el.header}
              <Tip>
                <p>{el.total}</p>
              </Tip>
            </span>
          </TH>
        ))}
      </TR>
    )
  }

  const renderTableCell = ({ row, column }) => {
    const packege = row?.packages.find((el) => el.milage === column.acessor)

    if (column.acessor === 'franchise') {
      return renderFormatedfranchiseCel(row.franchise)
    }

    return row[column.acessor] || handleBrlValue(packege?.price)
  }

  const renderFormatedfranchiseCel = (cel) => {
    return handleBrlValue(cel)
  }

  const renderTableRow = (row) => {
    return columns?.map((column, index) => (
      <TD key={index}>{renderTableCell({ row, column })}</TD>
    ))
  }

  const rederTableBody = () => {
    return data?.map((row, index) => {
      return (
        <TR
          key={index}
          data-gtm-click="click"
          data-gtm-clicktype="link"
          data-gtm-name={row.brand}
          data-gtm-subname={`${row.model}:${row.category}`}
          onClick={() => {
            if (row.specificationsLink) {
              window.open(row.specificationsLink)
            }
          }}
          cursor={row.specificationsLink ? 'pointer' : 'default '}
        >
          {renderTableRow(row)}
        </TR>
      )
    })
  }

  if (!data?.length) {
    return <Alert>Nenhum registro encontrado.</Alert>
  }

  return (
    <Container>
      <THead>{rederTableHead()}</THead>
      <TBody>{rederTableBody()}</TBody>
    </Container>
  )
}
