import React, { useState, useEffect, useCallback } from 'react'
import { FormContext, useForm } from 'react-hook-form'

import { convertToUsdValue } from 'utils'
import { USER_ROLES } from 'helpers'

import {
  Contract,
  Header,
  PersonalData,
  Vehicle,
  Broker,
  Footer,
  Proposal,
} from './components'

import { Allow } from 'components'

import { Container, Loader, ErrorMessage } from './styles'

import { creatRenovationProposal, getRenovationDetailed } from 'services'
import { useParams } from 'react-router'

import { ModalProvider } from 'contexts'
import validationSchema from './validation'

export default function View() {
  const forms = useForm({ validationSchema })
  const params = useParams()

  const [isSendingProposal, setIsSendingProposal] = useState(false)
  const [hasSuccess, setHasSuccess] = useState(false)
  const [hasProposalError, setHasProposalError] = useState('')
  const [createProposalButton, setCreateProposalButton] = useState({
    color: 'primary',
    text: 'Gerar contrato',
  })

  const [proposal, setProposal] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [hasError, setHasError] = useState('')
  const [carChassi, setCarChassi] = useState({})

  const [shouldOpenModal, setShouldOpenModal] = useState(false)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const fetchProposalData = useCallback(async () => {
    try {
      setIsLoading(true)
      const response = await getRenovationDetailed(params.id)

      setProposal(response)
      setHasError('')
    } catch (err) {
      const { isLocked, message } = err

      if (!isLocked) {
        setHasError(message)
        return
      }
      setShouldOpenModal(isLocked)
    } finally {
      setIsLoading(false)
    }
  }, [params.id])

  useEffect(() => {
    fetchProposalData()
  }, [fetchProposalData])

  const handleSubmit = forms.handleSubmit(async (values) => {
    setIsSendingProposal(true)

    try {
      const {
        proposal: { finalPriceFormated, suggestedValue },
      } = values

      const additionalPeriod = Number(values.proposal.additionalPeriod) || 0

      const kilometersPerMonth =
        (Number(values.proposal.bundle) / Number(values.proposal.period)) *
        additionalPeriod

      values.proposal.bundle =
        Number(values.proposal.bundle) + Number(kilometersPerMonth)

      values.proposal.same_car = true
      values.proposal.id = params.id
      values.proposal.final_price = convertToUsdValue(finalPriceFormated)
      values.proposal.suggestedValue = convertToUsdValue(suggestedValue)
      values.vehicle.installment_value = proposal?.car?.installment_value
      values.proposal.color = proposal?.car?.product_code
      values.vehicle.additional_mileage_value =
        proposal?.car?.additional_mileage_value

      const data = await creatRenovationProposal({ proposal: values })

      if (data) {
        setHasSuccess(true)
        setHasProposalError('')
        setCreateProposalButton({
          color: 'success',
          text: 'Contrato gerado com sucesso!',
        })
      }
    } catch (error) {
      setHasSuccess(false)
      setHasProposalError(error.message)
      setCreateProposalButton({
        color: 'error',
        text: 'Não foi possível gerar o contrato',
      })
    } finally {
      setIsSendingProposal(false)
    }
  })

  useEffect(() => {
    if (hasSuccess && !isSendingProposal) {
      setTimeout(() => {
        fetchProposalData()
        window.scrollTo(0, 0)
      }, 5000)
    }
  }, [fetchProposalData, hasSuccess, isSendingProposal])

  const renderProposal = () => (
    <ModalProvider
      clientId={params.id}
      fetchProposalData={fetchProposalData}
      openOnReanalyse={shouldOpenModal}
    >
      <Header
        clientName={proposal?.client?.name}
        contractStatus={proposal?.status?.description}
        isLoading={isLoading}
      />

      <FormContext {...forms}>
        <Contract
          forms={forms}
          contract={proposal}
          document={proposal?.document}
        />
        <PersonalData forms={forms} client={proposal?.client} />
        <Vehicle forms={forms} car={proposal?.car} />
        <Broker
          forms={forms}
          broker={proposal?.broker}
          consultant={proposal?.consultant}
        />
        {proposal?.status?.description === 'APROVADO' && (
          <Allow roles={[USER_ROLES.ADMINISTRATOR, USER_ROLES.ADVISER]}>
            <Proposal
              forms={forms}
              proposal={proposal}
              onSubmit={handleSubmit}
              isSendingProposal={isSendingProposal}
              hasSuccess={hasSuccess}
              createProposalButton={createProposalButton}
              hasProposalError={hasProposalError}
              carChassi={carChassi}
              setCarChassi={setCarChassi}
            />
          </Allow>
        )}
      </FormContext>

      <Footer hasError={hasError} clientId={params.id} />
    </ModalProvider>
  )

  return (
    <Container>
      {isLoading && <Loader />}

      {hasError ? (
        <ErrorMessage>
          <span>{hasError}</span>
        </ErrorMessage>
      ) : (
        renderProposal()
      )}
    </Container>
  )
}

View.properties = {
  metatags: {
    title: 'Renovação',
  },
}
