import * as moment from 'moment'

/**
 * @param {string} props.date            DD/MM/YYYY date
 */

export const formatDate = (date) => {
  if (date.length !== 10) return
  const [day, month, year] = date.split('/')

  return `${year}-${month}-${day}`
}

export const isAfterDate = (
  date,
  after = new Date(),
  pattern = 'DD/MM/YYYY'
) => {
  try {
    const dateFormatted = moment(date, pattern)
    const afterFormatted = moment(after, pattern)
    const result = dateFormatted.isSameOrAfter(afterFormatted)
    return result
  } catch (error) {
    return false
  }
}

export const isBeforeDate = (
  date,
  after = new Date(),
  pattern = 'DD/MM/YYYY'
) => {
  try {
    const dateFormatted = moment(date, pattern)
    const afterFormatted = moment(after, pattern)
    const result = dateFormatted.isSameOrBefore(afterFormatted)
    return result
  } catch (error) {
    return false
  }
}
