import styled, { css } from 'styled-components'

export const Container = styled.div`
  ${({ theme }) => css`
    position: relative;

    width: 100%;
    grid-column: 1/3;

    &:focus-within {
      .wrapperClassName {
        border-radius: 10px;
        border: 2px solid ${theme.colors.primary};
      }

      .toolbarClassName {
        border-radius: 10px;
        border-top-left-radius: 2px solid ${theme.colors.primary};
        border-top-right-radius: 2px solid ${theme.colors.primary};
      }
    }

    .wrapperClassName {
      border-radius: 10px;
      border: 2px solid #666666;
      max-width: 1440px;
    }

    .toolbarClassName {
      border-radius: 10px;
      border-top-left-radius: 2px solid #666666;
      border-top-right-radius: 2px solid #666666;
    }

    .editorClassName {
      padding: 0 ${theme.spacings.xsmall};
      min-height: 300px;

      .public-DraftEditorPlaceholder-inner {
        color: ${theme.colors.gray.medium};
        font-size: ${theme.fonts.sizes.xsmall};
        font-family: ${theme.fonts.medium};
      }
    }
  `}
`
