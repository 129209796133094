export const USER_ROLES = {
  ADMINISTRATOR: 'administrator',
  BROKER: 'broker',
  ADVISER: 'adviser',
}

export const USER_ROLE_BR = {
  administrator: 'Administrador',
  broker: 'Corretor',
  adviser: 'Consultor',
}
