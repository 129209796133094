import styled, { css } from 'styled-components'


export const Status = styled.span`
  ${({ theme, backgroundColor, textColor }) => css`
    padding: ${theme.spacings.xxsmall} ${theme.spacings.xsmall};
    display: block;

    width: max-content;
    text-align: center;
    color: ${textColor};
    background-color: ${backgroundColor};
    border-radius: ${theme.borderRadius.small };

    white-space: nowrap;
    font-size: ${theme.fonts.sizes.xxsmall};
    font-weight: 400;
    line-height: 1.5;
  `}
`
