import styled, { css } from 'styled-components'
import media from 'styled-media-query'
import { rgba, rem } from 'polished'

export const Container = styled.div`
  margin: 0;

  ${media.greaterThan('large')`
    height: calc(100vh - 85px);
    margin: 30px 0;


    display: flex;
    align-items: center;
    flex-direction: column;
  `}
`

export const Content = styled.div`
  padding-bottom: 20px;

  ${media.greaterThan('large')`
    max-width: 1300px;
    margin: 0 auto;

    display: flex;
    justify-content: space-between;
  `}

  ${media.lessThan('large')`
    padding: 20px;
  `}
`

export const Banner = styled.div`
  background-image: url('/images/users/banner.jpg');
  background-size: cover;
  border-radius: 10px;

  box-shadow: 0px 0px 50px ${rgba('#000', 0.1)};

  ${media.greaterThan('large')`
    margin-right: 70px;
    padding: 60px 70px 0;
    
    flex: 1;
  `}

  ${media.lessThan('large')`
    padding: 20px;
    margin-bottom: 20px;
  `}
`

export const Title = styled.h1`
  margin: 0;

  color: #fff;
  font-size: ${rem('70px')};
  font-family: ${({ theme }) => theme.fonts.bold};

  ${media.lessThan('large')`
    font-size: ${rem('30px')};
  `}
`

export const Box = styled.div`
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0px 0px 50px ${rgba('#000', 0.1)};

  ${media.greaterThan('large')`
    width: 390px;
    height: 600px;
    padding: 50px 30px;
  `}

  ${media.lessThan('large')`
    padding: 30px 20px;
  `}
`

export const Text = styled.h3`
  padding-bottom: 30px;

  font-size: ${rem('15px')};
  color: ${({ theme }) => theme.colors.gray.medium};

  ${media.lessThan('large')`
    font-size: ${rem('13px')};
  `}
`

export const Type = styled.div`
  margin-bottom: 20px;
`

export const Form = styled.form`
  > div {
    margin-bottom: 20px;

    &:last-of-type {
      margin-bottom: 50px;
    }
  }
`

export const ForgotPassword = styled.a`
  display: inline-block;
  padding: 20px 0;

  cursor: pointer;
  font-size: ${rem('12px')};
  font-family: ${({ theme }) => theme.fonts.bold};
  color: ${({ theme }) => theme.colors.gray.medium};

  ${media.lessThan('large')`
    font-size: ${rem('10px')};
  `}

  &:hover {
    text-decoration: underline;
  }
`

export const Muted = styled.p`
  padding-bottom: 20px;
  display: block;

  font-size: ${rem('13px')};
  font-family: ${({ theme }) => theme.fonts.bold};
  color: ${({ theme }) => theme.colors.gray.medium};

  ${media.lessThan('large')`
    font-size: ${rem('11px')};
  `}
`

export const Register = styled.p`
  ${({ theme, align = 'inherit' }) => css`
    margin-bottom: 20px;
    text-align: ${align};

    font-size: ${rem('13px')};
    font-family: ${theme.fonts.bold};
    color: ${theme.colors.gray.medium};

    &:last-child {
      margin-top: 7px;
    }

    ${media.lessThan('large')`
    font-size: ${rem('11px')};
  `}

    > a {
      cursor: pointer;
      text-decoration: none;
      margin-left: 5px;
      color: ${theme.colors.primary};

      &:hover {
        text-decoration: underline;
      }
    }
  `}
`

export const Error = styled.p`
  margin-top: 20px;

  text-align: center;
  font-size: ${rem('11px')};
  color: ${({ theme }) => theme.colors.danger};
  font-family: ${({ theme }) => theme.fonts.bold};

  ${media.lessThan('large')`
    font-size: ${rem('9px')};
  `}
`

export const ModalBody = styled.div`
  text-align: left;
`

export const ModalTitle = styled.p`
  margin-bottom: 30px;

  font-family: ${({ theme }) => theme.fonts.bold};
  font-size: 24px;
`

export const ModalText = styled.p`
  margin-bottom: 30px;

  font-family: ${({ theme }) => theme.fonts.book};
  font-size: 20px;
`
