import React from 'react'
import { links } from './data'
import { Container, Wrapper, Left, Item, Right, Text } from './styles'

export default function Bottom() {
  return (
    <Container>
      <Wrapper>
        <Left>
          {links.map((link) => (
            <Item
              key={link.text}
              href={link.path}
              data-gtm-type="click"
              data-gtm-clicktype="button"
              data-gtm-name={link.text.toLowerCase()}
              target="_blank"
            >
              {link.text}
            </Item>
          ))}
        </Left>

        <Right>
          <Text>
            Em atendimento à legislação vigente, informamos que incidem as
            alíquotas PIS 1,65% e COFINS 7,60%, sobre formação de preço.
          </Text>
          <Text>Imagens meramente ilustrativas</Text>
        </Right>
      </Wrapper>
    </Container>
  )
}
