import React from 'react'

import { Button } from 'components'
import { useLockBodyScroll } from 'hooks'

import { Container, Overlay, Dialog, Flag, Content, Actions } from './styles'

export default function Alert({
  isVisible,
  flag,
  actions,
  children,
  isLoading,
}) {
  const { primary, secondary } = actions

  useLockBodyScroll(isVisible)

  return (
    <>
      {isVisible && (
        <Container>
          <Overlay />

          <Dialog>
            {flag && <Flag color={flag.color}>{flag.text}</Flag>}

            <Content>{children}</Content>

            {actions && (
              <Actions>
                {secondary && (
                  <Button color="gray" onClick={secondary.onClick}>
                    {secondary.label}
                  </Button>
                )}

                {primary && (
                  <Button isLoading={isLoading} onClick={primary.onClick}>
                    {primary.label}
                  </Button>
                )}
              </Actions>
            )}
          </Dialog>
        </Container>
      )}
    </>
  )
}
