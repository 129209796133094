import api from 'services'

export async function procedureAsyncRanalyse(id) {
  try {
    const response = await api.post(`/cr_proposal/proposal-analyze`, {
      proposal_id: id,
    })

    return response.data
  } catch (error) {
    throw Error('Ocorreu um problema, tente mais tarde')
  }
}
