import { customMedia } from 'components/MediaMatch'
import styled, { css } from 'styled-components'
import media from 'styled-media-query'

export const Sidebar = styled.nav`
  ${({ theme }) => css`
    position: sticky;
    background-color: ${theme.colors.side};

    height: calc(100vh - 80px);
    width: max-content;

    position: sticky;
    top: 0;

    overflow-y: scroll;

    ::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
  `}
`

const DropdownModifiers = {
  open: () => css`
    opacity: 1;
    pointer-events: visible;
    transform: translateY(0);
  `,
  close: () => css`
    opacity: 0;
    pointer-events: none;
    display: none;
    transform: translateY(-2rem);
  `,
}

export const Dropdown = styled.div`
  ${({ theme, isOpen, isOpenDrawer }) => css`
    background: ${theme.colors.side};

    position: absolute;
    display: flex;
    flex: 1;
    top: 0;
    right: ${theme.spacings.xsmall};
    z-index: ${theme.layers.aside};
    overflow-y: auto;

    ${!!isOpenDrawer &&
    css`
      overflow-y: hidden;
    `}

    max-height: 80vh;

    ::-webkit-scrollbar {
      width: 4px;
    }

    ::-webkit-scrollbar-track {
      background: none;
    }

    ::-webkit-scrollbar-thumb {
      background-color: ${theme.colors.newGray[30]};
      border-radius: 20px;
    }

    transition: transform 0.3s ease-in, opacity 0.3s;

    ${isOpen && DropdownModifiers.open()}
    ${!isOpen && DropdownModifiers.close()}
  `}
`

export const Container = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin: ${theme.spacings.xsmall} 0 ${theme.spacings.xsmall};
    padding: 0 ${theme.spacings.small};

    ${media.lessThan('medium')`
      padding: 0 ${theme.spacings.xsmall};
    `}
    position: relative;
  `}
`

export const FilterTitle = styled.h2`
  ${({ theme }) => css`
    font-weight: 600;
    font-size: ${theme.fonts.sizes.medium};
    line-height: 1;
    color: ${theme.colors.newGray[60]};
    margin: 0;
  `}
`

export const Chip = styled.div`
  ${({ theme }) => css`
    background: ${theme.colors.side};
    border-radius: calc(${theme.borderRadius.pill} / 2);

    display: flex;
    justify-content: space-between;
    align-items: center;

    color: ${theme.colors.white};
    gap: ${theme.spacings.xxsmall};

    font-weight: 600;
    font-size: ${theme.fonts.sizes.xxsmall};
    line-height: 1.5;

    padding: ${theme.spacings.xxsmall} ${theme.spacings.xsmall};

    cursor: pointer;
  `}
`
export const IconWrapper = styled.div`
  ${({ theme }) => css`
    > svg {
      width: 24px;
      height: 24px;

      color: ${theme.colors.white};

      position: absolute;
      top: ${theme.spacings.xsmall};
      right: ${theme.spacings.small};
    }
    cursor: pointer;
    ${media.lessThan('huge')`
      > svg{
        top: 5%;
        right: 15px;
      }
    `}
  `}
`

export const Wrapper = styled.div``

export const FilterContainer = styled.div`
  width: 250px;
  height: 100%;

  ${media.lessThan('huge')`
    width: 230px;
  `}
`

export const Header = styled.header`
  ${({ theme }) => css`
    padding: ${theme.spacings.small} ${theme.spacings.small};

    ${media.lessThan('huge')`
      padding: ${theme.spacings.small} ${theme.spacings.xsmall};
    `}

    border-bottom: ${theme.border.thin} solid rgba(255, 255, 255, 0.25);

    display: flex;
    align-items: center;

    ${customMedia.greaterThan('xlarge')`
      justify-content: space-between;
    `}

    > h2 {
      font-weight: 600;
      font-size: ${theme.fonts.sizes.large};
      line-height: 1.5;

      color: ${theme.colors.white};
      margin-right: ${theme.spacings.small};
    }

    > svg {
      color: ${theme.colors.white};
      width: 20px;
      height: 20px;
      cursor: pointer;
    }
  `}
`
