import { createGlobalStyle } from 'styled-components'
import { normalize } from 'polished'

export default createGlobalStyle`
  ${normalize}

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  a { text-decoration: none; }

  html { font-size: 16px; }

  body {
    background-color: #F8F8F8;
    font-family: 'Open Sans', sans-serif;
  }
`
