const sucursal = (branch) => {
  const Dicionario = {
    0: 'SEM INFO',
    1: 'SUCURSAL MATRIZ',
    3: 'PERNAMBUCO',
    4: 'BAHIA',
    6: 'MINAS GERAIS',
    7: 'PARANA',
    8: 'MOGI DAS CRUZES',
    9: 'MARANHAO',
    10: 'PARA',
    11: 'DISTRITO FEDERAL',
    12: 'AMAZONAS',
    13: 'ESPIRITO SANTO',
    14: 'GOIAS',
    15: 'RIO GRANDE DO SUL',
    16: 'SANTA CATARINA',
    17: 'MATO GROSSO',
    18: 'ALAGOAS',
    19: 'RIO GRANDE DO NORTE',
    20: 'MATO GROSSO DO SUL',
    21: 'CEARA',
    22: 'LITORAL PAULISTA',
    23: 'PIAUI',
    26: 'CAMPINAS',
    32: 'SAO PAULO - PENHA',
    33: 'SAO PAULO - MORUMBI',
    34: 'SAO PAULO - PACAEMBU',
    35: 'SAO PAULO - SANTANA',
    36: 'SAO PAULO - CAMPOS ELISEOS I',
    40: 'SAO PAULO - TATUAPE',
    44: 'SAO PAULO - JARDINS II',
    45: 'SAO PAULO - CENTRO',
    46: 'SAO PAULO - VILA MARIA',
    47: 'SAO PAULO - IPIRANGA',
    49: 'SAO PAULO - JARDINS I',
    52: 'SAO PAULO - CAMPOS ELISEOS II',
    55: 'OSASCO',
    56: 'SAO CAETANO DO SUL',
    57: 'SAO B. DO CAMPO',
    59: 'GUARULHOS',
    60: 'SANTO ANDRE',
    62: 'ALPHAVILLE',
    64: 'VALE DO PARAIBA',
    65: 'RIBEIRAO PRETO',
    66: 'SOROCABA',
    67: 'SAO JOSE DO RIO PRETO',
    68: 'PIRACICABA',
    71: 'PARAIBA',
    72: 'SERGIPE',
    75: 'BRAGANCA PAULISTA',
    76: 'JUNDIAI',
    80: 'AMERICANA',
    85: 'CORPORATE - SP',
    86: 'RJ - BARRA DA TIJUCA',
    87: 'METROPOLITANA RJ',
    88: 'RJ - NITEROI',
    91: 'RJ - CENTRO RIO I',
    94: 'CANAL BANCO',
    95: 'SUCURSAL SAO PAULO',
    96: 'SUCURSAL RIO BRANCO',
    97: 'SUCURSAL INTERIOR - RJ',
    98: 'SUCURSAL ZONA NORTE - RJ',
    1000: 'Consorcio',
  }
  if (branch) return Dicionario[branch]
  return 'SEM-SURCUSAL'
}

export default sucursal
