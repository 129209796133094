import React, { useState } from 'react'
import { Switch, Route, BrowserRouter, Redirect } from 'react-router-dom'

import { useCurrentUser, useAuthentication } from 'hooks'
import { setShowUpdateUserModal } from 'store/reducers/users'

import { DefaultLayout } from 'layouts'

import { useDispatch, useSelector } from 'react-redux'

import { database } from 'services/firebase'
import { onValue, ref } from 'firebase/database'

import routes from './index'

export default function Router() {
  const { role } = useCurrentUser()
  const dispatch = useDispatch()
  const [inMaintenance, setMaintenance] = useState(false)

  const { isAuthenticated } = useAuthentication()

  const users = useSelector((state) => state.users)

  const checkMaintenance = () => {
    const query = ref(database, 'web/config/omni/maintenance/inMaintenance')

    onValue(query, (snap) => {
      const value = snap.val()
      if (inMaintenance !== value) {
        setMaintenance(value)
      }
    })
  }

  checkMaintenance()

  return (
    <BrowserRouter>
      <Switch>
        {routes.map(
          ({
            component: Component,
            layout: Layout = DefaultLayout,
            ...route
          }) => (
            <Route
              exact
              key={route.path}
              {...route}
              render={(props) => {
                if (inMaintenance && route.path !== '/maintenance') {
                  return <Redirect from={route.path} exact to="/maintenance" />
                }

                if (!route.public && !isAuthenticated) {
                  return (
                    <Redirect
                      to={{
                        pathname: '/login',
                        state: {
                          from: props.location,
                        },
                      }}
                    />
                  )
                }

                if (!route.roles || route.roles?.includes(role)) {
                  if (!users.isUpToDate && isAuthenticated) {
                    dispatch(setShowUpdateUserModal(true))
                  }

                  return (
                    <Layout>
                      <Component {...props} />
                    </Layout>
                  )
                }

                return (
                  <Redirect
                    to={{
                      pathname: '/',
                      state: {
                        from: props.location,
                        message: 'Acesso negado.',
                      },
                    }}
                  />
                )
              }}
            />
          )
        )}
      </Switch>
    </BrowserRouter>
  )
}
