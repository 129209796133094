import api from 'services'
import { AppError, toOptions } from 'utils'

export async function getStockVehicle(params) {
  try {
    const { data } = await api.get('/cf_stock/stock', {
      params: params,
    })

    const stock = {
      ...data,
      pagination: {
        ...data.pagination,
        end: data?.pagination.current_page === data?.pagination.total_pages,
      },
    }

    return stock
  } catch (error) {
    throw new AppError(
      'Ocorreu um problema, tente novamente mais tarde.',
      error.response.status
    )
  }
}

export async function getVehicleBrands(data) {
  try {
    const response = await api.get('/cf_stock/brands', {
      params: data,
    })

    const brands = toOptions(response.data, 'name', 'name')

    return brands
  } catch (error) {
    throw Error('Ocorreu um problema, tente novamente mais tarde.')
  }
}

export async function getEndOfPlate(id, params) {
  try {
    const response = await api.get(`/cf_stock/plates/${id}`, {
      params,
    })

    return response.data
  } catch (error) {
    throw Error('Ocorreu um problema, tente novamente mais tarde.')
  }
}

export async function getDeliveryPlaces() {
  try {
    const response = await api.get('/cf_stock/delivery_places')

    return response.data
  } catch (error) {
    throw Error('Ocorreu um problema ao buscar os pontos de entrega, tente novamente mais tarde.')
  }
}
