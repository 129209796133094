import { rem } from 'polished'
import styled from 'styled-components'
import media from 'styled-media-query'

export const TextareaGroup = styled.div`
  margin: 1rem 0 1.5rem 0;
  position: relative;

  ${media.lessThan('small')`
    margin-bottom: 1rem;
  `}
`

export const TextareaComponent = styled.textarea`
  width: 100%;
  height: 80px;

  resize: none;

  margin-top: 0.5rem;
  padding: 8px 20px;

  outline: none;

  color: ${({ theme }) => theme.colors.primary};
  font-family: ${({ theme }) => theme.fonts.medium};

  &::placeholder {
    color: ${({ theme }) => theme.colors.gray.medium};

    font-family: ${({ theme }) => theme.fonts.bold};
    font-size: 0.875rem;
  }

  font-size: 0.875rem;

  border-radius: 8px;
  border: 2px solid ${({ theme }) => theme.colors.gray.medium};

  ${media.greaterThan('huge')`
    font-size: ${rem('16px')};
  `}

  ${media.lessThan('small')`
    font-size: ${rem('12px')};
  `}
`

export const Error = styled.p`
  position: absolute;
  bottom: -18px;
  left: 0;

  padding: 6px 20px 0;

  font-size: ${rem('14px')};
  font-family: ${({ theme }) => theme.fonts.medium};

  color: ${({ theme }) => theme.colors.danger};

  ${media.lessThan('small')`
    position: static;
    
    font-size: ${rem('12px')};
  `}
`
