import { format, parseISO } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import api from 'services'
import { AppError, handleBrlValue, toOptions } from 'utils'

export async function getVehiclePrices(params) {
  try {
    const { data } = await api.get('/cf_stock/stock_price', {
      params,
    })

    const columns = data?.data
      .reduce((milages, vehicle) => {
        if (Array.isArray(vehicle.packages)) {
          const currentMilages = vehicle?.packages.map((el) => {
            return el.milage
          })

          milages = Array.from(new Set([...milages, ...currentMilages]))
        }

        return milages
      }, [])
      .map((milage) => ({
        header: `${(milage * 1000) / params.months} Km/Mês`,
        acessor: milage,
        total: `Total: ${milage * 1000} Km`,
      }))

    const lastUpdateDate = format(
      parseISO(data?.lastUpdateDate),
      "dd/MM/yyyy' às 'HH:mm'h'",
      {
        locale: ptBR,
      }
    )

    const formattedData = {
      ...data,
      columns,
      lastUpdateDate,
    }

    return formattedData
  } catch (error) {
    throw new AppError(
      'Ocorreu um problema, tente novamente mais tarde.',
      error.response.status
    )
  }
}

export async function getVehicleCategorys(params) {
  try {
    const { data } = await api.get('/cf_stock/categories', {
      params,
    })

    const categories = toOptions(data, 'name', 'name')

    return categories
  } catch (error) {
    throw Error('Ocorreu um problema, tente novamente mais tarde.')
  }
}

export async function updatePriceTable() {
  try {
    const { data } = await api.patch('/cf_stock/stock_price_trigger_routine')

    return data
  } catch (error) {
    throw Error('Ocorreu um problema, tente novamente mais tarde.')
  }
}

export async function getAdditionalKilometers() {
  try {
    const { data } = await api.get('/cf_stock/list_additional_kms')

    const formattedData = data.map((additionalKm) => {
      return {
        category: additionalKm.category,
        value: handleBrlValue(additionalKm.value),
      }
    })
    return formattedData
  } catch (error) {
    throw Error(
      'Ocorreu um problema ao buscar os dados da tabela, tente novamente mais tarde.'
    )
  }
}
