import styled, { css } from 'styled-components'
import media from 'styled-media-query'

export const Form = styled.form`
  ${({ theme }) => css`
    margin-block: ${theme.spacings.xsmall};
  `}
`

export const ModalText = styled.p`
  ${({ theme }) => css`
    font-weight: bold;
    font-size: ${theme.fonts.sizes.small};
    line-height: 20px;
    color: ${theme.colors.gray.davys};

    ${media.lessThan('small')`
      ${theme.fonts.sizes.xsmall};
    `}

    margin-bottom: ${theme.spacings.xsmall};
  `}
`
