import styled, { css } from 'styled-components'

export const Container = styled.div`
  ${({ theme }) => css`
    > svg {
      color: ${theme.colors.newGray[20]};
      cursor: pointer;
    }
  `}
`

export const FavoriteIconWrapper = styled.div`
  ${({ theme }) => css`
    > svg {
      cursor: pointer;
      color: ${theme.colors.warning};
      fill: ${theme.colors.warning};
    }
  `}
`

export const Animate = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;

    animation: loading 2s linear infinite;

    > svg {
      color: ${theme.colors.newGray[20]};
      cursor: not-allowed;
    }

    @keyframes loading {
      0% {
        transform: rotate(0);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  `}
`
