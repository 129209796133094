import React from 'react'
import Select from 'react-select'

import { Container, styles } from './styles'

export default function Multiselect({
  options,
  placeholder,
  onChange,
  selectedvalue,
  ...props
}) {
  return (
    <Container>
      <Select
        {...props}
        options={options}
        styles={styles}
        value={selectedvalue ? selectedvalue : null}
        noOptionsMessage={() => 'Nenhuma opção'}
        placeholder={placeholder}
        onChange={(option) => onChange(option)}
        closeMenuOnSelect={false}
        isSearchable={false}
        isMulti
        isClearable
      />
    </Container>
  )
}
