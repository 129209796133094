import { rem } from 'polished'
import styled, { css } from 'styled-components'
import media from 'styled-media-query'

export const Container = styled.div`
  ${({ theme }) => css`
    position: relative;
  `}
`

export const Input = styled.input`
  ${({ theme }) => css`
    width: 100%;
    height: 100%;

    padding: 0 16px;
    height: 48px;

    color: ${theme.colors.primary};

    font-size: ${rem('12px')};
    font-family: ${theme.fonts.medium};

    border-radius: 8px;
    border: 2px solid ${theme.colors.gray.medium};
    background: transparent;
    outline: none;

    ${media.greaterThan('huge')`
      height: 50px;

      font-size: ${rem('14px')};
    `}

    &::placeholder {
      color: ${theme.colors.gray.medium};
    }

    &:focus {
      border-color: ${theme.colors.primary};
    }
  `}
`

