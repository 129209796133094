import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { FormContext, useForm } from 'react-hook-form'

import { fetchBrokerBySusep, createUser } from 'services'

import { Grid, Input, Button, Select, Tip } from 'components'

import {
  Container,
  Content,
  Box,
  Label,
  Form,
  Fields,
  Actions,
  Error,
} from './styles'

import * as validation from './validation'

export default function Register() {
  const [error, setError] = useState('')
  const [type, setType] = useState('defaults')
  const [loading, setLoading] = useState(false)

  const [broker, setBroker] = useState({
    error: '',
    loading: false,
    fulfilled: false,
  })

  const form = useForm({
    validationSchema: validation[type],
  })

  const history = useHistory()

  const fetchBrokerInfos = async (event) => {
    if (!event.target.value) return
    if (form.watch('broker.susep').length > 6) {
      form.setError(
        'broker.susep',
        'notMatch',
        'Por favor, insira até 6 caracteres'
      )
      return
    }
    form.clearError('broker.susep')

    try {
      setBroker({
        error: '',
        loading: true,
        fulfilled: false,
      })

      const response = await fetchBrokerBySusep(form.watch('broker.susep'))

      setBroker((state) => ({ ...state, fulfilled: true }))

      form.setValue([
        {
          broker: {
            branch: response.branch?.number || '',
          },
        },
      ])
    } catch (error) {
      setBroker((state) => ({ ...state, error: error.message }))
    } finally {
      setBroker((state) => ({ ...state, loading: false }))
    }
  }

  const onSubmit = form.handleSubmit(async (values) => {
    try {
      if (values.role === 'adviser') {
        const branches = values.adviser.branches
          .split(',')
          .map((item) => item.trim())

        values.adviser.branches = branches
      }

      const data = {
        ...values,
        [values.role]: {
          ...values[values.role],
          cellphone: values[values.role].cellphone.replace(/\s/g, ''),
        },
      }

      setError('')
      setLoading(true)

      await createUser(data)

      history.push('/usuarios')
    } catch (error) {
      setError(error.message)
    } finally {
      setLoading(false)
    }
  })

  useEffect(() => {
    if (!form.watch('broker.susep')) {
      form.setValue([
        {
          broker: {
            susep: '',
            name: '',
            email: '',
            cellphone: '',
            document: '',
            branches: '',
            password: '',
          },
        },
      ])

      return setBroker({
        error: '',
        loading: false,
        fulfilled: false,
      })
    }
  }, [form.watch('broker.susep')]) // eslint-disable-line

  return (
    <Container>
      <Content>
        <Box>
          <Label>Cadastrar novo usuário</Label>

          <FormContext {...form}>
            <Form>
              <Grid
                m="30px 0"
                gridRowGap="20px"
                gridColumnGap="40px"
                gridTemplateColumns={['1fr', '1fr 1fr 1fr']}
              >
                <Select
                  options={[
                    {
                      label: 'Administrador',
                      value: 'administrator',
                    },
                    {
                      label: 'Corretor',
                      value: 'broker',
                    },
                    {
                      label: 'Consultor',
                      value: 'adviser',
                    },
                  ]}
                  name="role"
                  placeholder="Selecione o perfil de acesso"
                  onChange={(e) => setType(e.target.value)}
                />
              </Grid>

              {type === 'administrator' && (
                <Fields
                  m="30px 0"
                  gridRowGap="20px"
                  gridColumnGap="40px"
                  gridTemplateColumns={['1fr', '1fr 1fr 1fr']}
                >
                  <Input
                    name="administrator.name"
                    label="Nome"
                    placeholder="Digite o nome"
                  />

                  <Input
                    name="administrator.email"
                    label="E-mail"
                    placeholder="Digite o seu e-mail"
                  />

                  <Input
                    label="CPF"
                    name="administrator.document"
                    placeholder="Digite o CPF"
                    mask="###.###.###-##"
                    numericOnly
                  />
                  <Input
                    name="administrator.cellphone"
                    placeholder="Celular"
                    label="Celular"
                    phone
                  />

                  <Input
                    name="administrator.registry"
                    label="Matrícula"
                    placeholder="Digite a sua matrícula"
                    toUpperCase
                  />

                  <Input
                    name="administrator.password"
                    label="Senha"
                    type="password"
                    placeholder="Digite a senha"
                  />
                </Fields>
              )}

              {type === 'broker' && (
                <>
                  <Fields
                    m="30px 0"
                    gridRowGap="20px"
                    gridColumnGap="40px"
                    gridTemplateColumns={['1fr', '1fr 1fr 1fr']}
                  >
                    <Input
                      name="broker.susep"
                      label="SUSEP"
                      placeholder="Digite a SUSEP"
                      onBlur={fetchBrokerInfos}
                      isLoading={broker.loading}
                      toUpperCase
                    />
                  </Fields>

                  <Fields
                    m="30px 0"
                    gridRowGap="20px"
                    gridColumnGap="40px"
                    gridTemplateColumns={['1fr', '1fr 1fr 1fr']}
                    hidden={!broker.fulfilled}
                  >
                    <Input
                      name="broker.name"
                      label="Nome"
                      placeholder="Digite o nome"
                    />

                    <Input
                      name="broker.email"
                      label="E-mail"
                      placeholder="Digite o seu e-mail"
                    />

                    <Input
                      label="CPF"
                      name="broker.document"
                      placeholder="Digite o CPF"
                      mask="###.###.###-##"
                      numericOnly
                    />
                    <Input
                      name="broker.cellphone"
                      placeholder="Celular"
                      label="Celular"
                      phone
                    />
                    <Input
                      name="broker.branch"
                      label="Sucursal"
                      placeholder="Digite a Sucursal (opcional)"
                      numericOnly
                    />

                    <Input
                      name="broker.password"
                      type="password"
                      label="Senha"
                      placeholder="Digite a senha"
                    />
                  </Fields>
                </>
              )}

              {type === 'adviser' && (
                <Fields
                  m="30px 0"
                  gridRowGap="20px"
                  gridColumnGap="40px"
                  gridTemplateColumns={['1fr', '1fr 1fr 1fr']}
                >
                  <Input
                    name="adviser.name"
                    label="Nome"
                    placeholder="Digite o nome"
                  />

                  <Input
                    name="adviser.email"
                    label="E-mail"
                    placeholder="Digite o seu e-mail"
                  />

                  <Input
                    label="CPF"
                    name="adviser.document"
                    placeholder="Digite o CPF"
                    mask="###.###.###-##"
                    numericOnly
                  />

                  <Input
                    name="adviser.cellphone"
                    placeholder="Celular"
                    label="Celular"
                    phone
                  />

                  <Grid gridTemplateColumns="100% 5%">
                    <Input
                      name="adviser.branches"
                      label="Sucursal"
                      placeholder="Digite a Sucursal"
                      toUpperCase
                    />
                    <Tip size="15px" name="info" icon="info">
                      Separe as sucursais por{' '}
                      <b>
                        virgula
                        <br />
                        Ex: 23, 34, 45
                      </b>
                    </Tip>
                  </Grid>

                  <Input
                    name="adviser.password"
                    label="Senha"
                    type="password"
                    placeholder="Digite a senha"
                  />
                </Fields>
              )}
            </Form>
          </FormContext>

          <Actions>
            <Button color="gray" onClick={() => history.push('/usuarios')}>
              Voltar
            </Button>

            <Button onClick={onSubmit} isLoading={loading} color="success">
              Salvar novo usuário
            </Button>
          </Actions>

          {broker.error && <Error>{broker.error}</Error>}
          {error && <Error>{error}</Error>}
        </Box>
      </Content>
    </Container>
  )
}

Register.properties = {
  metatags: {
    title: 'Usuários',
  },
}
