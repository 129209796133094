import { useLayoutEffect } from 'react'

export function useLockBodyScroll(active) {
  useLayoutEffect(() => {
    // Get original body overflow
    const originalStyle = window.getComputedStyle(document.body).overflow
    if (active) {
      // Prevent scrolling on mount
      document.body.style.overflow = 'hidden'
    }
    // Re-enable scrolling when component unmounts
    return () => (document.body.style.overflow = originalStyle)
  }, [active]) // Empty array ensures effect is only run on mount and unmount
}
