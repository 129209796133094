import * as yup from 'yup'

export const employee = yup.object().shape({
  employee: yup.object().shape({
    email: yup
      .string()
      .email('Por favor insira um e-mail válido')
      .required('Por favor insira seu e-mail'),
    password: yup.string().required('Por favor insira a sua senha'),
  }),
})

export const broker = yup.object().shape({
  broker: yup.object().shape({
    susep: yup.string().required('Por favor insira a sua SUSEP'),
    password: yup.string().required('Por favor insira a sua senha'),
  }),
})
