import React, { useEffect } from 'react'
import ReactDOM from 'react-dom'
import { ThemeProvider as Theme } from 'styled-components'
import { Provider as Redux } from 'react-redux'
import { PersistGate as Persist } from 'redux-persist/integration/react'
import { QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'

import store, { persistor } from 'store'
import Router from 'routes/router'
import { AuthProvider as Auth, Error } from 'components'
import * as theme from 'styles'
import GlobalStyles from 'styles/global'
import { queryClient } from 'services/queryClient'
import { ErrorBoundary } from 'react-error-boundary'

const version = 'v1.0.3'

const App = () => {
  async function init() {
    window.build_version = version
  }

  useEffect(() => {
    init()
  }, [])

  return (
    <React.StrictMode>
      <Theme theme={theme}>
        <GlobalStyles />
        <ErrorBoundary FallbackComponent={Error}>
          <Redux store={store}>
            <Persist persistor={persistor}>
              <QueryClientProvider client={queryClient}>
                <Auth>
                  <Router />
                </Auth>
                <ReactQueryDevtools />
              </QueryClientProvider>
            </Persist>
          </Redux>
        </ErrorBoundary>
      </Theme>
    </React.StrictMode>
  )
}

ReactDOM.render(<App />, document.getElementById('root'))
