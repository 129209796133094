import axios from 'axios'
import { refreshAuth } from './apiV2/users'

import store from 'store'
import { handleLogoutToCurrentUser } from 'store/reducers/users/middlewares'

export * from './api/users'
export * from './api/plans'
export * from './api/roles'
export * from './api/orders'
export * from './api/coupons'
export * from './api/brokers'
export * from './api/products'
export * from './api/addresses'
export * from './api/customers'
export * from './api/system-roles'
export * from './apiV2/users'
export * from './apiV2/renovation'
export * from './apiV2/prices'
export * from './apiV2/stock'
export * from './apiV2/orders'
export * from './apiV2/products'
export * from './apiV2/news'


const api = axios.create({
  baseURL: `${process.env.REACT_APP_API_ENDPOINT}`,
})

api.interceptors.response.use(
  (response) => response,
  (error) => {
    const { dataLayer = [] } = window
    dataLayer.push({
      event: 'erro',
      codigo: error.response?.status,
      servico: error.response?.config.url,
      mensage: error.message,
    })

    return Promise.reject(error)
  }
)

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (
      error?.response?.status === 401 &&
      error?.response?.data?.message === 'Invalid token'
    ) {
      const { dispatch } = store
      dispatch(handleLogoutToCurrentUser())
    }

    return Promise.reject(error)
  }
)

api.interceptors.request.use(async (config) => {
  const refresh_token = localStorage.getItem('refresh_token')
  const { dispatch } = store

  if (!refresh_token) {
    dispatch(handleLogoutToCurrentUser())
    return config
  }

  await refreshAuth({ refresh_token })

  config.headers.fireToken = localStorage.getItem('token')
  return config
})

export default api
