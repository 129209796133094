import styled, { css } from 'styled-components'
import media from 'styled-media-query'

export const ContentDrawer = styled.div`
  ${({ theme }) => css`
    margin-top: ${theme.spacings.xxlarge};
    padding: ${theme.spacings.xsmall};

    display: grid;
    grid-template-columns: 280px 220px;
    gap: ${theme.spacings.medium};

    ${media.lessThan('small')`
    margin-top: ${theme.spacings.small};
      grid-template-columns: 1fr;
      align-items: center;
    `}
  `}
`

export const Description = styled.div`
  ${({ theme }) => css`
    margin-top: ${theme.spacings.small};

    max-width: 220px;
    font-weight: 400;
    font-size: 14px;
    line-height: 1.2;
    color: ${theme.colors.white};
    margin-bottom: ${theme.spacings.xsmall};

    p {
      color: inherit;
      font-size: inherit;
      font-weight: inherit;
    }
    h1,
    h2,
    h3,
    h4 {
      color: inherit;
    }
  `}
`

export const Image = styled.img`
  max-width: 280px;
  width: 100%;
  max-height: 700px;
  height: 100%;
`
