import { rem } from 'polished'
import styled, { css } from 'styled-components'
import media from 'styled-media-query'
import {
  Tip as HelpTip,
  Container as HelpContainer,
} from 'components/Help/styles'

export const Container = styled.div`
  ${HelpContainer} {
    width: 16px;
    height: 16px;
  }
`

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${rem('18px')};
`

export const Update = styled.span`
  ${({ theme }) => css`
    font-size: 1rem;
    color: ${theme.colors.gray.davys};
  `}
`

export const Title = styled.h1`
  font-size: ${rem('32px')};
  font-family: ${({ theme }) => theme.fonts.book};
  margin: 0;
`

export const ErrorMessage = styled.p`
  padding-top: 20px;

  font-size: 13px;
  color: ${({ theme }) => theme.colors.danger};
  font-family: ${({ theme }) => theme.fonts.bold};

  ${media.lessThan('large')`
    font-size: 11px;
  `}
`

export const KmAdditionalWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0.5rem 0;

  ${HelpTip} {
    ${media.between('medium', 'large')`
    width: ${rem('500px')};
  `}

    ${media.lessThan('medium')`
    left: -11rem; 
    width: ${rem('300px')};
    
    &::before {
      left: 11.5rem;
    }
  `}
  }
`

export const KmAdditional = styled.p`
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.gray.dark};
  margin-right: ${rem('18px')};
`
