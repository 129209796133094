import React, { useState } from 'react'
import { Loading, Icon, Flex, MediaMatch } from 'components'
import { useGetGroupStatus } from 'services/hooks/customers'

import SubMenu from '../SubMenu'
import StatusDrawer from '../StatusDrawer'

import * as S from './styles'

function StatusFilter() {
  const [filterMobileIsOpen, setFilterMobileIsOpen] = useState(false)
  const [drawerOpen, setDrawerOpen] = useState(false)
  const [selectedTitle, setSelectedTitle] = useState(null)

  const { data, isLoading } = useGetGroupStatus()

  const toggleFilterMobile = () => {
    setFilterMobileIsOpen((prevIsOpen) => !prevIsOpen)
  }

  const handleCloseDrawer = () => {
    setDrawerOpen(false)
  }

  const handleOpenDrawer = () => {
    setDrawerOpen(true)
  }

  const handleSelectedTitle = (item) => {
    setSelectedTitle(item)
  }

  return (
    <>
      <MediaMatch lessThan="xlarge">
        <S.Wrapper>
          <S.Container>
            <S.FilterTitle>Clientes</S.FilterTitle>
            <S.Chip
              data-gtm-click="click"
              data-gtm-clicktype="button"
              data-gtm-name="filtrar"
              onClick={toggleFilterMobile}
            >
              Filtrar
              <Icon name="chevron-down" size="16px" />
            </S.Chip>
            <S.Dropdown isOpen={filterMobileIsOpen} isOpenDrawer={drawerOpen}>
              <StatusDrawer isOpen={drawerOpen} onClose={handleCloseDrawer} />

              <S.IconWrapper>
                <Icon name="x" onClick={toggleFilterMobile} />
              </S.IconWrapper>

              <S.FilterContainer>
                <S.Header>
                  <h2>Filtros</h2>
                  <Icon name="alert-circle" onClick={handleOpenDrawer} />
                </S.Header>
                {isLoading ? (
                  <Flex py="16px">
                    <Loading size={'8px'} color="white" />
                  </Flex>
                ) : (
                  <>
                    {data?.map((item, index) => {
                      return (
                        <SubMenu
                          item={item}
                          key={index}
                          selectedDropdown={selectedTitle}
                          onSelectedDropdown={handleSelectedTitle}
                          onToggleFilterMobile={toggleFilterMobile}
                        />
                      )
                    })}
                  </>
                )}
              </S.FilterContainer>
            </S.Dropdown>
          </S.Container>
        </S.Wrapper>
      </MediaMatch>

      <MediaMatch greaterThan="xlarge">
        <S.Sidebar>
          <StatusDrawer isOpen={drawerOpen} onClose={handleCloseDrawer} />

          <S.FilterContainer>
            <S.Header>
              <h2>Filtros</h2>
              <Icon name="alert-circle" onClick={handleOpenDrawer} />
            </S.Header>
            {isLoading ? (
              <Flex py="16px">
                <Loading size={'8px'} color="white" />
              </Flex>
            ) : (
              <>
                {data?.map((item, index) => {
                  return (
                    <SubMenu
                      item={item}
                      key={index}
                      selectedDropdown={selectedTitle}
                      onSelectedDropdown={handleSelectedTitle}
                    />
                  )
                })}
              </>
            )}
          </S.FilterContainer>
        </S.Sidebar>
      </MediaMatch>
    </>
  )
}

export default StatusFilter
