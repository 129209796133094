import React from 'react'
import { convertHtmlToString, formatPublishDate } from 'utils'
import { Icon, MediaMatch } from 'components'

import * as S from './styles'

function NewsCards({ data, isLoading }) {
  if (isLoading) {
    return <S.Loader />
  }

  return (
    <>
      {!isLoading && data?.length === 0 ? (
        <S.Message>Nenhuma novidade encontrada.</S.Message>
      ) : (
        data?.map((news) => {
          const description = convertHtmlToString(news?.content)
          const publishDate = formatPublishDate(news?.createdAt)

          return (
            <S.Container key={news.id}>
              <S.Header>
                <S.PublishDate>{publishDate}</S.PublishDate>
              </S.Header>
              <S.Content>
                <S.Title to={`/novidades/${news?.id}`}>{news?.title}</S.Title>
                <S.Description>{description}</S.Description>
                <MediaMatch lessThan="medium">
                  <S.ReadMoreButton to={`/novidades/${news?.id}`}>
                    Leia mais <Icon name="chevron-right" />
                  </S.ReadMoreButton>
                </MediaMatch>
              </S.Content>
            </S.Container>
          )
        })
      )}
    </>
  )
}

export default NewsCards
