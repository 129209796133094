import styled from 'styled-components'

export const Dialog = styled.div`
  margin-bottom: 2.5rem;

  padding: 30px;

  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  background: #fff;
  border-radius: 5px;
`

export const Label = styled.p`
  color: ${({ theme }) => theme.colors.info};
  font-family: ${({ theme }) => theme.fonts.bold};
  font-size: 14px;
`

export const Content = styled.div`
  margin: 1.5rem 0;
`
