import * as yup from 'yup'

export default yup.object().shape({
  logo: yup
    .mixed()
    .test(
      'fileRequired',
      'Por favor, selecione uma imagem',
      (value) => value[0]?.name
    )
    .test(
      'fileType',
      'Por favor, selecione uma imagem válida.',
      (file) => file[0]?.name.match(/\.(jpg|jpeg|png)$/)
    ),
  name: yup.string().required('Por favor, insira o nome'),
  email: yup
    .string()
    .email('Por favor, insira um e-mail válido')
    .required('Por favor, insira o e-mail'),
  whatsapp_number: yup
    .string()
    .min(13, 'Por favor, insira um número válido')
    .max(13, 'Por favor, insira um número válido')
    .required('Por favor, insira o seu whatsapp'),
})
