import { useLayoutEffect } from 'react'

import api from 'services'

import { useCurrentUser } from 'hooks'

export default function Provider({ children }) {
  const { token } = useCurrentUser()

  useLayoutEffect(() => {
    api.defaults.headers.authorization = `Bearer ${token}`
  }, [token])

  return children
}
