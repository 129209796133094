import React, { useEffect } from 'react'
import qs from 'qs'
import { debounce } from 'lodash'
import { format, parse, parseISO } from 'date-fns'
import { FormContext, useForm } from 'react-hook-form'
import { USER_ROLES } from 'helpers'
import { Allow, IndicatorCard } from 'components'

import { useOrdersResume } from 'services/hooks/Indicators'
import { Input, DateRangePicker } from './components'
import { formatPtBRDate } from 'utils'
import * as S from './styles'
import { useParams } from 'hooks'
import { useLocation } from 'react-router-dom'

const Indicators = () => {
  const location = useLocation()
  const dataLayer = window.dataLayer || []

  const { params, setParams } = useParams()

  const formattedPeriodDefaultValue = () => {
    if (!params.startDate || !params.endDate) {
      return
    }

    const initDate = parse(params?.startDate, 'yyyy-MM-dd', new Date())
    const endDate = parse(params?.endDate, 'yyyy-MM-dd', new Date())

    const formattedStartDate = formatPtBRDate(initDate)
    const formattedEndDate = formatPtBRDate(endDate)

    return `${formattedStartDate} - ${formattedEndDate}`
  }

  const form = useForm({
    defaultValues: {
      ...params,
      period: formattedPeriodDefaultValue(),
    },
  })

  const onInputChange = debounce(({ name, value }) => {
    setParams({ [name]: value })
  }, 1000)

  const handlePeriodFilter = form.handleSubmit((values) => {
    if (values.period) {
      const [startDate, endDate] = values.period.split('-')

      const period = {
        start: parse(startDate, 'dd/MM/yyyy', new Date()),
        end: parse(endDate.trim(), 'dd/MM/yyyy', new Date()),
      }

      setParams({
        endDate: format(period.end, 'yyyy-MM-dd'),
        startDate: format(period.start, 'yyyy-MM-dd'),
      })
    } else {
      setParams({
        startDate: null,
        endDate: null,
      })
    }
  })


  const { data, isLoading, error, isError } = useOrdersResume(params, {
    onSuccess: (data) => {
      dataLayer.push({ ...data.dataLayer })
    },
  })

  const ordersResumeformatted = data?.ordersResume?.map((order) => {
    const periodParams = {
      start: params.startDate || null,
      end: params.endDate || null,
    }

    const queryString = qs.stringify({ ...periodParams }, { skipNulls: true })

    return {
      ...order,
      child_status: order.child_status.map((status) => ({
        ...status,
        filter: {
          pathname: '/clientes',
          search: !!queryString
            ? `?status=${status.code}&${queryString}&page=1`
            : `?status=${status.code}&page=1`,
          from: location.pathname,
        },
      })),
    }
  })

  useEffect(() => {
    dataLayer.push({
      event: 'step_change',
      etapa: '/indicadores',
    })
  }, []) //eslint-disable-line

  return (
    <S.Main>
      <S.Container>
        <S.Box>
          <S.Title>Propostas de novos negócios</S.Title>
          <S.Filters>
            <FormContext {...form}>
              <DateRangePicker
                name="period"
                placeholder="Escolha a data"
                label="Ordenar por data"
                onClick={handlePeriodFilter}
                initialState={() => {
                  const dateNow = new Date()

                  const startDate = params.startDate
                    ? parseISO(params.startDate)
                    : dateNow
                  const endDate = params.endDate
                    ? parseISO(params.endDate)
                    : dateNow

                  return {
                    startDate,
                    endDate,
                    key: 'selection',
                  }
                }}
              />
              <Allow roles={[USER_ROLES.ADMINISTRATOR, USER_ROLES.ADVISER]}>
                <Input
                  name="susep"
                  placeholder="Procurar por SUSEP"
                  label="SUSEP"
                  uppercase
                  type="text"
                  onChange={onInputChange}
                />
                <Input
                  name="branch"
                  placeholder="Procurar por Sucursal"
                  label="Sucursal"
                  type="number"
                  onChange={onInputChange}
                />
              </Allow>
            </FormContext>
          </S.Filters>
          {isLoading ? (
            <S.Message color="gray">
              Aguarde, estamos carregando as informações.
            </S.Message>
          ) : isError ? (
            <S.Message color="danger">{error.message}</S.Message>
          ) : (
            ordersResumeformatted?.map((order, index) => (
              <S.Wrapper key={index}>
                <S.Subtitle>{order.label}</S.Subtitle>
                <S.CardContainer>
                  {order.child_status.map((status, index) => (
                    <IndicatorCard key={index} {...status} />
                  ))}
                </S.CardContainer>
              </S.Wrapper>
            ))
          )}
        </S.Box>
      </S.Container>
    </S.Main>
  )
}

export default Indicators

Indicators.properties = {
  metatags: {
    title: 'Indicadores',
  },
}
