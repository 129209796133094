import styled, { css } from 'styled-components'
import media from 'styled-media-query'

const wrapperModifiers = {
  open: () => css`
    opacity: 1;
    pointer-events: auto;
    transform: translateX(0);
  `,
  close: () => css`
    opacity: 0;
    pointer-events: none;
    transform: translateX(-4rem);
  `,
}

export const FilterInfo = styled.div`
  ${({ theme, isOpen }) => css`
    width: 250px;
    
    ${media.lessThan('huge')`
      width: 230px;
    `}
    
    height: 100%;
    background-color: rgba(0, 0, 0, 0.95);

    z-index: ${theme.layers.modal};
    position: absolute;

    top: 0;
    left: 0;
    bottom: 0;
    transition: transform 0.2s ease-in, opacity 0.3s;

    ${isOpen && wrapperModifiers.open()}
    ${!isOpen && wrapperModifiers.close()}
  `}
`
export const Header = styled.header`
  ${({ theme }) => css`
    padding: ${theme.spacings.small};

    border-bottom: ${theme.border.thin} solid rgba(255, 255, 255, 0.25);

    display: flex;
    align-items: center;

    > h2 {
      font-weight: 600;
      font-size: ${theme.fonts.sizes.large};
      line-height: 1.5;

      color: ${theme.colors.white};
      margin-right: ${theme.spacings.small};
    }

    > svg {
      color: ${theme.colors.white};
      width: 20px;
      height: 20px;
      cursor: pointer;
    }
  `}
`

export const Content = styled.div`
  ${({ theme }) => css`
    font-weight: 400;
    font-size: ${theme.fonts.sizes.xsmall};
    line-height: 1.5;

    color: ${theme.colors.white};

    padding: ${theme.spacings.small};

    p {
      margin-bottom: ${theme.spacings.small};
    }
  `}
`

export const IconWrapper = styled.div`
  ${({ theme }) => css`
    > svg {
      width: 24px;
      height: 24px;

      color: ${theme.colors.white};
      margin-top: 5%;

      position: absolute;
      top: ${theme.spacings.xsmall};
      right: ${theme.spacings.small};
    }
    cursor: pointer;
  `}
`
