import * as yup from 'yup'
import { validatePhone, isValidCPF } from 'utils'

export const schema = yup.object().shape({
  name: yup.string().required('Por favor insira o nome'),
  document: yup
    .string()
    .required('Por favor insira o CPF')
    .test('document-test', 'Por favor insira um CPF válido', (value) =>
      isValidCPF(value)
    ),
  cellphone: yup
    .string()
    .required('Por favor insira o celular')
    .test('validateQuantity', 'Por favor insira um celular válido', (value) =>
      validatePhone(value)
    ),
  branches: yup.string().required('Por favor insira a sucursal'),
})

export const emailSchema = yup.object().shape({
  email: yup
    .string()
    .email('Por favor insira um e-mail válido')
    .required('Por favor insira um e-mail'),
})
