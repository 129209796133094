import { Button, Flex } from 'components'
import Modal from 'components/Modals/Simple'
import { useLockBodyScroll } from 'hooks'
import React from 'react'

function ModalRefreshTable ({ isOpen, toggleModal }) {
  useLockBodyScroll(isOpen)

  return (
    <Modal open={isOpen} title='Atualização de dados da operação'>
      <p>
        A rotina de atualização foi iniciada, aguarde alguns instantes e
        recarregue o Painel.
      </p>
      <Flex mt='50px' justifyContent='flex-end'>
        <Button onClick={toggleModal} px='30px'>
          Fechar
        </Button>
      </Flex>
    </Modal>
  )
}

export default ModalRefreshTable
