export const removeSpecialCharacters = (value) => {
  return value.replace(/[^A-Z0-9]/gi, '')
}

export const removeTelephoneMask = (tel) => {
  if (!tel) {
    return
  }

  return tel.replace(/\D/g, '')
}
