import * as yup from 'yup'
import { validatePhone, isValidCPF } from 'utils'

export default yup.object().shape({
  broker: yup.object().shape({
    susep: yup.string().required('Por favor insira a SUSEP'),
    name: yup.string().required('Por favor insira o nome'),
    email: yup
      .string()
      .email('Por favor insira um e-mail válido')
      .required('Por favor insira o e-mail'),
    cellphone: yup
      .string()
      .required('Por favor insira o celular')
      .test('validateQuantity', 'Por favor insira um celular válido', (value) =>
        validatePhone(value)
      ),
    document: yup
      .string()
      .required('Por favor insira o CPF')
      .test('document-test', 'Por favor insira um CPF válido', (value) =>
        isValidCPF(value)
      ),
    password: yup
      .string()
      .min(6, 'A senha deve conter no mínimo 6 caracteres')
      .required('Por favor insira uma senha de acesso'),
  }),
})
