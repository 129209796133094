import styled from 'styled-components'
import media from 'styled-media-query'

export const Main = styled.main`
  display: flex;
  justify-content: space-between;

  ${media.lessThan('large')`
    flex-direction: column;
  `}
`

export const Content = styled.div`
  flex: 1;
  width: 100%;

  ${media.lessThan('large')`
    order: 2;
  `}
`
