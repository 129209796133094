import styled, { css } from 'styled-components'
import media from 'styled-media-query'
import * as tipStyles from 'components/Tip/styles'

export const Container = styled.div``

export const ErrorMessage = styled.p`
  padding-top: 20px;

  font-size: 13px;
  color: ${({ theme }) => theme.colors.danger};
  font-family: ${({ theme }) => theme.fonts.bold};

  ${media.lessThan('large')`
    font-size: 11px;
  `}
`

export const Label = styled.p`
  margin-top: 10px;

  color: ${({ theme }) => theme.colors.gray.medium};
  font-family: ${({ theme }) => theme.fonts.bold};
  font-size: 12px;
`
export const TipContainer = styled.div`
  position: relative;

  ${tipStyles.Mark} {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: -2px;
  }
`

export const Actions = styled.div`
  display: flex;
  justify-content: space-between;

  ${media.lessThan('medium')`
    gap: 16px;
    flex-direction: column;
  `}
`
export const Text = styled.p`
  ${({theme}) => css`
    color: ${theme.colors.white};
    font-size: ${theme.fonts.sizes.xxsmall};
  `}
`