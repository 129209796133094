import styled, { css } from 'styled-components'
import media from 'styled-media-query'
import { rem } from 'polished'

export const Container = styled.div`
  background-color: #008fff;

  ${media.greaterThan('large')`
    ::-webkit-scrollbar {
      width: 0;
      height: 0;
    }  

    overflow-y: scroll;
    position: sticky;
    top: 0;
    z-index: ${({ theme }) => theme.layers.aside};
    
    max-width: 200px;
    width: 100%;
    height: 100vh;
    padding: 10px 0;
  `}

  ${media.lessThan('large')`
    display: flex;
    flex-wrap: wrap;
  `}
`

export const Box = styled.div`
  ${media.greaterThan('large')`
    position: sticky;
    top: 30px;
  `}
`

export const Item = styled.button`
  all: unset;

  width: 100%;
  padding: 16px 40px;

  color: #fff;
  cursor: pointer;
  transition: 0.3s;
  user-select: none;
  box-sizing: border-box;
  font-size: ${rem('14px')};
  font-family: ${({ theme }) => theme.fonts.bold};

  &:hover {
    transition: 0.3s;
    background-color: #36517e;
  }

  ${media.greaterThan('large')`
    &:not(:last-of-type) {
      margin-bottom: 5px;
    } 
  `}

  ${media.lessThan('large')`
    width: 50%;
    padding: 16px 20px;

    font-size: ${rem('11px')};
  `}

  ${({ active }) =>
    active &&
    css`
      transition: 0.3s;
      background-color: #36517e;

      ${media.greaterThan('large')`
        width: 103%;
      `}
    `}
`
