import { useQuery } from 'react-query'
import { getStatus, getAllOrders, getGroupStatus } from 'services'

export function useGetStatus(options) {
  return useQuery('status', () => getStatus(), {
    staleTime: Infinity,
    refetchOnWindowFocus: false,
    ...options,
  })
}

export function useGetGroupStatus(options) {
  return useQuery('groupStatus', () => getGroupStatus(), {
    staleTime: Infinity,
    refetchOnWindowFocus: false,
    ...options,
  })
}

export function useGetAllOrders(params, options) {
  return useQuery(
    ['orders', params],
    () => getAllOrders({ requestParams: params }),
    {
      refetchOnWindowFocus: false,
      ...options,
    }
  )
}
