import * as yup from 'yup'
import { convertHtmlToString } from 'utils'

function isOnlySpaces(str = '') {
  return str.trim().length === 0
}
export default yup.object().shape({
  title: yup
    .string()
    .required('Por favor, insira o título')
    .test('title-empty', 'Por favor, insira o título', (value) => {
      return !isOnlySpaces(value)
    }),
  tags: yup.mixed().required('Por favor, selecione a categoria'),
  subTitle: yup.string().transform((curr, orig) => (orig === '' ? undefined : curr)),
  content: yup
    .string()
    .required('Por favor, insira a descrição')
    .test('content-empty', 'Por favor, insira a descrição', (value) => {
      
      return !isOnlySpaces(convertHtmlToString(value))
    }),
})
