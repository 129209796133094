import React from 'react'
import {
  Controller,
  ErrorMessage as Error,
  useFormContext,
} from 'react-hook-form'
import Select from 'react-select'

import { styles } from './styles'
import colors from 'styles/colors'
import { ErrorMessage } from 'components'
import * as S from './styles'

function InputSelect({ name, ...props }) {
  const { errors, control } = useFormContext()

  return (
    <S.Container>
      <Controller
        control={control}
        name={name}
        as={
          <Select
            styles={styles}
            name={name}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary: colors.primary,
              },
            })}
            noOptionsMessage={() => 'Nenhuma opção'}
            {...props}
          />
        }
      />

      <Error errors={errors} name={name} as={<ErrorMessage />} />
    </S.Container>
  )
}

export default InputSelect
