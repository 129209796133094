import * as yup from 'yup'
import { validatePhone, isValidCPF } from 'utils'

const defaultFields = {
  name: yup
    .string()
    .trim()
    .notOneOf([''], 'Por favor, insira o nome válido')
    .required('Por favor, insira o nome'),
  email: yup
    .string()
    .email('Por favor, insira um e-mail válido')
    .required('Por favor, insira o e-mail'),
  document: yup
    .string()
    .required('Por favor, insira o CPF')
    .test('document-test', 'Por favor, insira um CPF válido', (value) =>
      isValidCPF(value)
    ),
  cellphone: yup
    .string()
    .required('Por favor insira o celular')
    .test('validateQuantity', 'Por favor insira um celular válido', (value) =>
      validatePhone(value)
    ),
  password: yup
    .string()
    .trim()
    .notOneOf([''], 'Por favor, insira uma senha válida')
    .min(6, 'A senha deve conter no mínimo 6 caracteres')
    .required('Por favor, insira uma senha de acesso'),
}

export const defaults = yup.object().shape({
  role: yup
    .mixed()
    .oneOf(
      ['administrator', 'broker', 'adviser'],
      'Por favor, selecione um perfil de acesso.'
    ),
})

export const administrator = yup.object().shape({
  administrator: yup.object().shape({
    ...defaultFields,
    registry: yup.string().required('Por favor, insira a matrícula'),
  }),
})

export const broker = yup.object().shape({
  broker: yup.object().shape({
    ...defaultFields,
    susep: yup
      .string()
      .trim()
      .required('Por favor, insira a SUSEP')
      .matches(
        /^[a-zA-Z0-9]+$/,
        'Por favor, insira apenas valores alfanuméricos'
      )
      .notOneOf([''], 'Por favor, insira uma SUSEP válida'),
  }),
})

export const adviser = yup.object().shape({
  adviser: yup.object().shape({
    ...defaultFields,
    branches: yup.string().required('Por favor insira a sucursal'),
  }),
})
