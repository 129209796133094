import api from 'services'

export async function getRoles() {
  try {
    const response = await api.get('/integracao/roles')

    return response.data
  } catch (error) {
    throw Error(
      'Houve uma instabilidade no sistema. Tente novamente mais tarde ou entre em contato com a Porto Seguro.'
    )
  }
}

export async function getRoleIdByName(name) {
  try {
    const response = await api.get('/integracao/roles')

    return response.data.find((role) => role.type.includes(name)).id
  } catch (error) {
    throw Error(
      'Houve uma instabilidade no sistema. Tente novamente mais tarde ou entre em contato com a Porto Seguro.'
    )
  }
}
