import styled, { css } from 'styled-components'
import { rem, size } from 'polished'
import media from 'styled-media-query'
import styledMap from 'styled-map'

export const Container = styled.label`
  position: relative;

  display: inline-flex;
  align-items: center;

  cursor: pointer;

  &:not(:last-of-type) {
    margin-right: 20px;
  }
`

export const Pin = styled.div`
  position: relative;

  ${size('13px')}
  margin-right: 8px;

  display: inline-block;

  border: 2px solid transparent;

  ${media.greaterThan('huge')`
    ${size('17px')}
  `}

  ${media.lessThan('small')`
    border: 1px solid transparent;
  `}

  &:before {
    position: absolute;
    top: 1px;
    left: 1px;

    ${size('7px')}

    content: "";

    ${media.greaterThan('huge')`
      top: 2px;
      left: 2px;

      ${size('9px')}
    `}

    ${media.lessThan('small')`
      top: 3px;
      left: 3px;

      ${size('5px')}
    `}
  }
`

export const Label = styled.span`
  display: inline-flex;
  align-items: center;

  user-select: none;
  font-size: ${rem('11px')};
  color: ${({ theme }) => theme.colors.gray.medium};
  font-family: ${({ theme }) => theme.fonts.medium};

  ${media.greaterThan('huge')`
    font-size: ${rem('14px')};
  `}
`

export const Input = styled.input`
  display: none;

  &:checked + ${Pin} {
    border-color: ${({ theme }) => theme.colors.secondary};

    &:before {
      background-color: ${({ theme }) => theme.colors.primary};
    }
  }

  + ${Pin} {
    border-color: ${({ theme }) => theme.colors.gray.light};
    border-radius: ${styledMap('type', {
      checkbox: '3px',
      radio: '30px',
    })};

    &:before {
      border-radius: ${styledMap('type', {
        checkbox: '1px',
        radio: '30px',
      })};
    }
  }

  &:checked + ${Pin} + ${Label} {
    color: ${({ theme }) => theme.colors.gray.dark};
  }

  ${({ primary }) =>
    primary &&
    css`
      + ${Pin} {
        border-color: ${({ theme }) => theme.colors.gray.light};
        border-radius: ${styledMap('type', {
          checkbox: '3px',
          radio: '30px',
        })};

        &:before {
          border-radius: ${styledMap('type', {
            checkbox: '1px',
            radio: '30px',
          })};
        }
      }

      + ${Pin} + ${Label} {
        color: ${({ theme }) => theme.colors.gray.light};
      }

      &:checked + ${Pin} {
        border-color: ${({ theme }) => theme.colors.primary};

        &:before {
          background-color: ${({ theme }) => theme.colors.primary};
        }
      }

      &:checked + ${Pin} + ${Label} {
        color: ${({ theme }) => theme.colors.primary};
      }
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      + ${Pin}, + ${Pin} + ${Label} {
        cursor: no-drop;
      }
    `}

  ${({ invertColor }) =>
    invertColor &&
    css`
      + ${Pin} {
        border-color: ${({ theme }) => theme.colors.gray.light};
      }

      + ${Pin} + ${Label} {
        color: ${({ theme }) => theme.colors.gray.medium};
      }

      &:checked + ${Pin} {
        border-color: ${({ theme }) => theme.colors.primary};

        &:before {
          background-color: ${({ theme }) => theme.colors.primary};
        }
      }

      &:checked + ${Pin} + ${Label} {
        color: ${({ theme }) => theme.colors.primary};
      }
    `}
`
