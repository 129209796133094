import styled from 'styled-components'
import { darken } from 'polished'
import { colors } from 'styles'

const buttonTheme = (color) => {
  const buttons = {
    gray: colors.gray.medium,
  }

  if (!color) {
    return colors.primary
  }
  return buttons[color]
}

export const Button = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 90px;
  background-color: ${({ btnColor }) => buttonTheme(btnColor)};

  padding: 12px 25px;
  cursor: pointer;

  span {
    font-size: 12px;
    line-height: 15px;
    font-weight: bold;
    color: #ffffff;
  }

  &:hover {
    transition: 0.3s;
    background-color: ${({ btnColor }) => darken('.06', buttonTheme(btnColor))};
  }
`
