import React from 'react'

import * as S from './styles'

function IndicatorCard({ name, color, number, filter }) {
  return (
    <>
      {!!filter ? (
        <S.Link
          to={{
            pathname: filter.pathname,
            search: filter.search,
            state: { from: filter.from },
          }}
          data-gtm-type="click"
          data-gtm-clicktype="card"
          data-gtm-name={name.toLowerCase()}
        >
          <S.Name>{name}</S.Name>
          <S.Number>{number}</S.Number>
          <S.Line color={color} />
        </S.Link>
      ) : (
        <S.Container>
          <S.Name>{name}</S.Name>
          <S.Number>{number}</S.Number>
          <S.Line color={color} />
        </S.Container>
      )}
    </>
  )
}

export default IndicatorCard
