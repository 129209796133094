import React, { useState, useRef } from 'react'

import { useOutsideClick } from 'hooks'
import { Icon } from 'components'

import {
  Container,
  Title,
  Main,
  Content,
  Hidden,
  Item,
  Animate,
} from './styles'

export default function Dropdown({ title, children }) {
  const dropdownRef = useRef()

  const [isShown, setShow] = useState(false)

  useOutsideClick(dropdownRef, () => {
    setShow(false)
  })

  return (
    <Container ref={dropdownRef}>
      <Content isShown={isShown}>
        <Main>
          <Item onClick={() => setShow(!isShown)}>
            <Title>{title}</Title>
            <Animate>
              <Icon name="chevron-down" size="24px" />
            </Animate>
          </Item>

          <Hidden isShown={isShown}>{children}</Hidden>
        </Main>
      </Content>
    </Container>
  )
}
