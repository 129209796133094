import { DateRange as DateRangePicker } from 'react-date-range'
import styled, { css } from 'styled-components'
import media from 'styled-media-query'
import * as Tip from 'components/Tip/styles'

export const Label = styled.label`
  ${({ theme }) => css`
    position: absolute;
    left: 16px;
    padding-top: calc(${theme.spacings.xxsmall} / 2);

    opacity: 0;
    visibility: hidden;

    transition: 0.3s;
    font-size: 10px;
    color: ${({ theme }) => theme.colors.primary};
    font-family: ${({ theme }) => theme.fonts.medium};
  `}
`

export const Content = styled.div`
  ${({ theme }) => css`
    height: 48px;

    ${media.greaterThan('huge')`
      height: 50px;
    `}
    display: grid;
    grid-template-columns: 1fr 18px;
    gap: calc(${theme.spacings.xxsmall} / 2);
    padding: 0 ${theme.spacings.xsmall};
  `}
`

export const Dropdown = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.black};
    border-radius: ${theme.borderRadius.medium};
    background-color: ${theme.colors.white};
    border: ${theme.border.hairline} solid ${theme.colors.primary};
    padding: ${theme.spacings.small};
    position: absolute;
    z-index: ${theme.layers.aside};
    margin-top: 8px;

    ${media.greaterThan('medium')`
      right: 0;
      transform: translateX(50%);
    `}

    ${media.lessThan('medium')`
      padding: ${theme.spacings.xsmall};
 
      
      left: -8px;
      transform: translateX(-50%);
    `}
  `}
`

export const DateRange = styled(DateRangePicker)`
  &.rdrMonthAndYearWrapper {
    align-items: center;
    height: 60px;
  }

  .rdrWeekDay {
    padding-right: 8px;
  }

  ${media.lessThan('small')`
      &.rdrCalendarWrapper {
        font-size: 10px;
      }

      &.rdrMonth {
        padding: none;
      }
    `}
`

export const ButtonContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: flex-end;

    gap: ${theme.spacings.small};
  `}
`

const DropdownModifiers = {
  open: (theme) => css`
    opacity: 1;
    visibility: visible;

    ${Label} {
      top: 3px;
      opacity: 1;
      visibility: visible;

      color: ${theme.colors.primary};
    }

    ${Input} {
      padding-top: ${theme.spacings.xxsmall};
      transition: 0.4s cubic-bezier(0.19, 1, 0.22, 1);
    }
  `,
  close: () => css`
    opacity: 0;
    visibility: hidden;
  `,
}

export const Container = styled.div`
  ${({ theme, isOpen, hasValue }) => css`
    position: relative;
    height: 50px;

    border-radius: ${theme.borderRadius.medium};
    border: ${theme.border.hairline} solid ${theme.colors.newGray[60]};

    ${hasValue &&
    css`
      ${Label} {
        top: 3px;
        opacity: 1;
        visibility: visible;
        color: ${theme.colors.gray.medium};

        transition: 0.2s cubic-bezier(0.895, 0.03, 0.685, 0.22);
      }

      ${isOpen &&
      css`
        border: ${theme.border.hairline} solid ${theme.colors.primary};

        ${Label} {
          color: ${theme.colors.primary};
        }
      `}

      ${Input} {
        padding-top: ${theme.spacings.xxsmall};
        transition: 0.4s cubic-bezier(0.19, 1, 0.22, 1);
      }
    `}

    ${Dropdown} {
      transition: transform 0.2s ease-in, opacity 0.3s ease-in;

      ${isOpen && DropdownModifiers.open(theme)}
      ${!isOpen && DropdownModifiers.close()}
    }
  `}
`

export const Input = styled.input`
  width: 100%;
  height: 95%;
  border: none;
  background: transparent;

  outline: none;

  color: ${({ theme }) => theme.colors.primary};
  transition: 0.3s;
  box-sizing: border-box;
  font-size: 12px;
  font-family: ${({ theme }) => theme.fonts.medium};

  &::placeholder {
    color: ${({ theme }) => theme.colors.gray.medium};
  }
`
export const IconWrapper = styled.div`
  ${({ theme, isOpen }) => css`
    display: flex;
    align-items: center;
    justify-content: center;

    > svg {
      width: 18px;
      height: 18px;

      color: ${theme.colors.newGray[60]};
      ${isOpen &&
      css`
        color: ${theme.colors.primary};
      `}
    }

    ${Tip.Mark} {
      padding: 0;
    }
  `}
`
export const Error = styled.p`
  font-weight: 600;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.danger};
  font-family: ${({ theme }) => theme.fonts.bold};

  ${media.lessThan('medium')`
    font-size: 11px;
  `}
`
