import styled from 'styled-components'
import { grid, layout, space } from 'styled-system'

/**
 * Grid usage example: https://styled-system.com/api#grid-layout
 * Layout usage example: https://styled-system.com/api#layout
 * Space usage example: https://styled-system.com/getting-started#margin--padding
 */
export const Grid = styled.div`
  ${grid}
  ${layout}
  ${space}

  display: grid;
  align-self: ${({ alignSelf }) => alignSelf};
  justify-content: ${({ justifyContent }) => justifyContent};
  justify-items: ${({ justifyItems }) => justifyItems};
  align-items: ${({ alignItems }) => alignItems};
`
