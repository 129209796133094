import styled, { css } from 'styled-components'
import media from 'styled-media-query'
import { rem, rgba } from 'polished'
import Cleave from 'cleave.js/react'
import * as tipStyles from 'components/Tip/styles'

import { componentLoader } from 'styles/helpers'

export const Container = styled.div`
  position: relative;

  ${tipStyles.Mark} {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: -2px;
  }
`

export const Label = styled.label`
  position: absolute;
  top: 10px;
  left: 16px;
  padding-top: 5px;

  opacity: 0;
  visibility: hidden;

  transition: 0.3s;
  font-size: 10px;
  color: ${({ theme }) => theme.colors.primary};
  font-family: ${({ theme }) => theme.fonts.medium};
`

export const Value = styled(Cleave)`
  ${({ theme }) => css`
    all: unset;

    width: 100%;
    height: 100%;
    display: block;

    color: ${theme.colors.primary};
    transition: 0.3s;
    box-sizing: border-box;
    font-size: ${theme.fonts.sizes.xxsmall};
    font-family: ${theme.fonts.medium};

    &::placeholder {
      color: ${theme.colors.gray.medium};
    }
  `}
`

const FieldModifiers = {
  rightIcon: (theme) => css`
    > svg {
      width: 18px;
      height: 18px;
      color: ${theme.colors.newGray[60]};
    }
  `,
}

export const Field = styled.div`
  ${({ theme, rightIcon }) => css`
    position: relative;

    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 0 ${theme.spacings.xsmall};

    height: 48px;
    ${media.greaterThan('huge')`
      height: 50px;
    `}

    border-radius: ${theme.borderRadius.medium};

    border: ${theme.border.hairline} solid ${theme.colors.newGray[60]};

    ${({ readOnly }) =>
      readOnly &&
      css`
        border-color: ${({ theme }) => theme.colors.secondary};

        ${Label} {
          color: ${({ theme }) => rgba(theme.colors.secondary, 0.5)};
        }

        ${Value} {
          color: ${({ theme }) => theme.colors.secondary};
        }
      `}

    ${({ disabled }) =>
      disabled &&
      css`
        border-color: ${({ theme }) => theme.colors.gray.light};

        ${Label} {
          color: ${({ theme }) => rgba(theme.colors.gray.light, 0.5)};
        }

        ${Value} {
          color: ${({ theme }) => theme.colors.gray.medium};
        }
      `}

  ${({ focused }) =>
      focused &&
      css`
        ${Label} {
          top: 3px;

          opacity: 1;
          visibility: visible;
          color: ${({ theme }) => theme.colors.gray.medium};

          transition: 0.2s cubic-bezier(0.895, 0.03, 0.685, 0.22);
        }

        ${Value} {
          padding-top: ${theme.spacings.xxsmall};

          transition: 0.4s cubic-bezier(0.19, 1, 0.22, 1);
        }
      `}

  ${({ isLoading }) =>
      isLoading &&
      css`
        ${componentLoader({
          visible: true,
          primary: true,
          centeredRight: true,
        })}
      `}


      ${!!rightIcon && FieldModifiers.rightIcon(theme)}
  `}
`

export const Error = styled.p`
  position: absolute;
  bottom: -18px;
  left: 0;

  padding: 6px 20px 0;

  font-size: ${rem('12px')};
  font-family: ${({ theme }) => theme.fonts.medium};

  color: ${({ theme }) => theme.colors.danger};

  ${media.lessThan('small')`
    position: static;
    
    font-size: ${rem('10px')};
  `}
`
