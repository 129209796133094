import * as yup from 'yup'

export default yup.object().shape({
  marital: yup.string().required('Por favor selecione o estado civil'),
  motherName: yup.string().required('Por favor insira o nome da mãe'),
  defaultAddress: yup.object().shape({
    street: yup.string().required('Por favor insira a rua'),
    number: yup.string().required('Por favor insira o numero'),
    neighborhood: yup.string().required('Por favor insira o bairro'),
    city: yup.string().required('Por favor insira a cidade'),
    state: yup.string().required('Por favor insira o estado'),
  }),
  income: yup.string().required('Por favor insira a renda'),
  occupation: yup.string().required('Por favor insira a ocupação'),
  gender: yup.string().required('Por favor insira o genêro'),
  company: yup.string().when('occupation', {
    is: "3",
    otherwise: yup.string().required('Por favor insira a empresa'),
}),
})
