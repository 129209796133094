import styled, { css } from 'styled-components'
import media from 'styled-media-query'
import { rem } from 'polished'

export const Container = styled.div`
  position: fixed;
  width: 100vw;
  height: 0;
  top: 0;
  z-index: ${({ theme }) => theme.layers.modal};
  overflow: hidden;

  ${({ showModal }) =>
    showModal &&
    css`
      height: 100vh;
    `}
`
export const Overlay = styled.div`
  top: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  display: block;
  z-index: -1;
  transition: opacity 0.5s ease;
  visibility: hidden;

  ${({ showModal }) =>
    showModal &&
    css`
      opacity: 1;
      z-index: 2;
      visibility: visible;

      background-color: rgba(0, 0, 0, 0.75);
      border-radius: 0.25rem;
    `}
`

export const Content = styled.div`
  padding: ${rem('24px')};
  z-index: 3;
  position: fixed;
  max-height: 80vh;
  border-radius: ${rem('10px')};
  top: -120vh;
  right: 50%;
  transform: translate(50%, 0);
  background-color: #fff;
  transition: top 0.7s ease;
  visibility: hidden;

  ${media.lessThan('small')`
    width: 90%;
  `}

  ${({ showModal }) =>
    showModal &&
    css`
      top: ${rem('100px')};
      visibility: visible;
    `}

  p {
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    color: ${({ theme }) => theme.colors.gray.davys};

    margin-bottom: 1rem;
  }
`
export const Title = styled.div`
  margin-bottom: 1rem;

  h2 {
    font-family: ${({ theme }) => theme.fonts.bold};
    color: ${({ theme }) => theme.colors.gray.dark};

    ${media.lessThan('large')`
    font-size: ${rem('15px')};
  `}
  }
`
