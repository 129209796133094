import styled from 'styled-components'
import media from 'styled-media-query'
import { rem } from 'polished'
import { Link } from 'react-router-dom'
import { Button } from 'components'

export const Container = styled.div`
  padding: 40px 50px;

  ${media.lessThan('small')`
        padding: 30px 10px;
    `}
`

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${rem('18px')};
  margin-bottom: 2rem;
`
export const Title = styled.h1`
  font-size: ${rem('32px')};
  font-family: ${({ theme }) => theme.fonts.book};
  margin: 0;
`

export const Box = styled.div`
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  padding: 30px 20px;
  width: 100%;

  ${media.greaterThan('large')`
    width: 420px;
    margin: 0 auto;
    padding: 50px 30px;
  `}

  button {
    margin: 50px 0;
  }
`

export const Wrapper = styled.div`
  padding: 0 50px;

  display: flex;
  justify-content: center;
  align-items: center;

  ${media.lessThan('small')`
    padding: 0;
  `}
`

export const Type = styled.div`
  margin-bottom: 20px;
`

export const Subtitle = styled.h3`
  margin: 0;
  padding-bottom: 30px;

  font-size: ${rem('15px')};
  color: ${({ theme }) => theme.colors.gray.medium};

  ${media.lessThan('large')`
    font-size: ${rem('13px')};
  `}
`
export const CustomButton = styled(Button)`
  width: 100%;

  &:disabled {
    background-color: ${({ theme }) => theme.colors.gray.light};
    cursor: not-allowed;

    &:hover {
      background-color: ${({ theme }) => theme.colors.gray.light};
    }
  }
`

export const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.colors.gray.medium};
  font-size: ${rem('14px')};
  font-family: ${({ theme }) => theme.fonts.medium};
  text-decoration: none;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    text-decoration: underline;
  }
`
