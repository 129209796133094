import { createSlice } from '@reduxjs/toolkit'

const layout = createSlice({
  name: 'layout',

  initialState: {
    offcanvas: false,
    notification: {
      type: '',
      content: '',
      visible: false,
    },
  },

  reducers: {
    toggleOffcanvas: (state) => {
      state.offcanvas = !state.offcanvas
    },

    toggleNotification: (state, action) => {
      const { type, content, visible } = action.payload

      state.notification = {
        type,
        content,
        visible,
      }
    },
  },
})

export const { toggleOffcanvas, toggleNotification } = layout.actions

export default layout.reducer
