import React from 'react'
import { Icon } from 'components'

import { Button, ButtonContainer } from './styles'

export default function ScrollButton({ icon, action }) {
  return (
    <Button onClick={action}>
      <ButtonContainer>
        <Icon color={'#FFF'} size={'20px'} name={icon} />
      </ButtonContainer>
    </Button>
  )
}
