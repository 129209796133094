import styled from 'styled-components'
import media from 'styled-media-query'

export const Head = styled.div`
  margin-bottom: 60px;
  margin-top: 50px;

  ${media.lessThan('large')`
    margin-bottom: 30px;
    margin-top: 20px;
  `}
`

export const Content = styled.div`
  margin-bottom: 0;

  ${media.lessThan('large')`
    margin-bottom: 30px;
  `}

  h2,
  span {
    font-weight: 450;
    color: rgba(0, 0, 0, 0.5438);
  }

  h2 {
    font-size: 2.5rem;
    line-height: 2.8rem;
    letter-spacing: -0.01rem;
    text-transform: uppercase;
  }

  span {
    font-size: 1rem;
    line-height: 1.25rem;
  }
`
export const FlexButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;

  ${media.lessThan('large')`
    margin-top: 1rem;
    flex-direction: column;
    align-items: flex-end;
  `}
`
