import styled, { css } from 'styled-components'
import media from 'styled-media-query'
import Datepicker from 'react-datepicker'
import { rem } from 'polished'

export const Container = styled.div`
  position: relative;

  .react-datepicker-wrapper {
    width: 100%;
  }

  .react-datepicker__day--keyboard-selected {
    color: #fff;
    background-color: ${({ theme }) => theme.colors.primary};
  }

  .react-datepicker__close-icon {
    right: 6px;

    &:after {
      width: 16px;
      height: 16px;
      padding: 0;

      display: block;

      line-height: 16px;
      font-size: ${rem('14px')};
      background-color: ${({ theme }) => theme.colors.primary};
    }
  }
`

export const Date = styled(Datepicker)`
  all: unset;

  width: 100%;
  height: 44px;
  padding: 0 20px;

  border-radius: 8px;
  box-sizing: border-box;
  font-size: ${rem('12px')};
  color: ${({ theme }) => theme.colors.primary};
  font-family: ${({ theme }) => theme.fonts.medium};
  border: 2px solid ${({ theme }) => theme.colors.gray.medium};

  &::placeholder {
    color: ${({ theme }) => theme.colors.gray.medium};
  }

  ${({ selected }) =>
    selected &&
    css`
      padding-top: 10px;
    `}

  ${media.greaterThan('huge')`
    height: 50px;
    font-size: ${rem('14px')};
  `}

  ${media.lessThan('small')`
    font-size: ${rem('10px')};
  `}

  ${({ disabled }) =>
    disabled &&
    css`
      border-color: ${({ theme }) => theme.colors.gray.light};

      &::placeholder {
        color: ${({ theme }) => theme.colors.gray.light};
      }
    `}
`

export const Error = styled.p`
  position: absolute;
  bottom: -18px;
  left: 0;

  padding: 6px 20px 0;

  font-size: ${rem('12px')};
  font-family: ${({ theme }) => theme.fonts.medium};

  color: ${({ theme }) => theme.colors.danger};

  ${media.lessThan('small')`
    font-size: ${rem('8px')};
  `}
`

export const Label = styled.label`
  font-size: ${rem('8px')};
  line-height: 14px;
  position: absolute;
  color: ${({ theme }) => theme.colors.gray.neutral};
  font-family: ${({ theme }) => theme.fonts.medium};
  top: ${rem('15px')};
  left: ${rem('22px')};
  opacity: 0;
  transition: top 0.2s ease;
  overflow: hidden;

  ${media.greaterThan('huge')`
    font-size: ${rem('11px')};
  `}

  ${media.lessThan('small')`
    font-size: ${rem('6px')};
  `}

  ${({ selected }) =>
    selected &&
    css`
      top: ${rem('8px')};
      opacity: 1;
    `}
`
