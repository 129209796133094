import { css } from 'styled-components'
import media from 'styled-media-query'

export const componentLoader = ({
  centered,
  centeredRight,
  visible,
  primary,
}) => css`
  position: relative;

  &:after {
    width: 12px;
    height: 12px;
    display: block;

    content: '';
    border-radius: 30px;
    border: 1px solid transparent;
    border-top-color: transparent;
    border-right-color: transparent;
    animation: spinAround 500ms infinite linear;

    ${media.lessThan('small')`
      width: 10px;
      height: 10px;
    `}
  }

  ${
    centered &&
    css`
      &:after {
        position: absolute;
        top: 50%;
        left: 50%;

        margin-top: -6px;
        margin-left: -6px;

        ${media.lessThan('small')`
          margin-top: -5px;
          margin-left: -5px;
        `}
      }
    `
  }

  ${
    centeredRight &&
    css`
      &:after {
        position: absolute;
        top: 50%;
        right: 20px;

        margin-top: -6px;

        ${media.lessThan('small')`
          right: 10px;
          
          margin-top: -5px;
        `}
      }
    `
  }

  ${
    visible &&
    css`
      color: transparent;

      &:after {
        border-color: #fff;
        border-top-color: transparent;
        border-right-color: transparent;
      }
    `
  }

  ${
    primary &&
    css`
      &:after {
        border-color: ${({ theme }) => theme.colors.primary};
        border-top-color: transparent;
        border-right-color: transparent;
      }
    `
  }

  @keyframes spinAround {
    from {
      transform: rotate(0);
    }

    to {
      transform: rotate(360deg);
    }
  }
`
