import styled, { css } from 'styled-components'
import media from 'styled-media-query'

export const Container = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Content = styled.div`
  ${({ theme }) => css`
    max-width: 1440px;
    width: 100%;
    padding: ${theme.spacings.small};

    ${media.lessThan('medium')`
      padding-inline: ${theme.spacings.xsmall};
    `}
  `}
`

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const Title = styled.h1`
  ${({ theme }) => css`
    color: ${theme.colors.black};
    font-weight: 600;
    font-size: ${theme.fonts.sizes.huge};
    line-height: 1;
    margin: 0;
  `}
`

export const Alert = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.gray.light};
    font-size: ${theme.fonts.sizes.xsmall};
    line-height: 19px;
  `}
`
export const Form = styled.form`
  ${({ theme }) => css`
    margin-top: 40px;
    display: grid;
    gap: ${theme.spacings.medium};

    grid-template-areas:
      'inputs inputs'
      'inputs inputs'
      'description description'
      'button button';

    ${media.lessThan('medium')`
      grid-template-areas:
        'inputs'
        'description '
        'button';
    `}
  `}
`

export const InputWrapper = styled.div`
  ${({ theme }) => css`
    grid-area: inputs;
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    ${media.lessThan('medium')`
    grid-template-columns: 1fr;
  `}
    gap: ${theme.spacings.small};
  `}
`

export const DescriptionWrapper = styled.div`
  grid-area: description;
`

export const ButtonWrapper = styled.div`
  grid-area: button;
  display: flex;
  align-items: center;
  justify-content: space-between;

  > button {
    max-width: 200px;
  }
`
export const Fieldset = styled.fieldset`
  border: none;
  padding: 0;
  position: relative;
`

export const Label = styled.label`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: ${theme.fonts.sizes.small};
    line-height: 1.5;
    color: ${theme.colors.black};
    margin-bottom: ${theme.spacings.xxsmall};
  `}
`

export const Loader = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: ${({ theme }) => theme.layers.modal};

  &:before {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    width: 50px;
    height: 50px;

    content: '';
    background-image: url('/images/loader.svg');
    background-size: contain;
  }
`
