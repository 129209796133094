import styled, { css } from 'styled-components'
import media from 'styled-media-query'

export const SidebarLink = styled.div`
  ${({ theme, active }) => css`
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: ${theme.spacings.xsmall};

    font-weight: 600;
    font-size: ${theme.fonts.sizes.xsmall};
    line-height: 1.5;

    padding: ${theme.spacings.xsmall} ${theme.spacings.small};

    ${media.lessThan('huge')`
       padding: ${theme.spacings.xsmall} ${theme.spacings.xsmall};
    `}

    color: ${theme.colors.white};

    cursor: pointer;

    transition: background 0.3s ease-in-out, border-left 0.3s;

    ${active &&
    css`
      background: rgba(0, 0, 0, 0.05);
      border-left: 3px solid ${theme.colors.white};
      text-decoration: underline;
    `}

    &:hover {
      background: rgba(0, 0, 0, 0.05);
    }
  `}
`

export const SidebarLabel = styled.div``

const DropdownModifiers = {
  open: () => css`
    opacity: 1;
    pointer-events: visible;
  `,
  close: () => css`
    opacity: 0;
    pointer-events: none;
  `,
}

export const Dropdown = styled.div`
  ${({ isOpen }) => css`
    transition: opacity 0.3s ease-in-out;
    transition: transform 0.3s ease-in, opacity 0.3s;

    ${isOpen && DropdownModifiers.open()}
    ${!isOpen && DropdownModifiers.close()}
  `}
`

export const DropdownLabel = styled.button`
  ${({ theme, active }) => css`
    border: none;
    border-top: ${theme.border.thin} solid rgba(255, 255, 255, 0.25);
    background: rgba(0, 0, 0, 0.05);
    display: flex;
    align-items: center;
    cursor: pointer;
    width: 100%;


    color: ${theme.colors.white};

    font-weight: 600;
    font-size: ${theme.fonts.sizes.xxsmall};
    line-height: 1.5;
    padding: ${theme.spacings.xsmall} ${theme.spacings.medium}};


    transition: background 0.3s ease-in-out;

    &:hover {
      background: rgba(0, 0, 0, 0.15);
    }

    ${
      active &&
      css`
        background: rgba(0, 0, 0, 0.15);
      `
    }
  `}
`
