import React, { useState, useEffect } from 'react'
import { Redirect } from 'react-router-dom'
import { FormContext, useForm } from 'react-hook-form'

import { resetPassowrd } from 'services'

import { useAuthentication, useNotification } from 'hooks'

import { Input, Choice } from 'components'

import {
  Container,
  Box,
  Subtitle,
  Wrapper,
  CustomButton as Button,
  StyledLink,
  Type,
  TitleWrapper,
  Title,
} from './styles'

import validationSchema from './validation'

export default function ForgotPassword() {
  const forms = useForm({ validationSchema })
  const notification = useNotification()
  const { isAuthenticated } = useAuthentication()
  const dataLayer = window.dataLayer || []

  const [isLoading, setIsLoading] = useState(false)
  
  useEffect(() => {
    dataLayer.push({
      event: 'step_change',
      etapa: '/recuperar-senha',
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    forms.setValue([{ email: '' }, { susep: '' }])
  }, [forms.watch('type')]) //eslint-disable-line

  if (isAuthenticated)
    return (
      <Redirect
        to={{
          pathname: '/',
        }}
      />
    )

  const onSubmit = forms.handleSubmit(async (values) => {
    const params = {
      type: values.email ? 'employee' : 'broker',
      ...values,
    }

    sendEmailForResetPassword(params)
  })

  const sendEmailForResetPassword = async (params) => {
    setIsLoading(true)

    try {
      const { email } = await resetPassowrd(params)

      notification.open({
        type: 'success',
        content: `Nós enviamos ao e-mail ${email} um link para alterar a senha.`,
      })

      dataLayer.push({
        event: 'solicitacao_servico',
        nome_servico: params.type === 'broker' ? 'susep' : 'e-mail',
        susep: forms.watch('susep') || '',
        tipo_servico: 'enviar-',
        retorno: 'sucesso',
      })

    } catch (error) {
      notification.open({
        type: 'danger',
        content: error.message,
      })

      dataLayer.push({
        event: 'solicitacao_servico',
        nome_servico: params.type === 'broker' ? 'susep' : 'email',
        tipo_servico: 'enviar-',
        retorno: 'erro',
        descricao: error?.message,
        erro: {
          codigo: error?.status,
          servico: 'recuperação de senha',
        },
      })
    } finally {
      setIsLoading(false)
    }
  }
  return (
    <Container>
      <TitleWrapper>
        <Title>Recuperação de senha</Title>
      </TitleWrapper>

      <Wrapper>
        <Box>
          <Subtitle>Selecione o tipo de usuário</Subtitle>

          <FormContext {...forms}>
            <Type>
              <Choice
                name="type"
                label="Funcionário"
                type="radio"
                value="employee"
                checked
              />

              <Choice
                name="type"
                label="Corretor"
                type="radio"
                value="broker"
              />
            </Type>
            {forms.watch('type') === 'broker' ? (
              <Input
                name="susep"
                label="SUSEP"
                placeholder="Digite sua SUSEP"
                toUpperCase
              />
            ) : (
              <Input
                name="email"
                label="E-mail"
                placeholder="Digite seu e-mail"
              />
            )}

            <Button
              disabled={!(forms.watch('email') || forms.watch('susep'))}
              onClick={onSubmit}
              isLoading={isLoading}
            >
              Enviar
            </Button>
          </FormContext>

          <StyledLink
            to="/login"
            data-gtm-click="click"
            data-gtm-clicktype="link"
            data-gtm-name="voltar-para-login"
          >
            Voltar para login
          </StyledLink>
        </Box>
      </Wrapper>
    </Container>
  )
}

ForgotPassword.properties = {
  metatags: {
    title: 'Recuperação de senha',
  },
}
