import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import Icon from 'components/Icon'

import * as S from './styles'

const CLEAR_FILTER_TITLE = 'Todos'

function SubMenu({
  item,
  selectedDropdown,
  onSelectedDropdown,
  onToggleFilterMobile,
}) {
  const location = useLocation()
  const history = useHistory()
  const customersParams = new URLSearchParams(location.search)

  const activeStatus = customersParams.get('status')

  const activeDropdown = selectedDropdown === item.title

  const handleFilter = (value) => {
    customersParams.set('status', value)
    customersParams.set('page', 1)

    history.replace({
      pathname: location.pathname,
      search: customersParams.toString(),
    })
  }

  const clearFilter = () => {
    customersParams.delete('status')
    customersParams.set('page', 1)

    history.replace({
      pathname: location.pathname,
      search: customersParams.toString(),
    })

    !!onToggleFilterMobile && onToggleFilterMobile()
  }

  return (
    <>
      <S.SidebarLink
       data-gtm-click="click"
       data-gtm-clicktype="button"
       data-gtm-name={item.title.toLowerCase()}
        active={activeDropdown}
        onClick={() => {
          onSelectedDropdown((prevState) =>
            prevState === item.title ? null : item.title
          )
          item.title === CLEAR_FILTER_TITLE && clearFilter()
        }}
      >
        <S.SidebarLabel>{item.title}</S.SidebarLabel>
        {item?.links?.length && activeDropdown ? (
          <Icon name="chevron-up" size="24px" />
        ) : item.links?.length ? (
          <Icon name="chevron-down" size="24px" />
        ) : null}
      </S.SidebarLink>

      <S.Dropdown isOpen={activeDropdown}>
        {activeDropdown &&
          item.links?.map((link, index) => {
            const activeFilter = activeStatus === link.value

            return (
              <S.DropdownLabel
                type="button"
                data-gtm-type="click"
                data-gtm-clicktype="button"
                data-gtm-name={link.label.toLowerCase()}
                data-gtm-subname={item.title.toLowerCase()}
                key={index}
                active={activeFilter}
                onClick={() => {
                  !!onToggleFilterMobile && onToggleFilterMobile()
                  handleFilter(link.value)
                }}
              >
                {link.label}
              </S.DropdownLabel>
            )
          })}
      </S.Dropdown>
    </>
  )
}

export default SubMenu
