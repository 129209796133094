import React, { useEffect } from 'react'
import { FormContext, useForm } from 'react-hook-form'
import { useHistory, useParams } from 'react-router-dom'

import { Button } from 'components'
import { Editor, Input, InputSelect } from '../components'
import validationSchema from '../Create/validation'

import {
  useGetOneNews,
  useTagsNews,
  useUpdateNewsMutation,
} from 'services/hooks/News'

import { useNotification } from 'hooks'
import { queryClient } from 'services/queryClient'
import * as S from '../Create/styles'
function EditNewsletter() {
  const { id } = useParams()
  const history = useHistory()
  const notification = useNotification()

  const { data: tagOptions, isLoading } = useTagsNews()
  const edit = useGetOneNews(id)

  const { handleSubmit, reset, ...form } = useForm({
    validationSchema,
  })

  useEffect(() => {
    reset(edit.data)
  }, [edit.data, reset])

  const updateNews = useUpdateNewsMutation({
    onSuccess: () => {
      notification.open({
        type: 'success',
        content: `Novidade alterada com sucesso!`,
      })

      queryClient.invalidateQueries('last_news')
      queryClient.invalidateQueries(['news', id])
      history.push('/novidades')
    },
    onError: () => {
      notification.open({
        type: 'danger',
        content: `Ocorreu um erro ao alterar sua Novidade, por favor tente novamente.`,
      })
    },
  })

  const onSubmit = (values) => {
    const data = {
      title: values.title,
      subTitle: values?.subTitle,
      content: values.content,
      tags: [values.tags.value],
    }

    updateNews.mutate({
      id,
      data,
    })
  }

  return (
    <>
      {edit.isLoading || edit.isFetching ? (
        <S.Loader />
      ) : (
        <S.Container>
          <S.Content>
            <S.Header>
              <S.Title>Editar novidade</S.Title>
              <S.Alert>* Campos obrigatórios</S.Alert>
            </S.Header>

            <FormContext {...form}>
              <S.Form onSubmit={handleSubmit(onSubmit)}>
                <S.InputWrapper>
                  <S.Fieldset>
                    <S.Label htmlFor="title">Título da publicação*</S.Label>
                    <Input name="title" placeholder="Escreva aqui" />
                  </S.Fieldset>

                  <S.Fieldset>
                    <S.Label htmlFor="subTitle">
                      Subtítulo da publicação
                    </S.Label>
                    <Input name="subTitle" placeholder="Escreva aqui" />
                  </S.Fieldset>

                  <S.Fieldset>
                    <S.Label htmlFor="tags">Categoria*</S.Label>
                    <InputSelect
                      name="tags"
                      options={tagOptions}
                      placeholder="Categoria"
                      isLoading={isLoading}
                    />
                  </S.Fieldset>
                </S.InputWrapper>

                {!edit.isLoading && (
                  <S.DescriptionWrapper>
                    <S.Fieldset>
                      <S.Label htmlFor="content">Descrição*</S.Label>
                      <Editor name="content" initialValue={edit.data.content} />
                    </S.Fieldset>
                  </S.DescriptionWrapper>
                )}

                <S.ButtonWrapper>
                  <Button
                    type="button"
                    color="gray"
                    onClick={() => {
                      history.goBack()
                    }}
                  >
                    Voltar
                  </Button>
                  <Button type="submit" isLoading={updateNews.isLoading}>
                    Publicar
                  </Button>
                </S.ButtonWrapper>
              </S.Form>
            </FormContext>
          </S.Content>
        </S.Container>
      )}
    </>
  )
}

export default EditNewsletter

EditNewsletter.properties = {
  metatags: {
    title: 'Editar novidade',
  },
}
