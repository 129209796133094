import styled, { css } from 'styled-components'
import styledMap from 'styled-map'
import { rem } from 'polished'
import media from 'styled-media-query'

export const Main = styled.main`
  ${({ theme }) => css`
    padding: 0 ${theme.spacings.small};
  `}
`

export const Container = styled.div`
  ${({ theme }) => css`
    width: 100%;
    max-width: 1200px;
    margin: ${theme.spacings.large} auto;

    background-color: ${theme.colors.white};
    box-shadow: -4px 5px 29px 24px rgba(0, 0, 0, 0.05);
    border-radius: ${theme.borderRadius.large};
    transition: 0.6s;

    &:hover {
      box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.25);
      transition: 0.3s;
    }
  `}
`

export const Message = styled.p`
  ${({ theme }) => css`
    margin-top: ${theme.spacings.medium};

    text-align: center;
    font-size: ${rem('13px')};
    font-family: ${theme.fonts.bold};
    color: ${styledMap('color', {
      ...theme.colors,
      gray: theme.colors.gray.medium,
    })};

    ${media.lessThan('large')`
      font-size: ${rem('11px')};
    `}
  `}
`

export const Box = styled.div`
  ${({ theme }) => css`
    width: 100%;
    padding: ${theme.spacings.medium};

    display: block;

    ${media.lessThan('large')`
      padding: ${theme.spacings.small};
    `}
  `}
`

export const Title = styled.div`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacings.medium};

    font-family: ${theme.fonts.bold};
    color: ${theme.colors.gray.dark};
    font-size: ${rem('32px')};
    text-align: center;

    ${media.lessThan('large')`
    font-size: ${rem('20px')};
  `}
  `}
`

export const Subtitle = styled.h4`
  ${({ theme }) => css`
    color: ${theme.colors.gray.dark};

    font-weight: 600;
    font-size: ${theme.fonts.sizes.small};
    line-height: 1;
  `}
`

export const Wrapper = styled.div`
  ${({ theme }) => css`
    margin-top: ${theme.spacings.xxlarge};
    width: 100%;
    display: flex;
    gap: ${theme.spacings.small};
    flex-direction: column;
  `}
`

export const CardContainer = styled.div`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: ${theme.spacings.medium};

    ${media.lessThan('medium')`
      grid-template-columns: repeat(2, 1fr);
    `}

    ${media.lessThan('small')`
      grid-template-columns: repeat(1, 1fr);
    `}
  `}
`

export const Filters = styled.div`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: repeat(3, 1fr);

    ${media.lessThan('medium')`
    grid-template-columns: repeat(1, 1fr);
  `}
    gap: ${theme.spacings.small};
  `}
`
