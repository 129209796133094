import styled from 'styled-components'
import media from 'styled-media-query'
import { rem } from 'polished'

export const TableContainer = styled.div`
  margin-top: ${rem('24px')};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px 2px;

  ${media.lessThan('small')`
    overflow-x: auto;
    overflow-y: hidden;

    > table {
      width: ${rem('900px')};;
    }
  `}
`
export const Error = styled.span`
  font-size: 1rem;
  font-family: ${({ theme }) => theme.fonts.medium};

  color: ${({ theme }) => theme.colors.danger};
  margin-top: 1.5rem;
`
export const Label = styled.span`
  color: ${({ theme }) => theme.colors.info};
  font-family: ${({ theme }) => theme.fonts.bold};
  font-size: 14px;
`
