import styled, { css } from 'styled-components'
import media from 'styled-media-query'

export const Title = styled.div`
  ${({ theme }) => css`
    position: relative;
    color: ${theme.colors.white};
    display: flex;
    align-items: center;

    z-index: ${theme.layers.aside};
    cursor: pointer;
  `}
`

export const Content = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    background: ${theme.colors.white};
    color: ${theme.colors.gray.dark};
    z-index: ${theme.layers.aside};

    background: ${theme.colors.side};
    border-radius: ${theme.borderRadius.large};
    min-width: 200px;
    width: 100%;

    position: absolute;
    top: 0;
    right: 0;
  `}
`

const wrapperModifiers = {
  open: () => css`
    opacity: 1;
    pointer-events: auto;
    transform: translateY(0);
  `,
  close: () => css`
    opacity: 0;
    pointer-events: none;
    transform: translateY(-2rem);
  `,
}

export const Wrapper = styled.div`
  ${({ theme, isOpen }) => css`
    position: relative;
    width: max-content;

    ${Content},
    ${Overlay} {
      transition: transform 0.2s ease-in, opacity 0.3s;

      ${isOpen && wrapperModifiers.open()}
      ${!isOpen && wrapperModifiers.close()}
    }
  `}
`
export const Overlay = styled.div`
  ${({ theme }) => css`
    position: fixed;
    inset: 0;
    z-index: calc(${theme.layers.aside} - 10);
  `}
`

export const FilterButton = styled.button`
  ${({ theme }) => css`
    justify-self: end;
    border-radius: ${theme.borderRadius.pill};
    background: ${theme.colors.side};
    border: none;

    padding: ${theme.spacings.xsmall} ${theme.spacings.medium};
    color: ${theme.colors.white};

    display: flex;
    align-items: center;
    font-weight: 600;
    cursor: pointer;
    gap: 80px;

    font-size: ${theme.fonts.sizes.xsmall};
    line-height: 1;

    svg {
      width: 18px;
      height: 18px;
    }

    ${media.lessThan('medium')`
      gap: ${theme.spacings.xsmall};

      padding: ${theme.spacings.xxsmall} ${theme.spacings.xsmall};
      font-weight: normal;
      font-size: ${theme.fonts.sizes.xxsmall};
    `}
  `}
`

export const DropdownContent = styled.div`
  ${({ theme }) => css`
    position: relative;
    padding: ${theme.spacings.small} 0;
  `}
`

export const DropdownHeader = styled.header`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    gap: ${theme.spacings.small};
    margin: ${theme.spacings.xsmall} ${theme.spacings.small};

    color: ${theme.colors.white};

    > span {
      font-weight: 600;
    }

    > svg {
      color: ${theme.colors.white};
      width: 16px;
      height: 16px;
    }
  `}
`

export const CloseIcon = styled.div`
  ${({ theme }) => css`
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;

    > svg {
      color: ${theme.colors.white};
    }
  `}
`
export const NavItems = styled.ul`
  display: flex;
  flex-direction: column;
  list-style: none;
`

export const Items = styled.li`
  ${({ theme, activeFilter }) => css`
    cursor: pointer;

    padding: ${theme.spacings.xsmall} ${theme.spacings.small};
    color: ${theme.colors.white};
    font-weight: 600px;

    &:hover {
      border-top: 1px solid rgba(255, 255, 255, 0.25);
      background: rgba(0, 0, 0, 0.05);
      border-left: 3px solid ${theme.colors.white};
      text-decoration: underline;
    }

    ${activeFilter &&
    css`
      border-top: 1px solid rgba(255, 255, 255, 0.25);
      background: rgba(0, 0, 0, 0.05);
      border-left: 3px solid ${theme.colors.white};
      text-decoration: underline;
    `}
  `}
`

export const Animate = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  animation: loading 2s linear infinite;

  @keyframes loading {
    0% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`
