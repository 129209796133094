import React from 'react'
import { CheckboxSelect } from 'components'
import { PERIOD_OPTIONS, STATE_OPTIONS, USER_ROLES } from 'helpers'
import { useCurrentUser } from 'hooks'
import {
  useGetVehiclesCategorys,
} from 'services/hooks/prices'

import * as S from './styles'

function Filters({
  defaultState,
  defaultMonth,
  selected,
  handleFilters,
  clearFilters,
  params,
  onSubmit,
}) {
  const { role } = useCurrentUser()

  const { data: categoryOptions, isLoading: categoryIsLoading } =
    useGetVehiclesCategorys(params)

  return (
    <S.Filters
      data-gtm-type="form"
      data-gtm-name="buscar-por"
      isAdmin={role === USER_ROLES.ADMINISTRATOR}
      onSubmit={onSubmit}
    >
      <CheckboxSelect
        data-testid="buscar-por-state"
        name="state"
        options={STATE_OPTIONS}
        placeholder="Estado"
        selected={selected.state}
        onChange={({ name, selected }) => handleFilters(name, selected)}
      />
      <CheckboxSelect
        name="months"
        options={PERIOD_OPTIONS}
        placeholder="Período"
        selected={selected.months}
        onChange={({ name, selected }) => handleFilters(name, selected)}
      />
      <CheckboxSelect
        name="category"
        options={categoryOptions}
        placeholder="Categoria"
        selected={selected.category}
        onChange={({ name, selected }) => handleFilters(name, selected)}
        isLoading={categoryIsLoading}
      />
      <S.Button
        disabled={!selected.months && !selected.state && !selected.category}
        color="primary"
        data-testid="botão-buscar"
        type="submit"
      >
        Buscar
      </S.Button>

      <S.FilterButton>
        {(selected.state?.value !== defaultState.value ||
          selected.months?.value !== defaultMonth.value ||
          selected.category) && (
          <button onClick={clearFilters} data-testid="clear_filter">
            Limpar filtros
          </button>
        )}
      </S.FilterButton>
    </S.Filters>
  )
}

export default Filters
